import React from "react";
import ReactApexChart from "react-apexcharts";

const SubscriptionChartByWeek = ({graphWeekData}) => {
  const options = {
    series: [
      {
        name: "Total Paid",
        data: graphWeekData?.weekBillingUsage,
      },
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "32%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: graphWeekData?.weekdays,
    },
    fill: {
      opacity: 1,
      colors: ["#104a77f0"]
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `$${val}`;
        },
      },
    },
  };

  return (
    <div className="graph__title">
      <div id="chart">
        <ReactApexChart
          options={options}
          series={options.series}
          type="bar"
          height={300}
        />
      </div>
    </div>
  );
};

export default SubscriptionChartByWeek;
