import React, { useEffect, useRef, useState } from "react";
import ConsultantLogConsole from "./ConsultantLogConsole";
import ReactDOMServer from "react-dom/server";
import {
  downloadTextFile,
  handleHTMLFileDownload,
} from "../../../Utils/UniversalFunction";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { NavLink } from "react-router-dom";

const ConsultantLogMainConsole = ({
  width,
  isFullScreen,
  setIsFullScreen,
  editorFullMode,
  copyToClipboard,
  codeLogs,
  copy,
  editorTheme
}) => {
  const [fontSize, setFontSize] = useState(14);

  function fontIncrease() {
    setFontSize((prevFontSize) => prevFontSize + 1);
  }

  function fontDecrease() {
    setFontSize((prevFontSize) => Math.max(prevFontSize - 1, 5));
  }

  const downloadOutput = async (type) => {
    let output = ReactDOMServer.renderToString(
      <span style={{ fontSize: fontSize }}>
        {codeLogs?.length === 0
          ? "No Logs"
          : codeLogs?.map((item, index) => (
              <div
                className={
                  item.type === "error"
                    ? "logsTextErrorColor"
                    : "logsTextSuccessColor"
                }
              >
                <b
                  style={{
                    fontSize: fontSize - 3,
                    textTransform: "capitalize",
                  }}
                >
                  "{item?.functionName}" Function Log:
                </b>{" "}
                <i>
                  <SyntaxHighlighter key={index} style={docco}>
                    {item?.result}
                  </SyntaxHighlighter>
                </i>
              </div>
            ))}
      </span>
    );
    if (type === "html") {
      handleHTMLFileDownload(output, "example");
    } else if (type === "text") {
      let output = ReactDOMServer.renderToString(
        codeLogs?.map((item) => (
          <>
            {item?.functionName} Function Log: {"\n" + item?.result + "\n"}
          </>
        ))
      );
      downloadTextFile(output, "example");
    } else {
      let output = ReactDOMServer.renderToString(
        codeLogs?.map((item) => (
          <>
            {item?.functionName} Function Log: {"\n" + item?.result + "\n"}
          </>
        ))
      );
      copyToClipboard(output, "logs");
    }
  };

  const ProfileRef = useRef(null);
  const [profileActive, setProfileActive] = useState(false);
  const toggleProfile = () => {
    setProfileActive(!profileActive);
  };
  useEffect(() => {
    const handleClickProfileMenuOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setProfileActive(false);
      }
    };
    document.addEventListener("click", handleClickProfileMenuOutside, true);

    return () => {
      document.removeEventListener(
        "click",
        handleClickProfileMenuOutside,
        true
      );
    };
  }, []);

  return (
    <>
      <div
        className={`p-0 output__section_widget ${!editorFullMode ? "" : "fullModeHeight"} ${!editorTheme ? "outputShadowEditorTheme" : "outputShadow"}`}
        style={{
          width: isFullScreen
            ? "98%"
            : width <= 1100
              ? 1500 - width + "px"
              : "400px",
        }}
      >
        <div
          className="border-bottom p-2"
          style={{ background: "#ebebeb" }}
        >
          <div className="hstack gap-2 header_tool justify-content-between OutputToolbar-Left">
            <p className="m-0">
              <b>Logs</b>
            </p>
            <div className="hstack gap-1">
              <i
                className="fa solid fa-plus"
                onClick={fontIncrease}
                title="Increase Font"
              ></i>
              <div className="vr"></div>
              <i
                className="fa solid fa-minus"
                onClick={fontDecrease}
                title="Decrease Font"
              ></i>
              <div className="vr"></div>
              <i
                className={
                  copy === "logs"
                    ? "fa-regular fa-circle-check"
                    : "fa-solid fa-copy"
                }
                onClick={() => downloadOutput("copy")}
                title={copy === "logs" ? "Copied" : "Copy Logs"}
              ></i>
              <div className="vr"></div>
              <div
                ref={ProfileRef}
                className={`profile position-relative ${
                  profileActive ? "active" : ""
                }`}
              >
                <div className="icon_wrap hstack gap-1" onClick={toggleProfile}>
                <i className={`fa-solid fa-download ${profileActive ? "activee__icon" : "" }`} title="Download Log"></i>
                </div>
                <div className="profile_dd shadow" style={{ right: 0 }}>
                  <ul className="profile_ul">
                    <li>
                      <NavLink
                        onClick={() => {
                          toggleProfile();
                          downloadOutput("html");
                        }}
                        title="Download Log as an HTML file"
                      >
                        <i className="fa-solid fa-file-code"></i> HTML
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          toggleProfile();
                          downloadOutput("text");
                        }}
                        title="Download Log as an TEXT file"
                      >
                        <i className="fa-solid fa-file-lines"></i> TEXT
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="vr"></div>
              <i
                className={
                  isFullScreen ? "fa-solid fa-minimize" : "fa-solid fa-maximize"
                }
                title={isFullScreen ? "Minimized Mode" : "Full Mode"}
                onClick={() => setIsFullScreen(!isFullScreen)}
              ></i>
            </div>
          </div>
        </div>
        <ConsultantLogConsole
          editorFullMode={editorFullMode}
          fontSize={fontSize}
          codeLogs={codeLogs}
        />
      </div>
    </>
  );
};

export default ConsultantLogMainConsole;
