import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const AnalyticsData = () => {
  return (
    <>
      <div className="graph__title mt-2">
        <p className="m-0">Orders & Packages </p>
      </div>
      <Row className="mt-2">
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>100</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Total Orders</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>50</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Complete Orders</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>30</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Pending Orders </p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>20</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Cancelled Orders</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>500</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Failed Orders</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted"><b>300</b></Card.Title>
                  <Card.Text>
                    <p>Total Purchased Packages</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>100</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Expired Packages </p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>100</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Unexpired Packages</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AnalyticsData;
