import React from "react";
import Moment from "react-moment";

const PackageInformationConsole = ({ packageInfo }) => {
  const informationTable = {
    overflow: "auto",
    padding: "1.5rem",
    paddingTop: "1rem",
    paddingBottom: "1rem"
  }

  const pooledTable = {
    padding: 10,
    background: "#fff",
    border: "1px solid var(--borderColor)",
    borderRadius: 8
  }

  const tableProerties = {
    margin: 0,
    border: "1px solid #d4d5d9",
    width: "100%"
  }

  const tableth = {
    padding: 6,
    border: "1px solid #d4d5d9",
    whiteSpace: "nowrap",
    width: "160px"
  }

  const tabletd = {
    padding: 6,
    border: "1px solid #d4d5d9",
    whiteSpace: "nowrap",
  }

  let activeTab = JSON.parse(localStorage.getItem("activeTabData")) || []
  let fileName = activeTab[0].split(" ")[0];
  return (
    <>
      <div style={informationTable}>
        <div style={pooledTable}>
          <div style={{ overflow: "auto", width: "100%" }}>
            <table style={tableProerties} >
              <thead>
                <tr>
                  <th colSpan={2} style={{ background: "#d4d5d9", padding: 5 }}>
                    <div style={{ textAlign: "center" }}>
                      <div>Header Information</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={tableth}>
                    <div>Developer Name</div>
                  </th>
                  <td style={tabletd}>
                    <div>{packageInfo?.fullName}</div>
                  </td>
                </tr>
                <tr>
                  <th style={tableth}>
                    <div>Package Name</div>
                  </th>
                  <td style={tabletd}>
                    <div>{packageInfo?.packageName}</div>
                  </td>
                </tr>
                <tr>
                  <th style={tableth}>
                    <div>File Name</div>
                  </th>
                  <td style={tabletd}>
                    <div>{fileName}</div>
                  </td>
                </tr>
                <tr>
                  <th style={tableth}>
                    <div>Current File Version</div>
                  </th>
                  <td style={tabletd}>
                    <div>v{packageInfo?.version}</div>
                  </td>
                </tr>
                <tr>
                  <th style={tableth}>
                    <div>Path</div>
                  </th>
                  <td style={tabletd}>
                    <div>
                      /packages/{packageInfo?.packageName}/files/{fileName}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th style={tableth}>
                    <div>Generated Date | Time</div>
                  </th>
                  <td style={tabletd}>
                    <div><Moment format="DD MMM YYYY | HH:mm">
                      {packageInfo?.generatedDateTime}
                    </Moment></div>
                  </td>
                </tr>
                <tr>
                  <th style={tableth}>
                    <div>Software Version</div>
                  </th>
                  <td style={tabletd}>
                    <div>v1.0.0</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageInformationConsole;
