import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import ViewRevenue from "./ViewRevenue";
import TableHeader from "../../../../Component/TableHeader/TableHeader";
import ExportRevenueData from "./ExportRevenueData";
import ReveneueChartData from "./ReveneueChartDataModal";
import Pagination from "../../../../Component/Paginatation/Pagination";

const Revenue = ({ tableRef, onMouseDown, editorFullMode }) => {
  const [openRevenue, setOpenRevenue] = useState(false);
  const [openChartRevenue, setOpenChartRevenue] = useState(false);
  const [exportRevenueData, setExportRevenueData] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const revenueData = [
    {
      _id: "1",
      name: "Package 1",
      price: 10,
      totalPurchased: 100,
      discount: 5,
      viewIcon: "👁️",
    },
    {
      _id: "2",
      name: "Package 2",
      price: 20,
      totalPurchased: 200,
      discount: 10,
      viewIcon: "👁️",
    },
    {
      _id: "3",
      name: "Package 3",
      price: 30,
      totalPurchased: 150,
      discount: 7,
      viewIcon: "👁️",
    },
    {
      _id: "4",
      name: "Package 2",
      price: 20,
      totalPurchased: 200,
      discount: 10,
      viewIcon: "👁️",
    },
    {
      _id: "5",
      name: "Package 3",
      price: 30,
      totalPurchased: 150,
      discount: 7,
      viewIcon: "👁️",
    },
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = revenueData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(revenueData?.length / itemsPerPage);

  return (
    <>
      <TableHeader
        ExportBtn
        handleExport={setExportRevenueData}
        ChartLine
        handleChart={setOpenChartRevenue}
        FilterSection
      />
      <div
        className="table_content overflow-auto mx-2"
        style={{
          maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
        }}
      >
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            className="m-0  custom-table"
            ref={tableRef}
            bordered
            striped
          >
            <thead className="thead-sticky">
              <tr>
                <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                  Sr.
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Packages Name</th>
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Price ($)</th>
                <th onMouseDown={(e) => onMouseDown(e, 3)}>Total Purchased</th>
                <th onMouseDown={(e) => onMouseDown(e, 4)}>Discount</th>
                <th onMouseDown={(e) => onMouseDown(e, 5)}>Total Revenue</th>
                <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 60 }}>
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {result?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1 + startIndex}</td>
                  <td>{item.name}</td>
                  <td>${item.price}</td>
                  <td>{item.totalPurchased}</td>
                  <td>${item.discount}</td>
                  <td>${item.price * item.totalPurchased - item.discount}</td>
                  <td>
                    <div className="">
                      <i
                        style={{ cursor: "pointer" }}
                        className="fa-solid fa-eye"
                        onClick={setOpenRevenue}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="mx-2">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={revenueData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>

      <ViewRevenue
        show={openRevenue}
        setShow={setOpenRevenue}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title={"Package Name(Price)"}
        submit={"Submit"}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
      />
     
      <ReveneueChartData
        show={openChartRevenue}
        setShow={setOpenChartRevenue}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title={"Revenue Analytics"}
        submit={"Submit"}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
      />
      <ExportRevenueData
        show={exportRevenueData}
        setShow={setExportRevenueData}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title={"Export Revenue Data "}
        submit={"Export"}
        disabled={disabled}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
      />
    </>
  );
};

export default Revenue;
