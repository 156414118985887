import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { consultantServices } from "../../../APIServices/Editor/consultantServices";
import ReactDOMServer from "react-dom/server";
import ConsultantOutputConsole from "./ConsultantOutputConsole";
import MAKETTESTOutput from "../GraphicalOutput/MAKETTESTOutput";
import DynamicTableRowColumn from "../GraphicalOutput/DynamicTableRowColumn";
import MAKESUMMARYOutput from "../GraphicalOutput/MAKESUMMARYOutput";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ProjectInformationConsole from "../HeaderInformation/ProjectInformationConsole";
import { handleHTMLFileDownload } from "../../../Utils/UniversalFunction";
import { NavLink } from "react-router-dom";
const ConsultantFullPageOutputConsole = ({ editorFullMode, fileId }) => {
  const [fontSize, setFontSize] = useState(13);
  const [projectInfo, setProjectInfo] = useState("");
  const [codeArrayOutputs, setCodeArrayOutputs] = useState([]);
  const getProjectFileOutputById = async () => {
    let data = await consultantServices?.getProjectFileOutputById(fileId);
    setCodeArrayOutputs(data?.output);
  };

  function fontIncrease() {
    setFontSize((prevFontSize) => prevFontSize + 1);
  }

  function fontDecrease() {
    setFontSize((prevFontSize) => Math.max(prevFontSize - 1, 5));
  }

  const getProjectInformation = async (fileId) => {
    let data = await consultantServices.getProjectInformation(fileId);
    setProjectInfo(data?.data);
  };
  const downloadOutput = async (type) => {
    let output = ReactDOMServer.renderToString(
      <>
        <ProjectInformationConsole projectInfo={projectInfo} />
        <>
          {codeArrayOutputs?.length === 0 ? (
            <div style={{ textAlign: "center", marginBottom: 10 }}>
              No Output
            </div>
          ) : (
            codeArrayOutputs?.map((item, index) => (
              <div key={index}>
                {item?.type === "output" ? (
                  <>
                    {item.functionName === "MAKE TTEST" ? (
                      <MAKETTESTOutput
                        codeOutputs={item.result}
                        functionName={item.functionName}
                      />
                    ) : item.functionName === "MAKE RANDOM" ||
                      item.functionName === "MAKE SORT" ||
                      item.functionName === "MAKE SWITCH" ||
                      item.functionName === "MAKE MERGE" ? (
                      <DynamicTableRowColumn
                        codeOutputs={item.result}
                        functionName={item.functionName}
                      />
                    ) : item.functionName === "MAKE SUMMARY" ? (
                      <MAKESUMMARYOutput
                        codeOutputs={item.result}
                        functionName={item.functionName}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <h6>"{item.functionName}" Function Output</h6>
                    </div>
                    <div className="mb-2">
                      <SyntaxHighlighter style={docco}>
                        {item.result}
                      </SyntaxHighlighter>
                    </div>
                  </>
                )}
              </div>
            ))
          )}
        </>
      </>
    );
    if (type === "html") {
      handleHTMLFileDownload(output, "example");
    } else {
    }
  };

  const ProfileRef = useRef(null);
  const [profileActive, setProfileActive] = useState(false);
  const toggleProfile = () => {
    setProfileActive(!profileActive);
  };
  useEffect(() => {
    const handleClickProfileMenuOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setProfileActive(false);
      }
    };
    document.addEventListener("click", handleClickProfileMenuOutside, true);

    return () => {
      document.removeEventListener(
        "click",
        handleClickProfileMenuOutside,
        true
      );
    };
  }, []);

  useEffect(() => {
    getProjectInformation(fileId);
    getProjectFileOutputById(fileId);
  }, [fileId]);
  return (
    <>
      <div className="border-bottom px-2 py-1">
        <div className="hstack justify-content-between">
          <div className="hstack gap-2 header_tool OutputToolbar-Left">
            <i
              className="fa solid fa-plus"
              onClick={fontIncrease}
              title="Increase Font"
            ></i>

            <div className="vr"></div>
            <i
              className="fa solid fa-minus"
              onClick={fontDecrease}
              title="Decrease Font"
            ></i>
            <div className="vr"></div>
            <div
              ref={ProfileRef}
              className={`profile position-relative ${
                profileActive ? "active" : ""
              }`}
            >
              <div className="icon_wrap hstack gap-1" onClick={toggleProfile}>
                <i
                  className={`fa-solid fa-download ${profileActive ? "activee__icon" : ""}`}
                  title="Download Log"
                ></i>
              </div>
              <div className="profile_dd shadow">
                <ul className="profile_ul">
                  <li>
                    <NavLink
                      onClick={() => {
                        toggleProfile();
                        downloadOutput("html");
                      }}
                      title="Download Log as an HTML file"
                    >
                      <i className="fa-solid fa-file-code"></i> HTML
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => {
                        toggleProfile();
                        downloadOutput("pdf");
                      }}
                      title="Download Log as an TEXT file"
                    >
                      <i className="fa-solid fa-file-lines"></i> TEXT
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="htmlContent">
        <ConsultantOutputConsole
          editorFullMode={editorFullMode}
          codeOutputs={codeArrayOutputs}
          projectInfo={projectInfo}
          fontSize={fontSize}
        />
      </span>
    </>
  );
};

export default ConsultantFullPageOutputConsole;
