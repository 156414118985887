import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const AllLogDataChart = ({ totalChartsLogs }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  let sum = Number(totalChartsLogs[0]) + Number(totalChartsLogs[1]);

  useEffect(() => {
    if (Array.isArray(totalChartsLogs)) {
      setChartData({
        series: totalChartsLogs,
        options: {
          chart: {
            type: "donut",
          },
          legend: {
            position: "bottom",
          },
          responsive: [
            {
              breakpoint: 580,
              options: {
                chart: {
                  width: 200,
                  height: 200,
                },
              },
            },
          ],
          labels: ["Login", "Logout"],
          colors: ["#042b49", "#38729e"],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return Math.round((val * sum) / 100);
            },
          },
          plotOptions: {
            pie: {
              donut: {
                size: "45%",
              },
            },
          },
        },
      });
    }
  }, [totalChartsLogs]);
  
  return (
    <div className="graph__title">
      <p>Log Status</p>
      <div id="chart">
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="donut"
          height="300"
        />
      </div>
    </div>
  );
};

export default AllLogDataChart;
