import config from "../config/config.json";
import saveAs from "file-saver";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const loginActivitiesServices = {
  // Login Activities
  getFailedLoginRecord,
  getTotalFailedLoginRecord,
  exportFailedLoginRecordMultiFormat,

  getLoginHistoryRecord,
  getTotalChartLoginHistoryRecord,
  exportLoginHistoryRecordMultiFormat,
};

// Get Failed Login Record Data
async function getFailedLoginRecord() {
  const response = await fetch(
    `${config.API_URL}getFailedLoginRecord`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Total Failed login Record
async function getTotalFailedLoginRecord() {
  const response = await fetch(
    `${config.API_URL}getTotalFailedLoginRecord`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export Failed Login Record in Multiformat
async function exportFailedLoginRecordMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportFailedLoginRecordMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}

// Get Login History Record Data
async function getLoginHistoryRecord() {
  const response = await fetch(
    `${config.API_URL}getLoginHistoryRecord`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Total Chart Login History Record
async function getTotalChartLoginHistoryRecord() {
  const response = await fetch(
    `${config.API_URL}getTotalChartLoginHistoryRecord`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export Login History Record in Multiformat
async function exportLoginHistoryRecordMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportLoginHistoryRecordMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}
