import React from "react";

const RecentActivity = () => {
  return (
    <>
      <div>
        <p
          className="p-2 m-0"
          style={{
            position: "sticky",
            top: "0",
            background: "#E3E6ED",
          }}
        >
          17 Days Ago
        </p>
        <div className="activity__section">
          <div>
            <ul className="p-0 m-0">
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0">
                  Sunmetrika Pvt Ltd has successfully created project ‘PROJ-04
                </p>
                <p className="m-0 p-0" style={{ color: "gray" }}>
                  20:23
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentActivity;
