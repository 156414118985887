import React from "react";
import { Table } from "react-bootstrap";

const LibraryHelpTable = ({
  tableRef,
  onMouseDown,
  dataList,
  columnList,
  columnType,
  pageNumber,
  itemsPerPage,
}) => {
  return (
    <>
      <div className="table-container tableLibrarySection">
        <Table
          id="resizable-table"
          className="m-0 tableLibrary custom-table"
          ref={tableRef}
          bordered
          striped
        >
          <thead className="thead-sticky">
            <tr>
              <th>Sr.</th>
              {columnList?.map((item, index) => (
                <th onMouseDown={(e) => onMouseDown(e, index + 1)}>
                  {columnType === "name" ? item.name : item.label}
                </th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataList?.map((item, index) => (
              <tr key={index}>
                <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
                {item?.map((element, key) => (
                  <td key={key}>{element.value}</td>
                ))}
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default LibraryHelpTable;
