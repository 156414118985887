import React, { useRef, useState } from "react";
import { Badge, Table } from "react-bootstrap";
import VerifiedUserDetailsinModal from "./VerifiedUserDetailsinModal.js.js";

const ReadmeDataDetails = ({
  packageName,
  statements,
  description,
  tags,
  version,
  validatorDetails,
}) => {
  const [showVerified, setShowVerified] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <div className="hstack gap-2 mb-3">
        <div style={{ fontSize: 18, fontWeight: 600 }}>{packageName}</div>
        <i
          className="fa-solid fa-circle-check"
          aria-hidden="true"
          onClick={() => setShowVerified(!showVerified)}
        ></i>
      </div>
      <div className="hstack gap-2 mb-3">
        <div className="versions_type">
          <span className="npm_set">VERSION</span>
          <span className="ver">v{version}</span>
        </div>
        <div className="versions_type">
          <span className="npm_set">LICENSE</span>
          <span className="artist">Galax® GAS</span>
        </div>
        <div className="versions_type">
          <span className="npm_set">VALIDATION</span>
          <span className="passing">
            {validatorDetails?.reviewStatus === "completed"
              ? "Passed"
              : "Pending"}
          </span>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      <div style={{ fontSize: 18 }}>
        <b>Statements</b>
      </div>
      <hr />
      {statements?.map((item, index) => (
        <div key={index}>
          <p style={{ fontSize: 14 }}>
            <b>{item?.statement}</b>
          </p>
          <p className="m-0">
            <b>Description</b>
          </p>
          <p>{item?.syntax}</p>
          <p className="m-0">
            <b>Syntex :</b>
          </p>
          <p>{item?.statementDescription};</p>
          {item.arguments?.length > 0 && (
            <>
              <p className="m-0">
                <b>Arguments :</b>
              </p>
              <Table bordered striped>
                <tbody>
                  {item.arguments?.map((data, key) => (
                    <tr key={key}>
                      <td>{data.title}</td>
                      <td>{data.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          <hr />
        </div>
      ))}
      <div className="taglist_type">
        {tags?.map((tag, key) => (
          <Badge key={key} className="tag">
            {tag}
          </Badge>
        ))}
      </div>
      <VerifiedUserDetailsinModal
        show={showVerified}
        setShow={setShowVerified}
        onStart={onStart}
        draggleRef={draggleRef}
        title={"Package Validator Details"}
        bounds={bounds}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
        validatorDetails={validatorDetails}
      />
    </>
  );
};
export default ReadmeDataDetails;
