import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import TableHeader from "../../../Component/TableHeader/TableHeader";
import Moment from "react-moment";
import MyorderChartDataModal from "./MyorderChartDataModal";
import Pagination from "../../../Component/Paginatation/Pagination";

const MyOrdersTable = ({ tableRef, onMouseDown, editorFullMode }) => {

  const [openChartMyorder, setOpenChartMyorder] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const transactions = [
    {
      _id: "1",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "completed",
      action: "INV001",
    },
    {
      _id: "2",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "pending",
      action: "INV001",
    },
    {
      _id: "3",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "cancelled",
      action: "INV001",
    },
    {
      _id: "4",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "failed",
      action: "INV001",
    },
    {
      _id: "5",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "completed",
      action: "INV001",
    },
    {
      _id: "6",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "completed",
      action: "INV001",
    },
    {
      _id: "7",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "completed",
      action: "INV001",
    },
    {
      _id: "8",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "completed",
      action: "INV001",
    },
    {
      _id: "9",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "completed",
      action: "INV001",
    },
    {
      _id: "10",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "completed",
      action: "INV001",
    },
    {
      _id: "11",
      orderId: "1284756",
      totalItems: "70",
      price: 100,
      transactionId: "T123",
      paymentId: "T124",
      transactionDateTime: "2024-04-25 10:30:00",
      status: "completed",
      action: "INV001",
    },
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = transactions?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(transactions?.length / itemsPerPage);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "#103c5e";
      case "completed":
        return "green";
      case "failed":
        return "red";
      case "cancelled":
        return "gray";
      default:
        return "inherit";
    }
  };
  return (
    <>
      <TableHeader
        ChartLine
        handleChart={setOpenChartMyorder}
        FilterSection />
      <div
        className="table_content overflow-auto mx-2"
        style={{
          maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
        }}
      >
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            className="m-0  custom-table"
            ref={tableRef}
            bordered
            striped
          >
            <thead className="thead-sticky">
              <tr>
                <th
                  onMouseDown={(e) => onMouseDown(e, 0)}
                  style={{ width: 40 }}
                >
                  Sr.
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Order Id</th>
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Total Items</th>
                <th onMouseDown={(e) => onMouseDown(e, 3)}>Price</th>
                <th onMouseDown={(e) => onMouseDown(e, 4)}>Transaction ID</th>
                <th onMouseDown={(e) => onMouseDown(e, 5)}>Payment ID</th>
                <th onMouseDown={(e) => onMouseDown(e, 6)}>
                  Transaction Date and Time
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 7)}>Status</th>
                <th
                  onMouseDown={(e) => onMouseDown(e, 8)}
                  style={{ width: 60 }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {result?.map((transaction, index) => (
                <tr key={index}>
                  <td>{startIndex + index + 1}</td>
                  <td>{transaction.orderId}</td>
                  <td>{transaction.totalItems}</td>
                  <td>{transaction.price}</td>
                  <td>{transaction.paymentId}</td>
                  <td>{transaction.transactionId}</td>
                  <td>
                    {" "}
                    <Moment format="DD MMM YYYY | HH:mm">
                      {transaction.transactionDateTime}
                    </Moment>
                  </td>
                  <td className="text-capitalize">
                    <div
                      style={{
                        color: getStatusColor(transaction.status),
                        fontWeight: 500,
                      }}
                    >
                      {transaction.status}
                    </div>
                  </td>
                  <td className="text-center">
                    <div>
                      <i className="fa-solid fa-download"></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="mx-2">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={transactions?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
      <MyorderChartDataModal
        show={openChartMyorder}
        setShow={setOpenChartMyorder}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title={"Revenue Analytics"}
        submit={"Submit"}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
      />
    </>
  );
};

export default MyOrdersTable;
