import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const PackagesSubFilePropertyTableData = ({ subFileTableProperties, packageFileType }) => {
  return (
    <div>
      <div className="table_content overflow-auto m-2">
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            className="m-0  custom-table"
            bordered
            striped
          >
            <thead className="thead-sticky">
              <tr>
                <th>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>File Name</td>
                <td>{subFileTableProperties?.name}{(packageFileType === "files" && packageFileType === ".glx") || (packageFileType === "data" && packageFileType === ".glxdat") || (packageFileType === "docs" && packageFileType === `.${subFileTableProperties?.extension}`)}</td>
              </tr>
              <tr>
                <td>Path</td>
                <td>/{packageFileType}/{subFileTableProperties?.name}{(packageFileType === "files" && packageFileType === ".glx") || (packageFileType === "data" && packageFileType === ".glxdat") || (packageFileType === "docs" && packageFileType === `.${subFileTableProperties?.extension}`)}</td>
              </tr>
              {packageFileType === "docs" &&
                <>
                  <tr>
                    <td>Size</td>
                    <td>{subFileTableProperties.size} KB</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>.{subFileTableProperties.extension}</td>
                  </tr>
                </>
              }
              {packageFileType === "files" && subFileTableProperties === "index" &&
                <tr>
                  <td>Version</td>
                  <td>{subFileTableProperties.version}.0.0</td>
                </tr>
              }
              <tr>
                <td>Created Date & Time</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">
                    {subFileTableProperties.createdAt}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Updated Date & Time</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">
                    {subFileTableProperties.updatedAt}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PackagesSubFilePropertyTableData;
