import React from "react";
import { Form, Table } from "react-bootstrap";

const EditorShortcutKeys = ({
  width,
  isFullScreen,
  setIsFullScreen,
  editorFullMode,
  editorTheme
}) => {
  return (
    <>
      <div
        className={`p-0 output__section_widget ${!editorFullMode ? "" : "fullModeHeight"} ${!editorTheme ? "outputShadowEditorTheme" : "outputShadow"}`}
        style={{
          width: isFullScreen
            ? "98%"
            : width <= 1100
              ? 1500 - width + "px"
              : "400px",
        }}
      >
        <div
          className="border-bottom p-2"
          style={{ background: "#ebebeb" }}
        >
          <div className="hstack gap-2 header_tool justify-content-between">
            <p className="m-0">
              <b>Shortcut keys</b>
            </p>
            <div className="hstack gap-1">
              <i
                className={
                  isFullScreen ? "fa-solid fa-minimize" : "fa-solid fa-maximize"
                }
                title={isFullScreen ? "Minimized Mode" : "Full Mode"}
                onClick={() => setIsFullScreen(!isFullScreen)}
              ></i>
            </div>
          </div>
        </div>
        <div
          className={`px-2 pt-2 ${!editorFullMode ? "output__section_widget_height" : "output__section_widget_full_height"}`}
        >
          <div style={{ overflow: "auto" }}>
            <Form.Label>Note : Shortcut Key's only for Editor</Form.Label>
            <Table striped className="table_content m-0">
              <tbody>
                <tr>
                  <td>Ctrl + X</td>
                  <td>Cut line (empty selection)</td>
                </tr>
                <tr>
                  <td>Ctrl + C</td>
                  <td>Copy line (empty selection)</td>
                </tr>
                <tr>
                  <td>Ctrl + D</td>
                  <td>Select Save Content</td>
                </tr>
                <tr>
                  <td>Ctrl + G</td>
                  <td>Go to Line</td>
                </tr>
                <tr>
                  <td>Ctrl + S</td>
                  <td>Save Code</td>
                </tr>
                <tr>
                  <td>Ctrl + R</td>
                  <td>Run Code</td>
                </tr>
                <tr>
                  <td>Ctrl + H</td>
                  <td>Replace</td>
                </tr>
                <tr>
                  <td>Ctrl + F</td>
                  <td>Find</td>
                </tr>
                <tr>
                  <td>Ctrl + Z</td>
                  <td>Undo</td>
                </tr>

                <tr>
                  <td>Ctrl + Y</td>
                  <td>Redo</td>
                </tr>

                <tr>
                  <td>Ctrl + +</td>
                  <td>Font Increase</td>
                </tr>
                <tr>
                  <td>Ctrl + -</td>
                  <td>Font Decrease</td>
                </tr>
                <tr>
                  <td>Ctrl + L </td>
                  <td>Lowercase</td>
                </tr>
                <tr>
                  <td>Ctrl + U</td>
                  <td>Uppercase</td>
                </tr>
                <tr>
                  <td>Ctrl + E</td>
                  <td>Clean</td>
                </tr>
                <tr>
                  <td>Ctrl + Shift + F </td>
                  <td>Code Formatting</td>
                </tr>
                <tr>
                  <td>Ctrl + Shift + Y</td>
                  <td>Undo</td>
                </tr>
                <tr>
                  <td>Ctrl + Shift + Z</td>
                  <td>Redo</td>
                </tr>

                <tr>
                  <td>ALT + L</td>
                  <td>View Logs</td>
                </tr>
                <tr>
                  <td>ALT + O</td>
                  <td>View Output</td>
                </tr>
                <tr>
                  <td>ALT + I</td>
                  <td>View Info</td>
                </tr>
                <tr>
                  <td>ALT + V</td>
                  <td>View Version</td>
                </tr>
                <tr>
                  <td>ALT + C</td>
                  <td>Compare Code</td>
                </tr>
                <tr>
                  <td>Alt + ↑</td>
                  <td>Move Line Up</td>
                </tr>
                <tr>
                  <td>Alt + ↓</td>
                  <td>Move Line Down</td>
                </tr>
                <tr>
                  <td>Esc</td>
                  <td>Stop Running Code</td>
                </tr>
                <tr>
                  <td>PgUp</td>
                  <td>Goto First Line</td>
                </tr>
                <tr>
                  <td>PgDn</td>
                  <td>Goto Last Line</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorShortcutKeys;
