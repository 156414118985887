import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form, Table } from "react-bootstrap";
import { handleResponse } from "../../../APIHelpers/Responses";
const ViewLibraryTableFileProperty = ({
  show,
  title,
  fileId,
  bounds,
  onStart,
  setShow,
  disabled,
  draggleRef,
  onMouseOut,
  columnList,
  onMouseOver,
  hideShowData,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const handleChange = (e, index) => {
    const { checked, name } = e.target;
    let updatedSelectedRows = [...selectedRows];
    if (name === "selectAll") {
      if (checked) {
        updatedSelectedRows = columnList?.map((item, index) => index);
      } else {
        updatedSelectedRows = [];
      }
    } else {
      if (checked) {
        if (!updatedSelectedRows?.includes(index)) {
          updatedSelectedRows?.push(index);
        }
      } else {
        updatedSelectedRows = updatedSelectedRows.filter(
          (item) => item !== index
        );
      }
    }
    setSelectedRows(updatedSelectedRows);
  };

  const libraryHideShowColumn = () => {
    let findData = hideShowData?.find((item) => item.fileId === fileId);
    let result = hideShowData?.filter((item) => !(item.fileId === fileId));
    let userData = {};
    userData.fileId = findData?.fileId;
    userData.index = selectedRows;
    result.push(userData);
    result.sort((a, b) => a.createdAt - b.createdAt);
    localStorage.setItem("hideShowLibraryData", JSON.stringify(result));
    let Message = {
      statusCode: 200,
      customMessage: "File Column been Successfully Updated.",
    };
    handleResponse(Message);
  };

  useEffect(() => {
    let findHideSHowData = hideShowData?.find((item) => item.fileId === fileId);
    if (findHideSHowData && findHideSHowData.index) {
      setSelectedRows(findHideSHowData.index);
    }
  }, [hideShowData]);

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={1200}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="table_content ">
          <div className="tablePropertiesHeight">
            <Table>
              <thead className="thead-sticky">
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      name="selectAll"
                      className="text-center"
                      title="Checked For Hide All Column"
                      checked={
                        selectedRows?.length === 0
                          ? false
                          : selectedRows?.length === columnList?.length
                      }
                      onChange={(e) => handleChange(e)}
                    />
                  </th>
                  <th style={{ width: "32%" }}>Column Name</th>
                  <th>Column Label</th>
                  <th>Type</th>
                  <th>Length</th>
                </tr>
              </thead>
              <tbody>
                {columnList?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        name={item?.name}
                        id={item?.id}
                        className="text-center"
                        title="Checked For Hide Column"
                        checked={selectedRows?.includes(index)}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.label}</td>
                    <td className="text-capitalize">{item.type}</td>
                    <td>{item.length}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="button_type footer_btn text-end px-3 pt-2">
            <button
              onClick={() => {
                libraryHideShowColumn();
                setShow();
              }}
              className="mb-2"
            >
              {"Update"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ViewLibraryTableFileProperty;
