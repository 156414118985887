import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import validator from "validator";
import AlreadyRegisteredMessage from "./AlreadyRegisteredMessage";
import ExpiredInvitationMessage from "./ExpiredInvitationMessage";
import InviteConfirmation from "./InviteConfirmation";
import { invitedDataServices } from "../../APIServices/invitedDataServices";
import { addressServices } from "../../APIServices/addressServices";

const InvitationForm = () => {
  const { token } = useParams();
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [gender, setGender] = useState("");

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [phone, setPhone] = useState("");
  const [office_phone, setOfficePhone] = useState("");

  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");

  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");

  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [statusCode, setStatusCode] = useState(false);

  const [validated, setValidated] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const history = useNavigate();

  useEffect(() => {
    getCountries();
    getInvitationData();
  }, []);

  const getInvitationData = async () => {
    let data = await invitedDataServices.getInvitationData(token);
    setEmail(data?.email);
    setStatus(data?.status);
  };

  const getCountries = async (e) => {
    let data = await addressServices.getCountries(e);
    setCountryLists(data.data);
  };

  const getStates = async (e) => {
    let data = await addressServices.getStates(e);
    setStateLists(data.data);
  };

  const getCities = async (e) => {
    let data = await addressServices.getCities(e);
    setCityLists(data.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      e.stopPropagation();

      if (validator.isEmail(email)) {
        let userData = {};
        userData.token = token;
        userData.firstName = firstName;
        userData.middleName = middleName;
        userData.lastName = lastName;
        userData.address = address;
        userData.email = email;
        userData.gender = gender;
        userData.phone = phone;
        userData.officePhone = office_phone;
        userData.cityId = city_id;
        userData.stateId = state_id;
        userData.countryId = country_id;
        userData.zipCode = postalCode;
        let data = await invitedDataServices.registeredUserByInvitation(
          userData,
          history
        );
        setStatusMessage(data);
        if (data?.statusCode === 200) {
          setFirstName("");
          setMiddleName("");
          setLastName("");
          setGender("");
          setEmail("");
          setPhone("");
          setAddress("");
          setOfficePhone("");
          setPostalCode("");
          setCityID("");
          setStateID("");
          setCountryID("");
          setStatusCode(true);
        }
      }
    }
    setValidated(true);
  };
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setError("");
    } else {
      setError("Invalid Email, Please Enter Valid Email!");
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setError("");
    } else {
      setError("Phone Number should have 10 digits ");
    }
  };
  const validateOffice_PhoneNumber = (office_phone) => {
    const office_PhonePattern = /^\d{10}$/;

    if (office_PhonePattern.test(office_phone)) {
      setError("");
    } else {
      setError("Office Phone Number should have 10 digits ");
    }
  };

  return (
    <>
      {statusCode ? (
        <InviteConfirmation />
      ) : status === "invited" ? (
        <>
          <Container>
            <div className="sign_url">
              <div className="form_details">
                <div
                  className="text-center mb-3"
                  style={{ color: "#103c5e", fontSize: "25px" }}
                >
                  Invitation Form
                </div>
                <Form>
                  <Row>
                    <Col md={7}>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="mb-3" md="6">
                          <Form.Label>
                            First Name<span>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="First name"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            className="place_hold"
                          />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" md="6">
                          <Form.Label>Middle Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Middle Name"
                            onChange={(e) => setMiddleName(e.target.value)}
                            value={middleName}
                            className="place_hold"
                          />
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" md="6">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            className="place_hold"
                          />
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" md="6">
                          <Form.Label>Gender</Form.Label>
                          <Form.Select
                            onChange={(e) => setGender(e.target.value)}
                            aria-label="Default select example"
                          >
                            <option>Select Gender</option>
                            <option selected={gender === "male"} value="male">
                              Male
                            </option>
                            <option
                              selected={gender === "female"}
                              value="female"
                            >
                              Female
                            </option>
                            <option selected={gender === "other"} value="other">
                              Other
                            </option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" md="12">
                          <Form.Label>
                            <Form.Label>
                              Address<span>*</span>
                            </Form.Label>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Enter Address"
                            style={{ height: "29px" }}
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                            className="place_hold"
                          />
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" md="6">
                          <Form.Label>
                            Country <span>*</span>
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => setCountryID(e.target.value)}
                            value={country_id}
                            onClick={(e) =>
                              getStates(e.target[e.target.selectedIndex].title)
                            }
                          >
                            <option>-Select-</option>
                            {countryLists.length > 0
                              ? countryLists.map((item, key) => (
                                  <option
                                    value={item._id}
                                    key={key}
                                    title={item.id}
                                  >
                                    {item.name}
                                  </option>
                                ))
                              : ""}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" md="6">
                          <Form.Label>
                            State<span>*</span>
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => setStateID(e.target.value)}
                            value={state_id}
                            onClick={(e) =>
                              getCities(e.target[e.target.selectedIndex].title)
                            }
                          >
                            <option>-Select-</option>
                            {stateLists.length > 0
                              ? stateLists.map((item, key) => (
                                  <option
                                    value={item._id}
                                    key={key}
                                    title={item.id}
                                  >
                                    {item.name}
                                  </option>
                                ))
                              : ""}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" md="6">
                          <Form.Label>
                            City<span>*</span>
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => setCityID(e.target.value)}
                            value={city_id}
                          >
                            <option>-Select-</option>
                            {cityLists.length > 0
                              ? cityLists.map((item, key) => (
                                  <option value={item._id} key={key}>
                                    {item.name}
                                  </option>
                                ))
                              : ""}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" md="6">
                          <Form.Label>
                            Postal Code
                            <span>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="number"
                            placeholder="Enter Postel Code"
                            onChange={(e) => setPostalCode(e.target.value)}
                            value={postalCode}
                          />
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <Form.Group as={Col} className="mb-3" md="12">
                          <Form.Label>
                            Mobile Number
                            <span>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="number"
                            placeholder="Enter Mobile Number"
                            onChange={(e) => {
                              setPhone(e.target.value);
                              validatePhoneNumber(e.target.value);
                            }}
                            value={phone}
                          />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" md="12">
                          <Form.Label>Office Phone</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter Office Phone"
                            onChange={(e) => {
                              setOfficePhone(e.target.value);
                              validateOffice_PhoneNumber(e.target.value);
                            }}
                            value={office_phone}
                          />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" md="12">
                          <Form.Label>
                            Email Id
                            <span>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="email"
                            placeholder="Enter Email Id"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              validateEmail(e.target.value);
                            }}
                            value={email}
                          />
                        </Form.Group>
                        <Col md={12}>
                          <div className="hstack sub_buttons">
                            <button
                              className="w-50"
                              style={{ fontSize: "14px" }}
                              disabled={
                                !token ||
                                !firstName ||
                                !address ||
                                !phone ||
                                !city_id ||
                                !state_id ||
                                !country_id ||
                                !postalCode
                              }
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between">
                    {error ? (
                      <p className="message" style={{ color: "red" }}>
                        {error}
                      </p>
                    ) : (
                      <p
                        className="message"
                        style={
                          statusMessage?.statusCode === 200
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        {statusMessage?.customMessage}
                      </p>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </Container>
        </>
      ) : status === "registered" ? (
        <AlreadyRegisteredMessage />
      ) : status === "cancelled" ? (
        <ExpiredInvitationMessage />
      ) : (
        <ExpiredInvitationMessage />
      )}
    </>
  );
};
export default InvitationForm;
