import React from "react";

const SignUpSuccess = () => {
  return (
    <>
      <div className="sign_url">
        <div className="form_details text-center w-50 py-5">
        <div className="mb-3">
            <i
              style={{ color: "green", fontSize: 50 }}
              class="fa-solid fa-circle-check"
              alt="Registration Successful"
            ></i>
          </div>
          <div className=" mb-2">
            <h6>Thanks your account has been successfully created.</h6>
            <p>
              Please check your inbox, your <b>login credentials</b> is sent on
              your <b>email</b>, which will be require for <b>login</b> your
              account
            </p>
          </div>
          <span style={{ fontSize: "14px" }}>
            Your Invitation link has been expired. Please contact "
            <b>Administrator</b>". After that check your mail, then you are able
            to Registration
          </span>
          <div className="sub_buttons pt-4">
            <a href="/login">
              <button>Login</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpSuccess;
