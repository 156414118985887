import React from "react";
import Moment from "react-moment";
const ChangesLog = ({ changelogs }) => {
  return (
    <>
      {changelogs?.map((item, index) => (
        <>
          <h6 key={index} className="mb-3">Version {item?.subVersion} – Released: <Moment format="DD MMM YYYY | HH:mm:ss" withTitle>
            {item.publishDateTime}
          </Moment></h6>
          <div className="log_change">
            {(item?.changelogs)?.map((data, key) => (
              <pre key={key}>- {data.type}: {data.changelog}</pre>
            ))}
          </div>
        </>
      ))}
    </>
  );
};
export default ChangesLog;
