import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form } from "react-bootstrap";
const AddOutputForConsultantExample = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  name,
  setName,
  description,
  setDescription,
  addExampleForPackageValidation
}) => {

  const addNewOutputExampleData = () => {
    addExampleForPackageValidation("subadmin")
    setShow(false)
  }

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Form.Label>
            Example Title<span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Example Title"
            autoFocus
          />
          <Form.Label className="mt-3">
            Description<span>*</span>
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Example Description"
            autoFocus
          />
        </div>
        <hr className="w-100 m-0" />
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            disabled={!name || !description}
            onClick={addNewOutputExampleData}
          >
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default AddOutputForConsultantExample;
