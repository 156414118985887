import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const PackagesPropertiesTableData = ({ tableRef, packagesPropertiesData }) => {
  return (
    <>
      <div className="table_content overflow-auto m-2">
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            className="m-0  custom-table"
            ref={tableRef}
            bordered
            striped
          >
            <thead className="thead-sticky">
              <tr>
                <th>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Folder Name</td>
                <td>{packagesPropertiesData?.name}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{packagesPropertiesData.description}</td>
              </tr>
              <tr>
                <td>Path</td>
                <td>/packages</td>
              </tr>
              <tr>
                <td>Version</td>
                <td>{packagesPropertiesData.version}</td>
              </tr>
              <tr>
                <td>Created Date & Time</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">
                    {packagesPropertiesData.createdAt}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Updated Date & Time</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">
                    {packagesPropertiesData.updatedAt}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default PackagesPropertiesTableData;
