import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { authServices } from "../../APIServices/authServices";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      e.stopPropagation();
      let userData = {};
      userData.email = email;
      userData.password = password;
      userData.type = "editor"
      userData.roles = ["consultant", "validator", "developer", "subadmin"];
      await authServices.login(userData);
    }
    setValidated(true);
  };

  return (
    <>
      <div className="log_url">
        <div className="forms_details">
          <div className="login_box">
            <div className="Welcome_to mb-3">
              <h2>Welcome To GAS</h2>
            </div>
            <div>
              <Form.Label>Email ID</Form.Label>
              <InputGroup className="mb-2">
                <InputGroup.Text id="basic-addon1">
                  <i className="fa-solid fa-envelope"></i>
                </InputGroup.Text>
                <Form.Control
                  required
                  placeholder="Enter your email id"
                  aria-label="email"
                  aria-describedby="basic-addon1"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
              <Form.Label>Password</Form.Label>
              <div className="position-relative">
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa-solid fa-lock"></i>
                  </InputGroup.Text>
                  <Form.Control
                    required
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    aria-label="password"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                <div
                  className="password_visible"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <i className="fa-regular fa-eye"></i>
                  ) : (
                    <i className="fa-regular fa-eye-slash"></i>
                  )}
                </div>
              </div>
              <div className="text-end mb-2">
                <NavLink
                  to={"/forget-password"}
                  className="cursor-pointer"
                  style={{
                    textDecoration: "none",
                    color: "#103c5e",
                    fontSize: "13px",
                  }}
                >
                  Forgot Password?
                </NavLink>
              </div>
              <NavLink to={"/otp-verification"}>
                <button
                  className="button_sign_log"
                  disabled={!email || !password}
                  validated={validated}
                  onClick={(e) => handleSubmit(e)}
                >
                  Login
                </button>
              </NavLink>
              <div className="mt-2 text-center">
                <p className="" style={{ color: "#103c5e" }}>
                  Don't have an account?
                  <NavLink to={"/sign-up"}>
                    <span
                      style={{
                        color: "#103c5e",
                        cursor: "pointer",
                        marginLeft: "3px",
                        fontWeight: "500",
                      }}
                    >
                      SignUp
                    </span>
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
