import React from "react";

const DynamicTableRowColumn = ({ codeOutputs, functionName }) => {
  let dataArray = JSON.parse(codeOutputs ? codeOutputs : "");
  const columnKeys = Object.keys(dataArray[0]);
  const rowValues = dataArray?.map((item) =>
    columnKeys.map((key) => String(item[key]))
  );

  const informationTable = {
    overflow: "auto",
    padding: "1.5rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  };

  const pooledTable = {
    padding: 10,
    background: "#fff",
    border: "1px solid var(--borderColor)",
    borderRadius: 8,
  };

  const tableProerties = {
    margin: 0,
    border: "1px solid #d4d5d9",
  };

  const tableth = {
    border: "1px solid var(--borderColor)",
    padding: "5px 10px",
    background: "rgb(212, 213, 217)"
  };

  const tabletd = {
    border: "1px solid #d4d5d9",
    padding: "0 10px",
  };

  const tableCenter = {
    display: "flex",
    placeContent: "space-around",
    width: "100%",
    overflow: "auto",
  };

  return (
    <>
      <div style={informationTable}>
        <div style={{textAlign: "center", marginBottom: 5}}>
          <div><b>"{functionName}" Function Output</b></div>
        </div>
        <div style={pooledTable}>
          <div style={tableCenter}>
            <table style={tableProerties}>
              <thead>
                <tr>
                  {columnKeys?.map((item, index) => (
                    <th style={tableth} key={index}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rowValues?.map((item, index) => (
                  <tr key={index}>
                    {item?.map((data, key) => (
                      <td style={tabletd} key={key}>
                        {data}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicTableRowColumn;
