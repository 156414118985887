import React from "react";
import ReactApexChart from "react-apexcharts";

const PayoutTrasactionChartByYear = ({ graphYearData }) => {
  const options = {
    series: [
      {
        name: "Total Paid",
        data: ["20", "15", "34", "23", "12", "18","43", "32","78", "50","19", "47"],
        // colors: ["red", "green", "blue"]
      },
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["jan", "Feb", "Mar", "Apr", "May", "June","July", "Aug", "Sept", "Octo", "Nav", "Dec",],
    },
    fill: {
      opacity: 1,
      colors: ["#104a77f0"]
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `$${val}`;
        },
      },
    },
  };

  return (
    <div className="graph__title">
      {/* <p><b>{title} </b></p> */}
      <div id="chart">
        <ReactApexChart
          options={options}
          series={options.series}
          type="bar"
          height={300}
        />
      </div>
    </div>
  );
};

export default PayoutTrasactionChartByYear;
