import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form } from "react-bootstrap";
import { packagesServices } from "../../../APIServices/Editor/packagesServices";
import Swal from "sweetalert2";

const PackageFunctionValidationForUpdate = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  draggleRef,
  onMouseOut,
  onMouseOver,
  openPackageFileById,
  packageId,
}) => {
  const [status, setStatus] = useState("");
  const [remark, setRemark] = useState("");

  const updatePackageFunctionValidationStatus = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Validated this Package?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, validate it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = packageId;
        userData.status = status;
        userData.remark = remark;
        let data = await packagesServices.updatePackageFunctionValidationStatus(userData);
        if (data?.statusCode === 200) {
          openPackageFileById(data?.subFolder);
          setStatus("")
          setRemark("")
          setShow(false)
        }
      }
    });
  }
  
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Form.Label>
            Validation Status<span>*</span>
          </Form.Label>
          <Form.Select
            onChange={(e) => setStatus(e.target.value)}
            aria-label="Default select example"
            style={{ fontSize: "13px" }}
          >
            <option>Select Validation Status</option>
            <option value="completed">Completed</option>
            <option value="rejected">Rejected</option>
          </Form.Select>
          <Form.Label>
            Remark<span>*</span>
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            placeholder="Enter Remark"
            autoFocus
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </div>
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            onClick={updatePackageFunctionValidationStatus}
            disabled={!status || !remark}
          >
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default PackageFunctionValidationForUpdate;
