import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Col, Form, Row } from "react-bootstrap";
import { authServices } from "../../../APIServices/authServices";
import TwoFactorAuthByApps from "./TwoFactorAuthByApps";
import TwoFactorAuthByEmail from "./TwoFactorAuthByEmail";

const TwofactorAuthData = ({
  show,
  Hide,
  setShow,
  onStart,
  draggleRef,
  bounds,
  onMouseOver,
  onMouseOut,
  disabled,
  title,
  roleType,
  emailTwoFA,
  appTwoFA,
  status,
  authTypeData,
  authType,
  getTwoFactorAuthType,
  setAuthType,
}) => {
  const [nextBy, setNextBy] = useState(false);
  const [modalSize, setModalSize] = useState(false);

  const setAuthenticationType = async () => {
    let userData = {};
    userData.authType = authType;
    let data = await authServices.twoFactorAuthType(userData);
    if (data?.statusCode === 200) {
      getTwoFactorAuthType();
      setNextBy(true);
    }
  };

  const hideModalData = () => {
    Hide();
    setNextBy(false);
  };

  let twoFAData = emailTwoFA || appTwoFA;
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        {!nextBy && !twoFAData ? (
          <div>
            <div className="common_css">
              <Form.Label>
                Authentication Type <span>*</span>
              </Form.Label>
              <Form.Select
                disabled={
                  roleType !== "consultant" ||
                  roleType !== "developer" ||
                  roleType !== "validator" ||
                  status
                }
                onChange={(e) => setAuthType(e.target.value)}
                aria-label="Default select example"
              >
                <option>Select Authentication Type</option>
                <option selected={authTypeData === "email"} value="email">
                  By Email
                </option>
                <option selected={authTypeData === "apps"} value="apps">
                  By Apps
                </option>
              </Form.Select>
            </div>
            <hr className="w-100 m-0" />
            <div className="button_type footer_btn text-end px-3 pt-2">
              {roleType === "consultant" ||
              roleType === "developer" ||
              roleType === "validator" ? (
                <button onClick={() => setNextBy(true)}>Next</button>
              ) : (
                <button onClick={() => setAuthenticationType()}>Next</button>
              )}
            </div>
          </div>
        ) : (
          <>
            {authTypeData === "apps" ? (
              <TwoFactorAuthByApps
                setNextBy={setNextBy}
                setModalSize={setModalSize}
                appTwoFA={appTwoFA}
                getTwoFactorAuthType={getTwoFactorAuthType}
              />
            ) : authTypeData === "email" ? (
              <TwoFactorAuthByEmail
                setNextBy={setNextBy}
                setModalSize={setModalSize}
                emailTwoFA={emailTwoFA}
                getTwoFactorAuthType={getTwoFactorAuthType}
              />
            ) : (
              ""
            )}
          </>
        )}
      </Modal>
    </>
  );
};
export default TwofactorAuthData;
