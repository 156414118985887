import React from "react";
import Header from "./Header";
import TopHeader from "./TopHeader";

const Navbar = ({ setShowSidebarMenu, roleType, userCurrentData, handleTabChange }) => {
  return (
    <>
      <div className="header-bg">
        <TopHeader roleType={roleType} userCurrentData={userCurrentData} />
        <hr className="m-0" />
        <Header roleType={roleType} setShowSidebarMenu={setShowSidebarMenu}  handleTabChange={handleTabChange}/>
      </div>
    </>
  );
};

export default Navbar;
