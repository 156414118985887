import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import VerifiedUserData from "./VerifiedUserDetailsinModal.js.js";
import Moment from "react-moment";

const VersionsDataDetails = ({ consultantPackageData }) => {
  const [showVerified, setShowVerified] = useState(false);
  const [validatorData, setValidatorData] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const viewValidatorDetails = (item) => {
    setShowVerified(true)
    setValidatorData(item)
  }

  return (
    <>
      <Table bordered striped style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>Versions</th>
            <th>Validated By</th>
            <th>Developer</th>
            <th>Publish Date & Time</th>
          </tr>
        </thead>
        <tbody>
          {consultantPackageData?.map((item, index) => (
            <tr key={index}>
              <td>
                v{item.version}
              </td>
              <td>
                {item?.validatorDetails?.companyName}
                <i
                  className="fa-solid fa-circle-info version_icons mx-2"
                  style={{cursor: "pointer"}}
                  title="Validator info"
                  onClick={() => viewValidatorDetails(item.validatorDetails)}
                ></i>
              </td>
              <td>{item.developerName}</td>
              <td><Moment format="DD MMM YYYY | HH:mm">
                {item?.publishDateTime}
              </Moment></td>
            </tr>
          ))}
        </tbody>
      </Table>
      <VerifiedUserData
        show={showVerified}
        setShow={setShowVerified}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
        validatorDetails={validatorData}
        title={"Validators Details"}
      />
    </>
  );
};
export default VersionsDataDetails;
