import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form, Spinner } from "react-bootstrap";
import { authServices } from "../../../APIServices/authServices";

const ChangePasswordDetails = ({
  show,
  setShow,
  onStart,
  draggleRef,
  bounds,
  onMouseOver,
  onMouseOut,
  disabled,
  title,
  submit,
}) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loader, setLoader] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const changePassword = async () => {
    let userData = {};
    setLoader(true);
    userData.currentPassword = currentPassword;
    userData.newPassword = newPassword;
    userData.repeatPassword = repeatPassword;
    let data = await authServices.changePassword(userData);
    if (data?.statusCode === 200) {
      resetInputField();
      setLoader(false);
    }
  };

  const [error, setError] = useState("");
  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;
    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };

  const resetInputField = () => {
    setCurrentPassword("");
    setNewPassword("");
    setRepeatPassword("");
  };
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5 style={{ fontWeight: "bold" }}>{title}</h5>
          </div>
        }
        width={450}
        open={show}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="add_sponsor_field">
          <div className="common_css">
            <div className="mb-3">
              <Form.Label>
                Current Password<span className="text-danger">*</span>
              </Form.Label>
              <div className="position-relative">
                <Form.Control
                  required
                  type={showPassword ? "text" : "password"}
                  name="currentPassword"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  value={currentPassword}
                  placeholder="Current password"
                  aria-label="password"
                  aria-describedby="basic-addon1"
                />
                <div
                  className="password_visible"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <i className="fa-regular fa-eye"></i>
                  ) : (
                    <i className="fa-regular fa-eye-slash"></i>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <Form.Label>
                New Password<span className="text-danger">*</span>
              </Form.Label>
              <div className="position-relative">
                <Form.Control
                  required
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    validatePassword(e.target.value);
                  }}
                  value={newPassword}
                  placeholder="New password"
                  aria-label="password"
                  aria-describedby="basic-addon1"
                />
                <div
                  className="password_visible"
                  onClick={toggleNewPasswordVisibility}
                >
                  {showNewPassword ? (
                    <i className="fa-regular fa-eye"></i>
                  ) : (
                    <i className="fa-regular fa-eye-slash"></i>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <Form.Label>
                Confirm Password<span className="text-danger">*</span>
              </Form.Label>
              <div className="position-relative">
                <Form.Control
                  required
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  value={repeatPassword}
                  placeholder="Confirm password"
                  aria-label="password"
                  aria-describedby="basic-addon1"
                />
                <div
                  className="password_visible"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? (
                    <i className="fa-regular fa-eye"></i>
                  ) : (
                    <i className="fa-regular fa-eye-slash"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr className="w-100 m-0" />
          <div className="button_type footer_btn text-end px-3 pt-2">
            <button
              disabled={!currentPassword || !newPassword || !repeatPassword || error}
              onClick={changePassword}>
              {loader ? (
                <Spinner
                  animation="border"
                  style={{ width: 13, height: 13 }}
                  className="me-1"
                />
              ) : null}
              {submit}</button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ChangePasswordDetails;
