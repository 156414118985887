import React, { useState } from "react";
import {Col, Form,InputGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
// import LoginImage from "../../image/LoginPage.gif";
import { authServices } from "../../APIServices/authServices";

const ResetPassword = () => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const resetPassword = async () => {
    let userData = {};
    userData.token = token;
    userData.newPassword = newPassword;
    userData.repeatPassword = repeatPassword;
    let data = await authServices.resetPassword(userData);
    if (data?.statusCode === 200) {
      resetInputField();
      window.location.href = "/";
    }
  };
  const [error, setError] = useState("");
  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;
    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };
  const resetInputField = () => {
    setNewPassword("");
    setRepeatPassword("");
  };
  return (
    <>
      <div className="log_url">
        <div className="forms_details">
          <div className="login_box">
            <div className="Welcome_to">
              <h2>Create New Password</h2>
              <p>
                Your new password must be different from all your previous used
                password.
              </p>
            </div>
            <div>
              <Form>
                <Form.Label>New Password</Form.Label>
                <InputGroup className="mb-4">
                  {/* <InputGroup.Text id="basic-addon1">
                  <i className="fa-solid fa-lock"></i>
                  </InputGroup.Text> */}
                  <div className="position-relative">
                    <Form.Control
                      required
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter New Password"
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        validatePassword(e.target.value);
                      }}
                      value={newPassword}
                    />
                    <div
                      className="passwordvisiability"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <i class="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i class="fa-regular fa-eye"></i>
                      )}
                    </div>
                  </div>
                  {!newPassword ? (
                    ""
                  ) : error ? (
                    <span
                      style={{
                        fontSize: 10,
                        color: "red",
                        fontWeight: 600,
                      }}
                    >
                      Password Must have 1 Capital Letter, 1 Small Letter, 1
                      Special Character with 8 Character
                    </span>
                  ) : (
                    <span
                      style={{
                        fontSize: 10,
                        color: "green",
                        fontWeight: 600,
                      }}
                    >
                      Strong Password
                    </span>
                  )}
                </InputGroup>
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup className="mb-4">
                  {/* <InputGroup.Text id="basic-addon1">
                  <i className="fa-solid fa-lock"></i>
                  </InputGroup.Text> */}
                  <div className="position-relative">
                    <Form.Control
                      required
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      value={repeatPassword}
                    />
                    <div
                      className="passwordvisiability"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? (
                        <i class="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i class="fa-regular fa-eye"></i>
                      )}
                    </div>
                  </div>
                  {newPassword === repeatPassword ? (
                    newPassword?.length === 0 ? (
                      ""
                    ) : (
                      <span
                        style={{
                          fontSize: 10,
                          color: "green",
                          fontWeight: 600,
                        }}
                      >
                        Password Match
                      </span>
                    )
                  ) : (
                    <span
                      style={{
                        fontSize: 10,
                        color: "red",
                        fontWeight: 600,
                      }}
                    >
                      Password Not Match
                    </span>
                  )}
                </InputGroup>
                <Form.Group as={Col} md="12">
                  <div className="hstack sub_buttons">
                    <button
                      onClick={resetInputField}
                    >
                      Reset
                    </button>
                    {error || newPassword !== repeatPassword ? (
                      <button>
                        {"Reset Password"}
                      </button>
                    ) : (
                      <button
                        onClick={resetPassword}
                      >
                        {"Reset Password"}
                      </button>
                    )}
                  </div>
                  <div className="text-end">
                    <p className="message">
                      <a href="/login">
                        <b>Back to Login</b>
                      </a>
                    </p>
                  </div>
                </Form.Group>
                {/* <NavLink to={"/login"}>
                <button className="button_sign_log">Reset Password</button>
                </NavLink> */}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
