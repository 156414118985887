import React, { useState } from "react";
import { Modal } from "antd";
import * as csv from "csvtojson";
import Draggable from "react-draggable";
import { Col, Form, Row } from "react-bootstrap";
import { handleResponse } from "../../../APIHelpers/Responses";

const LibraryUploadFile = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  libraryId,
  draggleRef,
  onMouseOut,
  onMouseOver,
}) => {
  let [name, setName] = useState("");
  let [label, setLabel] = useState("");
  let [fileName, setFileName] = useState("");
  let [mergedData, setMergedData] = useState([]);
  let [fileExtension, setFileExtension] = useState("");
  let [fileDescription, setFileDescription] = useState("");
  let [libraryFileList, setLibraryFileList] = useState(
    JSON.parse(localStorage.getItem("libraryFileList")) || []
  );
  let [hideShowLibraryData, setHideShowLibraryData] = useState(
    JSON.parse(localStorage.getItem("hideShowLibraryData")) || []
  );
  let [activeSection, setActiveSection] = useState("choose_file");

  const clearInputData = () => {
    setShow(!show);
    setName("");
    setLabel("");
    setFileName("");
    setMergedData([]);
    setFileExtension("");
    setFileDescription("");
    setLibraryFileList(
      JSON.parse(localStorage.getItem("libraryFileList")) || []
    );
    setHideShowLibraryData(
      JSON.parse(localStorage.getItem("hideShowLibraryData")) || []
    );
    setActiveSection("choose_file");
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const dataList = await csv().fromString(text);
      const natestedArray = dataList.map((obj) => Object.values(obj));
      const headerRow = Object.keys(dataList[0]);
      natestedArray.unshift(headerRow);
      setMergedData(natestedArray);
    };
    reader.readAsText(file);
  };

  const handleFileUploadSubmit = async () => {
    let result;
    let nameColumn;
    let labelColumn;
    if (!name && !label) {
      result = convertKeysToColumns(mergedData);
      nameColumn = Object.keys(result[0]);
      labelColumn = Object.keys(result[0]);
    } else if (!name && label) {
      result = convertDataonlyLabelorName(mergedData, label);
      nameColumn = Object.keys(result[label]);
      labelColumn = Object.keys(result[label]);
    } else if (name && !label) {
      result = convertDataonlyLabelorName(mergedData, name);
      nameColumn = Object.keys(result[name]);
      labelColumn = Object.keys(result[name]);
    } else {
      result = convertDataLabelandName(mergedData, name);
      nameColumn = Object.values(mergedData[name]);
      labelColumn = Object.values(mergedData[label]);
      if (Number(name) === 0 && Number(label) === 0) {
        result.splice(0, 1);
      } else if (Number(name) === 0 && Number(label) === 1) {
        result.splice(0, 1);
        result.splice(0, 1);
      } else if (Number(name) === 1 && Number(label) === 0) {
        result.splice(0, 1);
        result.splice(0, 1);
      } else if (Number(name) === 1 && Number(label) === 1) {
        result.splice(1, 1);
      }
    }

    let newArray = result?.map((item) => Object.values(item));
    const classifiedTypeValueData = newArray?.map((innerArray) =>
      innerArray?.map(extractCheckValueType)
    );

    const classifiedTypeData = newArray?.map((innerArray) =>
      innerArray?.map(extractCheckType)
    );
    const typeArray = classifiedTypeData.map((innerArray) =>
      innerArray.map((obj) => obj.type)
    );
    const lengthArray = classifiedTypeData.map((innerArray) =>
      innerArray.map((obj) => obj.length)
    );
    const maxNumbers = findMaxMatch(lengthArray);

    const uniqueArrays = [];
    const uniqueSet = new Set();

    typeArray.forEach((arr) => {
      const strArr = JSON.stringify(arr);
      if (!uniqueSet.has(strArr)) {
        uniqueArrays.push(arr);
        uniqueSet.add(strArr);
      }
    });

    const mergedArray = uniqueArrays[0]?.map((typeItem, index) => ({
      type: typeItem,
      length: maxNumbers[index],
    }));

    let newNameColumn = nameColumn?.map((column) =>
      column.replace(/[\W_]+/g, "_")
    );

    let finalNameColumn = addUnderscoreToDuplicates(newNameColumn);

    const mergeColumnData = finalNameColumn?.map((key, index) => {
      return {
        type: mergedArray[index]?.type,
        name: key,
        label: labelColumn[index],
        length: mergedArray[index]?.length,
      };
    });

    let indexArray = [];
    let numberIndex =
      mergeColumnData?.length < 30 ? mergeColumnData?.length : 30;

    for (var i = 0; i < numberIndex; i++) {
      indexArray.push(i);
    }

    let uniqueId = Math.random().toString().substr(2, 10);

    let hideShowData = {
      fileId: uniqueId,
      index: indexArray,
      libraryId: libraryId,
    };

    let userData = {};
    userData.fileId = uniqueId;
    userData.libraryId = libraryId;
    userData.fileName = fileName;
    userData.description = fileDescription;
    userData.createdAt = new Date();
    userData.updatedAt = new Date();
    userData.noofrow = classifiedTypeValueData?.length;
    userData.noofcolumn = mergeColumnData?.length;
    userData.data = classifiedTypeValueData;
    userData.column = mergeColumnData;
    if (libraryFileList?.length === 0 || !libraryFileList) {
      localStorage.setItem("libraryFileList", JSON.stringify([userData]));
      setLibraryFileList([userData]);
    } else {
      libraryFileList.push(userData);
      libraryFileList.sort((a, b) => a.createdAt - b.createdAt);
      localStorage.setItem("libraryFileList", JSON.stringify(libraryFileList));
      setLibraryFileList(libraryFileList);
    }
    if (hideShowLibraryData?.length === 0 || !hideShowLibraryData) {
      localStorage.setItem(
        "hideShowLibraryData",
        JSON.stringify([hideShowData])
      );
      setHideShowLibraryData([hideShowData]);
    } else {
      hideShowLibraryData.push(hideShowData);
      hideShowLibraryData.sort((a, b) => a.createdAt - b.createdAt);
      localStorage.setItem(
        "hideShowLibraryData",
        JSON.stringify(hideShowLibraryData)
      );
      setHideShowLibraryData(hideShowLibraryData);
    }
    let Message = {
      statusCode: 200,
      customMessage: "File has been Successfully Uploaded.",
    };
    clearInputData();
    handleResponse(Message);
  };

  function convertKeysToColumns(arrayData) {
    let convertedData = [];
    for (const element of arrayData) {
      let convertedObject = {};
      let keys = Object.keys(element);

      keys.forEach((key, index) => {
        convertedObject["Col" + (index + 1)] = element[key];
      });
      convertedData.push(convertedObject);
    }
    return convertedData;
  }

  function convertDataonlyLabelorName(arrayData, index) {
    let convertedData = [];
    for (const element of arrayData) {
      const result = {};
      for (const key in element) {
        if (element.hasOwnProperty(key)) {
          const mappedKey = arrayData[index][key];
          result[mappedKey] = element[key];
        }
      }
      convertedData.push(result);
    }
    convertedData.splice(index, 1);
    return convertedData;
  }

  function convertDataLabelandName(arrayData, name) {
    let convertedData = [];
    for (const element of arrayData) {
      const result = {};
      for (const key in element) {
        if (element.hasOwnProperty(key)) {
          const mappedKey = arrayData[name][key];
          result[mappedKey] = element[key];
        }
      }
      convertedData.push(result);
    }

    return convertedData;
  }

  function extractCheckValueType(value) {
    const numRegex = /^-?\d+(\.\d+)?$/;
    function checkDateFormat(value) {
      const dateFormats = [
        /^(0[1-9]|[12]\d|3[01])(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\d{4}$/i,
        /^(0[1-9]|[12]\d|3[01])(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\d{2}$/i,
        /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/,
        /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
        /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
        /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\d{2}$/i,
        /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\d{2}$/i,
        /^(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{2}$/,
        /^\d{8}$/,
        /^[A-Za-z]+,\s(January|February|March|April|May|June|July|August|September|October|November|December)\s\d{2},\s\d{4}$/,
        /^(0[1-9]|[12]\d|3[01])(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\d{2}:([01]\d|2[0-3]):([0-5]\d):([0-5]\d):([0-5]\d)$/,
        /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun),\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(0[1-9]|[12]\d|3[01]),\s\d{4}\s([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
        /^\d{4}-\d{2}-\d{2}T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
        /^\d{4}-\d{2}-\d{2}T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)\+(\d{2}):(\d{2})$/,
        /^\d{8}T([01]\d|2[0-3])([0-5]\d)([0-5]\d)\+(\d{2})(\d{2})$/,
        /^(January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2},\s\d{4}$/,
        /^\d{7}$/,
        /^(January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2},\s\d{4}$/,
        /^(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])-\d{4}$/,
        /^(0?[1-9]|1[0-2]) (0?[1-9]|[12]\d|3[01]) \d{4}$/,
        /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/\d{4}$/,
        /^(0?[1-9]|[12]\d|3[01]) (0?[1-9]|1[0-2]) \d{4}$/,
        /^\d{4}-\d{2}-\d{2}T([01]\d|2[0-3]):([0-5]\d)$/,
        /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/\d{4}$/,
      ];
      for (let i = 0; i < dateFormats.length; i++) {
        if (dateFormats[i].test(value)) {
          return true;
        }
      }
      return false;
    }

    if (numRegex.test(value)) {
      return { value: value };
    } else if (checkDateFormat(value)) {
      return { value: value };
    } else {
      return { value: value };
    }
  }

  function extractCheckType(value) {
    const numRegex = /^-?\d+(\.\d+)?$/;
    function checkDateFormat(value) {
      const dateFormats = [
        /^(0[1-9]|[12]\d|3[01])(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\d{4}$/i,
        /^(0[1-9]|[12]\d|3[01])(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\d{2}$/i,
        /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/,
        /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
        /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
        /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\d{2}$/i,
        /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\d{2}$/i,
        /^(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{2}$/,
        /^\d{8}$/,
        /^[A-Za-z]+,\s(January|February|March|April|May|June|July|August|September|October|November|December)\s\d{2},\s\d{4}$/,
        /^(0[1-9]|[12]\d|3[01])(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\d{2}:([01]\d|2[0-3]):([0-5]\d):([0-5]\d):([0-5]\d)$/,
        /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun),\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(0[1-9]|[12]\d|3[01]),\s\d{4}\s([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
        /^\d{4}-\d{2}-\d{2}T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
        /^\d{4}-\d{2}-\d{2}T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)\+(\d{2}):(\d{2})$/,
        /^\d{8}T([01]\d|2[0-3])([0-5]\d)([0-5]\d)\+(\d{2})(\d{2})$/,
        /^(January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2},\s\d{4}$/,
        /^\d{7}$/,
        /^(January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2},\s\d{4}$/,
        /^(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])-\d{4}$/,
        /^(0?[1-9]|1[0-2]) (0?[1-9]|[12]\d|3[01]) \d{4}$/,
        /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/\d{4}$/,
        /^(0?[1-9]|[12]\d|3[01]) (0?[1-9]|1[0-2]) \d{4}$/,
        /^\d{4}-\d{2}-\d{2}T([01]\d|2[0-3]):([0-5]\d)$/,
        /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/\d{4}$/,
      ];
      for (let i = 0; i < dateFormats.length; i++) {
        if (dateFormats[i].test(value)) {
          return true;
        }
      }
      return false;
    }

    if (numRegex.test(value)) {
      return { type: "number", length: value?.length };
    } else if (checkDateFormat(value)) {
      return { type: "date", length: value?.length };
    } else {
      return { type: "character", length: value?.length };
    }
  }

  function findMaxMatch(arr) {
    let maxCount = 0;
    let maxMatchedArray = [];

    for (let i = 0; i < arr.length; i++) {
      let currentCount = 0;
      let currentMatchedArray = arr[i];

      for (let j = 0; j < arr.length; j++) {
        let count = 0;
        for (let k = 0; k < arr[i].length; k++) {
          if (arr[i][k] === arr[j][k]) {
            count++;
          }
        }
        if (count > currentCount) {
          currentCount = count;
          currentMatchedArray = arr[j];
        }
      }

      if (currentCount > maxCount) {
        maxCount = currentCount;
        maxMatchedArray = currentMatchedArray;
      }
    }

    return maxMatchedArray;
  }

  function addUnderscoreToDuplicates(lst) {
    let countMap = {};
    let newList = [];

    lst.forEach((item) => {
      if (countMap[item]) {
        countMap[item]++;
        newList.push(`${item}_${countMap[item]}`);
      } else {
        countMap[item] = 1;
        newList.push(item);
      }
    });

    return newList;
  }

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const [error, setError] = useState("");
  const validateFileTitle = (input) => {
    const fileTitleRegex = /^[a-zA-Z0-9_]+$/;

    if (!fileTitleRegex.test(input)) {
      setError(
        "File Title should only contain capital letters, small letters, or numbers"
      );
    } else {
      setError("");
    }
  };

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={450}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={clearInputData}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div>
          {activeSection === "choose_file" && (
            <div id="choose_file">
              <div className="common_css table_content">
                <Form.Group as={Col} md="12" className="mb-3">
                  <Form.Label>
                    Choose File Type <span>*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setFileExtension(e.target.value)}
                    aria-label="Default select example"
                    style={{ fontSize: "13px" }}
                  >
                    <option>Select Format</option>
                    <option value="csv">CSV</option>
                    <option value="excel">Excel</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} md="12" className="mb-3">
                  <Form.Label>
                    Upload File <span>*</span>
                  </Form.Label>
                  <Form.Control
                    disabled={!fileExtension}
                    required
                    type="file"
                    accept={fileExtension === "csv" ? ".csv" : ".xls, .xlsx"}
                    onChange={(event) => handleFileUpload(event)}
                    placeholder="Upload file"
                    style={{ fontSize: 13 }}
                  />
                </Form.Group>
              </div>
              <div className="button_type footer_btn text-end px-3 pt-2">
                <button
                  disabled={!fileExtension}
                  onClick={() => handleSectionChange("select_name_label")}
                >
                  {submit}
                </button>
              </div>
            </div>
          )}
          {activeSection === "select_name_label" && (
            <div id="select_name_label">
              <div className="common_css table_content">
                <Form.Group as={Col} md="12" className="mb-3">
                  <Row>
                    <Col md={3}>
                      <Form.Label>
                        Select Name
                      </Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ fontSize: "13px" }}
                        className="mb-2 w-100"
                        onChange={(e) => setName(e.target.value)}
                      >
                        <option>Select Name</option>
                        <option value={0}>Row 1</option>
                        <option value={1}>Row 2</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} md="12" className="mb-3">
                  <Row>
                    <Col md={3}>
                      <Form.Label>
                        Select Label
                      </Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ fontSize: "13px" }}
                        className="mb-1 w-100"
                        onChange={(e) => setLabel(e.target.value)}
                      >
                        <option>Select Label</option>
                        <option value={0}>Row 1</option>
                        <option value={1}>Row 2</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
              </div>
              <div className="button_type footer_btn text-end px-3 pt-2">
                <button onClick={() => handleSectionChange("choose_file")}>
                  Back
                </button>
                <button
                  // disabled={!name || !label}
                  onClick={() => handleSectionChange("choose_file_name")}
                  style={{ marginLeft: 5 }}
                >
                  {submit}
                </button>
              </div>
            </div>
          )}
          {activeSection === "choose_file_name" && (
            <div id="choose_file_name">
              <div className="common_css table_content">
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    File Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) => {setFileName(e.target.value); validateFileTitle(e.target.value)}}
                    value={fileName}
                    placeholder="Enter File Name"
                    style={{ fontSize: 13 }}
                  />
                  {error && (
                    <span style={{ fontSize: 10, color: "red", fontWeight: 600 }}>
                      Folder Name Title should only contain capital letters, small letters,
                      underScore, and numbers "Space Not Allowed"<br />
                    </span>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="12" className="my-3">
                  <Form.Label>
                    File Description <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    onChange={(e) => setFileDescription(e.target.value)}
                    value={fileDescription}
                    placeholder="Enter Description"
                    style={{ fontSize: 13 }}
                  />
                </Form.Group>
              </div>
              <div className="button_type footer_btn text-end px-3 pt-2">
                <button
                  onClick={() => handleSectionChange("select_name_label")}
                >
                  Back
                </button>
                <button
                disabled={!fileName || !fileDescription || error}
                  onClick={handleFileUploadSubmit}
                  style={{ marginLeft: 5 }}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
export default LibraryUploadFile;
