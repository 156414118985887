import React from "react";

const MAKESUMMARYOutput = ({ codeOutputs, functionName }) => {
  let dataArray = JSON.parse(codeOutputs ? codeOutputs : []);
  const informationTable = {
    overflow: "auto",
    padding: "1.5rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  };

  const pooledTable = {
    padding: 10,
    background: "#fff",
    border: "1px solid var(--borderColor)",
    borderRadius: 8,
  };

  const tableProperties = {
    margin: 0,
    border: "1px solid #d4d5d9",
  };

  const tableth = {
    padding: 6,
    textAlign: "right",
    border: "1px solid #d4d5d9",
  };

  const tabletd = {
    border: "1px solid #d4d5d9",
    textAlign: "left",
    padding: 6,
  };

  const tableCenter = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    overflow: "auto",
    marginBottom: 20,
  };
  const headingCenter = {
    textAlign: "center",
    border: "1px solid #d4d5d9",
  };

  return (
    <>
      <div style={informationTable}>
        <div style={{ textAlign: "center", marginBottom: 5 }}>
          <div><b>"{functionName}" Function Output</b></div>
        </div>
        {dataArray?.map((item, index) => (
          <div key={index} style={pooledTable}>
            <div style={{ textAlign: "center", marginBottom: 10 }}><b>{item.CATEGORY}</b></div>
            <div style={tableCenter}>
              <table style={tableProperties}>
                <thead>
                  <th style={{ textAlign: "center", background: "rgb(212, 213, 217)", padding: 6 }} colSpan={2}>Moments</th>
                </thead>
                <tbody >
                  <tr>
                    <th style={tableth}>N</th>
                    <td style={tabletd}>{item.N}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Sum Observations</th>
                    <td style={tabletd}>{item.sum_observations}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Mean</th>
                    <td style={tabletd}>{item.mean}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Varience</th>
                    <td style={tabletd}>{item.variance}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Standard Deviation</th>
                    <td style={tabletd}>{item.standard_deviation}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Skewness</th>
                    <td style={tabletd}>{item.skewness}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Kurtosis</th>
                    <td style={tabletd}>{item.kurtosis}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Coefficient of Variation</th>
                    <td style={tabletd}>{item.coefficient_of_variation}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Uncorrected SS</th>
                    <td style={tabletd}>{item.uncorrected_SS}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Corrected SS</th>
                    <td style={tabletd}>{item.corrected_SS}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Standard Error Mean</th>
                    <td style={tabletd}>{item.standard_error_mean}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={tableCenter}>
              <table style={tableProperties}>
                <thead>
                  <tr>
                    <th colSpan={2} style={{ textAlign: "center", background: "rgb(212, 213, 217)", padding: 6 }}>Basic Statistical Measures</th>
                  </tr>
                  <tr>
                    <th style={headingCenter} colSpan={2}>
                      Location
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th style={tableth}>Mean</th>
                    <td style={tabletd}>{item.mean}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Median</th>
                    <td style={tabletd}>{item.median}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Mode</th>
                    <td style={tabletd}>{(item.mode).join(", ")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={tableCenter}>
              <table style={tableProperties}>
                <thead>
                  <tr>
                    <th colSpan={2} style={{ textAlign: "center", background: "rgb(212, 213, 217)", padding: 6 }}>Basic Statistical Measures</th>
                  </tr>
                  <tr>
                    <th colSpan={2} style={headingCenter}>
                      Variability
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th style={tableth}>Varience</th>
                    <td style={tabletd}>{item.variance}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Standard Deviation</th>
                    <td style={tabletd}>{item.standard_deviation}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Range</th>
                    <td style={tabletd}>{item.range}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>Interquartile Range</th>
                    <td style={tabletd}>{item.interquartile_range}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={tableCenter}>
              <table style={tableProperties}>
                <thead>
                  <th colSpan={2} style={{ textAlign: "center", background: "rgb(212, 213, 217)", padding: 6 }}>Quantiles (Definition 5)</th>
                  <tr>
                    <th style={tableth}>Level</th>
                    <th style={tableth}>Quantile</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th style={tableth}>100% Max</th>
                    <td style={tabletd}>{item.quantile_100th}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>99%</th>
                    <td style={tabletd}>{item.percentile_99th}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>95%</th>
                    <td style={tabletd}>{item.percentile_95th}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>90%</th>
                    <td style={tabletd}>{item.percentile_90th}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>75% Q3</th>
                    <td style={tabletd}>{item.quantile_75th}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>50% Median</th>
                    <td style={tabletd}>{item.quantile_50th}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>25% Q1</th>
                    <td style={tabletd}>{item.quantile_25th}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>10%</th>
                    <td style={tabletd}>{item.percentile_10th}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>5%</th>
                    <td style={tabletd}>{item.percentile_5th}</td>
                  </tr>
                  <tr>
                    <th style={tableth}>1%</th>
                    <td style={tabletd}>{item.percentile_1th}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MAKESUMMARYOutput;
