import React from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form } from "react-bootstrap";
import Moment from "react-moment";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices";
import Swal from "sweetalert2";

const PackagesUpdateRequestModalData = ({
  show,
  title,
  bounds,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  packageUpdateRequest,
  updatedFileStatus,
  getPackageFolderLists,
  getPackageFileCodeById,
  submit
}) => {

  const updateDeveloperStatusInRequestVersionPackage = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Publish this Updated package?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, publish now!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = packageUpdateRequest?._id;
        userData.packageId = packageUpdateRequest?.packageId;
        let data = await packagesServices.updateDeveloperStatusInRequestVersionPackage(userData);
        if (data?.statusCode === 200) {
          getPackageFolderLists();
          getPackageFileCodeById();
          setShow(false);
        }
      }
    });
  };

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={600}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="p-2">
          <Form.Label>Change Logs (For Next Version : v{packageUpdateRequest.version} )</Form.Label>
          <div className="log_change mb-3">
            {(packageUpdateRequest?.changelogs)?.map((data, key) => (
              <pre key={key}>- {data.type}: {data.changelog}</pre>
            ))}
          </div>
          <Form.Label>Assign Date | Time : <Moment format="DD MMM YYYY | HH:mm:ss" withTitle>
            {packageUpdateRequest?.createdAt}
          </Moment></Form.Label>
        </div>
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            onClick={updateDeveloperStatusInRequestVersionPackage}
            disabled={updatedFileStatus === "draft"}
          >
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default PackagesUpdateRequestModalData;
