import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form } from "react-bootstrap";
import { handleResponse } from "../../../APIHelpers/Responses";
const UpdateLibrary = ({
  show,
  title,
  submit,
  bounds,
  onStart,
  setShow,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  sessionLibraryData,
  setDummyLibraryList,
}) => {
  const [libraryName, setLibraryName] = useState("");
  const [description, setDescription] = useState("");

  const updateSessionLibraryName = () => {
    let newArray = JSON.parse(localStorage.getItem("dummyLibraryList"));

    let findData = newArray?.find(
      (item) => item._id === sessionLibraryData?._id
    );
    let result = newArray?.filter(
      (item) => !(item._id === sessionLibraryData?._id)
    );

    let userData = {};
    userData._id = sessionLibraryData?._id;
    userData.name = libraryName;
    userData.description = description;
    userData.createdAt = findData?.createdAt;
    userData.updatedAt = new Date();
    result.push(userData);
    result.sort((a, b) => a.createdAt - b.createdAt);

    let checkData = newArray.find((item) => item.name === libraryName);
    if (checkData) {
      let Message = {
        statusCode: 400,
        customMessage: "Opps! Folder Name Already Exist.",
      };
      handleResponse(Message);
      clearInputData();
    } else {
      localStorage.setItem("dummyLibraryList", JSON.stringify(result));
      setDummyLibraryList(result);
      let Message = {
        statusCode: 200,
        customMessage: "Folder has been Successfully Updated.",
      };
      handleResponse(Message);
      clearInputData();
    }
  };

  const clearInputData = () => {
    setShow(!show);
  };

  const [error, setError] = useState("");
  const validateFileTitle = (input) => {
    const fileTitleRegex = /^[a-zA-Z0-9_]+$/;

    if (!fileTitleRegex.test(input)) {
      setError(
        "File Title should only contain capital letters, small letters, or numbers"
      );
    } else {
      setError("");
    }
  };

  useEffect(() => {
    setLibraryName(sessionLibraryData?.name);
    setDescription(sessionLibraryData?.description);
  }, [sessionLibraryData]);

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={clearInputData}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Form.Label>Folder Name<span>*</span></Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter Folder Name"
            autoFocus
            value={libraryName}
            onChange={(e) => {
              setLibraryName(e.target.value);
              validateFileTitle(e.target.value);
            }}
          />
          {error && (
            <span style={{ fontSize: 10, color: "red", fontWeight: 600 }}>
              Folder Name Title should only contain capital letters, small
              letters, underScore, and numbers "Space Not Allowed"
              <br />
            </span>
          )}
          <Form.Label className="mt-3">Description<span>*</span></Form.Label>
          <Form.Control
            className="mb-3"
            required
            as="textarea"
            placeholder="Enter Folder Description"
            autoFocus
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            onClick={updateSessionLibraryName}
            disabled={!libraryName || !description || error}
          >
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default UpdateLibrary;
