import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { consultantServices } from "../../../APIServices/Editor/consultantServices";

const UpdateProjectInformationConsole = ({
  width,
  isFullScreen,
  setIsFullScreen,
  editorFullMode,
  fileId,
  editorTheme
}) => {
  const [rows, setRows] = useState([]);
  const [projectInfo, setProjectInfo] = useState("");
  const collumnsArray = ["title", "value"];

  const handleAddRow = () => {
    const item = {};
    setRows([...rows, item]);
  };

  const handleRemoveSpecificRow = (idx) => {
    const tempRows = [...rows];
    tempRows.splice(idx, 1);
    setRows(tempRows);
  };

  const updateState = (e) => {
    let prope = e.target.attributes.collumn.value;
    let index = e.target.attributes.index.value;
    let fieldValue = e.target.value;

    const tempRows = [...rows];
    const tempObj = rows[index];
    tempObj[prope] = fieldValue;

    tempRows[index] = tempObj;
    setRows(tempRows);
  };

  const getProjectInformation = async (fileId) => {
    let data = await consultantServices.getProjectInformation(fileId);
    setProjectInfo(data?.data);
    setRows(data?.data?.information);
  };

  const updateConsultantProjectHeaderInformation = async () => {
    let userData = {};
    userData.projectId = projectInfo?.projectUniqueId;
    userData.information = rows;

    let data =
      await consultantServices.updateConsultantProjectHeaderInformation(
        userData
      );
    if (data?.statusCode === 200) {
      getProjectInformation(fileId);
    }
  };

  useEffect(() => {
    getProjectInformation(fileId);
  }, [fileId]);
  return (
    <>
      <div
        className={`p-0 output__section_widget ${!editorFullMode ? "" : "fullModeHeight"} ${!editorTheme ? "outputShadowEditorTheme" : "outputShadow"}`}
        style={{
          width: isFullScreen
            ? "98%"
            : width <= 1100
              ? 1500 - width + "px"
              : "400px",
        }}
      >
        <div
          className="border-bottom p-2 position-relative"
          style={{ background: "#ebebeb" }}
        >
          <div className="hstack gap-2 header_tool justify-content-between">
            <p className="m-0">
              <b>
                Header
                <i
                  className="fa-solid fa-square-plus"
                  onClick={handleAddRow}
                  title="Add New Row"
                ></i>
              </b>
            </p>
            <div className="hstack gap-1">
              <i
                className={
                  isFullScreen ? "fa-solid fa-minimize" : "fa-solid fa-maximize"
                }
                title={isFullScreen ? "Minimized Mode" : "Full Mode"}
                onClick={() => setIsFullScreen(!isFullScreen)}
              ></i>
            </div>
          </div>
        </div>
        <div
          className={`informationTable px-2 pt-2 ${!editorFullMode ? "output__section_widget_height" : "output__section_widget_full_height"}`}
          style={{ overflowX: "hidden" }}
        >
          <Table bordered className="m-0">
            <tbody>
              <tr>
                <th>
                  <p>Sponsor Name</p>
                </th>
                <td>
                  <p>{projectInfo?.sponsorName}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Project Id</p>
                </th>
                <td>
                  <p>{projectInfo?.projectId}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Study Id</p>
                </th>
                <td>
                  <p>{projectInfo?.studyId}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Programmer Name</p>
                </th>
                <td>
                  <p>{projectInfo?.programmerName}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Program Name</p>
                </th>
                <td>
                  <p>{projectInfo?.fileName}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Program Location</p>
                </th>
                <td>
                  <p>
                    /project/{projectInfo?.projectName}/programs/
                    {projectInfo?.fileName}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Version</p>
                </th>
                <td>
                  <p>v{projectInfo?.version}</p>
                </td>
              </tr>
              {rows.map((item, idx) => (
                <tr key={idx} style={{ position: "relative" }}>
                  {collumnsArray.map((collumn, index) => (
                    <td key={index} className="p-0">
                      <input
                        className={index === 0 && "bold"}
                        type="text"
                        collumn={collumn}
                        value={rows[idx][collumn]}
                        index={idx}
                        onChange={(e) => updateState(e)}
                      />
                    </td>
                  ))}
                  <i
                    className="fa-solid fa-xmark"
                    title="Delete Row"
                    onClick={() => handleRemoveSpecificRow(idx)}
                  ></i>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="save_btn py-2" title="Save changes">
            <button
              onClick={updateConsultantProjectHeaderInformation}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProjectInformationConsole;
