import React from "react";

const PackagesDetailsTabs = ({ setTab, tab }) => {
  return (
    <>
      <div className={`border-bottom`}>
        <div className="all_packageslist pt-3">
          <div
            onClick={() => setTab("readme")}
            className={tab === "readme" ? "activeTab" : "inactiveTab"}
          >
            <i className="fa-solid fa-file-lines"></i> Readme
          </div>
          <div
            onClick={() => setTab("logs")}
            className={tab === "logs" ? "activeTab" : "inactiveTab"}
          >
            <i className="fa-solid fa-clipboard-list"></i> Change Logs
          </div>
          <div
            onClick={() => setTab("examples")}
            className={tab === "examples" ? "activeTab" : "inactiveTab"}
          >
            <i className="fa-solid fa-file"></i> Examples
          </div>
          <div
            onClick={() => setTab("versions")}
            className={tab === "versions" ? "activeTab" : "inactiveTab"}
          >
            <i className="fa-solid fa-code-compare"></i> Versions
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagesDetailsTabs;
