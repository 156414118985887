import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Moment from "react-moment";
import Pagination from "../../../Component/Paginatation/Pagination";

const PackageHistroryTableData = ({
  roleType,
  tableRef,
  onMouseDown,
  transactions,
}) => {
  const [action, setAction] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = transactions?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(transactions?.length / itemsPerPage);

  return (
    <>
      <Table
        id="resizable-table"
        className="m-0 custom-table"
        ref={tableRef}
        bordered
        striped
      >
        <thead className="thead-sticky">
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 1)}>Package Name</th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Versions</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Review Date & Time</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Review Status</th>
            <th onMouseDown={(e) => onMouseDown(e, 5)}>Comments</th>
            <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 60 }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {result?.map((item, index) => (
            <tr key={index}>
              <td>{startIndex + index + 1}</td>
              <td>{item.packageName}</td>
              <td>{item.version}</td>
              <td>
                <Moment format="DD MMM YYYY | HH:mm">
                  {item.reviewDateTime}
                </Moment>
              </td>
              <td className="text-capitalize">{item.reviewStatus}</td>
              <td>{item.comments}</td>
              <td>
                <div className="text-center action__buttons">
                  {action === item._id ? (
                    <>
                      <div className="saperate_action_button">
                        <Button
                          onClick={""}
                          title="View Details"
                          className="rounded-circle"
                        >
                          <i className="fa-solid fa-eye"></i>
                        </Button>
                        <Button
                          onClick={""}
                          title="View Statements"
                          className="rounded-circle"
                        >
                          <i className="fa-solid fa-file-lines"></i>
                        </Button>
                        <Button
                          onClick={""}
                          title="View Examples"
                          className="rounded-circle"
                        >
                          <i className="fa-solid fa-code"></i>
                        </Button>
                        <Button
                          onClick={""}
                          title="View Dependancies"
                          className="rounded-circle"
                        >
                          <i className="fa-solid fa-cube"></i>
                        </Button>
                        <Button
                          onClick={""}
                          title="View Validations"
                          className="rounded-circle"
                        >
                          <i className="fa-solid fa-file-shield"></i>
                        </Button>
                        {roleType === "validator" ? (
                          <>
                            <Button className="rounded-circle">
                              <i className="fa-solid fa-user-shield"></i>
                            </Button>
                            <Button className="rounded-circle">
                              <i className="fa-solid fa-user-tie"></i>
                            </Button>
                          </>
                        ) : (
                          <Button className="rounded-circle">
                            <i className="fa-solid fa-building-user"></i>
                          </Button>
                        )}
                      </div>
                      <Button
                        onClick={() => setAction("")}
                        title="Close Action"
                        className="rounded-circle close_circle"
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => setAction(item._id)}
                      title="Open Action"
                      className="rounded-circle"
                    >
                      <i className="fa-solid fa-bars"></i>
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="mx-2">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={transactions?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default PackageHistroryTableData;
