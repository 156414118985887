import React from "react";

const MAKETTESTOutput = ({ codeOutputs, functionName }) => {
  let dataArray = JSON.parse(codeOutputs ? codeOutputs : []);
  const informationTable = {
    overflow: "auto",
    padding: "1.5rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  };

  const pooledTable = {
    padding: 10,
    background: "#fff",
    border: "1px solid var(--borderColor)",
    borderRadius: 8,
  };

  const tableProperties = {
    margin: 0,
    border: "1px solid #d4d5d9",
  };

  const tableth = {
    padding: 6,
    textAlign: "right",
    border: "1px solid #d4d5d9",
  };

  const tabletd = {
    border: "1px solid #d4d5d9",
    textAlign: "left",
    padding: 6,
  };

  const tableCenter = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    overflow: "auto",
    marginBottom: 20,
  };

  let transformedData = dataArray?.[0]?.test === "TWOSAMPLEMEANS" ?
    dataArray
    :
    Object.entries(dataArray?.[0]).map(([key, value]) => {
      return { attribute: key, value: value };
    });

  return (
    <>
      {dataArray?.[0]?.test === "TWOSAMPLEMEANS" ?
        dataArray?.map((item, index) => (
          <div key={index} style={{ textAlign: "center" }}>
            <div style={informationTable}>
              <div style={{ textAlign: "center", marginBottom: 5 }}>
                <div><b>"{functionName}" Function Output (Two Sample Means)</b></div>
              </div>
              <div style={pooledTable}>
                <div style={tableCenter}>
                  <table style={tableProperties}>
                    <tbody>
                      <tr>
                        <th style={{ background: "#d4d5d9", padding: 6 }} colSpan={3}>
                          <div style={{ textAlign: "center" }}>
                            <div>Method : Pooled</div>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th style={tableth}>T-statistics</th>
                        <td style={tabletd}>{item?.Pooled_t_Value}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>DF</th>
                        <td style={tabletd}>{item?.Pooled_DF}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Pr {">"} |t|</th>
                        <td style={tabletd}>{item?.Pooled_Pr_t}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={tableCenter}>
                  <table style={tableProperties}>
                    <tbody>
                      <tr>
                        <th colSpan={6} style={{ background: "#d4d5d9", padding: 6 }}>
                          <div style={{ textAlign: "center" }}>
                            <div>Diffrence Analysis</div>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th style={tableth}></th>
                        <td style={tabletd}>{item?.Diff}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Mean</th>
                        <td style={tabletd}>{item?.Pooled_Mean_Diff}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Standard Deviation</th>
                        <td style={tabletd}>{item?.Pooled_Std_Dev_Diff}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Standard Error</th>
                        <td style={tabletd}>{item?.Pooled_Std_Err_Diff}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Mean Confidence Limit</th>
                        <td style={tabletd}>
                          {item?.Pooled_CL_Mean_Diff_L},{" "}
                          {item?.Pooled_CL_Mean_Diff_U}
                        </td>
                      </tr>
                      <tr>
                        <th style={tableth}>Standard Deviation Confidence Limit</th>
                        <td style={tabletd}>
                          {item?.Pooled_CL_std_Diff_L},{" "}
                          {item?.Pooled_CL_std_Diff_U}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={tableCenter}>
                  <table bordered striped>
                    <tbody>
                      <tr>
                        <th colSpan={3} style={{ background: "#d4d5d9", padding: 6 }}>
                          <div style={{ textAlign: "center" }}>
                            <div>Method : Satterthwaite</div>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th style={tableth}>T-statistics</th>
                        <td style={tabletd}>{item?.Satterthwaite_t_Value}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>DF</th>
                        <td style={tabletd}>{item?.Satterthwaite_DF}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Pr {">"} |t|</th>
                        <td style={tabletd}>{item?.Satterthwaite_Pr_t}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={tableCenter}>
                  <table bordered striped>
                    <tbody>
                      <tr>
                        <th colSpan={4} style={{ background: "#d4d5d9", padding: 6 }}>
                          <div style={{ textAlign: "center" }}>
                            <div>Diffrence Analysis</div>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th style={tableth}></th>
                        <td style={tabletd}>{item?.Diff}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Mean</th>
                        <td style={tabletd}>{item?.Satterthwaite_Mean_Diff}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Std Err</th>
                        <td style={tabletd}>{item?.Satterthwaite_Std_Err_Diff}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Mean CL</th>
                        <td style={tabletd}>
                          {item?.Satterthwaite_CL_Mean_Diff_L},{" "}
                          {item?.Satterthwaite_CL_Mean_Diff_U}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={tableCenter}>
                  <table bordered striped>
                    <tbody>
                      <tr>
                        <th colSpan={4} style={{ background: "#d4d5d9", padding: 6 }}>
                          <div style={{ textAlign: "center" }}>
                            <div>Method : Equality of Variances</div>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th style={tableth}>F-Value</th>
                        <td style={tabletd}>{item?.F_statistic}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Num DF</th>
                        <td style={tabletd}>{item?.Freedom_for_numerator}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Den DF</th>
                        <td style={tabletd}>{item?.Freedom_for_denominator}</td>
                      </tr>
                      <tr>
                        <th style={tableth}>Pr {">"} |t|</th>
                        <td style={tabletd}>{item?.P_value_for_equality_of_variances}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ))
        :
        <div style={{ textAlign: "center" }}>
          <div style={informationTable}>
            <div style={{ textAlign: "center", marginBottom: 5 }}>
              <div><b>"{functionName}" Function Output (One Sample Means)</b></div>
            </div>
            <div style={pooledTable}>
              <div style={tableCenter}>
                <table style={tableProperties}>
                  <tbody>
                    <tr>
                      <th style={{ background: "#d4d5d9", padding: 6 }} colSpan={2}>
                        <div style={{ textAlign: "center" }}>
                          <div>One Sample TTEST</div>
                        </div>
                      </th>
                    </tr>
                    {transformedData?.map((item, index) => (
                      item.attribute === "test" ? "" :
                        <>
                          <tr key={index}>
                            <th style={tableth}>{(item.attribute).replace(/_/g, ' ')}</th>
                            <td style={tabletd}>{item.value}</td>
                          </tr>
                        </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default MAKETTESTOutput;
