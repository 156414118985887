import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import VerifiedUserData from "./VerifiedUserDetailsinModal.js.js";
import ReeferenceListDetailsinModal from "./ReeferenceListDetailsinModal.js";
import Moment from "react-moment";
const PackageDetailsSidebar = ({
  version,
  regularPrice,
  salePrice,
  totalPurchased,
  validatorDetails,
  referances,
  publishDateTime,
}) => {
  const [showVerified, setShowVerified] = useState(false);
  const [showReferenceData, setShowReferenceData] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <div className="repos mb-3">
        <div>
          <p className="mb-1 mrp_section">
            {`MRP $${salePrice} (Inclusive of all taxes)`}
          </p>
          <h6 className="off_type mb-2">
            ${salePrice}{" "}
            <span className="off_span">
              {100 - (Number(salePrice) * 100) / Number(regularPrice)}% OFF
            </span>
          </h6>
        </div>
        <div className="add_pack_buy">
          <button>
            <i className="fa-solid fa-cart-plus"></i>Add To Cart
          </button>
          <button>
            <i className="fa-solid fa-cart-shopping"></i>Buy Now
          </button>
          <button>
            <i className="fa-regular fa-heart"></i>Add To Fevorites
          </button>
        </div>
      </div>
      <hr />
      <div className="repos">
        <h6>Total Purchased</h6>
        <p>{totalPurchased || 0}</p>
        <hr />
      </div>
      <Row>
        <Col md={6}>
          <div className="repos">
            <h6>Latest Version</h6>
            <p>v{version}</p>
          </div>
        </Col>
        <Col md={6}>
          <div className="repos">
            <h6>License</h6>
            <p>Galax® GAS</p>
          </div>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col md={6}>
          <div className="repos icon_valids">
            <h6>Validated By</h6>
            <p>
              {validatorDetails?.companyName}
              <i
                className="fa-solid fa-circle-info mx-2"
                title="Validator info"
                onClick={() => setShowVerified(!showVerified)}
              ></i>
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="repos">
            <h6>Validation Date & Time</h6>
            <p>
              <Moment format="DD MMM YYYY | HH:mm">
                {validatorDetails?.reviewDateTime}
              </Moment>
            </p>
          </div>
        </Col>
      </Row>
      <hr className="mt-0"></hr>
      <div className="repos mb-3">
        <h6>References</h6>
        <ul>
          <li>{referances?.[0]?.reference}</li>
          {referances?.length > 1 &&
            <button
              className="mt-2"
              onClick={() => setShowReferenceData(!showReferenceData)}
            >
              View More
            </button>
          }
        </ul>
      </div>
      <hr />
      <div className="repos">
        <h6>Last Published Date & Time</h6>
        <p>
          <Moment format="DD MMM YYYY | HH:mm">{publishDateTime}</Moment>
        </p>
      </div>
      <ReeferenceListDetailsinModal
        show={showReferenceData}
        setShow={setShowReferenceData}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
        referances={referances}
        title={"References"}
      />
      <VerifiedUserData
        show={showVerified}
        setShow={setShowVerified}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title="Package Validator Details"
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
        validatorDetails={validatorDetails}
      />
    </>
  );
};
export default PackageDetailsSidebar;
