import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import Pagination from "../../../../Component/Paginatation/Pagination";

const PayoutTableData = ({
  transactions,
  tableRef,
  onMouseDown,
  editorFullMode,
}) => {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return "green";
      case "processing":
        return "gray";
      default:
        return "inherit";
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = transactions?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(transactions?.length / itemsPerPage);

  return (
    <>
      <div
        className="table_content overflow-auto mx-2"
        style={{
          maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
        }}
      >
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            className="m-0  custom-table"
            ref={tableRef}
            bordered
            striped
          >
            <thead className="thead-sticky">
              <tr>
                <th
                  onMouseDown={(e) => onMouseDown(e, 0)}
                  style={{ width: 40 }}
                >
                  Sr.
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Amount</th>
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Transaction ID</th>
                <th onMouseDown={(e) => onMouseDown(e, 3)}>Status</th>
                <th onMouseDown={(e) => onMouseDown(e, 4)}>
                  Transaction Date and Time
                </th>
                <th
                  onMouseDown={(e) => onMouseDown(e, 5)}
                  style={{ width: 60 }}
                >
                  Invoice
                </th>
              </tr>
            </thead>
            <tbody>
              {result?.map((transaction, index) => (
                <tr key={index}>
                  <td>{index + 1 + startIndex}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.transactionId}</td>
                  <td
                    style={{
                      color: getStatusColor(transaction.status),
                      fontWeight: "500",
                    }}
                  >
                    {transaction.status}
                  </td>
                  <td> <Moment format="DD MMM YYYY | HH:mm">{transaction.transactionDateTime}</Moment></td>
                  <td>
                    <div>
                      <i className="fa-solid fa-eye"></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="mx-2">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={transactions?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default PayoutTableData;
