import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Col, Form, Row } from "react-bootstrap";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices";

const PackagesUploadDataModal = ({
  show,
  title,
  bounds,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  packageId,
  userCurrentData,
  openPackageFileById
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [label, setLabel] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [selectFormat, setSelectFormat] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [activeSection, setActiveSection] = useState("choose_file");

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const handleFormatChange = (format) => {
    setSelectFormat(format);
    setUploadFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const csvType = file.type === "text/csv";
    const excelType =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (
      (selectFormat === "csv" && csvType) ||
      (selectFormat === "excel" && excelType)
    )
      setUploadFile(file);
    else {
      setUploadFile(null);
      alert(`Please select a ${selectFormat.toUpperCase()} file.`);
    }
  };

  const validateFileTitle = (input) => {
    const fileTitleRegex = /^[a-zA-Z0-9_]+$/;

    if (!fileTitleRegex.test(input)) {
      setError(
        "File Title should only contain capital letters, small letters, or numbers"
      );
    } else {
      setError("");
    }
  };

  const handleImageChange = async () => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("label", label);
      formData.append("name", name);
      formData.append("fileName", fileName);
      formData.append("userId", userCurrentData?._id);
      formData.append("description", fileDescription);
      formData.append("type", "package");
      formData.append("packageId", packageId);
      let result = await packagesServices.uploadFileinLibrary(formData);
      if (result?.statusCode === 200) {
        cleanInputBox();
        openPackageFileById("fileId", "datas")
      }
    }
  };

  const cleanInputBox = () => {
    setUploadFile(null)
    setLabel("")
    setName("")
    setFileName("")
    setFileDescription("")
    setActiveSection("choose_file")
    setShow(!show)
  }

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={450}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={cleanInputBox}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div>
          {activeSection === "choose_file" && (
            <div id="choose_file">
              <div className="common_css table_content">
                <Form.Group as={Col} md="12" className="mb-3">
                  <Form.Label>
                    Choose File Type <span>*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => handleFormatChange(e.target.value)}
                    value={selectFormat}
                    aria-label="Default select example"
                    style={{ fontSize: "13px" }}
                  >
                    <option>Select Format</option>
                    <option value="csv">CSV</option>
                    <option value="excel">Excel</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} md="12" className="mb-3">
                  <Form.Label>
                    Upload {selectFormat.toUpperCase()} File <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="file"
                    disabled={!selectFormat}
                    accept={selectFormat === "csv" ? ".csv" : ".xls, .xlsx"}
                    onChange={handleFileChange}
                    placeholder="Upload file"
                    style={{ fontSize: 13 }}
                  />
                </Form.Group>
              </div>
              <div className="button_type footer_btn text-end px-3 pt-2">
                <button
                  // disabled={!fileExtension}
                  onClick={() => handleSectionChange("select_name_label")}
                >
                  {/* {submit} */}Next
                </button>
              </div>
            </div>
          )}
          {activeSection === "select_name_label" && (
            <div id="select_name_label">
              <div className="common_css table_content">
                <Form.Group as={Col} md="12" className="mb-3">
                  <Row>
                    <Col md={3}>
                      <Form.Label>Select Name</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ fontSize: "13px" }}
                        className="mb-2 w-100"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      >
                        <option>Select Name</option>
                        <option value={0}>Row 1</option>
                        <option value={1}>Row 2</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} md="12" className="mb-3">
                  <Row>
                    <Col md={3}>
                      <Form.Label>Select Label</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ fontSize: "13px" }}
                        className="mb-1 w-100"
                        onChange={(e) => setLabel(e.target.value)}
                        value={label}
                      >
                        <option value={null}>Select Label</option>
                        <option value={0}>Row 1</option>
                        <option value={1}>Row 2</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
              </div>
              <div className="button_type footer_btn text-end px-3 pt-2">
                <button onClick={() => handleSectionChange("choose_file")}>
                  Back
                </button>
                <button
                  // disabled={!name || !label}
                  onClick={() => handleSectionChange("choose_file_name")}
                  style={{ marginLeft: 5 }}
                >
                  {/* {submit} */}Next
                </button>
              </div>
            </div>
          )}
          {activeSection === "choose_file_name" && (
            <div id="choose_file_name">
              <div className="common_css table_content">
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    File Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) => {
                      setFileName(e.target.value);
                      validateFileTitle(e.target.value);
                    }}
                    value={fileName}
                    placeholder="Enter File Name"
                    style={{ fontSize: 13 }}
                  />
                  {error && (
                    <span
                      style={{ fontSize: 10, color: "red", fontWeight: 600 }}
                    >
                      Folder Name Title should only contain capital letters,
                      small letters, underScore, and numbers "Space Not Allowed"
                      <br />
                    </span>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="12" className="my-3">
                  <Form.Label>
                    File Description <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    onChange={(e) => setFileDescription(e.target.value)}
                    value={fileDescription}
                    placeholder="Enter Description"
                    style={{ fontSize: 13 }}
                  />
                </Form.Group>
              </div>
              <div className="button_type footer_btn text-end px-3 pt-2">
                <button
                  onClick={() => handleSectionChange("select_name_label")}
                >
                  Back
                </button>
                <button
                  disabled={!fileName || !fileDescription || error}
                  onClick={handleImageChange}
                  style={{ marginLeft: 5 }}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>

        {/* 
        <div className="common_css">
          <Form.Label>
            Upload Data<span>*</span>
          </Form.Label>
          <Form.Control
            className="mb-3"
            required
            type="file"
            accept=".csv, .excel"
            placeholder="Upload data"
            autoFocus
            value={uploadData}
            onChange={(e) => setUploadData(e.target.value)}
          />
        </div>
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button disabled={!uploadData}>{submit}</button>
        </div> */}
      </Modal>
    </>
  );
};
export default PackagesUploadDataModal;
