import React, { useEffect, useRef, useState } from "react";
import ConsultantOutputConsole from "./ConsultantOutputConsole";
import { consultantServices } from "../../../APIServices/Editor/consultantServices";
import { NavLink } from "react-router-dom";

const ConsultantOutputMainConsole = ({
  width,
  isFullScreen,
  setIsFullScreen,
  editorFullMode,
  codeOutputs,
  editorTheme,
  fileId,
}) => {
  const [fontSize, setFontSize] = useState(12);
  const [projectInfo, setProjectInfo] = useState("");

  function fontIncrease() {
    setFontSize((prevFontSize) => prevFontSize + 1);
  }

  function fontDecrease() {
    setFontSize((prevFontSize) => Math.max(prevFontSize - 1, 5));
  }

  const getProjectInformation = async (fileId) => {
    let data = await consultantServices.getProjectInformation(fileId);
    setProjectInfo(data?.data);
  };

  const ProfileRef = useRef(null);
  const [profileActive, setProfileActive] = useState(false);
  const toggleProfile = () => {
    setProfileActive(!profileActive);
  };
  useEffect(() => {
    const handleClickProfileMenuOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setProfileActive(false);
      }
    };
    document.addEventListener("click", handleClickProfileMenuOutside, true);

    return () => {
      document.removeEventListener(
        "click",
        handleClickProfileMenuOutside,
        true
      );
    };
  }, []);

  useEffect(() => {
    getProjectInformation(fileId);
  }, [fileId]);
  return (
    <>
      <div
        className={`p-0 outputFont output__section_widget ${!editorFullMode ? "" : "fullModeHeight"} ${!editorTheme ? "outputShadowEditorTheme" : "outputShadow"}`}
        style={{
          width: isFullScreen
            ? "98%"
            : width <= 1100
              ? 1500 - width + "px"
              : "400px",
        }}
      >
        <div
          className="border-bottom p-2"
          style={{ background: "#ebebeb" }}
        >
          <div className="hstack gap-2 header_tool justify-content-between">
            <p className="m-0 d-flex">
              <b>Output</b>
            </p>
            <div className="hstack gap-1 OutputToolbar-Left">
              <i
                className="fa solid fa-plus"
                onClick={fontIncrease}
                title="Increase Font"
              ></i>

              <div className="vr"></div>
              <i
                className="fa solid fa-minus"
                onClick={fontDecrease}
                title="Decrease Font"
              ></i>
              <div className="vr"></div>
              <div
                ref={ProfileRef}
                className={`profile position-relative ${
                  profileActive ? "active" : ""
                }`}
              >
                <div className="icon_wrap hstack gap-1" onClick={toggleProfile}>
                  <i
                    className={`fa-solid fa-download ${profileActive ? "activee__icon" : ""}`}
                    title="Download Log"
                  ></i>
                </div>
                <div className="profile_dd shadow" style={{right: 0}}> 
                  <ul className="profile_ul">
                    <li>
                      <NavLink
                        onClick={() => {
                          toggleProfile();
                        }}
                        title="Download Log as an HTML file"
                      >
                        <i className="fa-solid fa-file-code"></i> HTML
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          toggleProfile();
                        }}
                        title="Download Log as an TEXT file"
                      >
                        <i className="fa-solid fa-file-lines"></i> TEXT
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="vr"></div>
              <i
                className={
                  isFullScreen ? "fa-solid fa-minimize" : "fa-solid fa-maximize"
                }
                title={isFullScreen ? "Minimized Mode" : "Full Mode"}
                onClick={() => setIsFullScreen(!isFullScreen)}
              ></i>
            </div>
          </div>
        </div>
        <ConsultantOutputConsole
          editorFullMode={editorFullMode}
          codeOutputs={codeOutputs}
          projectInfo={projectInfo}
          fontSize={fontSize}
        />
      </div>
    </>
  );
};

export default ConsultantOutputMainConsole;
