import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const ConsultantLogConsole = ({
  editorFullMode,
  fontSize,
  codeLogs,
}) => {
  return (
    <>
      <div
        className={`px-2 pt-2 
        ${!editorFullMode ? "output__section_widget_height" : "output__section_widget_full_height"}`}
      >
        <span style={{ fontSize: fontSize }}>
          {codeLogs?.length === 0 ? "No Logs" :
            codeLogs?.map((item, index) => (
              <div className={item.type === "error" ? "logsTextErrorColor" : "logsTextSuccessColor"}>
                <b style={{ fontSize: fontSize - 3, textTransform: "capitalize" }}>"{item?.functionName}" Function Log:</b> <i><SyntaxHighlighter key={index} style={docco}>{item?.result}</SyntaxHighlighter></i>
              </div>
            ))}
        </span>
      </div>
    </>
  );
};

export default ConsultantLogConsole;
