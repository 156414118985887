import React from "react";
import TableHeader from "../../../Component/TableHeader/TableHeader";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const PackageRecentHistory = ({ Validtable, handleTabChange }) => {
  const transactions = [
    {
      _id: "1",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "completed",
      comments: "i am here",
    },
    {
      _id: "2",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "pending",
      comments: "i am here",
    },
    {
      _id: "3",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "completed",
      comments: "i am here",
    },
    {
      _id: "4",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "pending",
      comments: "i am here",
    },
    {
      _id: "5",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "completed",
      comments: "i am here",
    },
    {
      _id: "6",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "completed",
      comments: "i am here",
    },
    {
      _id: "7",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "completed",
      comments: "i am here",
    },
  ];

  const getStatusColor = (reviewStatus) => {
    switch (reviewStatus.toLowerCase()) {
      case "completed":
        return "green";
      case "pending":
        return "red";
      default:
        return "inherit";
    }
  };

  return (
    <>
      {Validtable && (
        <div className="graph__title mt-2 d-flex justify-content-between">
          <p className="m-0">Recent Packages for validation</p>
        </div>
      )}
      <TableHeader />
      <div className="table_content">
        <Table striped hover>
          <thead>
            <tr>
              <th style={{ width: 40 }}>Sr.</th>
              <th>Package Name</th>
              <th>Versions</th>
              <th>Review Date & Time</th>
              <th>Review Status</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{transaction.packageName}</td>
                <td>{transaction.version}</td>
                <td>
                  {
                    <Moment format="DD MMM YYYY | HH:mm">
                      {transaction.reviewDateTime}
                    </Moment>
                  }
                </td>
                <td
                  className="text-capitalize"
                  style={{
                    color: getStatusColor(transaction.reviewStatus),
                    fontWeight: 500,
                  }}
                >
                  {transaction.reviewStatus}
                </td>
                <td>{transaction.comments}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="button_type text-end py-3">
          <button
            onClick={(e) =>
              handleTabChange(
                e,
                "History",
                "packageHistory",
                "fa-solid fa-clock-rotate-left"
              )
            }
          >
            View All
          </button>
        </div>
      </div>
    </>
  );
};

export default PackageRecentHistory;
