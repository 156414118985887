import React, { useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices";

function PackageListSidebar({ editorFullMode, searchPublishedPackageListForConsultant }) {
  const [packageCategory, setPackageCategory] = useState([]);
  const [packageTags, setPackageTags] = useState([]);
  const [searchData, setSearchData] = useState("");

  const getActivePackageCategory = async () => {
    let data = await packagesServices.getActivePackageCategory()
    setPackageCategory(data?.data)
  }

  const getPackageTagList = async () => {
    let data = await packagesServices.getPackageTagList()
    setPackageTags(data?.data)
  }

  useEffect(() => {
    getActivePackageCategory();
    getPackageTagList();
  }, []);
  return (
    <div className="d-flex">
      <div className={`dataHistory`} style={{ width: 400, maxHeight: "100%" }}>
        <div className="search_tabs">
          <i className="fa-solid fa-magnifying-glass"></i>
          <Form.Control
            type="search"
            placeholder="Search Packages"
            value={searchData}
            onChange={(e) => {searchPublishedPackageListForConsultant(e.target.value); setSearchData(e.target.value)}}
          />
        </div>
        <hr className="m-0" />
        <div
          className={
            editorFullMode ? "packageSidebarFullHeight" : "packageSidebaHeight"
          }
        >
          <div className="p-2 border-bottom">
            <Form.Label className="mb-1">Sort Packages</Form.Label>
            <div className="mb-1">
              <Form.Check
                type="radio"
                id="radio-popular"
                name="radioGroup"
                label="Popular"
              />
              <Form.Check
                type="radio"
                id="radio-quality"
                name="radioGroup"
                label="Quality"
              />
              <Form.Check
                type="radio"
                id="radio-best-sell"
                name="radioGroup"
                label="Best Sell Program"
              />
            </div>
          </div>
          <div className="p-2 border-bottom">
            <Form.Label className="mb-2">Filter By Category</Form.Label>
            <Form.Select aria-label="Select Category">
              <option>Select Category</option>
              {packageCategory?.map((item, index) => (
                <option key={index} value={item.categoryId}>{item.category}</option>
              ))}
            </Form.Select>
          </div>
          <div className="p-2 border-bottom">
            <Form.Label>Filter By Type of</Form.Label>
            <div className="taglist_type">
              <Badge className="tag activetagfilter">Free</Badge>
              <Badge className="tag">Paid</Badge>
            </div>
          </div>
          <div className="p-2">
            <Form.Label>Filter By Tags</Form.Label>
            <div className="taglist_type">
              <Badge className="tag activetagfilter">Json</Badge>
              {packageTags?.map((item, index) => (
                <Badge key={index} className="tag">{item?.tags}</Badge>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageListSidebar;
