import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import VerifiedUserData from "./VerifiedUserDetailsinModal.js.js";
import ReferenceListDetails from "./ReeferenceListDetailsinModal.js";
import ChangesLog from "./ChangesLog.js";
import VersionsDataDetails from "./VersionsData.js";
import PackagesDetailsTabs from "./PackagesDetailsTabs.js";
import PackageDetailsSidebar from "./PackageDetailsSidebar.js";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices.jsx";
import ReadmeDataDetails from "./ReadmeDataDetails.js";
import PackageExample from "./PackageExample.js";

const PackagesDetailsData = ({ editorFullMode }) => {
  const [disabled, setDisabled] = useState(true);
  const [packageData, setPackageData] = useState("");
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  const [tab, setTab] = useState("readme");
  const [showReferenceData, setShowReferenceData] = useState(false);

  const activeTab = JSON.parse(localStorage.getItem("activeTabData")) || [];

  const getPublishedPackageDetailsForConsultant = async (packageId) => {
    let data =
      await packagesServices.getPublishedPackageDetailsForConsultant(packageId);
    setPackageData(data?.data);
  };

  useEffect(() => {
    getPublishedPackageDetailsForConsultant(activeTab[1]);
  }, [activeTab[1]]);


  return (
    <>
      <Container fluid className="p-0">
        <PackagesDetailsTabs tab={tab} setTab={setTab} />
        <div className="main_partsof">
          <Row>
            <Col
              md={8}
              className="p-0"
              style={{ borderRight: "1px solid var(--bs-border-color)" }}
            >
              <div
                className={`${editorFullMode ? "packageSidebarFullHeight" : "packageSidebaHeight"}`}
              >
                <div className="popular_container p-3">
                  {tab === "readme" ? (
                    <ReadmeDataDetails
                      packageName={packageData?.packageName}
                      statements={packageData?.statements}
                      description={packageData?.longDescription}
                      tags={packageData?.allTags}
                      version={packageData?.version}
                      validatorDetails={packageData?.validatorDetails}
                    />
                  ) : tab === "logs" ? (
                    <ChangesLog
                      changelogs={packageData?.changeLogs}
                    />
                  ) : tab === "examples" ? (
                    <PackageExample
                      examples={packageData?.examples}
                    />
                  ) : tab === "versions" ? (
                    <VersionsDataDetails
                      consultantPackageData={packageData?.consultantPackageData}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div
                className={`${editorFullMode ? "packageSidebarFullHeight" : "packageSidebaHeight"}`}
              >
                <div className="p-3">
                  <PackageDetailsSidebar
                    version={packageData?.version}
                    regularPrice={packageData?.regularPrice}
                    salePrice={packageData?.salePrice}
                    totalPurchased={packageData?.totalPurchased}
                    validatorDetails={packageData?.validatorDetails}
                    referances={packageData?.referances}
                    publishDateTime={packageData?.publishDateTime}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <ReferenceListDetails
          show={showReferenceData}
          setShow={setShowReferenceData}
          onStart={onStart}
          draggleRef={draggleRef}
          bounds={bounds}
          onMouseOver={() => setDisabled(false)}
          onMouseOut={() => setDisabled(true)}
          disabled={disabled}
          title={"Reference Details"}
          submit={"Update"}
        />
      </Container>
    </>
  );
};

export default PackagesDetailsData;
