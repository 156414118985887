import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const invitedDataServices = {
  // Invited Data
  registeredUserByInvitation,
  getInvitationData,

  //Register
  userRegistration
};

// Registered User By invitation
async function registeredUserByInvitation(userData) {
  let headerData = {
    "content-type": "application/json",
  };
  const response = await fetch(
    `${config.API_URL}registeredUserByInvitation`,
    headersWithAuth("POST", userData, headerData)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get Inivitation Details
async function getInvitationData(token) {
  const response = await fetch(
    `${config.API_URL}getInvitationData/${token}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Registered User
async function userRegistration(userData) {
  let headerData = {
    "content-type": "application/json",
  };
  const response = await fetch(
    `${config.API_URL}userRegistration`,
    headersWithAuth("POST", userData, headerData)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
