import React from "react";
import MAKETTESTOutput from "../GraphicalOutput/MAKETTESTOutput";
import DynamicTableRowColumn from "../GraphicalOutput/DynamicTableRowColumn";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import MAKESUMMARYOutput from "../GraphicalOutput/MAKESUMMARYOutput";
import ProjectInformationConsole from "../HeaderInformation/ProjectInformationConsole";
const ConsultantOutputConsole = ({
  editorFullMode,
  codeOutputs,
  projectInfo,
  fontSize,
}) => {
  return (
    <>
      <div
        className={`px-2 pt-2 ${!editorFullMode ? "output__section_widget_height" : "output__section_widget_full_height"}`}
      >
        <div style={{ fontSize: fontSize }}>
          <ProjectInformationConsole projectInfo={projectInfo} />
          {codeOutputs?.length === 0 ? (
            <div style={{ textAlign: "center", marginBottom: 10 }}>
              No Output
            </div>
          ) : (
            codeOutputs?.map((item, index) => (
              <div key={index}>
                {item?.type === "output" ? (
                  <>
                    {item.functionName === "MAKE TTEST" ? (
                      <MAKETTESTOutput
                        codeOutputs={item.result}
                        functionName={item.functionName}
                      />
                    ) : item.functionName === "MAKE RANDOM" ||
                      item.functionName === "MAKE SORT" ||
                      item.functionName === "MAKE SWITCH" ||
                      item.functionName === "MAKE MERGE" ? (
                      <DynamicTableRowColumn
                        codeOutputs={item.result}
                        functionName={item.functionName}
                      />
                    ) : item.functionName === "MAKE SUMMARY" ? (
                      <MAKESUMMARYOutput
                        codeOutputs={item.result}
                        functionName={item.functionName}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <h6>"{item.functionName}" Function Output</h6>
                    </div>
                    <div className="mb-2">
                      <SyntaxHighlighter style={docco}>
                        {item.result}
                      </SyntaxHighlighter>
                    </div>
                  </>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default ConsultantOutputConsole;
