import React from "react";
import TableHeader from "../../../../Component/TableHeader/TableHeader";
import { Form, Table } from "react-bootstrap";
import Moment from "react-moment";

const ProgramByProjectTable = ({ Validtable, handleTabChange }) => {
  const transactions = [
    {
      _id: "1",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "2",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "3",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "4",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "5",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "6",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "7",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
  ];

  return (
    <>
      {Validtable && (
        <div className="graph__title mt-1 d-flex justify-content-between">
          <Form.Select
            aria-label="Default select example"
            style={{ width: 127, fontSize: 13 }}
          >
            <option value="project 1">Project 1</option>
            <option value="project 2">Project 2</option>
            <option value="project 3">Project 3</option>
          </Form.Select>
        </div>
      )}
      <TableHeader />
      <div className="table_content" style={{ maxHeight: "100%" }}>
        <Table striped hover className="mb-3">
          <thead className="thead-sticky">
            <tr>
              <th style={{ width: 40 }}>Sr.</th>
              <th>Program File Name</th>
              <th>Location</th>
              <th>Versions</th>
              <th>Created Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((transaction, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{transaction.programFileName}</td>
                <td>{transaction.location}</td>
                <td>{transaction.version}</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">
                    {transaction.createdDateTime}
                  </Moment>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="button_type text-end">
          <button
            onClick={(e) => handleTabChange(e, "History", "consultantPackageHistory", "fa-solid fa-clock-rotate-left", "custom")}
          >
            View All
          </button>
        </div>
      </div>
    </>
  );
};

export default ProgramByProjectTable;
