import React from "react";
import { Spinner } from "react-bootstrap";

export const NoDataFoundTable = ({ MSG, Height, Class }) => (
  <p
    className={`d-flex justify-content-center ${
      Height || "fiftyVh"
    } align-items-center blue ${Class}`}
  >
    {MSG}
  </p>
);

export const SpinerDataLoader = ({ width, height, Height, Class }) => (
  <p
    className={`d-flex justify-content-center ${
      Height || "fiftyVh"
    } align-items-center blue ${Class}`}
  >
    <Spinner className="me-1" style={{ width: width || 20, height: height|| 20, color: "var(--blue)" }} animation="border" />
  </p>
);

export const IdDetails = ({ CrfView, NoData }) => (
  <span className={CrfView ? "crfHight" : "tableRightContent"}>
    {NoData || "Please Select Any Item To Show Details"}
  </span>
);