import React, { useEffect, useRef, useState } from "react";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";
import TableHeader from "../../../Component/TableHeader/TableHeader";
import ExportFailedStatusData from "./ExportFailedStatusData";
import FailedStatusTableData from "./FailedStatusTableData";

const FailedStatus = ({ tableRef, onMouseDown, editorFullMode }) => {
  const [failedLoginData, setFailedLoginData] = useState([]);
  const [exportFailedLogData, setExportFailedLogData] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const getFailedLoginRecord = async () => {
    let data = await loginActivitiesServices.getFailedLoginRecord();
    setFailedLoginData(data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getFailedLoginRecord();
  }, []);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  return (
    <>
      <TableHeader ExportBtn handleExport={setExportFailedLogData} />
      <FailedStatusTableData
        tableRef={tableRef}
        loading={loading}
        onMouseDown={onMouseDown}
        failedLoginData={failedLoginData}
        editorFullMode={editorFullMode}
      />

      <ExportFailedStatusData
        show={exportFailedLogData}
        setShow={setExportFailedLogData}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        disabled={disabled}
        title={"Export Failed Log Data "}
        submit={"Export"}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
      />
    </>
  );
};

export default FailedStatus;
