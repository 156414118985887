import React, { useState } from "react";
import TableHeader from "../../../../Component/TableHeader/TableHeader";
import { Form, Table } from "react-bootstrap";
import Moment from "react-moment";
import Pagination from "../../../../Component/Paginatation/Pagination";

const ProgramByProjectHistoryTableData = ({ Validtable, tableRef, onMouseDown, editorFullMode }) => {
  const transactions = [
    {
      _id: "1",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "2",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "3",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "4",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "5",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "6",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "7",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "8",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "9",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "10",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "11",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
    {
      _id: "12",
      programFileName: "Lorem ipsum dolor sit",
      location: "USA",
      version: "10.1.0",
      createdDateTime: "2024-04-25 10:30:00",
    },
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = transactions?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(transactions?.length / itemsPerPage);

  return (
    <>
      {Validtable && (
        <div className="graph__title mt-1 d-flex justify-content-between">
          <Form.Select
            aria-label="Default select example"
            style={{ width: 127, fontSize: 13 }}
          >
            <option value="project 1">Project 1</option>
            <option value="project 2">Project 2</option>
            <option value="project 3">Project 3</option>
          </Form.Select>
        </div>
      )}
      <TableHeader ExportBtn />
      <div
        className="table_content overflow-auto mx-2"
        style={{
          maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
        }}
      >
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            className="m-0  custom-table"
            ref={tableRef}
            bordered
            striped
          >
            <thead className="thead-sticky">
              <tr>
                <th style={{ width: 40 }} onMouseDown={(e) => onMouseDown(e, 0)}>Sr.</th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Program File Name</th>
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Location</th>
                <th onMouseDown={(e) => onMouseDown(e, 3)}>Versions</th>
                <th onMouseDown={(e) => onMouseDown(e, 4)}>Created Date & Time</th>
              </tr>
            </thead>
            <tbody>
              {result?.map((transactions, index) => (
                <tr key={index}>
                  <td>{startIndex + index + 1}</td>
                  <td>{transactions.programFileName}</td>
                  <td>{transactions.location}</td>
                  <td>{transactions.version}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm">
                      {transactions.createdDateTime}
                    </Moment>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="mx-2">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={transactions?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default ProgramByProjectHistoryTableData;
