import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { consultantServices } from "../../../APIServices/Editor/consultantServices";
import Pagination from "../../../Component/Paginatation/Pagination";
import { NoDataFoundTable } from "../../../Component/NoData/NoDataFoundTable";

const TrashHistoryTableData = ({ tableRef, onMouseDown, editorFullMode, trashHistory, getConsultantDeletedProjectFileFolder, loading }) => {

  const restoreDevelopmentFolderData = async (e, folderId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Restore this Item?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Restore it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.id = folderId
        let data = await consultantServices.restoreDevelopmentFolderData(userData);
        if (data?.statusCode === 200) {
          getConsultantDeletedProjectFileFolder();
        }
      }
    });
  };

  const permanentDeleteConsultantFolders = async (e, projectId, folderId, type) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Permanenet Delete this Item?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.folderId = folderId
        userData.projectId = projectId
        userData.folderType = type
        let data = await consultantServices.permanentDeleteConsultantFolders(userData);
        if (data?.statusCode === 200) {
          getConsultantDeletedProjectFileFolder();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = trashHistory?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(trashHistory?.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {trashHistory?.length > 0 ? (
            <div
              className="table_content overflow-auto mx-2"
              style={{
                maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
              }}
            >
              <div className="table-container tableLibrarySection">
                <Table
                  id="resizable-table"
                  className="m-0  custom-table"
                  ref={tableRef}
                  bordered
                  striped
                >
                  <thead className="thead-sticky">
                    <tr>
                      <th
                        onMouseDown={(e) => onMouseDown(e, 0)}
                        style={{ width: 40 }}
                      >
                        Sr.
                      </th>
                      <th onMouseDown={(e) => onMouseDown(e, 1)}>Name</th>
                      <th onMouseDown={(e) => onMouseDown(e, 2)}>Type</th>
                      <th onMouseDown={(e) => onMouseDown(e, 3)}>Deleted Date | Time</th>
                      <th className="text-center" style={{ width: 80 }} onMouseDown={(e) => onMouseDown(e, 4)}>Restore</th>
                      <th className="text-center" style={{ width: 80 }} onMouseDown={(e) => onMouseDown(e, 5)}>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result?.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1 + startIndex}</td>
                        <td className={item.type !== "file" && "text-capitalize"}>
                          <i style={{ color: "#103c5e" }} className={`px-2 fa ${item.type === "file" ? "fa-file" : "fa-folder"} edit`}></i>
                          {item.type === "project" ? item.projectName : item.name}
                        </td>
                        <td className="text-capitalize">{item.type}</td>
                        <td style={{ width: 50 }}>
                          <Moment format="DD MMM YYYY | HH:mm">
                            {item.deleteDateTime}
                          </Moment>
                        </td>
                        <td onClick={(e) => restoreDevelopmentFolderData(e, item._id)} className="text-center"><i style={{ color: "green", cursor: "pointer" }} title={`Restore ${item.type === "project" ? item.projectName : item.name}`} className="fa-solid fa-trash-can-arrow-up"></i></td>
                        <td onClick={(e) => permanentDeleteConsultantFolders(e, item.projectId, item._id, item.type)} className="text-center"><i style={{ color: "red", cursor: "pointer" }} title={`Delete ${item.type === "project" ? item.projectName : item.name}`} className="fa fa-trash-can"></i></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="mx-2">
                <Pagination
                  totalPage={totalPage}
                  pageNumber={pageNumber}
                  itemsPerPage={itemsPerPage}
                  totalItems={trashHistory?.length}
                  setPageNumber={setPageNumber}
                  setItemsPerPage={setItemsPerPage}
                />
              </div>
            </div>
          ) : (
            <NoDataFoundTable MSG="No Trash Data Found" />
          )}
        </>
      )}
    </>
  );
};

export default TrashHistoryTableData;
