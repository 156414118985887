import React, { useEffect, useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { subscriptionPlanServices } from "../../../APIServices/Editor/subscriptionPlanServices";
import Moment from "react-moment";

const ActiveSubscriptionPlan = ({ handleTabChange, userCurrentData }) => {
  const [planData, setPlanData] = useState("");
  const [loading, setloading] = useState(false);

  const getSubscriptionPlan = async (id) => {
    setloading(true)
    let data = await subscriptionPlanServices.getSubscriptionPlan(id);
    setPlanData(data?.data);
    setloading(false);
  };

  useEffect(() => {
    getSubscriptionPlan(userCurrentData?.subscriptionPlanId);
  }, [userCurrentData]);
  return (
    <>
      {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '30vh' }}>
            <Spinner animation="border" style={{height: 15, width: 15}}/>
          </div>
          ) : (
          <div>
            <div className="d-flex justify-content-between analytic_part mb-2">
              <p style={{ fontSize: 14, fontWeight: 500 }}>{planData?.planName}</p>
              <h6>
                <Badge bg="success">
                  {planData?.type === "year" ? "Yearly" : "Monthly"}
                </Badge>
              </h6>
            </div>
            <div className="d-flex justify-content-between">
              <p className="m-0" style={{ fontWeight: 500 }}>
                Price
              </p>
              <h6 className="m-0" style={{ fontSize: 13 }}>
                {planData?.currency}
                {planData?.price}
              </h6>
            </div>
            <div className="d-flex justify-content-between analytic_part mb-2">
              <p style={{ fontWeight: 500 }}>Expiry Date & Time</p>
              <h6 style={{ fontSize: 13 }}>
                <Moment format="DD MMM YYYY | HH:mm:ss">
                  {userCurrentData?.subscriptionExpiry}
                </Moment>
              </h6>
            </div>
            <div className="list_analytic mb-2">
              <h6 style={{ fontSize: 14 }}>Featured</h6>
              <ul>
                {planData?.featured?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="button_type text-end">
              <button
                className="m-0"
                onClick={(e) =>
                  handleTabChange(
                    e,
                    "Billing",
                    "billing",
                    "fa-solid fa-file-invoice-dollar"
                  )
                }
              >
                View History
              </button>
            </div>
          </div>
        )}
        </>
  );
};

export default ActiveSubscriptionPlan;
