import React, {useRef, useState } from "react";
import TableHeader from "../../../Component/TableHeader/TableHeader";
import ExportPackageHistoryData from "./ExportPackageHistoryData";
import PackageHistroryTableData from "./PackageHistroryTableData";

const PackageHistrory = ({
  tableRef,
  onMouseDown,
  editorFullMode,
}) => {
  const [exportPackageHistory, setExportPackageHistory] = useState("");
  const [disabled, setDisabled] = useState(true);

  const transactions = [
    {
      _id: "1",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "completed",
      comments: "i am here",
      action: "1",
    },
    {
      _id: "2",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "pending",
      comments: "i am here",
      action: "1",
    },
    {
      _id: "3",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "completed",
      comments: "i am here",
      action: "1",
    },
    {
      _id: "4",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "pending",
      comments: "i am here",
      action: "1",
    },
    {
      _id: "5",
      packageName: "Lorem ipsum dolor sit",
      version: "10.1.0",
      reviewDateTime: "2024-04-25 10:30:00",
      reviewStatus: "completed",
      comments: "i am here",
      action: "1",
    },
  ];



  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <TableHeader ExportBtn handleExport={setExportPackageHistory}/>
      <div
        className="table_content overflow-auto mx-2"
        style={{
          maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
        }}
      >
        <div className="table-container tableLibrarySection">
          <PackageHistroryTableData
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            transactions={transactions}
          />
        </div>
      </div>
    
      <ExportPackageHistoryData
        show={exportPackageHistory}
        setShow={setExportPackageHistory}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title={"Export Package History Data "}
        submit={"Export"}
        disabled={disabled}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
      />
    </>
  );
};

export default PackageHistrory;
