import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const ProjectPropertiesTableData = ({ tableRef, projectData }) => {
  return (
    <>
      <div className="table_content overflow-auto m-2">
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            className="m-0  custom-table"
            ref={tableRef}
            bordered
            striped
          >
            <thead className="thead-sticky">
              <tr>
                <th>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Project Name</td>
                <td>{projectData?.projectName}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{projectData.description}</td>
              </tr>
              <tr>
                <td>Path</td>
                <td>/project</td>
              </tr>
              <tr>
                <td>Created Date & Time</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">
                    {projectData.createdAt}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Updated Date & Time</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">
                    {projectData.updatedAt}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default ProjectPropertiesTableData;
