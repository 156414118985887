import React from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Col, Form } from "react-bootstrap";

const ExportPackageHistoryData = ({
  show,
  title,
  submit,
  bounds,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
}) => {
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={350}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div>
          <div className="common_css table_content">
            <Form.Group as={Col} md="12" className="mb-1">
              <Form.Label>
                Format <span>*</span>
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                style={{ fontSize: "13px" }}
              >
                <option>Select Format</option>
                <option value="csv">CSV</option>
                <option value="excel">Excel</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="button_type footer_btn text-end px-3 pt-2">
            <button className="mb-2">{submit}</button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ExportPackageHistoryData;
