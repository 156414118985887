import { Modal } from 'antd';
import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import Draggable from "react-draggable";

const AddSoftwareValidation = ({
    show,
    title,
    bounds,
    submit,
    setShow,
    onStart,
    disabled,
    draggleRef,
    onMouseOut,
    onMouseOver,
    addData
}) => {
    let [software, setSoftware] = useState("");

    const addNewSoftwareData = () => {
        addData({software: software}); 
        setShow(false)
        setSoftware("")
    }
    return (
        <>
            <Modal
                title={
                    <div
                        className="modal_Heading"
                        onMouseOver={() => {
                            if (disabled) {
                                onMouseOver();
                            }
                        }}
                        onMouseOut={onMouseOut}
                    >
                        <h5>{title}</h5>
                    </div>
                }
                width={400}
                open={show}
                onOk={() => setShow(!show)}
                onCancel={() => setShow(!show)}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        nodeRef={draggleRef}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            >
                <div className="common_css ">
                    <Form.Label>
                        Software<span>*</span>
                    </Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={software}
                        onChange={(e) => setSoftware(e.target.value)}
                        placeholder="Enter Software"
                        autoFocus
                    />
                </div>
                <div className="button_type footer_btn text-end px-3 pt-2">
                    <button
                    onClick={addNewSoftwareData}
                    >
                        {submit}
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default AddSoftwareValidation