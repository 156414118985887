import React from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import PackagesPropertiesTableData from "./PackagesPropertiesTableData";

const PackagesPropertiesModalData = ({
  show,
  title,
  bounds,
  setShow,
  onStart,
  tableRef,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  packagesPropertiesData,
}) => {
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <PackagesPropertiesTableData
          tableRef={tableRef}
          packagesPropertiesData={packagesPropertiesData}
        />
      </Modal>
    </>
  );
};
export default PackagesPropertiesModalData;
