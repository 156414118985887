import React, { useEffect, useState } from "react";
import TableHeader from "../../../Component/TableHeader/TableHeader";
import TrashHistoryTableData from "./TrashHistoryTableData";
import { consultantServices } from "../../../APIServices/Editor/consultantServices";

const TrashHistory = ({ tableRef, onMouseDown, editorFullMode }) => {
  const [trashHistory, setTrashHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const getConsultantDeletedProjectFileFolder = async () => {
    setLoading(true)
    let data = await consultantServices.getConsultantDeletedProjectFileFolder();
    setTrashHistory(data?.data);
    setLoading(false)
  };
  
  useEffect(() => {
    getConsultantDeletedProjectFileFolder();
  }, []);

  return (
    <>
      <TableHeader
        Search
      />
      <TrashHistoryTableData
        loading={loading}
        tableRef={tableRef}
        onMouseDown={onMouseDown}
        trashHistory={trashHistory}
        editorFullMode={editorFullMode}
        getConsultantDeletedProjectFileFolder={getConsultantDeletedProjectFileFolder}
      />
    </>
  );
};

export default TrashHistory;
