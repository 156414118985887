import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form, Spinner } from "react-bootstrap";
import { consultantServices } from "../../../APIServices/Editor/consultantServices";
const AddNewCustomProjectFolderModal = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  projectId,
  folderId,
  getProjectFolderStructured,
}) => {
  let [folderName, setFolderName] = useState("")
  const [loader, setLoader] = useState(false);

  const addNewProjectFolderStructured = async () => {
    setLoader(true);
    let userData = {};
    userData.projectId = projectId;
    userData.folderId = folderId;
    userData.folderName = folderName;
    userData.structureType = "custom";
    let data = await consultantServices.addNewProjectFolderStructured(userData);
    if (data?.statusCode === 200) {
      setShow(false);
      setLoader(false);
      setFolderName("");
      getProjectFolderStructured(folderId);
    }
  };

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Form.Label>
            Folder Name<span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder="Enter Folder Name"
            autoFocus
          />
        </div>
        <hr className="w-100 m-0" />
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            disabled={!folderName}
            onClick={addNewProjectFolderStructured}
          >
            {loader ? (
                <Spinner
                  animation="border"
                  style={{ width: 13, height: 13 }}
                  className="me-1"
                />
              ) : null}
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default AddNewCustomProjectFolderModal;
