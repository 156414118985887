import React from "react";
import { Col, Row } from "react-bootstrap";

export default () => {
  const onDragStart = (event, icon, nodeType) => {
    let dataValue = {};
    dataValue.icon = icon;
    dataValue.nodeType = nodeType;
    event.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify(dataValue)
    );
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <aside>
      <div className="listing_output">
        <div className="px-2">
          <h6 className="m-0">IO Listing</h6>
          <Row>
            <Col md={4}>
              <div
                className="dndnode input"
                onDragStart={(event) =>
                  onDragStart(event, "fa-solid fa-table", "Excel Reader")
                }
                draggable
              >
                <div className="nodes_listing">
                  <i className="fa-solid fa-table"></i>
                  <p>Excel Reader</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div
                className="dndnode"
                onDragStart={(event) => onDragStart(event, "fa-solid fa-table", "Excel Writer")}
                draggable
              >
                <div className="nodes_listing">
                  <i className="fa-solid fa-table"></i>
                  <p>Excel Writer</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div
                className="dndnode output"
                onDragStart={(event) =>
                  onDragStart(event, "fa-solid fa-table", "Microsoft Authenticator")
                }
                draggable
              >
                <div className="nodes_listing">
                  <i className="fa-solid fa-table"></i>
                  <p>Microsoft Authenticator</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div
                className="dndnode"
                onDragStart={(event) =>
                  onDragStart(event, "fa-solid fa-table", "Google Authenticator")
                }
                draggable
              >
                <div className="nodes_listing">
                  <i className="fa-solid fa-table"></i>
                  <p>Google Authenticator</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div
                className="dndnode"
                onDragStart={(event) =>
                  onDragStart(event, "fa-solid fa-table", "Google Sheet Reader")
                }
                draggable
              >
                <div className="nodes_listing">
                  <i className="fa-solid fa-table"></i>
                  <p>Google Sheet Reader</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div
                className="dndnode"
                onDragStart={(event) =>
                  onDragStart(event, "fa-solid fa-table", "Google Sheet Writer")
                }
                draggable
              >
                <div className="nodes_listing">
                  <i className="fa-solid fa-table"></i>
                  <p>Google Sheet Writer</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div
                className="dndnode"
                onDragStart={(event) => onDragStart(event, "fa-solid fa-table", "CSV Reader")}
                draggable
              >
                <div className="nodes_listing">
                  <i className="fa-solid fa-table"></i>
                  <p>CSV Reader</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div
                className="dndnode"
                onDragStart={(event) => onDragStart(event, "fa-solid fa-table", "CSV Writer")}
                draggable
              >
                <div className="nodes_listing">
                  <i className="fa-solid fa-table"></i>
                  <p>CSV Writer</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="nodes_listing">
                <i className="fa-regular fa-circle-right"></i>
                <p>Show All</p>
              </div>
            </Col>
          </Row>
        </div>
        <hr className="my-2" />
        <div className="px-2">
          <h6 className="m-0">Manipulation Listing</h6>
          <Row>
            <Col md={4}>
              <div className="nodes_listing">
                <i className="fa-solid fa-table"></i>
                <p>Excel Reader</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="nodes_listing">
                <i className="fa-solid fa-table"></i>
                <p>Excel Writer</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="nodes_listing">
                <i className="fa-solid fa-table"></i>
                <p>Microsoft Authenticator</p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col md={4}>
              <div className="nodes_listing">
                <i className="fa-solid fa-table"></i>
                <p>Google Authenticator</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="nodes_listing">
                <i className="fa-solid fa-table"></i>
                <p>Google Sheet Reader</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="nodes_listing">
                <i className="fa-solid fa-table"></i>
                <p>Google Sheet Writer</p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col md={4}>
              <div className="nodes_listing">
                <i className="fa-solid fa-table"></i>
                <p>CSV Reader</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="nodes_listing">
                <i className="fa-solid fa-table"></i>
                <p>CSV Writer</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="nodes_listing">
                <i className="fa-regular fa-circle-right"></i>
                <p>Show All</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </aside>
  );
};
