import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form } from "react-bootstrap";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices";
import Swal from "sweetalert2";
const PackageFileCheckIn = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  openPackageFileById,
  packagesPropertiesData,
  getPackageFileCodeById,
  packageFileType
}) => {
  let [remark, setRemark] = useState("");

  const checkInPackageFiles = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to check in this file?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, check in!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = packagesPropertiesData?._id;
        userData.remark = remark;
        let data = await packagesServices.checkInPackageFiles(userData);
        if (data?.statusCode === 200) {
          openPackageFileById(packageFileType);
          getPackageFileCodeById()
          setShow(!show)
        }
      }
    });
  };

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Form.Label>
            Remark<span>*</span>
          </Form.Label>
          <Form.Control
            className="mb-3"
            required
            as="textarea"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            placeholder="Enter Remark"
            autoFocus
          />
        </div>
        <hr className="w-100 m-0" />
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            disabled={!remark}
            onClick={checkInPackageFiles}
          >
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default PackageFileCheckIn;
