import React from "react";

const AlreadyRegisteredMessage = () => {
  return (
    <>
      <div className="sign_url">
        <div className="form_details text-center w-50 py-5">
          <div className="mb-3 ">
            <i
              style={{ color: "green", fontSize: 60 }}
              class="fa-solid fa-circle-check"
              alt="Invitation Link Expired"
            ></i>
          </div>
          <div className=" mb-2" style={{ color: "#103c5e", fontSize: "25px" }}>
            <b>Already Registered</b>
          </div>
          <span style={{fontSize: "14px"}}>
            Your registration alredy completed. If you have any query.<br /> Feel free
            to contact "Administrator".
          </span>

          <div className="sub_buttons pt-4">
            <a href="/">
              <button>Login</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlreadyRegisteredMessage;
