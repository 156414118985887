import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices";

const PublishPackageModal = ({
  show,
  setShow,
  onStart,
  draggleRef,
  bounds,
  onMouseOver,
  onMouseOut,
  disabled,
  title,
  submit,
  Close,
  openEditFolderData,
  categoryList,
  getPackageFolderLists,
}) => {
  const [packageName, setPackageName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const Editor = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  let [tagList, setTagList] = useState([]);
  const [referance, setReferance] = useState([{reference: ""}]);
  const publishPackagesDataForDeveloper = async () => {
    let userData = {};
    userData.id = openEditFolderData?._id;
    userData.packageName = packageName;
    userData.shortDescription = shortDescription;
    userData.longDescription = longDescription;
    userData.category = category;
    userData.referance = referance;
    userData.tags = [tags];
    let data = await packagesServices.publishPackagesDataForDeveloper(userData);
    if (data?.statusCode === 200) {
      getPackageFolderLists();
      clearPackageData();
    }
  };

  const clearPackageData = () => {
    setPackageName("");
    setLongDescription("");
    setShortDescription("");
    setCategory("");
    setTags("");
    setShow(!show);
  };

  const getPackageTagList = async () => {
    let data = await packagesServices.getPackageTagList();
    let result = data?.data;
    setTagList(result);
  };

  const handleAddReferance = () => {
    setReferance([
      ...referance,
      { referance: "" },
    ]);
  };

  const handleRemoveReferances = (sectionIndex) => {
    const updatedReferance = referance.filter(
      (_, index) => index !== sectionIndex
    );
    setReferance(updatedReferance);
  };

  const updateReferanceState = (e, sectionIndex, key) => {
    const updatedReferance = [...referance];
    updatedReferance[sectionIndex][key] = e.target.value;
    setReferance(updatedReferance);
  };

  useEffect(() => {
    getPackageTagList();
    setPackageName(openEditFolderData?.name);
    setShortDescription(openEditFolderData?.description);
    setLongDescription(openEditFolderData?.longDescription);
    setCategory(openEditFolderData?.categoryId);
    setTags(openEditFolderData?.tagId);
  }, [openEditFolderData]);

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={"min(1440px, 50% + 100px)"}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={clearPackageData}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="details_data1 height_scroll">
          <div className="mb-3">
            <Row>
              <Form.Group as={Col} md="12" className="mb-2">
                <Form.Label>Package Full Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setPackageName(e.target.value)}
                  value={packageName}
                  placeholder="Enter Package Full Name"
                />
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2">
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  onChange={(e) => setShortDescription(e.target.value)}
                  value={shortDescription}
                  placeholder="Enter Short Description"
                />
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2">
                <Form.Label>Long Description</Form.Label>
                <div className="sidebar_Content">
                  <ReactQuill
                    modules={modules}
                    formats={Editor}
                    theme="snow"
                    value={longDescription}
                    onChange={setLongDescription}
                  />
                </div>
              </Form.Group>
              <Form.Group as={Col} md="12">
              <Form.Label>References <i title="Add reference" className="fa fa-circle-plus" onClick={handleAddReferance}></i></Form.Label>
                {referance?.map((item, index) => (
                  <InputGroup className="mb-3" key={index}>
                    <InputGroup.Text style={{fontSize: 12, backgroundColor: "#103c5e", color: "#fff"}}>{`Reference ${index+1}`}</InputGroup.Text>
                    <Form.Control
                      placeholder={`Enter Reference`}
                      onChange={(e) => updateReferanceState(e, index, "reference")}
                      value={item.reference}
                    />
                    <div className="d-flex align-items-center ">
                      <i onClick={() => handleRemoveReferances(index)} style={{ fontSize: 12, color: "red", cursor: "pointer" }} className="mx-2 fa-regular fa-trash-can"></i>
                    </div>
                  </InputGroup>
                ))}
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label>Categories</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option>Select Categories</option>
                  {categoryList?.map((item, index) => (
                    <option
                      selected={category === item.categoryId}
                      key={index}
                      value={item.categoryId}
                    >
                      {item.category}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-2">
                <Form.Label>Tags</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setTags(e.target.value)}
                >
                  <option>Select Categories</option>
                  {tagList?.map((item, index) => (
                    <option
                      selected={tags?.[0] === item.tagId}
                      key={index}
                      value={item.tagId}
                    >
                      {item.tags}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>Selected Tag List</Form.Label>
                <div className="badgesChips">
                  <div className="chip hstack">
                    <span>
                      <b>tag 1</b>
                    </span>
                    <div className="vr"></div>
                    <i
                      className="fa-regular fa-circle-xmark"
                      style={{ color: "red" }}
                      title="Delete"
                    ></i>
                  </div>
                </div>
              </Form.Group>
            </Row>
          </div>
        </div>
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            disabled={
              !packageName ||
              !shortDescription ||
              !longDescription ||
              !category ||
              !tags
            }
            onClick={publishPackagesDataForDeveloper}
          >
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default PublishPackageModal;
