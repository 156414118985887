import React, { useRef, useState } from "react";
import TableHeader from "../../../../Component/TableHeader/TableHeader";
import PayoutTableData from "./PayoutTableData";
import PayoutChartData from "./PayoutChartDataModal";
import ExportPayoutData from "./ExportPayoutData";

const Payout = ({ tableRef, onMouseDown, editorFullMode }) => {
  const [openChartPayout, setOpenChartPayout] = useState(false);
  const [openExportPayout, setOpenExportPayout] = useState("");
  const [disabled, setDisabled] = useState(true);

  const transactions = [
    {
      _id: "1",
      amount: 100,
      transactionDateTime: "2024-04-25 10:30:00",
      transactionId: "T123",
      status: "completed",
      invoice: "INV001",
    },
    {
      _id: "2",
      amount: 200,
      transactionDateTime: "2024-04-24 09:45:00",
      transactionId: "T124",
      status: "processing",
      invoice: "INV002",
    },
    {
      _id: "3",
      amount: 150,
      transactionDateTime: "2024-04-23 15:20:00",
      transactionId: "T125",
      status: "completed",
      invoice: "INV003",
    },
    {
      _id: "4",
      amount: 150,
      transactionDateTime: "2024-04-23 15:20:00",
      transactionId: "T125",
      status: "processing",
      invoice: "INV003",
    },
    {
      _id: "5",
      amount: 200,
      transactionDateTime: "2024-04-24 09:45:00",
      transactionId: "T124",
      status: "processing",
      invoice: "INV002",
    },
  ];

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <TableHeader
        ExportBtn
        handleExport={setOpenExportPayout}
        ChartLine
        handleChart={setOpenChartPayout}
        FilterSection
      />
      <div>
        <PayoutTableData
          editorFullMode={editorFullMode}
          transactions={transactions}
          tableRef={tableRef}
          onMouseDown={onMouseDown}
        />

        <PayoutChartData
          show={openChartPayout}
          setShow={setOpenChartPayout}
          onStart={onStart}
          draggleRef={draggleRef}
          bounds={bounds}
          title={"Payout Analytics"}
          submit={"Submit"}
          onMouseOver={() => setDisabled(false)}
          onMouseOut={() => setDisabled(true)}
          disabled={disabled}
        />
      </div>
      <ExportPayoutData
        show={openExportPayout}
        setShow={setOpenExportPayout}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title={"Export Payout Data"}
        submit={"Submit"}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
      />
    </>
  );
};

export default Payout;
