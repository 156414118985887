import React from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const VerifiedUserDetailsinModal = ({
  show,
  setShow,
  onStart,
  draggleRef,
  bounds,
  onMouseOver,
  onMouseOut,
  disabled,
  title,
  validatorDetails,
}) => {
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Table bordered striped>
            <tbody>
              {/* <tr>
                <th colSpan={2} className="text-center">Package Validator Details</th>
              </tr> */}
              <tr>
                <th>Organization Name</th>
                <td>{validatorDetails?.companyName}</td>
              </tr>
              <tr>
                <th>Short Name</th>
                <td>{validatorDetails?.shortName}</td>
              </tr>
              <tr>
                <th>Website</th>
                <td>
                  <a href={validatorDetails?.website} target="_blank">
                    {validatorDetails?.website}
                  </a>
                </td>
              </tr>
              <tr>
                <th>Validation Status</th>
                <td>
                  <div className="text-capitalize">
                    {validatorDetails?.reviewStatus}
                  </div>
                </td>
              </tr>
              <tr>
                <th>Validation Date | TIme</th>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">
                    {validatorDetails?.reviewDateTime}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
    </>
  );
};
export default VerifiedUserDetailsinModal;
