import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { packagesServices } from "../../APIServices/Editor/packagesServices";
import Moment from "react-moment";
import CodeCompareModal from "./CodeCompareModal";

const HistoryConsole = ({
  width,
  isFullScreen,
  setIsFullScreen,
  editorFullMode,
  fileId,
  editorTheme
}) => {
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  const [codeCompare, setCodeCompare] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [codeVersionHistory, setCodeVersionHistory] = useState([]);

  const getPackageFileCodeVersionById = async (fileId) => {
    let data = await packagesServices?.getPackageFileCodeVersionById(fileId);
    setCodeVersionHistory(data?.data);
  };

  useEffect(() => {
    getPackageFileCodeVersionById(fileId);
  }, [fileId]);
  return (
    <>
      <div
        className={`p-0 output__section_widget ${!editorFullMode ? "" : "fullModeHeight"} ${!editorTheme ? "outputShadowEditorTheme" : "outputShadow"}`}
        style={{
          width: isFullScreen
            ? "98%"
            : width <= 1100
              ? 1500 - width + "px"
              : "400px",
        }}
      >
        <div
          className="border-bottom p-2"
          style={{ background: "#ebebeb", position: "relative" }}
        >
          <div className="hstack gap-2 header_tool justify-content-between">
            <p className="m-0">
              <b>History</b>
            </p>
            <div className="hstack gap-1">
              <i
                onClick={() => setCodeCompare(true)}
                className={"fa-solid fa-code-compare"}
                title={"Compare Code Version"}
              ></i>
              <div className="vr"></div>
              <i
                className={
                  isFullScreen ? "fa-solid fa-minimize" : "fa-solid fa-maximize"
                }
                title={isFullScreen ? "Minimized Mode" : "Full Mode"}
                onClick={() => setIsFullScreen(!isFullScreen)}
              ></i>
            </div>
          </div>
        </div>
        <div
          className={`px-2 pt-2 ${!editorFullMode ? "output__section_widget_height" : "output__section_widget_full_height"}`}
        >
          {codeVersionHistory?.length > 0 ? (
            <Table bordered className="m-0">
              <thead className="thead-sticky">
                <tr className="background__color">
                  <th style={{ width: 40 }}>Version</th>
                  <th>Remark</th>
                  <th style={{ width: 150 }}>Remarked By</th>
                  <th style={{ width: 120 }}>CheckIn Date | Time</th>
                  <th style={{ width: 120 }}>CheckOut Date | Time</th>
                </tr>
              </thead>
              <tbody>
                {codeVersionHistory?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <p>{item.version}</p>
                    </td>
                    <td>
                      <p>{item.remark}</p>
                    </td>
                    <td>
                      <p>{item.remarkedBy}</p>
                    </td>
                    <td>
                      <p>
                        <Moment format="DD MMM YYYY | HH:mm">
                          {item.checkIn}
                        </Moment>
                      </p>
                    </td>
                    <td>
                      <p>
                        {item.checkOut ? (
                          <Moment format="DD MMM YYYY | HH:mm">
                            {item.checkOut}
                          </Moment>
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center">
              <p>No Version History</p>
            </div>
          )}
        </div>
      </div>
      <CodeCompareModal
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        show={codeCompare}
        setShow={setCodeCompare}
        title={"Code Compare By Version"}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        fileId={fileId}
        codeVersionHistory={codeVersionHistory}
      />
    </>
  );
};

export default HistoryConsole;
