import config from "../../config/config.json";
import { headers } from "../../APIHelpers/Headers";
import saveAs from "file-saver";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../APIHelpers/Responses";
export const libraryServices = {
  getLibraryFolderData,
  getLibraryDataByFolderId,
  exportLibraryFileMultiFormat,
  libraryHideShowColumn,
  getLibraryPackageFolderData
}
// library data
async function getLibraryFolderData(type) {
  const response = await fetch(
    `${config.API_URL}getLibraryFolderData/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// library data
async function getLibraryPackageFolderData(id) {
  const response = await fetch(
    `${config.API_URL}getLibraryPackageFolderData/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//libraryData by id
async function getLibraryDataByFolderId(libraryId) {
  const response = await fetch(
    `${config.API_URL}getLibraryDataByFolderId/${libraryId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//ExportData
async function exportLibraryFileMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportLibraryFileMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}

// libraryHideshowColumn
async function libraryHideShowColumn(userData) {
  const response = await fetch(
    `${config.API_URL}libraryHideShowColumn`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}