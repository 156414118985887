import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import MenUserProfile from "../img/profile.jpg";
import ChangePasswordDetails from "./ChangePassword/ChangePasswordData";
import TwofactorAuthData from "./TwoFactorAuth/TwoFactorAuth";
import { NavLink } from "react-router-dom";
import { authServices } from "../../APIServices/authServices";

const TopHeader = ({ userCurrentData, currentUserData, roleType }) => {
  const ProfileRef = useRef(null);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showTwoFactor, setShowTwoFactor] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [authType, setAuthType] = useState("");
  const [authTypeData, setAuthTypeData] = useState("");
  const [status, setStatus] = useState(false);
  const [appTwoFA, setAppTwoFA] = useState(false);
  const [profileActive, setProfileActive] = useState(false);
  const [emailTwoFA, setEmailTwoFA] = useState(false);
  let [firstName, setFirstName] = useState("");
  let [middleName, setMiddleName] = useState("");
  let [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const toggleProfile = () => {
    setProfileActive(!profileActive);
  };
  useEffect(() => {
    const handleClickProfileMenuOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setProfileActive(false);
      }
    };
    document.addEventListener("click", handleClickProfileMenuOutside, true);

    return () => {
      document.removeEventListener(
        "click",
        handleClickProfileMenuOutside,
        true
      );
    };
  }, []);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  const getTwoFactorAuthType = async () => {
    let data = await authServices.getTwoFactorAuthType();
    setAuthType(data?.authType);
    setAuthTypeData(data?.authType);
    setStatus(data?.status);
    setAppTwoFA(data?.appTwoFA);
    setEmailTwoFA(data?.emailTwoFA);
  };

  useEffect(() => {
    getTwoFactorAuthType();
    setFirstName(userCurrentData?.firstName ? userCurrentData?.firstName : "");
    setMiddleName(
      userCurrentData?.middleName ? userCurrentData?.middleName : ""
    );
    setLastName(userCurrentData?.lastName ? userCurrentData?.lastName : "");
  }, [userCurrentData]);

  const logout = async () => {
    await authServices.logout();
  };

  useEffect(() => {
    getTwoFactorAuthType();
    setProfileImage(
      userCurrentData?.profileImage
        ? userCurrentData?.profileImage
        : MenUserProfile
    );
  }, [userCurrentData, currentUserData]);

  return (
    <div>
      <Container fluid>
        <div className="hstack justify-content-between">
          <h4 className="m-0 py-2">Galax® GAS</h4>
          <div className="hstack gap-3">
            <div style={{ fontSize: "12px" }} title="Time Zone Details">
              Time Zone : UTC+05:30 (IST)
            </div>
            <div
              className="text-capitalize"
              style={{ fontSize: "12px" }}
              title="User Role Details"
            >
              User Role : {roleType === "subadmin" ? "Admin Validator" : roleType}
            </div>
            <div
              ref={ProfileRef}
              className={`profile position-relative ${
                profileActive ? "active" : ""
              }`}
            >
              <div className="icon_wrap hstack gap-1" onClick={toggleProfile}>
                <img src={profileImage} alt="Profile" title="User Profile" />
                <div
                  className={`title_name hstack gap-1 px-2 p-1 ${
                    profileActive ? "profileNameActive" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <p className="m-0">
                    <b>{firstName + " " + middleName + " " + lastName}</b>
                  </p>
                  <i className="fas fa-chevron-down"></i>
                </div>
              </div>

              <div className="profile_dd shadow" style={{ right: 0 }}>
                <ul className="profile_ul">
                  <li>
                    <NavLink
                      onClick={() => {
                        toggleProfile();
                        setShowTwoFactor(!showTwoFactor);
                      }}
                    >
                      <i className="fa-solid fa-shield me-2"></i>
                      2FA
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => {
                        toggleProfile();
                        setShowChangePassword(!showChangePassword);
                      }}
                    >
                      <i className="fa-solid fa-lock me-2"></i>
                      Change Password
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={logout} className="logout-link">
                      <i className="fas fa-sign-out-alt me-2"></i>
                      Logout
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <ChangePasswordDetails
        show={showChangePassword}
        setShow={setShowChangePassword}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
        title={"Change Password"}
        submit={"Update"}
      />
      <TwofactorAuthData
        show={showTwoFactor}
        setShow={setShowTwoFactor}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
        title={"Two Factor Authentication"}
        getTwoFactorAuthType={getTwoFactorAuthType}
        setAuthType={setAuthType}
        authType={authType}
        authTypeData={authTypeData}
        status={status}
        appTwoFA={appTwoFA}
        emailTwoFA={emailTwoFA}
        roleType={roleType}
      />
    </div>
  );
};

export default TopHeader;
