import React, { useState } from "react";
import { Form, InputGroup} from "react-bootstrap";
import { authServices } from "../../APIServices/authServices";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const changePassword = async () => {
    let userData = {};
    userData.currentPassword = currentPassword;
    userData.newPassword = newPassword;
    userData.repeatPassword = repeatPassword;
    let data = await authServices.changePassword(userData);
    if (data?.statusCode === 200) {
      resetInputField();
      (window.location = "/")
    }
  };

  const [error, setError] = useState("");
  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;
    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };

  const resetInputField = () => {
    setCurrentPassword("");
    setNewPassword("");
    setRepeatPassword("");
  };
  return (
    <>
      <div className="log_url">
        <div className="forms_details">
          <div className="login_box">
            <div className="Welcome_to mb-3">
              <h2 className="" style={{ fontSize: "15px", fontWeight: "bold"}}>
                Change Password
              </h2>
            </div>
            <div>
              <Form.Label>
                Current Password<span>*</span>
              </Form.Label>
              <div className="position-relative">
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa-solid fa-lock"></i>
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type={showPassword ? "text" : "password"}
                    name="currentPassword"
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    value={currentPassword}
                    placeholder="current password"
                    aria-label="password"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                <div
                  className="password_visible"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                      <i className="fa-regular fa-eye"></i>
                    ) : (
                      <i className="fa-regular fa-eye-slash"></i>
                  )}
                </div>
              </div>
              <Form.Label>
                New Password<span>*</span>
              </Form.Label>
              <div className="position-relative">
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa-solid fa-lock"></i>
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      validatePassword(e.target.value);
                    }}
                    value={newPassword}
                    placeholder="new password"
                    aria-label="password"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                <div
                  className="password_visible"
                  onClick={toggleNewPasswordVisibility}
                >
                  {showNewPassword ? (
                      <i className="fa-regular fa-eye"></i>
                    ) : (
                      <i className="fa-regular fa-eye-slash"></i>
                  )}
                </div>
              </div>
              <Form.Label>
                Confirm Password<span>*</span>
              </Form.Label>
              <div className="position-relative">
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa-solid fa-lock"></i>
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    value={repeatPassword}
                    placeholder="Confirm password"
                    aria-label="password"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                <div
                  className="password_visible"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? (
                      <i className="fa-regular fa-eye"></i>
                    ) : (
                      <i className="fa-regular fa-eye-slash"></i>
                  )}
                </div>
              </div>
              <div className="mt-3">
                <button className="button_sign_log" 
                disabled={!currentPassword || !newPassword || !repeatPassword || error}
                onClick={changePassword}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
