import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import { copyToClipboard } from "../../../Utils/UniversalFunction";
import Pagination from "../../../Component/Paginatation/Pagination";
import { NoDataFoundTable } from "../../../Component/NoData/NoDataFoundTable";

const SubscriptionBillingTable = ({
  tableRef,
  onMouseDown,
  editorFullMode,
  transactions,
  loading,
}) => {
  const [copied, setCopied] = useState("");
  const getStatusColor = (status) => {
    switch (status) {
      case "succeeded":
        return "green";
      case "pending":
        return "orange";
      case "canceled":
        return "red";
      case "failed":
        return "darkred";
      default:
        return "inherit";
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = transactions?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(transactions?.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {transactions?.length > 0 ? (
            <div
              className="table_content overflow-auto mx-2"
              style={{
                maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
              }}
            >
              <div className="table-container tableLibrarySection">
                <Table
                  id="resizable-table"
                  className="m-0 tableLibrary custom-table"
                  ref={tableRef}
                  bordered
                  striped
                >
                  <thead className="thead-sticky">
                    <tr>
                      <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                        Sr.
                      </th>
                      <th onMouseDown={(e) => onMouseDown(e, 1)}>Plan Name</th>
                      <th onMouseDown={(e) => onMouseDown(e, 2)} style={{ width: 90 }}>Plan Type</th>
                      <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 110 }}>Price</th>
                      <th onMouseDown={(e) => onMouseDown(e, 4)}>Subscription Date & Time</th>
                      <th onMouseDown={(e) => onMouseDown(e, 5)}>Expiry Date & Time</th>
                      <th onMouseDown={(e) => onMouseDown(e, 6)}>Transaction ID</th>
                      <th onMouseDown={(e) => onMouseDown(e, 7)}>Payment ID</th>
                      <th onMouseDown={(e) => onMouseDown(e, 8)}>Status</th>
                      <th onMouseDown={(e) => onMouseDown(e, 9)} style={{ width: 40 }} className="text-center">
                        Invoice
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {result?.map((item, index) => (
                      <tr key={item.transactionId} className="position-relative">
                        <td>{startIndex + index + 1}</td>
                        <td>{item.planName}</td>
                        <td className="text-capitalize">{item.planType}</td>
                        <td>{item.currency}{item.payment}</td>
                        <td>
                          <Moment format="DD MMM YYYY | HH:ss">{item.createdAt}</Moment>
                        </td>
                        <td>
                          {item.expiryDateTime ? (
                            <Moment format="DD MMM YYYY | HH:ss">{item.expiryDateTime}</Moment>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          <div className="hstack gap-2">
                            <div className="transactionId" title={item.transactionId}>{item.transactionId}</div>
                            <div
                              className="hstack justify-content-center"
                              style={{ cursor: "pointer" }}
                              onClick={() => copyToClipboard(item.transactionId, "transactionId" + index, setCopied)}
                              title={`${copied === "transactionId" + index ? "Copied" : "Copy"} Transaction ID`}
                            >
                              <i className={`fa-regular ${copied === "transactionId" + index ? "fa-circle-check" : "fa-copy"}`}></i>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="hstack gap-2">
                            <div className="paymentId" title={item.paymentId}>{item.paymentId || "N/A"}</div>
                            {item.paymentId && (
                              <div
                                className="hstack justify-content-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => copyToClipboard(item.paymentId, "paymentId" + index, setCopied)}
                                title={`${copied === "paymentId" + index ? "Copied" : "Copy"} Payment ID`}
                              >
                                <i className={`fa-regular ${copied === "paymentId" + index ? "fa-circle-check" : "fa-copy"}`}></i>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div style={{ color: getStatusColor(item.status) }} className="text-capitalize">
                            <b>
                              {item.status === "pending"
                                ? "Pending"
                                : item.status === "succeeded"
                                  ? "Completed"
                                  : "Cancelled"}
                            </b>
                            {item.status === "pending" && (
                              <a href={item.paymentUrl} target="_blank" rel="noreferrer" className="button_type">
                                <button className="p-0 px-2 ms-2 m-0" title="Pay for Pending Transaction" style={{ fontSize: 10 }}>
                                  Pay
                                </button>
                              </a>
                            )}
                          </div>
                        </td>
                        <td className="text-center button_type">
                          {item.status === "succeeded" ? (
                            <a href={`/subscriptioninvoiceReceipt/${item.transactionId}`} target="_blank" rel="noreferrer">
                              <button
                                className="rounded-circle p-1 px-2 m-0"
                                title="View Invoice"
                                style={{ fontSize: 10, width: 20, height: "18.5px" }}
                              >
                                <div className="hstack justify-content-center">
                                  <i className="fa-solid fa-eye"></i>
                                </div>
                              </button>
                            </a>
                          ) : (
                            <button
                              className="rounded-circle p-1 px-2 m-0"
                              title="disabled"
                              style={{ fontSize: 10, width: 20, height: "18.5px" }}
                              disabled
                            >
                              <div className="hstack justify-content-center">
                                <i className="fa-solid fa-eye"></i>
                              </div>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="mx-2">
                <Pagination
                  totalPage={totalPage}
                  pageNumber={pageNumber}
                  itemsPerPage={itemsPerPage}
                  totalItems={transactions?.length}
                  setPageNumber={setPageNumber}
                  setItemsPerPage={setItemsPerPage}
                />
              </div>
            </div>
          ) : (
            <NoDataFoundTable MSG="No Billing Data Found" />
          )}
        </>
      )}
    </>
  );
};

export default SubscriptionBillingTable;
