import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
const OrderStatusChartData = () => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });
  useEffect(() => {
    setChartData({
      series: [51, 21, 15, 18],
      options: {
        chart: {
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 580,
            options: {
              chart: {
                width: 200,
                height: 200,
              },
            },
          },
        ],
        labels: [ "Complete Orders", "Pending orders", "Cancelled Orders", "Failed Orders"],
        colors: ["#042b49", "#103c5e", "#38729e", "#9abdd9"],
        plotOptions: {
          pie: {
            donut: {
              size: "45%",
            },
          },
        },
      },
    });
  }, []);
  return (
    <div className="graph__title">
      {/* <p><b>Words</b></p> */}
      <div id="chart">
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="donut"
          height="300"
        />
      </div>
    </div>
  );
};
export default OrderStatusChartData;