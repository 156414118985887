import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { addressServices } from "../../APIServices/addressServices";
import { invitedDataServices } from "../../APIServices/invitedDataServices";
import SignUpSuccess from "./SignUpSuccess";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  let [error, setError] = useState("");
  const [phone, setPhone] = useState("");
  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");
  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");
  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async (e) => {
    let data = await addressServices.getCountries(e);
    setCountryLists(data.data);
  };

  const getStates = async (e) => {
    let data = await addressServices.getStates(e);
    setStateLists(data.data);
  };

  const getCities = async (e) => {
    let data = await addressServices.getCities(e);
    setCityLists(data.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userData = {};
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.address = address;
    userData.email = email;
    userData.gender = gender;
    userData.phone = phone;
    userData.cityId = city_id;
    userData.stateId = state_id;
    userData.countryId = country_id;
    userData.zipCode = postalCode;
    userData.roleType = "consultant";
    let data = await invitedDataServices.userRegistration(userData);
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setGender("");
      setEmail("");
      setPhone("");
      setAddress("");
      setPostalCode("");
      setCityID("");
      setStateID("");
      setCountryID("");
      setStatusCode(true);
    }
  };
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setError("");
    } else {
      setError("Invalid Email, Please Enter Valid Email!");
    }
  };
  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setError("");
    } else {
      setError("Phone Number should have 10 digits ");
    }
  };
  return (
    <>
      {statusCode ? (
        <SignUpSuccess />
      ) : (
        <div className="sign_url">
          <div className="form_details">
            <div
              className="text-center mb-3"
              style={{ color: "#103c5e", fontSize: "25px" }}
            >
              Sign Up
            </div>
            <Form>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      First Name<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Enter first name"
                      className="place_hold"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={middleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                      placeholder="Enter middle name"
                      className="place_hold"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Enter last name"
                      className="place_hold"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Email<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        validateEmail(e.target.value);
                      }}
                      value={email}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Phone Number<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                        validatePhoneNumber(e.target.value);
                      }}
                      value={phone}
                      placeholder="Enter phone number"
                      className="place_hold"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Gender</Form.Label>
                    <Form.Select
                      onChange={(e) => setGender(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option>Select Gender</option>
                      <option selected={gender === "male"} value="male">
                        Male
                      </option>
                      <option selected={gender === "female"} value="female">
                        Female
                      </option>
                      <option selected={gender === "other"} value="other">
                        Other
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      Address<span>*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      rows={3}
                      placeholder="Enter your address"
                      className="place_hold"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Country<span>*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setCountryID(e.target.value)}
                      value={country_id}
                      onClick={(e) =>
                        getStates(e.target[e.target.selectedIndex].title)
                      }
                    >
                      <option>-Select-</option>
                      {countryLists.length > 0
                        ? countryLists.map((item, key) => (
                            <option value={item._id} key={key} title={item.id}>
                              {item.name}
                            </option>
                          ))
                        : ""}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      State<span>*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setStateID(e.target.value)}
                      value={state_id}
                      onClick={(e) =>
                        getCities(e.target[e.target.selectedIndex].title)
                      }
                    >
                      <option>-Select-</option>
                      {stateLists.length > 0
                        ? stateLists.map((item, key) => (
                            <option value={item._id} key={key} title={item.id}>
                              {item.name}
                            </option>
                          ))
                        : ""}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      City<span>*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setCityID(e.target.value)}
                      value={city_id}
                    >
                      <option>-Select-</option>
                      {cityLists.length > 0
                        ? cityLists.map((item, key) => (
                            <option value={item._id} key={key}>
                              {item.name}
                            </option>
                          ))
                        : ""}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Zip Code<span>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Enter zip Code"
                      onChange={(e) => setPostalCode(e.target.value)}
                      value={postalCode}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <div className="hstack sub_buttons">
                    <button
                      // style={{ cursor: "pointer" }}
                      disabled={
                        !firstName ||
                        !address ||
                        !phone ||
                        !email ||
                        !city_id ||
                        !state_id ||
                        !country_id ||
                        !postalCode
                      }
                      onClick={handleSubmit}
                    >
                      Sign Up
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
            <div className="mt-2 text-center">
              <p className="" style={{ color: "#000" }}>
                Already have an account?
                <NavLink to={"/login"}>
                  <span
                    style={{
                      color: "#103c5e",
                      cursor: "pointer",
                      marginLeft: "3px",
                      fontWeight: "500",
                    }}
                  >
                    Log in
                  </span>
                </NavLink>
              </p>
              <div className="d-flex justify-content-between">
                {error ? (
                  <p className="message" style={{ color: "red" }}>
                    {error}
                  </p>
                ) : (
                  <p
                    className="message"
                    style={
                      statusMessage?.statusCode === 200
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    {statusMessage?.customMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
