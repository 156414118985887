import React, { useRef, useState } from "react";
// import OTP_Verification from "../../img/brainpic.jpg";
import { NavLink } from "react-router-dom";


const OtpVerification = () => {
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const otpInputs = useRef([]);
  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedValues = [...otpValues];
      updatedValues[index] = value;
      setOtpValues(updatedValues);
      if (value !== "" && index < 5) {
        otpInputs.current[index + 1].focus();
      }
      if (value === "" && index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const finalKey = otpValues.join("");
  };
  return (
    <>
      <div className="log_url">
        <div className="forms_details">
          <div className="login_box">
            <div className="Welcome_to">
              <h2>Verify OTP</h2>
              <p>An OTP has been sent to ********k876@gmail.com</p>
              <p>Please enter OTP to verify</p>
            </div>
            <div>
              <div className="otp-input-fields hstack gap-2 mb-3">
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    pattern="\d*"
                    maxLength="1"
                    className={`otp__digit otp__field__${index + 1}`}
                    value={value}
                    onChange={(e) => handleInputChange(index, e)}
                    ref={(input) => (otpInputs.current[index] = input)}
                  />
                ))}
              </div>
              <NavLink to={"/otp-verification"}>
                <button className="button_sign_log">Verify OTP</button>
              </NavLink>
            </div>
            <div className="d-flex justify-content-between">
              <p className="message">
                <NavLink to="/forget">Change Email</NavLink>
              </p>
              <p className="message">
                <NavLink to="#">Resend OTP</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OtpVerification;
