import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { Form } from "react-bootstrap";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices";

const CurrentCodeCompareModal = ({
  show,
  title,
  bounds,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  packagesPropertiesData,
}) => {
  const [versionCode, setVersionCode] = useState("");
  const [totalVersion, setTotalVersion] = useState([]);
  const getPackageCodeByVersion = async (version) => {
    let data = await packagesServices.getPackageCodeByVersion(packagesPropertiesData?._id, version)
    setVersionCode(data?.code)
  }

  useEffect(() => {
    let totalVersionData = Array.from({ length: packagesPropertiesData?.version }, (_, i) => i + 1)
    setTotalVersion(totalVersionData || []);
  }, [packagesPropertiesData]);

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={1100}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <div className="hstack justify-content-between border-bottom pb-3">
            <div className="hstack gap-2 text-nowrap">
              <Form.Label>Old Version</Form.Label>
              <Form.Select
                onChange={(e) => getPackageCodeByVersion(e.target.value)}
              >
                <option selected>Select Version</option>
                {totalVersion?.map((item, index) => (
                  <option key={index} value={item}>v{item}.0.0</option>
                ))}
              </Form.Select>
            </div>
            <Form.Label>Current Version : v{packagesPropertiesData?.version}.0.0</Form.Label>
          </div>
          <div className="diffTabHeight">
            <ReactDiffViewer
              newValue={packagesPropertiesData?.code}
              oldValue={versionCode}
              compareMethod={DiffMethod.WORDS}
              splitView={true}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CurrentCodeCompareModal;
