import config from "../../config/config.json";
import { headers } from "../../APIHelpers/Headers";
import saveAs from "file-saver";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody
} from "../../APIHelpers/Responses";

export const subscriptionPlanServices = {
  //Subscription Plan
  getSubscriptionPlanListByStatus,
  findCouponCodeData,
  customerSubscriptionPlanPayment,
  confirmSubscriptionPaymentStatus,
  getSubscriptionPlan,
  getSubscriptionTransectionList,
  exportSubscriptionBillingTransectionsMultiFormat,
  getSubscriptionBillingTransectionForYearGraph,
  getSubscriptionBillingTransectionForWeekGraph

};

// Subscription Billing Transection For Week Graph
async function getSubscriptionBillingTransectionForWeekGraph() {
  const response = await fetch(
    `${config.API_URL}getSubscriptionBillingTransectionForWeekGraph`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Subscription Billing Transection For Year Graph
async function getSubscriptionBillingTransectionForYearGraph(year) {
  const response = await fetch(
    `${config.API_URL}getSubscriptionBillingTransectionForYearGraph/${year}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}


// Get All Subscription Plan List By Id
async function getSubscriptionPlanListByStatus(status) {
  const response = await fetch(
    `${config.API_URL}getSubscriptionPlanListByStatus/${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Subscription Plan List By Id
async function findCouponCodeData(couponCode, currency) {
  const response = await fetch(
    `${config.API_URL}findCouponCodeData/${couponCode}/${currency}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Customer Subscription Plan Payment
async function customerSubscriptionPlanPayment(userData) {
  const response = await fetch(
    `${config.API_URL}customerSubscriptionPlanPayment`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Confirm Customer Subscription Payment
async function confirmSubscriptionPaymentStatus(userData) {
  const response = await fetch(
    `${config.API_URL}confirmSubscriptionPaymentStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// get Active Plan Data
async function getSubscriptionPlan(id) {
  const response = await fetch(
    `${config.API_URL}getSubscriptionPlan/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Subscription Transection List
async function getSubscriptionTransectionList() {
  const response = await fetch(
    `${config.API_URL}getSubscriptionTransectionList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export Subscription Billing Transection in Multiformat
async function exportSubscriptionBillingTransectionsMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportSubscriptionBillingTransectionsMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}
