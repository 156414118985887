import React from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";

const ReeferenceListDetailsinModal = ({
  show,
  setShow,
  onStart,
  draggleRef,
  bounds,
  onMouseOver,
  onMouseOut,
  disabled,
  title,
  referances
}) => {
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={800}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div>
          <ul>
            {referances?.map((item, index) => (
              <li key={index} style = {{fontSize: 13}} className="mb-3">
                {item?.referance}
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};
export default ReeferenceListDetailsinModal;
