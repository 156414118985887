import React, { useEffect, useRef, useState } from "react";
import { Collapse, Container, Form, InputGroup} from "react-bootstrap";
import { subscriptionPlanServices } from "../../../APIServices/Editor/subscriptionPlanServices";
import CheckoutSubscription from "./CheckoutSubscription";

const SubscriptionBasePlan = () => {
  const [disabled, setDisabled] = useState(true);
  const [openPromo, setOpenPromo] = useState(false);
  const [loader, setLoader] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState("");
  const [plans, setPlans] = useState([]);
  const [planData, setPlanData] = useState({});
  const [checkoutProcess, setCheckoutProcess] = useState(false);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const getSubscriptionPlanListByStatus = async (status) => {
    let data = await subscriptionPlanServices.getSubscriptionPlanListByStatus(status)
    setPlans(data?.data)
  }

  const applyCouponCode = async (id, price, currency) => {
    setLoader(true)
    let data = await subscriptionPlanServices.findCouponCodeData(couponCode, currency)
    let couponName = data.couponName
    let couponDiscountType = data.discountType
    let couponDiscount = data.discount

    let discuntedPrice;
    if (data?.foundStatus?.statusCode === 200) {
      if (couponDiscountType === "percentage") {
        discuntedPrice = price - (price * couponDiscount) / 100
      } else {
        discuntedPrice = price - couponDiscount
      }
    } else {
      discuntedPrice = price
    }

    let couponData = {
      id: id,
      discuntedPrice: discuntedPrice,
      couponName: couponName,
      message: data?.foundStatus?.customMessage,
      statusCode: data?.foundStatus?.statusCode
    }
    setCouponData(couponData)
    setLoader(false)
  }

  const removeApplyCouponCode = () => {
    setCouponData("")
    setCouponCode("")
  }

  const processToCheckout = (planId, currency, discuntedPrice, price, planName) => {
    setCheckoutProcess(true)
    setPlanData({
      planId: planId,
      currency: currency,
      discuntedPrice: discuntedPrice,
      price: price,
      planName: planName,
    })
  }

  useEffect(() => {
    getSubscriptionPlanListByStatus(true);
  }, []);
  return (
    <>
      <Container fluid>
        <div className="pt-4 total_analysis">
          {plans?.map((item, index) => (
            <div key={index} className="shadow rounded shadow_type">
              <div className="text-center base_analyse rounded-top">
                <h6>{item.planName}</h6>
                <hr
                  style={{
                    width: 70,
                    margin: "10px auto",
                    color: "var(--whiteColor)",
                  }}
                />
                <div className="text-center">
                  <Form.Label className="mb-2" style={{ color: "#fff", fontSize: 19 }}>
                    {!loader ?
                      <><b>{item.currency}{couponData.id === item._id ? couponData.discuntedPrice : item.price}</b> / {item.type}</>
                      :
                      <div className="couponLoader"></div>
                    }
                  </Form.Label>
                </div>
                {couponData?.statusCode === 200 ?
                  <>
                    <Form.Text style={{ color: "#fff", fontSize: 12, cursor: "pointer" }}>{couponCode} : {couponData?.couponName} <i onClick={removeApplyCouponCode} title="Remove Coupon" style={{ color: "red" }} className="mx-2 fa-regular fa-circle-xmark"></i></Form.Text><br />
                    <Form.Text style={{ color: "#18ff18", fontSize: 12, cursor: "pointer" }}>{couponData?.message}</Form.Text><br />
                    <Form.Text style={{ color: "#fff", fontSize: 12, cursor: "pointer" }}><b>You Save Money {item.currency}{item.price - couponData.discuntedPrice}</b></Form.Text>
                  </>
                  :
                  <>
                    <Form.Text style={{ color: "#fff", fontSize: 12, cursor: "pointer" }} onClick={() => setOpenPromo(!openPromo)}>Apply Promo Code</Form.Text>
                    <Collapse in={openPromo}>
                      <div id="example-collapse-text">
                        <div className="prmoField">
                          <InputGroup className="w-50 m-auto p-0" >
                            <Form.Control
                              placeholder="Enter Promo Code"
                              className="px-1"
                              value={couponCode}
                              onChange={(e) => setCouponCode(e.target.value)}
                              style={couponCode ? { fontSize: 12, borderRadius: "3px 0px 0px 3px" } : { fontSize: 12, borderRadius: "3px 3px 3px 3px" }}
                            />
                            {couponCode &&
                              <InputGroup.Text
                                className="p-1"
                                onClick={() => applyCouponCode(item._id, item.price, item.currency)}
                                style={{ fontSize: 12, cursor: "pointer", borderRadius: "0 3px 3px 0" }}>
                                <small>Apply</small>
                              </InputGroup.Text>
                            }
                          </InputGroup>
                        </div>
                      </div>
                    </Collapse>
                    {couponData?.message &&
                      <Form.Text style={{ color: "red", fontSize: 12, cursor: "pointer" }}>{couponData?.message}</Form.Text>
                    }
                  </>
                }
              </div>
              <div className="text-center py-2">
                <p className="m-0">
                  <b>Featured</b>
                </p>
              </div>
              <div className="ulHeight">
                <ul>
                  {(item?.featured)?.map((data, key) => (
                    <li key={key}>
                      <p className="m-0">{data}</p>
                    </li>
                  ))}
                </ul>
              </div>
              <div class="button_type pt-2 text-center">
                <button onClick={() => processToCheckout(item._id, item.currency, couponData.discuntedPrice, item.price, item.planName)} className="w-100 m-0" style={{ borderRadius: 0 }} type="submit">Subscribe Now</button>
              </div>
            </div>
          ))}
        </div>
      </Container>
      <CheckoutSubscription
        bounds={bounds}
        submit={"Proceed to Checkout"}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        show={checkoutProcess}
        setShow={setCheckoutProcess}
        title={`Checkout`}
        planData={planData}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
      />
    </>
  );
};

export default SubscriptionBasePlan;
