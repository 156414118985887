import React, { useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Table } from "react-bootstrap";
import { subscriptionPlanServices } from "../../../APIServices/Editor/subscriptionPlanServices";
const CheckoutSubscription = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  planData
}) => {

  let planId = planData?.planId
  let currency = planData?.currency
  let planName = planData?.planName
  let planPrice = planData?.price
  let couponDiscount = (planData?.price - planData?.discuntedPrice) || 0
  let CGST = (planData?.price * 9) / 100
  let SGST = (planData?.price * 9) / 100
  let totalPrice = planPrice - couponDiscount + CGST + SGST

  const makeSubscriptionPayments = async () => {
    const stripe = await loadStripe("pk_test_51P9pwLSJpif8ij89TJfYP3uWjqApcLyiOMT46AVUtChgNQCbjPxePwsDlb0s2xrwU1tdclzCY9Cwb69vtFUiJueC00t12fuxoA")
    let userData = {}
    userData.item = {
      planId: planId,
      planName: planName,
      price: totalPrice,
      currency: currency
    }
    let data = await subscriptionPlanServices.customerSubscriptionPlanPayment(userData)
    let sessionId = data.sessionId

    stripe.redirectToCheckout({
      sessionId: sessionId
    })
  }
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={500}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Table bordered striped>
            <tbody>
              <tr>
                <th colSpan={2} className="text-center">{planName}</th>
              </tr>
              <tr>
                <td>Plan Price</td>
                <td className="text-end">{currency}{planPrice}</td>
              </tr>
              <tr>
                <td>Coupon Discount</td>
                <td className="d-flex justify-content-between" style={{ color: "green" }}>
                  <div> -</div>
                  <div>{currency}{couponDiscount}</div>
                </td>
              </tr>
              <tr>
                <td>CGST (9%)</td>
                <td className="d-flex justify-content-between" style={{ color: "red" }}>
                  <div>+</div>
                  <div>{currency}{CGST}</div>
                </td>
              </tr>
              <tr>
                <td>SGST (9%)</td>
                <td className="d-flex justify-content-between" style={{ color: "red" }}>
                  <div>+</div>
                  <div>{currency}{SGST}</div>
                </td>
              </tr>
              <tr>
                <td><b>Total</b></td>
                <td className="text-end"><b>{currency}{totalPrice}</b>  </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <hr className="w-100 m-0" />
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
          onClick={makeSubscriptionPayments}
          >
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default CheckoutSubscription;
