import React, { useRef, useState } from "react";
import { Badge, Row, Col } from "react-bootstrap";
import VerifiedUserData from "../PackageDetails/VerifiedUserDetailsinModal.js.js";
import Moment from "react-moment";

function PackageLists({ handleTabChange, editorFullMode, packageLists }) {
  const [showVerified, setShowVerified] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [validatorData, setValidatorData] = useState("");
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const validatorDetails = (validatorDetails) => {
    setShowVerified(true);
    setValidatorData(validatorDetails);
  };

  return (
    <>
      <div
        className={`${editorFullMode ? "packageSidebarFullHeight" : "packageSidebaHeight"}`}
      >
        {packageLists?.length > 0
          ? packageLists?.map((item, index) => (
              <div key={index} className="whole_details_package mb-3">
                <Row>
                  <Col md={10}>
                    <div className="d-flex hstack">
                      <h6
                        className="mb-1"
                        onClick={(e) =>
                          handleTabChange(
                            e,
                            item.packageName,
                            item.packageId,
                            "fa-solid fa-cube",
                            "packageList"
                          )
                        }
                      >
                        {item.packageName}
                      </h6>
                      <i
                        className="fa-solid fa-circle-check"
                        aria-hidden="true"
                        title="Verified"
                        style={{ cursor: "pointer" }}
                        onClick={() => validatorDetails(item.validatorDetails)}
                      ></i>
                    </div>
                    <p className="mb-2 jason_data">{item.description}</p>
                    <div className="taglist_type mb-3">
                      {item.allTags?.map((tag, key) => (
                        <Badge key={key} className="tag">
                          {tag}
                        </Badge>
                      ))}
                    </div>
                    <div className="all_package_data">
                      <p className="text-capitalize">
                        <b>Status</b>: <span>{item.status}</span>
                      </p>
                      <p>
                        <b>Latest Version</b>: {item.version}
                      </p>
                      <p>
                        <b>Category</b> : {item.categoryName}
                      </p>
                      <p>
                        <b>Published Date</b>:{" "}
                        <Moment format="DD MMM YYYY | HH:mm">
                          {item.publishDateTime}
                        </Moment>
                      </p>
                    </div>
                  </Col>
                  <Col md={2} className="mt-1">
                    <div
                      className="off_type mb-2"
                      title={`MRP $${item.salePrice} (Inclusive of all taxes)`}
                    >
                      ${item.salePrice}{" "}
                      <span className="off_span">
                        {100 -
                          (Number(item.salePrice) * 100) /
                            Number(item.regularPrice)}
                        % OFF
                      </span>
                    </div>
                    <div className="icon_sets1">
                      <i
                        className="fa-solid fa-cart-plus"
                        title="Add To Cart"
                      ></i>
                      <i
                        className="fa-solid fa-cart-shopping"
                        title="Buy Now"
                      ></i>
                      <i
                        className="fa-solid fa-heart"
                        title="Remove to Favorite"
                      ></i>
                    </div>
                  </Col>
                </Row>
              </div>
            ))
          : "Package not Found"}
      </div>

      <VerifiedUserData
        show={showVerified}
        setShow={setShowVerified}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
        validatorDetails={validatorData}
        title={"Validators Details"}
      />
    </>
  );
}

export default PackageLists;
