import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form } from "react-bootstrap";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices";

const UpdatePackageMetadataModal = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  draggleRef,
  onMouseOut,
  onMouseOver,
  openEditFolderData,
  getPackageFolderLists,
  categoryList
}) => {
  const [editTitle, setEditTitle] = useState("");
  let [updateDescription, setUpdateDescription] = useState("");
  let [category, setCategory] = useState("");
  
  const updatePackagesData = async () => {
    let userData = {};
    userData.id = openEditFolderData?._id;
    userData.name = editTitle;
    userData.description = updateDescription;
    userData.category = category;
    let data = await packagesServices.updatePackagesData(userData);
    if (data?.statusCode === 200) {
      setShow();
      getPackageFolderLists();
    }
  };

  useEffect(() => {
    setEditTitle(openEditFolderData?.name);
    setUpdateDescription(openEditFolderData?.description);
    setCategory(openEditFolderData?.categoryId);
  }, [openEditFolderData]);
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Form.Label>
            Folder Name<span>*</span>
          </Form.Label>
          <Form.Control
            className="mb-3"
            required
            type="text"
            placeholder="Enter folder name"
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
          />
          <Form.Label>
            Description<span>*</span>
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            placeholder="Enter Description"
            autoFocus
            value={updateDescription}
            onChange={(e) => setUpdateDescription(e.target.value)}
          />
          <Form.Label className="mt-3">Categories<span>*</span></Form.Label>
          <Form.Select
            className="mb-3"
            aria-label="Default select example"
            onChange={(e) => setCategory(e.target.value)}
          >
            <option>Select Categories</option>
            {categoryList?.map((item, index) => (
              <option key={index} selected={category === item.categoryId} value={item.categoryId}>{item.category}</option>
            ))}
          </Form.Select>
        </div>
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            onClick={updatePackagesData}
            disabled={!editTitle || !updateDescription || !category}
          >
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default UpdatePackageMetadataModal;
