import React, { useRef, useState } from "react";
import {Collapse } from "react-bootstrap";
import LogConsole from "./ConsoleLogs/LogConsole";
import OutputConsole from "./ConsoleOutputs/OutputConsole";
import InformationConsole from "./HeaderInformation/UpdateProjectInformationConsole";
import OverviewFlow from "../../Component/FlowChart/OverviewFlow";
import HistoryConsole from "./HistoryConsole";
import { useNodesState } from "reactflow";
import ToolSidebar from "./ToolSidebar";

function GraphicsComponent({ editorFullMode, setEditorGraphics }) {
  const [consoleWidth, setConsoleWidth] = useState(1100);
  const [openOutputLogs, setOpenOutputLogs] = useState(false);
  const [openInformationLogs, setOpenInformationLogs] = useState(false);
  const [openHistoryLogs, setOpenHistoryLogs] = useState(false);
  const [openConsoleLogs, setOpenConsoleLogs] = useState(false);
  const [opengraphicalbar, setOpengraphicalbar] = useState(false);

  const onConsoleResizeX = (e) => {
    document.addEventListener("mousemove", onmousemove);
    document.addEventListener("mouseup", onmouseup);
    function onmousemove(e) {
      setConsoleWidth(e.pageX);
    }
    function onmouseup(e) {
      document.removeEventListener("mousemove", onmousemove);
      document.removeEventListener("mouseup", onmouseup);
    }
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const openConsoleLogsData = () => {
    setOpenOutputLogs(false);
    setOpenConsoleLogs(!openConsoleLogs);
    setIsFullScreen(false);
    setOpenInformationLogs(false);
    setOpenHistoryLogs(false);
  };

  const openOutputData = () => {
    setOpenOutputLogs(!openOutputLogs);
    setOpenConsoleLogs(false);
    setIsFullScreen(false);
    setOpenInformationLogs(false);
    setOpenHistoryLogs(false);
  };

  const openInformationData = () => {
    setOpenInformationLogs(!openInformationLogs);
    setOpenConsoleLogs(false);
    setIsFullScreen(false);
    setOpenOutputLogs(false);
    setOpenHistoryLogs(false);
  };

  const openHistoryData = () => {
    setOpenInformationLogs(false);
    setOpenConsoleLogs(false);
    setIsFullScreen(false);
    setOpenOutputLogs(false);
    setOpenHistoryLogs(!openHistoryLogs);
  };

  const editorRef = useRef(null);

  const undoEditorData = () => {
    if (editorRef.current) {
      editorRef.current.editor.undo();
    }
  };

  const redoEditorData = () => {
    if (editorRef.current) {
      editorRef.current.editor.redo();
    }
  };

  const [nodes, setNodes, onNodesChange] = useNodesState([]);

  // const addObjectToArray = (icon, label) => {
  //   let newObject;
  //   if (nodes.length === 0) {
  //     newObject = {
  //       id: nodes.length + 1,
  //       type: "input",
  //       data: {
  //         label: (
  //           <div className="rendered_icon">
  //             {icon} <p className="m-0">{label}</p>
  //           </div>
  //         ),
  //       },
  //       position: { x: nodes.length * 25, y: nodes.length * 25 },
  //       className: "light",
  //     };
  //   } else {
  //     newObject = {
  //       id: nodes.length + 1,
  //       className: "fa-solid fa-reply",
  //       type: "input",
  //       data: {
  //         label: (
  //           <div className="rendered_icon">
  //             {icon} <p className="m-0">{label}</p>
  //           </div>
  //         ),
  //       },
  //       position: { x: nodes.length * 25, y: nodes.length * 25 },
  //       className: "light",
  //       parentNode: nodes.length,
  //     };
  //   }

  //   setNodes((prevObjects) => [...prevObjects, newObject]);
  // };

  return (
    <>
      <div className="border-bottom px-2 py-1">
        <div className="hstack justify-content-between">
          <div className="hstack gap-2 header_tool">
            <i className="fa-solid fa-floppy-disk" title="Save"></i>
            <div className="vr"></div>
            <i
              className="fa-solid fa-reply"
              title="Undo"
              onClick={undoEditorData}
            ></i>
            <div className="vr"></div>
            <i
              className="fa-solid fa-share"
              title="Redo"
              onClick={redoEditorData}
            ></i>
          </div>
          <p
            className="view__graphn"
            onClick={() => setEditorGraphics("editor")}
          >
            Editor View
          </p>
        </div>
      </div>
      <div className={!isFullScreen ? "d-flex" : ""}>
        {!isFullScreen && (
          <div
            className={`w-100 findWrapper ${
              !editorFullMode ? "graphicalBarCodePoint" : "fullModeHeight"
            }`}
          >
            <div className="d-flex">
              <div className="d-flex">
                <div
                  className={`graphicalBar ${
                    !editorFullMode ? "graphicalBar_height" : "fullModeHeight"
                  }`}
                >
                  <ul className="border-0 m-0 p-0">
                    <li
                      onClick={() => setOpengraphicalbar(!opengraphicalbar)}
                      aria-controls="example-collapse-text"
                      aria-expanded={opengraphicalbar}
                      title="Collapse/Expand Graphical Bar"
                      className={!opengraphicalbar === true ? "activee" : " "}
                    >
                      <i className="fa-solid fa-diagram-project"></i>
                    </li>
                  </ul>
                </div>
                <Collapse in={!opengraphicalbar} dimension="width">
                  <div
                    id="example-collapse-text"
                    className={`graphicalBar ${
                      !editorFullMode ? "graphicalBar_height" : "fullModeHeight"
                    }`}
                  >
                    <div style={{ width: "400px" }}>
                      <div className="px-2 pt-2 hstack justify-content-between header_tool">
                        <h6 className="m-0">Nodes</h6>
                        <i
                          className="fa-solid fa-filter"
                          title="Filter Nodes"
                        ></i>
                      </div>
                      <hr className="my-2" />
                      <div className="px-2">
                        <div className="search_tabs border rounded">
                          <i className="fa-solid fa-magnifying-glass"></i>
                          <input
                            type="search"
                            placeholder="Search Starter Nodes"
                          />
                        </div>
                      </div>
                      <hr className="my-2" />
                      <ToolSidebar />
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="p-2 w-100">
                <OverviewFlow
                  nodes={nodes}
                  setNodes={setNodes}
                  onNodesChange={onNodesChange}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={`outputtool_point ${
            !editorFullMode ? " outputtool_point__height" : "fullModeHeight"
          }`}
        >
          <ul>
            <li
              onClick={() => openConsoleLogsData(!openConsoleLogs)}
              aria-controls="example-collapse-text"
              title="View Debug Console Logs"
              className={openConsoleLogs === true ? "activee" : " "}
            >
              <i className="fa-solid fa-file-lines"></i>
            </li>
            <li
              onClick={() => openOutputData(!openOutputLogs)}
              aria-controls="example-collapse-text"
              title="View Output"
              className={openOutputLogs === true ? "activee" : " "}
            >
              <i className="fa-solid fa-computer"></i>
            </li>
            <li
              onClick={() => openInformationData(!openInformationLogs)}
              aria-controls="example-collapse-text"
              title="Information"
              className={openInformationLogs === true ? "activee" : " "}
            >
              <i className="fa-solid fa-info"></i>
            </li>
            <li
              onClick={() => openHistoryData(!openHistoryLogs)}
              aria-controls="example-collapse-text"
              title="History"
              className={openHistoryLogs === true ? "activee" : " "}
            >
              <i className="fa-solid fa-clock-rotate-left"></i>
            </li>
          </ul>
        </div>
        <Collapse in={openConsoleLogs} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              <LogConsole
                width={consoleWidth}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                editorFullMode={editorFullMode}
              />
            </div>
          </div>
        </Collapse>
        <Collapse in={openOutputLogs} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              <OutputConsole
                width={consoleWidth}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                editorFullMode={editorFullMode}
              />
            </div>
          </div>
        </Collapse>
        <Collapse in={openInformationLogs} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              <InformationConsole
                width={consoleWidth}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                editorFullMode={editorFullMode}
              />
            </div>
          </div>
        </Collapse>
        <Collapse in={openHistoryLogs} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              <HistoryConsole
                width={consoleWidth}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                editorFullMode={editorFullMode}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
}

export default GraphicsComponent;
