import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import ConsultantLogConsole from "./ConsultantLogConsole";
import { consultantServices } from "../../../APIServices/Editor/consultantServices";
import {
  copyToClipboard,
  downloadTextFile,
  handleHTMLFileDownload,
} from "../../../Utils/UniversalFunction";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { NavLink } from "react-router-dom";

const ConsultantFullPageLogConsole = ({ fileId, copy, setCopy }) => {
  const [fontSize, setFontSize] = useState(14);
  const [codeArrayLogs, setCodeArrayLogs] = useState([]);
  const getProjectFileLogById = async (fileId) => {
    let data = await consultantServices?.getProjectFileLogById(fileId);
    setCodeArrayLogs(data?.log);
  };

  function fontIncrease() {
    setFontSize((prevFontSize) => prevFontSize + 1);
  }

  function fontDecrease() {
    setFontSize((prevFontSize) => Math.max(prevFontSize - 1, 5));
  }

  const downloadOutput = async (type) => {
    let output = ReactDOMServer.renderToString(
      <span style={{ fontSize: fontSize }}>
        {codeArrayLogs?.length === 0
          ? "No Logs"
          : codeArrayLogs?.map((item, index) => (
              <div
                className={
                  item.type === "error"
                    ? "logsTextErrorColor"
                    : "logsTextSuccessColor"
                }
              >
                <b
                  style={{
                    fontSize: fontSize - 3,
                    textTransform: "capitalize",
                  }}
                >
                  "{item?.functionName}" Function Log:
                </b>{" "}
                <i>
                  <SyntaxHighlighter key={index} style={docco}>
                    {item?.result}
                  </SyntaxHighlighter>
                </i>
              </div>
            ))}
      </span>
    );
    if (type === "html") {
      handleHTMLFileDownload(output, "example");
    } else if (type === "text") {
      let output = ReactDOMServer.renderToString(
        codeArrayLogs?.map((item) => (
          <>
            {item?.functionName} Function Log: {"\n" + item?.result + "\n"}
          </>
        ))
      );
      downloadTextFile(output, "example");
    } else {
      let output = ReactDOMServer.renderToString(
        codeArrayLogs?.map((item) => (
          <>
            {item?.functionName} Function Log: {"\n" + item?.result + "\n"}
          </>
        ))
      );
      copyToClipboard(output, "logs", setCopy);
    }
  };

  const ProfileRef = useRef(null);
  const [profileActive, setProfileActive] = useState(false);
  const toggleProfile = () => {
    setProfileActive(!profileActive);
  };
  useEffect(() => {
    const handleClickProfileMenuOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setProfileActive(false);
      }
    };
    document.addEventListener("click", handleClickProfileMenuOutside, true);

    return () => {
      document.removeEventListener(
        "click",
        handleClickProfileMenuOutside,
        true
      );
    };
  }, []);

  useEffect(() => {
    getProjectFileLogById(fileId);
  }, [fileId]);
  return (
    <>
      <div className="border-bottom px-2 py-1">
        <div className="hstack justify-content-between">
          <div className="hstack gap-2 header_tool OutputToolbar-Left">
            <i
              className="fa solid fa-plus"
              onClick={fontIncrease}
              title="Increase Font"
            ></i>

            <div className="vr"></div>
            <i
              className="fa solid fa-minus"
              onClick={fontDecrease}
              title="Decrease Font"
            ></i>
            <div className="vr"></div>
            <i
              className={
                copy === "logs"
                  ? "fa-regular fa-circle-check"
                  : "fa-solid fa-copy"
              }
              onClick={() => downloadOutput("copy")}
              title={copy === "logs" ? "Copied" : "Copy Logs"}
            ></i>
            <div className="vr"></div>
            <div
              ref={ProfileRef}
              className={`profile position-relative ${
                profileActive ? "active" : ""
              }`}
            >
              <div className="icon_wrap hstack gap-1" onClick={toggleProfile}>
                <i
                  className={`fa-solid fa-download ${profileActive ? "activee__icon" : ""}`}
                  title="Download Log"
                ></i>
              </div>
              <div className="profile_dd shadow">
                <ul className="profile_ul">
                  <li>
                    <NavLink
                      onClick={() => {
                        toggleProfile();
                        downloadOutput("html");
                      }}
                      title="Download Log as an HTML file"
                    >
                      <i className="fa-solid fa-file-code"></i> HTML
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => {
                        toggleProfile();
                        downloadOutput("text");
                      }}
                      title="Download Log as an TEXT file"
                    >
                      <i className="fa-solid fa-file-lines"></i> TEXT
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConsultantLogConsole
        editorFullMode={"fullMode"}
        fontSize={fontSize}
        codeLogs={codeArrayLogs}
      />
    </>
  );
};

export default ConsultantFullPageLogConsole;
