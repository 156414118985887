import React, { useEffect, useRef, useState } from "react";
import { Table, Spinner } from "react-bootstrap";
import Moment from "react-moment";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";
import TableHeader from "../../../Component/TableHeader/TableHeader";
import ExportLogData from "./ExportLogData";
import Pagination from "../../../Component/Paginatation/Pagination";
import { NoDataFoundTable } from "../../../Component/NoData/NoDataFoundTable";

const LogData = ({ tableRef, onMouseDown, editorFullMode }) => {
  const [logdata, setLogData] = useState([]);
  const [exportLogData, setExportLogData] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const getLogRecord = async () => {
    setLoading(true);
    let data = await loginActivitiesServices.getLoginHistoryRecord();
    setLogData(data?.data);
    setLoading(false);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = logdata?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(logdata?.length / itemsPerPage);

  useEffect(() => {
    getLogRecord();
  }, []);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "login":
        return "green";
      case "logout":
        return "red";
      default:
        return "inherit";
    }
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {logdata?.length > 0 ? (
            <>
              <TableHeader ExportBtn handleExport={setExportLogData} />
              <div
                className="table_content overflow-auto mx-2"
                style={{
                  maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
                }}
              >
                <div className="table-container tableLibrarySection">
                  <Table
                    id="resizable-table"
                    className="m-0 custom-table"
                    ref={tableRef}
                    bordered
                    striped
                  >
                    <thead className="thead-sticky">
                      <tr>
                        <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                          Sr.
                        </th>
                        <th onMouseDown={(e) => onMouseDown(e, 1)}>IP Address</th>
                        <th onMouseDown={(e) => onMouseDown(e, 2)}>Time Zone</th>
                        <th onMouseDown={(e) => onMouseDown(e, 3)}>Location</th>
                        <th onMouseDown={(e) => onMouseDown(e, 4)}>Activities</th>
                        <th onMouseDown={(e) => onMouseDown(e, 5)}>Status</th>
                        <th onMouseDown={(e) => onMouseDown(e, 6)}>In-Out time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1 + startIndex}</td>
                          <td>{item.ipAddress}</td>
                          <td>{item.timeZone}</td>
                          <td>{item.location}</td>
                          <td>{item.activities}</td>
                          <td className="text-capitalize">
                            <div
                              style={{
                                color: getStatusColor(item.status),
                                fontWeight: 500,
                              }}
                            >
                              {item.status}
                            </div>
                          </td>
                          <td style={{ width: 50 }}>
                            <Moment format="DD MMM YYYY | HH:mm">
                              {item.createdAt}
                            </Moment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="mx-2">
                  <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={logdata?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  />
                </div>
              </div>
            </>
          ) : (
            <NoDataFoundTable MSG="No Log Data Found" />
          )}
        </>
      )}
      <ExportLogData
        show={exportLogData}
        setShow={setExportLogData}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title={"Export Log Data "}
        submit={"Export"}
        disabled={disabled}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
      />
    </>
  );
};

export default LogData;
