import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { packagesServices } from "../../../APIServices/Editor/packagesServices";

const UpdatePackageInformationConsole = ({
  width,
  isFullScreen,
  setIsFullScreen,
  editorFullMode,
  fileId,
  editorTheme
}) => {
  const [packageInfo, setPackageInfo] = useState("");
  const getPackageInformation = async (fileId) => {
    let data = await packagesServices.getPackageInformation(fileId);
    setPackageInfo(data?.data);
  };
  useEffect(() => {
    getPackageInformation(fileId);
  }, [fileId]);
  return (
    <>
      <div
        className={`p-0 output__section_widget ${!editorFullMode ? "" : "fullModeHeight"} ${!editorTheme ? "outputShadowEditorTheme" : "outputShadow"}`}
        style={{
          width: isFullScreen
            ? "98%"
            : width <= 1100
              ? 1500 - width + "px"
              : "400px",
        }}
      >
        <div
          className="border-bottom p-2"
          style={{ background: "#ebebeb", position: "relative" }}
        >
          <div className="hstack gap-2 header_tool justify-content-between">
            <p className="m-0">
              <b>Header </b>
            </p>
            <div className="hstack gap-1">
              <i
                className={
                  isFullScreen ? "fa-solid fa-minimize" : "fa-solid fa-maximize"
                }
                title={isFullScreen ? "Minimized Mode" : "Full Mode"}
                onClick={() => setIsFullScreen(!isFullScreen)}
              ></i>
            </div>
          </div>
        </div>
        <div
          className={`px-2 pt-2 ${!editorFullMode ? "output__section_widget_height" : "output__section_widget_full_height"}`}
        >
          <Table bordered className="m-0">
            <thead>
              <tr>
                <th colSpan={2} style={{ background: "#d4d5d9" }}>
                  <div className="text-center">
                    <p>Header Information</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th style={{ width: 100 }}>
                  <p>Developer Name</p>
                </th>
                <td>
                  <p>{packageInfo?.fullName}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Package Name</p>
                </th>
                <td>
                  <p>{packageInfo?.packageName}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>File Name</p>
                </th>
                <td>
                  <p>index.glx</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Current File Version</p>
                </th>
                <td>
                  <p>{packageInfo?.version}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Path</p>
                </th>
                <td>
                  <p>/packages/{packageInfo?.packageName}/files/index.glx</p>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default UpdatePackageInformationConsole;
