import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import Swal from "sweetalert2";
import Draggable from "react-draggable";
import { Collapse, Form } from "react-bootstrap";
import RightClick from "../RightClick/RightClick";
import { ContextMenuTrigger } from "react-contextmenu";
import { handleResponse } from "../../APIHelpers/Responses";
import { libraryServices } from "../../APIServices/Editor/libraryServices";
import { packagesServices } from "../../APIServices/Editor/packagesServices";
import ViewLibrary from "../../Page/LibraryHelp/LibraryFolders/ViewLibrary";
import AddNewLibrary from "../../Page/LibraryHelp/LibraryFolders/AddNewLibrary";
import UpdateLibrary from "../../Page/LibraryHelp/LibraryFolders/UpdateLibrary";
import { consultantServices } from "../../APIServices/Editor/consultantServices";
import AddNewProjectModal from "../../Page/Consultant/Projects/AddNewProjectModal";
import UpdateProjectModal from "../../Page/Consultant/Projects/UpdateProjectModal";
import UpdateLibraryFile from "../../Page/LibraryHelp/LibraryFiles/UpdateLibraryFile";
import ExportLibraryData from "../../Page/LibraryHelp/LibraryHelps/ExportLibraryData";
import LibraryUploadFile from "../../Page/LibraryHelp/LibraryFiles/LibraryUploadFile";
import PackageFileCheckIn from "../../Page/Developer/Packages/PackageFiles/PackageFileCheckIn";
import AddNewPackagesModal from "../../Page/Developer/Packages/PackageFolder/AddNewPackagesModal";
import PublishPackageModal from "../../Page/Developer/Packages/PackageFolder/PublishPackageModal";
import ViewPropertiesProjectModal from "../../Page/Consultant/Projects/ViewPropertiesProjectModal";
import AddNewDevelopmentFileModal from "../../Page/Consultant/Projects/AddNewDevelopmentFileModal";
import PackageFunctionValidation from "../../Page/AdminValidator/Packages/PackageFunctionValidation";
import AddNewDevelopmentFolderModal from "../../Page/Consultant/Projects/AddNewDevelopmentFolderModal";
import UpdateDevelopmentFolderModal from "../../Page/Consultant/Projects/UpdateDevelopmentFolderModal";
import PackagesUploadDocsModal from "../../Page/Developer/Packages/PackageDocs/PackagesUploadDocsModal";
import PackagesUploadDataModal from "../../Page/Developer/Packages/PackageDatas/PackagesUploadDataModal";
import CurrentCodeCompareModal from "../../Page/Developer/Packages/PackageFiles/CurrentCodeCompareModal";
import PackageFunctionFinalValidation from "../../Page/Validator/Packages/PackageFunctionFinalValidation";
import AddNewCustomProjectFolderModal from "../../Page/Consultant/Projects/AddNewCustomProjectFolderModal";
import ViewLibraryTableFileProperty from "../../Page/LibraryHelp/LibraryFiles/ViewLibraryTableFileProperty";
import HelpLibraryTablePropertyModal from "../../Page/LibraryHelp/LibraryHelps/HelpLibraryTablePropertyModal";
import ViewLibraryGeneralFileProperty from "../../Page/LibraryHelp/LibraryFiles/ViewLibraryGeneralFileProperty";
import UpdatePackageMetadataModal from "../../Page/Developer/Packages/PackageFolder/UpdatePackageMetadataModal";
import PackagesPropertiesModalData from "../../Page/Developer/Packages/PackageFolder/PackagesPropertiesModalData";
import LibraryFileHelpTempPropertiesModalData from "../../Page/LibraryHelp/LibraryFileHelpTempPropertiesModalData";
import UpdatePackageLibraryDataModal from "../../Page/Developer/Packages/PackageDatas/UpdatePackageLibraryDataModal";
import UpdatePackageDocumentDataModal from "../../Page/Developer/Packages/PackageDocs/UpdatePackageDocumentDataModal";
import PackageFunctionValidationForUpdate from "../../Page/AdminValidator/Packages/PackageFunctionValidationForUpdate";
import PackagesUpdateRequestModalData from "../../Page/Developer/Packages/PackageFolder/PackagesUpdateRequestModalData";
import PackagesSubFilePropertyModalData from "../../Page/Developer/Packages/PackageFiles/PackagesSubFilePropertyModalData";
import PackageFunctionFinalValidationForUpdate from "../../Page/Validator/Packages/PackageFunctionFinalValidationForUpdate";
const CollapseMenu = ({
  i,
  openLogs,
  roleType,
  tableRef,
  activeTab,
  libraryId,
  columnList,
  activePage,
  libraryList,
  setOpenLogs,
  openOutputs,
  columnStatus,
  setOpenOutputs,
  showSidebarMenu,
  openDataLibrary,
  userCurrentData,
  libraryTempList,
  handleTabChange,
  currentCodeCompareData,
  getPackageFileCodeById,
  getLibraryDataByFolderId,
  getTempLibraryFolderData,
  setCurrentCodeCompareData,
}) => {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [exportData, setExportData] = useState(false);
  const [packageSubfile, setPackageSubfile] = useState([]);
  const [tableProperty, setTableProperty] = useState(false);
  const [openEditFolder, setOpenEditFolder] = useState(false);
  const [packageSubFolder, setPackageSubFolder] = useState([]);
  const [packageSubFileId, setPackageSubFileId] = useState("");
  const [openEditFolderData, setOpenEditFolderData] = useState("");
  const [packageSubFolderId, setPackageSubFolderId] = useState("");
  const [openAddNewPackage, setOpenAddNewPackage] = useState(false);
  const [uploadLibraryFile, setUploadLibraryFile] = useState(false);
  const [subFileProperties, setSubFileProperties] = useState(false);
  const [uploadDocsPackage, setUploadDocsPackage] = useState(false);
  const [uploadDataPackage, setUploadDataPackage] = useState(false);
  const [packagesProperties, setPackagesProperties] = useState(false);
  const [openAddNewPackages, setOpenAddNewPackages] = useState(false);
  const [packagesFileCheckIn, setPackagesFileCheckIn] = useState(false);
  const [openAddLibraryFolder, setOpenAddLibraryFolder] = useState(false);
  const [packagesPropertiesData, setPackagesPropertiesData] = useState("");
  const [openViewLibraryFolder, setOpenViewLibraryFolder] = useState(false);
  const [updatePackageDocument, setUpdatePackageDocument] = useState(false);
  const [subFileTableProperties, setSubFileTableProperties] = useState(false);
  const [packageValidationPopup, setPackageValidationPopup] = useState(false);
  const [openUpdateLibraryFolder, setOpenUpdateLibraryFolder] = useState(false);
  const [packagesUpdateRequestTask, setPackagesUpdateRequestTask] = useState(false);
  const [libraryFileGeneralProperty, setLibraryFileGeneralProperty] = useState(false);
  const [packageFinalValidationPopup, setPackageFinalValidationPopup] = useState(false);
  const [packageValidationForUpdatePopup, setPackageValidationForUpdatePopup] = useState(false);
  const [packageFinalValidationForUpdatePopup, setPackageFinalValidationForUpdatePopup] = useState(false);
  const [packagesUpdateLibraryFile, setPackagesUpdateLibraryFile] =
    useState(false);
  const [
    openLibraryTablePropertyFileData,
    setOpenLibraryTablePropertyFileData,
  ] = useState(false);
  const [
    openLibraryGeneralPropertyFileData,
    setOpenLibraryGeneralPropertyFileData,
  ] = useState(false);
  const [openLibraryUpdateFileData, setOpenLibraryUpdateFileData] =
    useState(false);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const handleOk = (e) => {
    setOpen(false);
  };

  const handleCancel = (e) => {
    setOpen(false);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const [dummyLibraryList, setDummyLibraryList] = useState(
    JSON.parse(localStorage.getItem("dummyLibraryList")) || []
  );
  const [libraryFileList, setLibraryFileList] = useState(
    JSON.parse(localStorage.getItem("libraryFileList")) || []
  );
  const [sessionLibraryId, setSessionLibraryId] = useState("");
  const [sessionLibraryData, setSessionLibraryData] = useState("");
  const [libraryFileData, setLibraryFileData] = useState("");
  const [packageFolders, setPackageFolders] = useState([]);
  const [packageId, setPackageId] = useState("");

  let hideShowData = JSON.parse(localStorage.getItem("hideShowLibraryData"));

  const deleteSessionLibrary = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Folder?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let newArray = JSON.parse(localStorage.getItem("dummyLibraryList"));
        let result = newArray?.filter((item) => !(item._id === id));
        result.sort((a, b) => a.createdAt - b.createdAt);
        localStorage.setItem("dummyLibraryList", JSON.stringify(result));
        setDummyLibraryList(result);
        let Message = {
          statusCode: 200,
          customMessage: "Folder Name has been Successfully Deleted!",
        };
        handleResponse(Message);
      }
    });
  };

  const updateSessionLibraryDetails = (item) => {
    setSessionLibraryData(item);
    setOpenUpdateLibraryFolder(true);
  };

  const uploadLibraryFileData = (id) => {
    setSessionLibraryId(id);
    setUploadLibraryFile(true);
  };

  const viewSessionLibraryProperties = (item) => {
    setSessionLibraryData(item);
    setOpenViewLibraryFolder(true);
  };

  const viewLibraryFileTableProperties = (item) => {
    setLibraryFileData(item);
    setOpenLibraryTablePropertyFileData(true);
  };

  const viewLibraryFileGeneralProperties = (data, folderName) => {
    let object = {
      fileName: data.fileName,
      description: data.description,
      noofrow: data.noofrow,
      noofcolumn: data.noofcolumn,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      folderName: folderName,
    };
    setLibraryFileData(object);
    setOpenLibraryGeneralPropertyFileData(true);
  };

  const updateLibraryFileData = (item) => {
    setLibraryFileData(item);
    setOpenLibraryUpdateFileData(true);
  };

  const filterLibrarySubmenu = (id) => {
    let newArray = JSON.parse(localStorage.getItem("libraryFileList"));
    let finalResult = newArray?.filter((item) => item.libraryId === id);
    setLibraryFileList(finalResult);
  };

  const deleteSessionLibraryFileData = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this File?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let newArray = JSON.parse(localStorage.getItem("libraryFileList"));
        let result = newArray?.filter(
          (item) => !(item.fileId === data?.fileId)
        );
        result.sort((a, b) => a.createdAt - b.createdAt);
        localStorage.setItem("libraryFileList", JSON.stringify(result));
        let finalResult = result?.filter(
          (item) => item.libraryId === data.libraryId
        );
        setLibraryFileList(finalResult);
        let Message = {
          statusCode: 200,
          customMessage: "File has been Successfully Deleted!",
        };
        handleResponse(Message);
      }
    });
  };

  const deletePackageFolderData = async (packageId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this Package?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await packagesServices.deletePackageFolderData(packageId);
        if (data?.statusCode === 200) {
          getPackageFolderLists();
        }
      }
    });
  };

  const checkInCodeFile = (item) => {
    setPackagesPropertiesData(item);
    setPackagesFileCheckIn(true);
  };

  const currentCodeCompare = (item) => {
    setCurrentCodeCompareData(true);
    setPackagesPropertiesData(item);
  };

  const [subFolderPackageId, setSubFolderPackageId] = useState("");
  const packageFunctionValidationData = (id) => {
    setSubFolderPackageId(id);
    setPackageValidationPopup(true)
  }

  const packageFunctionValidationDataForUpdatePackage = (id) => {
    setSubFolderPackageId(id);
    setPackageValidationForUpdatePopup(true)
  }

  const packageFunctionFinalValidation = (id) => {
    setSubFolderPackageId(id);
    setPackageFinalValidationPopup(true)
  }

  const packageUpdateFunctionFinalValidation = (id) => {
    setSubFolderPackageId(id);
    setPackageFinalValidationForUpdatePopup(true)
  }

  const deleteLibraryFolderAndData = async (libraryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this file?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await packagesServices.deleteLibraryFolderAndData(libraryId);
        if (data?.statusCode === 200) {
          openPackageFileById(packageFileType);
          getTempLibraryFolderData()
        }
      }
    });
  };

  const deletePackageDocumentData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Document?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await packagesServices.deletePackageDocumentData(id);
        if (data?.statusCode === 200) {
          openPackageFileById(packageFileType);
        }
      }
    });
  };

  const duplicateLibraryFolderAndData = async (libraryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Duplicate this file?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Duplicate it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.libraryId = libraryId;
        let data =
          await packagesServices.duplicateLibraryFolderAndData(userData);
        if (data?.statusCode === 200) {
          openPackageFileById(packageFileType);
          getTempLibraryFolderData()
        }
      }
    });
  };

  const checkOutPackageFiles = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to checkout this file?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, check out!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        let data = await packagesServices.checkOutPackageFiles(userData);
        if (data?.statusCode === 200) {
          openPackageFileById(packageFileType);
          getPackageFileCodeById();
        }
      }
    });
  };

  const getPackageFolderLists = async () => {
    let data = await packagesServices.getPackageFolderLists();
    setPackageFolders(data?.data);
  };

  const openPackageSubFolderById = async (packageId) => {
    let data = await packagesServices.openPackageSubFolderById(packageId);
    setPackageSubFolder((data?.data).reverse());
  };

  const openPackagesSubFolderFunction = (item) => {
    openPackageSubFolderById(item.packageId);
    setPackageId(item._id);
  };

  const [packageFileType, setPackageFileType] = useState("");
  const [packageFileDocs, setPackageFileDocs] = useState("");

  const openPackageFileById = async (subFolder) => {
    let data;
    if (subFolder?.name === "files") {
      setPackageSubFolderId(subFolder?.fileId);
      data = await packagesServices.openPackageFileById(subFolder?.fileId);
    } else if (subFolder?.name === "data") {
      setPackageSubFolderId(subFolder?._id);
      data = await libraryServices.getLibraryPackageFolderData(packageId);
    } else if (subFolder?.name === "docs") {
      setPackageSubFolderId(subFolder?._id);
      data = await packagesServices.getPackageDocumentLists(
        subFolder?._id,
        subFolder?.packageId
      );
    }
    setPackageSubfile(data?.data);
    setPackageFileType(subFolder);
  };

  let [categoryList, setCategoryList] = useState([]);
  const getActivePackageCategory = async () => {
    let data = await packagesServices.getActivePackageCategory()
    setCategoryList(data?.data)
  }

  const viewPackageProperties = (item) => {
    setPackagesProperties(true);
    setPackagesPropertiesData(item);
  };

  const [packageUpdateRequest, setPackageUpdateRequest] = useState("");
  const [updatedFileStatus, setupdatedFileStatus] = useState("");
  const viewAssigntaskForUpdate = (packageUpdateRequest, fileStatus) => {
    setPackagesUpdateRequestTask(true);
    setPackageUpdateRequest(packageUpdateRequest);
    setupdatedFileStatus(fileStatus);
  };

  const uploadPackageDocument = (item) => {
    setUploadDocsPackage(true);
    setPackageFileDocs(item);
  };

  const updatePackageDocumentData = (item) => {
    setUpdatePackageDocument(true);
    setPackageFileDocs(item);
  };

  const updatePackageFileData = (item) => {
    setPackagesPropertiesData(item);
    setPackagesUpdateLibraryFile(true);
  };

  const viewPackagesSubFileProperty = (subFiles) => {
    setSubFileProperties(true);
    setSubFileTableProperties(subFiles);
  };

  const viewLibraryFileProperty = (item) => {
    setLibraryFileGeneralProperty(true);
    setSubFileTableProperties(item);
  };

  const UpdateFolderdata = (item) => {
    setOpenEditFolder(true);
    setOpenEditFolderData(item);
  };

  const publishDeveloperPackage = (item) => {
    setOpenAddNewPackage(true);
    setOpenEditFolderData(item);
  };

  //Consultant Project
  let [projectList, setProjectList] = useState([])
  let [projectFolderList, setProjectFolderList] = useState([])
  let [projectProgramFileList, setProjectProgramFileList] = useState([])
  let [addProjectPop, setAddProjectPop] = useState(false)
  let [updateProjectPop, setUpdateProjectPop] = useState(false)
  let [addProjectCustomFolderPop, setAddProjectCustomFolderPop] = useState(false)
  let [addDevelopmentFolderPop, setAddDevelopmentFolderPop] = useState(false)
  let [addDevelopmentFilePop, setAddDevelopmentFilePop] = useState(false)
  let [updateDevelopmentFolderPop, setUpdateDevelopmentFolderPop] = useState(false)
  let [viewPropertiesProjectPop, setViewPropertiesProjectPop] = useState(false)
  let [projectData, setProjectData] = useState("")
  let [folderData, setFolderData] = useState("")
  let [projectId, setProjectId] = useState("")
  let [folderId, setFolderId] = useState("")
  let [type, setType] = useState("")
  let [structuredType, setStructuredType] = useState("")
  let [subFolderId, setSubFolderId] = useState("")
  let [sub1FolderId, setSub1FolderId] = useState("")
  let [sub2FolderId, setSub2FolderId] = useState("")
  let [sub3FolderId, setSub3FolderId] = useState("")
  let [sub4FolderId, setSub4FolderId] = useState("")
  let [sub5FolderId, setSub5FolderId] = useState("")
  let [sub6FolderId, setSub6FolderId] = useState("")
  let [sub7FolderId, setSub7FolderId] = useState("")

  const getConsultantProjectFolder = async () => {
    let data = await consultantServices.getConsultantProjectFolder()
    setProjectList(data?.data)
  }

  let [projectSubFolderList, setProjectSubFolderList] = useState([])
  let [projectSub1FolderList, setProjectSub1FolderList] = useState([])
  let [projectSub2FolderList, setProjectSub2FolderList] = useState([])
  let [projectSub3FolderList, setProjectSub3FolderList] = useState([])
  let [projectSub4FolderList, setProjectSub4FolderList] = useState([])
  let [projectSub5FolderList, setProjectSub5FolderList] = useState([])
  let [projectSub6FolderList, setProjectSub6FolderList] = useState([])
  let [projectSub7FolderList, setProjectSub7FolderList] = useState([])

  const getProjectFolderStructured = async (folderId, menuType) => {
    let data = await consultantServices.getProjectFolderStructured(folderId)
    if (menuType === "mainFolder") {
      setProjectFolderList(data?.data)
    } else if (menuType === "folder") {
      setProjectSubFolderList(data?.data)
    } else if (menuType === "subFolder") {
      setProjectSub1FolderList(data?.data)
    } else if (menuType === "sub1Folder") {
      setProjectSub2FolderList(data?.data)
    } else if (menuType === "sub2Folder") {
      setProjectSub3FolderList(data?.data)
    } else if (menuType === "sub3Folder") {
      setProjectSub4FolderList(data?.data)
    } else if (menuType === "sub4Folder") {
      setProjectSub5FolderList(data?.data)
    } else if (menuType === "sub5Folder") {
      setProjectSub6FolderList(data?.data)
    } else if (menuType === "sub6Folder") {
      setProjectSub7FolderList(data?.data)
    }

    console.log(">>menuType", menuType);
  }

  const getProjectFileFolderById = async (folderId) => {
    let data = await consultantServices.getProjectFileFolderById(folderId)
    setProjectProgramFileList(data?.data)
  }

  const updateProjectData = (e, item) => {
    setProjectData(item)
    getProjectFolderStructured(item?.projectId)
    setProjectId(item?.projectId)
    setUpdateProjectPop(true)
  }

  const viewProjectPropertiesData = (e, item) => {
    setProjectData(item)
    getProjectFolderStructured(item?.projectId)
    setProjectId(item?.projectId)
    setViewPropertiesProjectPop(true)
  }

  const addProjectCustomFolder = (e, folderId, projectId) => {
    setFolderId(folderId)
    setProjectId(projectId)
    getProjectFolderStructured(folderId)
    setAddProjectCustomFolderPop(true)
  }

  const addNewDevelopmentFolder = (e, type, standardStructured, folderId, subFolderId) => {
    if (type === "folder") {
      setFolderId(folderId)
      getProjectFolderStructured(folderId, type)
    } else if (type === "subFolder") {
      setSubFolderId(subFolderId)
      getProjectFolderStructured(subFolderId, type)
    } else if (type === "sub1Folder") {
      setSub1FolderId(subFolderId)
      getProjectFolderStructured(subFolderId, type)
    } else if (type === "sub2Folder") {
      setSub2FolderId(subFolderId)
      getProjectFolderStructured(subFolderId, type)
    } else if (type === "sub3Folder") {
      setSub3FolderId(subFolderId)
      getProjectFolderStructured(subFolderId, type)
    } else if (type === "sub4Folder") {
      setSub4FolderId(subFolderId)
      getProjectFolderStructured(subFolderId, type)
    } else if (type === "sub5Folder") {
      setSub5FolderId(subFolderId)
      getProjectFolderStructured(subFolderId, type)
    } else if (type === "sub6Folder") {
      setSub6FolderId(subFolderId)
      getProjectFolderStructured(subFolderId, type)
    }
    setType(type)
    setStructuredType(standardStructured)
    setAddDevelopmentFolderPop(true)
  }

  const addNewDevelopmentFile = (e, type, subFolderId, standardStructured) => {
    if (type === "subFolder") {
      setSubFolderId(subFolderId)
    } else if (type === "sub1Folder") {
      setSub1FolderId(subFolderId)
    } else if (type === "sub2Folder") {
      setSub2FolderId(subFolderId)
    } else if (type === "sub3Folder") {
      setSub3FolderId(subFolderId)
    } else if (type === "sub4Folder") {
      setSub4FolderId(subFolderId)
    } else if (type === "sub5Folder") {
      setSub5FolderId(subFolderId)
    } else if (type === "sub6Folder") {
      setSub6FolderId(subFolderId)
    }
    setType(type)
    setStructuredType(standardStructured)
    getProjectFolderStructured(subFolderId, type)
    setAddDevelopmentFilePop(true)
  }

  const updateDevelopmentFolderData = (e, type, item) => {
    setFolderData(item)
    getProjectFolderStructured(item._id, type)
    if (type === "folder") {
      setFolderId(item._id)
    } else if (type === "subFolder") {
      setSubFolderId(item._id)
    } else if (type === "sub1Folder") {
      setSub1FolderId(item._id)
    } else if (type === "sub2Folder") {
      setSub2FolderId(item._id)
    } else if (type === "sub3Folder") {
      setSub3FolderId(item._id)
    } else if (type === "sub4Folder") {
      setSub4FolderId(item._id)
    } else if (type === "sub5Folder") {
      setSub5FolderId(subFolderId)
    } else if (type === "sub6Folder") {
      setSub6FolderId(subFolderId)
    } else if (type === "sub7Folder") {
      setSub7FolderId(subFolderId)
    }
    setType(type)
    setUpdateDevelopmentFolderPop(true)
  }

  const deleteConsultantProjectFolder = async (e, projectId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Project?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {
          projectId: projectId
        }
        let data = await consultantServices.deleteConsultantProjectFolder(userData);
        if (data?.statusCode === 200) {
          getConsultantProjectFolder();
        }
      }
    });
  };

  const deleteDevelopmentFolder = async (e, folderId, menuType) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Folder?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {
          folderId: folderId
        }
        let data = await consultantServices.deleteDevelopmentFolderData(userData);
        if (data?.statusCode === 200) {
          getProjectFolderStructured(folderId, menuType);
        }
      }
    });
  };

  const addNewProjectFolderStructured = async (e, folderId, projectId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Create Standard Stracture?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Create it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.structureType = "standard"
        userData.projectId = projectId
        userData.folderId = folderId
        let data = await consultantServices.addNewProjectFolderStructured(userData);
        if (data?.statusCode === 200) {
          getProjectFolderStructured(folderId);
        }
      }
    });
  };

  const lockUnlockConsultantFolders = async (e, folderId, projectId, status, folderType, type) => {
    Swal.fire({
      title: `Do you want to ${status === "lock" ? "Lock" : "Unlock"} Folder?`,
      text: `Select ${status === "lock" ? "Lock" : "Unlock"} only Current Folder of All Children Folder?`,
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#103c5e",
      denyButtonColor: "#103c5e",
      confirmButtonText: `${status === "lock" ? "Lock" : "Unlock"} Current Folder`,
      denyButtonText: `${status === "lock" ? "Lock" : "Unlock"} Current with Child Folders`,
      customClass: {
        actions: 'my-actions',
        confirmButton: 'order-1 PopSwalButton',
        denyButton: 'order-2 PopSwalButton right-gap',
        cancelButton: 'order-4 PopSwalButton',
      },
    }).then(async (result) => {
      let userData = {}
      userData.projectId = projectId
      userData.folderId = folderId
      userData.folderType = folderType
      userData.status = status
      if (result.isConfirmed && !result.isDenied) {
        userData.type = "current"
        let data = await consultantServices.lockUnlockConsultantFolders(userData);
        if (data?.statusCode === 200) {
          if (type === "mainFolder") {
            setProjectId(folderId)
            setFolderId("")
            setSubFolderId("")
            setSub1FolderId("")
            setSub2FolderId("")
            setSub3FolderId("")
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSubFolderList([])
            setProjectSub1FolderList([])
            setProjectSub2FolderList([])
            setProjectSub3FolderList([])
            setProjectSub4FolderList([])
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "folder") {
            setFolderId(folderId)
            setSubFolderId("")
            setSub1FolderId("")
            setSub2FolderId("")
            setSub3FolderId("")
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub1FolderList([])
            setProjectSub2FolderList([])
            setProjectSub3FolderList([])
            setProjectSub4FolderList([])
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "subFolderId") {
            setSubFolderId(folderId)
            setSub1FolderId("")
            setSub2FolderId("")
            setSub3FolderId("")
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub2FolderList([])
            setProjectSub3FolderList([])
            setProjectSub4FolderList([])
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "sub1FolderId") {
            setSub1FolderId(folderId)
            setSub2FolderId("")
            setSub3FolderId("")
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub3FolderList([])
            setProjectSub4FolderList([])
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "sub2FolderId") {
            setSub2FolderId(folderId)
            setSub3FolderId("")
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub4FolderList([])
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "sub3FolderId") {
            setSub3FolderId(folderId)
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "sub4FolderId") {
            setSub4FolderId(folderId)
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "sub5FolderId") {
            setSub5FolderId(folderId)
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub7FolderList([])
          } else if (type === "sub6FolderId") {
            setSub6FolderId(folderId)
            setSub7FolderId("")
          } else if (type === "sub7FolderId") {
            setSub7FolderId(folderId)
          }
        }
      } else if (!result.isConfirmed && result.isDenied) {
        userData.type = "all"
        let data = await consultantServices.lockUnlockConsultantFolders(userData);
        if (data?.statusCode === 200) {
          if (type === "mainFolder") {
            setProjectId(folderId)
            setFolderId("")
            setSubFolderId("")
            setSub1FolderId("")
            setSub2FolderId("")
            setSub3FolderId("")
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSubFolderList([])
            setProjectSub1FolderList([])
            setProjectSub2FolderList([])
            setProjectSub3FolderList([])
            setProjectSub4FolderList([])
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "folder") {
            setFolderId(folderId)
            setSubFolderId("")
            setSub1FolderId("")
            setSub2FolderId("")
            setSub3FolderId("")
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub1FolderList([])
            setProjectSub2FolderList([])
            setProjectSub3FolderList([])
            setProjectSub4FolderList([])
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "subFolderId") {
            setSubFolderId(folderId)
            setSub1FolderId("")
            setSub2FolderId("")
            setSub3FolderId("")
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub2FolderList([])
            setProjectSub3FolderList([])
            setProjectSub4FolderList([])
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "sub1FolderId") {
            setSub1FolderId(folderId)
            setSub2FolderId("")
            setSub3FolderId("")
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub3FolderList([])
            setProjectSub4FolderList([])
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "sub2FolderId") {
            setSub2FolderId(folderId)
            setSub3FolderId("")
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub4FolderList([])
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "sub3FolderId") {
            setSub3FolderId(folderId)
            setSub4FolderId("")
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub5FolderList([])
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "sub4FolderId") {
            setSub4FolderId(folderId)
            setSub5FolderId("")
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub6FolderList([])
            setProjectSub7FolderList([])
            getProjectFolderStructured(folderId, type)
          } else if (type === "sub5FolderId") {
            setSub5FolderId(folderId)
            setSub6FolderId("")
            setSub7FolderId("")
            setProjectSub7FolderList([])
          } else if (type === "sub6FolderId") {
            setSub6FolderId(folderId)
            setSub7FolderId("")
          } else if (type === "sub7FolderId") {
            setSub7FolderId(folderId)
          }
        }
      }
    });
  };

  const addNewProjectProgramEnvironment = async (e, folderId, projectId, type, standardStructured) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Create Programming Environment?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Create it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.folderId = folderId
        userData.projectId = projectId
        userData.structuredType = standardStructured
        let data = await consultantServices.addNewProjectProgramEnvironment(userData);
        if (data?.statusCode === 200) {
          getProjectFolderStructured(folderId, type);
        }
      }
    });
  };

  const onCLickOpenProject = (folderId, projectId) => {
    getProjectFolderStructured(folderId, "mainFolder")
    setProjectId(projectId)
    setFolderId("")
    setSubFolderId("")
    setSub1FolderId("")
    setSub2FolderId("")
    setSub3FolderId("")
    setSub4FolderId("")
    setSub5FolderId("")
    setSub6FolderId("")
    setSub7FolderId("")
    setProjectFolderList([])
    setProjectSubFolderList([])
    setProjectSub1FolderList([])
    setProjectSub2FolderList([])
    setProjectSub3FolderList([])
    setProjectSub4FolderList([])
    setProjectSub5FolderList([])
    setProjectSub6FolderList([])
    setProjectSub7FolderList([])
  }

  const onCLickOpenProjectFolder = (folderId) => {
    getProjectFolderStructured(folderId, "folder")
    setFolderId(folderId)
    setSubFolderId("")
    setSub1FolderId("")
    setSub2FolderId("")
    setSub3FolderId("")
    setSub4FolderId("")
    setSub5FolderId("")
    setSub6FolderId("")
    setSub7FolderId("")
    setProjectSubFolderList([])
    setProjectSub1FolderList([])
    setProjectSub2FolderList([])
    setProjectSub3FolderList([])
    setProjectSub4FolderList([])
    setProjectSub5FolderList([])
    setProjectSub6FolderList([])
    setProjectSub7FolderList([])
  }

  const onCLickOpenProjectSubFolder = (folderId) => {
    getProjectFolderStructured(folderId, "subFolder")
    setSubFolderId(folderId)
    setSub1FolderId("")
    setSub2FolderId("")
    setSub3FolderId("")
    setSub4FolderId("")
    setSub5FolderId("")
    setSub6FolderId("")
    setSub7FolderId("")
    setProjectSub1FolderList([])
    setProjectSub2FolderList([])
    setProjectSub3FolderList([])
    setProjectSub4FolderList([])
    setProjectSub5FolderList([])
    setProjectSub6FolderList([])
    setProjectSub7FolderList([])
  }

  const onCLickOpenProjectSub1Folder = (folderId) => {
    getProjectFolderStructured(folderId, "sub1Folder")
    setSub1FolderId(folderId)
    setSub2FolderId("")
    setSub3FolderId("")
    setSub4FolderId("")
    setSub5FolderId("")
    setSub6FolderId("")
    setSub7FolderId("")
    setProjectSub2FolderList([])
    setProjectSub3FolderList([])
    setProjectSub4FolderList([])
    setProjectSub5FolderList([])
    setProjectSub6FolderList([])
    setProjectSub7FolderList([])
  }

  const onCLickOpenProjectSub2Folder = (folderId) => {
    getProjectFolderStructured(folderId, "sub2Folder")
    setSub2FolderId(folderId)
    setSub3FolderId("")
    setSub4FolderId("")
    setSub5FolderId("")
    setSub6FolderId("")
    setSub7FolderId("")
    setProjectSub3FolderList([])
    setProjectSub4FolderList([])
    setProjectSub5FolderList([])
    setProjectSub6FolderList([])
    setProjectSub7FolderList([])
  }

  const onCLickOpenProjectSub3Folder = (folderId) => {
    getProjectFolderStructured(folderId, "sub3Folder")
    setSub3FolderId(folderId)
    setSub4FolderId("")
    setSub5FolderId("")
    setSub6FolderId("")
    setSub7FolderId("")
    setProjectSub4FolderList([])
    setProjectSub5FolderList([])
    setProjectSub6FolderList([])
    setProjectSub7FolderList([])
  }

  const onCLickOpenProjectSub4Folder = (folderId) => {
    getProjectFolderStructured(folderId, "sub4Folder")
    setSub4FolderId(folderId)
    setSub5FolderId("")
    setSub6FolderId("")
    setSub7FolderId("")
    setProjectSub5FolderList([])
    setProjectSub6FolderList([])
    setProjectSub7FolderList([])
  }

  const onCLickOpenProjectSub5Folder = (folderId) => {
    getProjectFolderStructured(folderId, "sub5Folder")
    setSub5FolderId(folderId)
    setSub6FolderId("")
    setSub7FolderId("")
    setProjectSub6FolderList([])
    setProjectSub7FolderList([])
  }

  const onCLickOpenProjectSub6Folder = (folderId) => {
    getProjectFolderStructured(folderId, "sub6Folder")
    setSub6FolderId(folderId)
    setSub7FolderId("")
    setProjectSub7FolderList([])
  }

  const onCLickOpenProjectSub7Folder = (folderId) => {
    getProjectFileFolderById(folderId)
    setSub7FolderId(folderId)
  }
  useEffect(() => {
    getPackageFolderLists();
    getActivePackageCategory();
    getConsultantProjectFolder();
  }, []);
  return (
    <>
      {roleType === "consultant" && showSidebarMenu === "file" && (
        <>
          <div className="link__tabs1">
            <div className="hstack justify-content-between">
              <div>
                <i className="fa-solid fa-folder-plus me-2"></i>
                <Form.Label className="m-0">Projects</Form.Label>
              </div>
              <i
                className="fa-solid fa-circle-plus"
                title="Add New Package"
                style={{ cursor: "pointer" }}
                onClick={setAddProjectPop}
              ></i>
            </div>
          </div>
          {projectList?.map((item, index) => (
            <>
              <div key={index} className="link__tab">
                <ContextMenuTrigger id={`project_${index}`}>
                  <div
                    className={`text-start h-auto tab_url w-100 ${projectId === item?.projectId ? "activee" : ""
                      }`}
                    onClick={() => onCLickOpenProject(item._id, (projectId === item?.projectId ? "" : item?.projectId))}
                    aria-expanded={projectId === item?.projectId}
                  >
                    <div
                      className={`hstack justify-content-between ${item.status === "lock" && "fileReviewRed"}`}>
                      <div>
                        <i
                          className={`me-2 fa-solid ${projectId === item?.projectId ? "fa-folder-open" : "fa-folder"}`}
                        ></i>
                        <Form.Label className="m-0 text-capitalize">{item.projectName}</Form.Label>
                      </div>
                      <i
                        className={`fa-solid ${projectId === item?.projectId ? "fa-angle-down" : "fa-angle-right"}`}
                      ></i>
                    </div>
                  </div>
                  {item.status === "unlock" ?
                    <RightClick
                      ContextId={`project_${index}`}
                      Menu1={!item.standardStructured ? true : item.standardStructured === "standard" ? false : item.standardStructured === "custom"}
                      MenuName1={"Add New Custom Folder"}
                      icons1={<i className="fa-solid fa-circle-plus edit"></i>}
                      handleClick1={(e) => addProjectCustomFolder(e, item._id, item.projectId)}
                      Menu2={!item.standardStructured ? true : (item.standardStructured === "standard" ? !(item.standardStructured === "standard") : false)}
                      MenuName2={"Add Standard Structure"}
                      icons2={<i className="fa-solid fa-circle-plus edit"></i>}
                      handleClick2={(e) => addNewProjectFolderStructured(e, item._id, item.projectId)}
                      Menu3
                      MenuName3={"Update Project"}
                      icons3={<i className="fa-solid fa-edit edit"></i>}
                      handleClick3={(e) => updateProjectData(e, item)}
                      Menu4
                      className4={item.status === "unlock" ? "delete" : "fileReviewGreen"}
                      MenuName4={item.status === "unlock" ? "Lock Project" : "Unlock Project"}
                      icons4={<i className={`fa-solid ${item.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                      handleClick4={(e) => lockUnlockConsultantFolders(e, item?._id, item.projectId, (item.status === "lock" ? "unlock" : "lock"), item.type, "mainFolder")}
                      Menu5
                      className5="delete"
                      MenuName5={"Delete Project"}
                      icons5={<i className="fa-solid fa-trash-can delete"></i>}
                      handleClick5={(e) => deleteConsultantProjectFolder(e, item.projectId)}
                      Menu6
                      MenuName6={"Properties"}
                      icons6={<i className="fa-solid fa-circle-info edit"></i>}
                      handleClick6={(e) => viewProjectPropertiesData(e, item)}
                    />
                    :
                    <RightClick
                      ContextId={`project_${index}`}
                      Menu1
                      className1={item.status === "unlock" ? "delete" : "fileReviewGreen"}
                      MenuName1={item.status === "unlock" ? "Lock Project" : "Unlock Project"}
                      icons1={<i className={`fa-solid ${item.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                      handleClick1={(e) => lockUnlockConsultantFolders(e, item?._id, item.projectId, (item.status === "lock" ? "unlock" : "lock"), item.type, "mainFolder")}
                      Menu2
                      MenuName2={"Properties"}
                      icons2={<i className="fa-solid fa-circle-info edit"></i>}
                      handleClick2={(e) => viewProjectPropertiesData(e, item)}
                    />
                  }
                </ContextMenuTrigger>
              </div>
              <Collapse in={projectId === item?.projectId}>
                <div>
                  {projectFolderList?.map((folder, key) => (
                    <ContextMenuTrigger id={`projectfolder_${key}`}>
                      <div key={key}>
                        <div className="link__tab">
                          <div
                            onClick={() => onCLickOpenProjectFolder(folderId === folder._id ? "" : folder._id)}
                            aria-expanded={folderId === folder._id}
                            className={`text-start h-auto tab_url w-100 ${folderId === folder._id ? "activee" : ""
                              }`}
                          >
                            <div className={`hstack justify-content-between ${(folder.status === "lock" || item.status === "lock") && "fileReviewRed"}`}>
                              <div>
                                <i
                                  className={`me-2 ps-2 fa-solid ${folderId === folder._id ? "fa-folder-open" : "fa-folder"}`}
                                ></i>
                                <Form.Label className="m-0 text-capitalize">{folder.name}</Form.Label>
                              </div>
                              <i
                                className={`fa-solid ${folderId === folder._id ? "fa-angle-down" : "fa-angle-right"}`}
                              ></i>
                            </div>
                          </div>
                          {(folder.name === "development" || folder.structureType === "custom") &&
                            (folder.status === "lock" || item.status === "lock") ?
                            <RightClick
                              ContextId={`projectfolder_${key}`}
                              Menu1={item.status === "unlock"}
                              className1={folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                              MenuName1={folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                              icons1={<i className={`fa-solid ${folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                              handleClick1={(e) => lockUnlockConsultantFolders(e, folder?._id, item.projectId, (folder.status === "lock" ? "unlock" : "lock"), "folder", "folder")}
                            />
                            :
                            <RightClick
                              ContextId={`projectfolder_${key}`}
                              Menu1={(!folder.programEnvironment ? true : folder.programEnvironment === "customFile")}
                              MenuName1={"Add New Folder"}
                              icons1={<i className="fa-solid fa-circle-plus edit"></i>}
                              handleClick1={(e) => addNewDevelopmentFolder(e, "folder", item.standardStructured, folder._id, "")}
                              Menu2={!folder.programEnvironment ? true : (folder.programEnvironment === "customFile" ? !(folder.programEnvironment === "customFile") : !(folder.programEnvironment === "programFile"))}
                              MenuName2={"Add Program Environment"}
                              icons2={<i className="fa-solid fa-circle-plus edit"></i>}
                              handleClick2={(e) => addNewProjectProgramEnvironment(e, folder._id, folder.projectId, "folder", item.standardStructured)}
                              Menu3={folder.structureType === "custom"}
                              MenuName3={"Update Folder Name"}
                              icons3={<i className="fa-solid fa-edit edit"></i>}
                              handleClick3={(e) => updateDevelopmentFolderData(e, "folder", folder)}
                              Menu4
                              className4={folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                              MenuName4={folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                              icons4={<i className={`fa-solid ${folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                              handleClick4={(e) => lockUnlockConsultantFolders(e, folder?._id, item.projectId, (folder.status === "lock" ? "unlock" : "lock"), "folder", "folder")}
                              Menu5={folder.structureType === "custom"}
                              className5="delete"
                              MenuName5={"Delete Folder"}
                              icons5={<i className="fa-solid fa-trash-can delete"></i>}
                              handleClick5={(e) => deleteDevelopmentFolder(e, folder._id, "folder")}
                            />
                          }
                        </div>
                        <Collapse in={folderId === folder._id}>
                          <div>
                            {projectSubFolderList?.map((subFolder, value) => (
                              <ContextMenuTrigger id={`projectsubfolder_${value}`}>
                                <div key={value}>
                                  <div className="link__tab">
                                    <div
                                      className={`text-start h-auto tab_url w-100 ${subFolderId === subFolder._id ? "activee" : ""
                                        }`}
                                      onClick={
                                        subFolder.type === "folder" ?
                                          () => onCLickOpenProjectSubFolder(subFolderId === subFolder._id ? "" : subFolder._id)
                                          :
                                          (e) => handleTabChange(
                                            e,
                                            subFolder.name + (folder.name === "logs" ? ".glxlog" : folder.name === "outputs" ? ".glxhtm" : folder.name === "programs" ? ".glx" : ""),
                                            subFolder._id,
                                            folder.name === "outputs" || folder.name === "logs" ? "fa-solid fa-file" : "fa-solid fa-file-code",
                                            folder.name === "outputs" ? "codeEditorOutput" : folder.name === "logs" ? "codeEditorLog" : "codeEditor",
                                          )
                                      }
                                      aria-expanded={subFolderId === subFolder._id}
                                    >
                                      <div className={`hstack justify-content-between ${(subFolder.status === "lock" || folderId.status === "lock" || item.status === "lock") && "fileReviewRed"}`}>
                                        <div>
                                          <i
                                            className={`me-2 ps-3 fa-solid ${subFolderId === subFolder._id ? (subFolder.structureType === "programEnvironment" ? (subFolder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (subFolder.type === "folder" ? "fa-folder-open" : "fa-file-code")) : (subFolder.structureType === "programEnvironment" ? (subFolder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (subFolder.type === "folder" ? "fa-folder" : "fa-file-code"))}`}
                                          ></i>
                                          <Form.Label className={`m-0 ${subFolder.type === "folder" && "text-capitalize"}`}>{subFolder.name}</Form.Label>
                                        </div>
                                        {subFolder.type === "folder" &&
                                          <i
                                            className={`fa-solid ${subFolderId === subFolder._id ? "fa-angle-down" : "fa-angle-right"}`}
                                          ></i>
                                        }
                                      </div>
                                    </div>
                                    {(subFolder.status === "lock" || folder.status === "lock" || item.status === "lock") ?
                                      <RightClick
                                        ContextId={`projectsubfolder_${key}`}
                                        Menu1={!(folder.status === "lock" || item.status === "lock")}
                                        className1={subFolder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                        MenuName1={subFolder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                        icons1={<i className={`fa-solid ${subFolder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                        handleClick1={(e) => lockUnlockConsultantFolders(e, subFolder?._id, item.projectId, (subFolder.status === "lock" ? "unlock" : "lock"), "folder", "subFolder")}
                                      />
                                      :
                                      <RightClick
                                        ContextId={`projectsubfolder_${value}`}
                                        Menu1={(!subFolder.programEnvironment ? ((subFolder.name === "programs" || subFolder.name === "outputs" || subFolder.name === "logs" || folder.name === "production" || folder.name === "validation") ? false : true) : ((subFolder.name === "programs" || subFolder.name === "outputs" || subFolder.name === "logs" || folder.name === "production" || folder.name === "validation") ? false : subFolder.programEnvironment === "customFile"))}
                                        MenuName1={"Add New Folder"}
                                        icons1={<i className="fa-solid fa-circle-plus edit"></i>}
                                        handleClick1={(e) => addNewDevelopmentFolder(e, "subFolder", item.standardStructured, folder._id, subFolder._id)}
                                        Menu2={!subFolder.programEnvironment ? ((subFolder.name === "programs" || subFolder.name === "outputs" || subFolder.name === "logs" || folder.name === "production" || folder.name === "validation") ? false : true) : (subFolder.programEnvironment === "customFile" ? !(subFolder.programEnvironment === "customFile") : !(subFolder.programEnvironment === "programFile"))}
                                        MenuName2={"Add Program Environment"}
                                        icons2={<i className="fa-solid fa-circle-plus edit"></i>}
                                        handleClick2={(e) => addNewProjectProgramEnvironment(e, subFolder._id, item.projectId, "subFolder", item.standardStructured)}
                                        Menu3={subFolder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                        MenuName3={"Add New File"}
                                        icons3={<i className="fa-solid fa-circle-plus edit"></i>}
                                        handleClick3={(e) => addNewDevelopmentFile(e, "subFolder", subFolder._id, item.standardStructured)}
                                        Menu4
                                        className4={subFolder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                        MenuName4={subFolder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                        icons4={<i className={`fa-solid ${subFolder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                        handleClick4={(e) => lockUnlockConsultantFolders(e, subFolder?._id, item.projectId, (subFolder.status === "lock" ? "unlock" : "lock"), "folder", "subFolder")}
                                        Menu5={(subFolder.name === "programs" || subFolder.name === "outputs" || subFolder.name === "logs" || folder.name === "production" || folder.name === "validation") ? false : true}
                                        MenuName5={"Update Folder Name"}
                                        icons5={<i className="fa-solid fa-edit edit"></i>}
                                        handleClick5={(e) => updateDevelopmentFolderData(e, "subFolder", subFolder)}
                                        Menu6={(subFolder.name === "programs" || subFolder.name === "outputs" || subFolder.name === "logs" || folder.name === "production" || folder.name === "validation") ? false : true}
                                        className6="delete"
                                        MenuName6={"Delete Folder"}
                                        icons6={<i className="fa-solid fa-trash-can delete"></i>}
                                        handleClick6={(e) => deleteDevelopmentFolder(e, subFolder._id, "subFolder")}
                                      />
                                    }
                                  </div>
                                  <Collapse in={subFolderId === subFolder._id}>
                                    <div>
                                      {projectSub1FolderList?.map((sub1Folder, value) => (
                                        <ContextMenuTrigger id={`projectsub1folder_${value}`}>
                                          <div key={value}>
                                            <div className="link__tab">
                                              <div
                                                className={`text-start h-auto tab_url w-100 ${sub1FolderId === sub1Folder._id ? "activee" : ""
                                                  }`}
                                                onClick={
                                                  sub1Folder.type === "folder" ?
                                                    () => onCLickOpenProjectSub1Folder(sub1FolderId === sub1Folder._id ? "" : sub1Folder._id)
                                                    :
                                                    (e) => handleTabChange(
                                                      e,
                                                      sub1Folder.name + (subFolder.name === "logs" ? ".glxlog" : subFolder.name === "outputs" ? ".glxhtm" : subFolder.name === "programs" ? ".glx" : "") + (folder.structureType === "standard" && ` (${folder?.name})`),
                                                      sub1Folder._id,
                                                      subFolder.name === "outputs" || subFolder.name === "logs" ? "fa-solid fa-file" : "fa-solid fa-file-code",
                                                      subFolder.name === "outputs" ? "codeEditorOutput" : subFolder.name === "logs" ? "codeEditorLog" : "codeEditor",
                                                    )
                                                }
                                                aria-expanded={sub1FolderId === sub1Folder._id}
                                              >
                                                <div className={`hstack justify-content-between ${(sub1Folder.status === "lock" || subFolder.status === "lock" || folderId.status === "lock" || item.status === "lock") && "fileReviewRed"}`}>
                                                  <div>
                                                    <i
                                                      className={`me-2 ps-4 fa-solid ${sub1FolderId === sub1Folder._id ? (sub1Folder.structureType === "programEnvironment" ? (sub1Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub1Folder.type === "folder" ? "fa-folder-open" : "fa-file-code")) : (sub1Folder.structureType === "programEnvironment" ? (sub1Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub1Folder.type === "folder" ? "fa-folder" : "fa-file-code"))}`}
                                                    ></i>
                                                    <Form.Label className="m-0"><span className={`${sub1Folder.type === "folder" && "text-capitalize"}`}>{sub1Folder.name}</span>{subFolder.name === "logs" ? ".glxlog" : subFolder.name === "outputs" ? ".glxhtm" : subFolder.name === "programs" ? ".glx" : ""}</Form.Label>
                                                  </div>
                                                  {sub1Folder.type === "folder" &&
                                                    <i
                                                      className={`fa-solid ${sub1FolderId === sub1Folder._id ? "fa-angle-down" : "fa-angle-right"}`}
                                                    ></i>
                                                  }
                                                </div>
                                              </div>
                                              {(sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock") ?
                                                <RightClick
                                                  ContextId={`projectsub1folder_${value}`}
                                                  Menu1={!(subFolder.status === "lock" || folder.status === "lock" || item.status === "lock")}
                                                  className1={sub1Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                  MenuName1={sub1Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                  icons1={<i className={`fa-solid ${sub1Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                  handleClick1={(e) => lockUnlockConsultantFolders(e, sub1Folder?._id, item.projectId, (sub1Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub1Folder")}
                                                />
                                                :
                                                <RightClick
                                                  ContextId={`projectsub1folder_${value}`}
                                                  Menu1={(!sub1Folder.programEnvironment ? ((sub1Folder.name === "programs" || sub1Folder.name === "outputs" || sub1Folder.name === "logs" || subFolder.name === "programs" || subFolder.name === "outputs" || subFolder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true) : ((sub1Folder.name === "programs" || sub1Folder.name === "outputs" || sub1Folder.name === "logs" || subFolder.name === "programs" || subFolder.name === "outputs" || subFolder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : sub1Folder.programEnvironment === "customFile"))}
                                                  MenuName1={"Add New Folder"}
                                                  icons1={<i className="fa-solid fa-circle-plus edit"></i>}
                                                  handleClick1={(e) => addNewDevelopmentFolder(e, "sub1Folder", item.standardStructured, folder._id, sub1Folder._id)}
                                                  Menu2={!sub1Folder.programEnvironment ? ((sub1Folder.name === "programs" || sub1Folder.name === "outputs" || sub1Folder.name === "logs" || subFolder.name === "programs" || subFolder.name === "outputs" || subFolder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true) : (sub1Folder.programEnvironment === "customFile" ? !(sub1Folder.programEnvironment === "customFile") : !(sub1Folder.programEnvironment === "programFile"))}
                                                  MenuName2={"Add Program Environment"}
                                                  icons2={<i className="fa-solid fa-circle-plus edit"></i>}
                                                  handleClick2={(e) => addNewProjectProgramEnvironment(e, sub1Folder._id, item.projectId, "sub1Folder", item.standardStructured)}
                                                  Menu3={sub1Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                  MenuName3={"Add New File"}
                                                  icons3={<i className="fa-solid fa-circle-plus edit"></i>}
                                                  handleClick3={(e) => addNewDevelopmentFile(e, "sub1Folder", sub1Folder._id, item.standardStructured)}
                                                  Menu4={sub1Folder.type === "folder"}
                                                  className4={sub1Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                  MenuName4={sub1Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                  icons4={<i className={`fa-solid ${sub1Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                  handleClick4={(e) => lockUnlockConsultantFolders(e, sub1Folder?._id, item.projectId, (sub1Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub1Folder")}
                                                  Menu5={(sub1Folder.name === "programs" || sub1Folder.name === "outputs" || sub1Folder.name === "logs" || subFolder.name === "programs" || subFolder.name === "outputs" || subFolder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                  MenuName5={"Update Folder Name"}
                                                  icons5={<i className="fa-solid fa-edit edit"></i>}
                                                  handleClick5={(e) => updateDevelopmentFolderData(e, "sub1Folder", sub1Folder)}
                                                  Menu6={(sub1Folder.name === "programs" || sub1Folder.name === "outputs" || sub1Folder.name === "logs" || subFolder.name === "programs" || subFolder.name === "outputs" || subFolder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                  className6="delete"
                                                  MenuName6={"Delete Folder"}
                                                  icons6={<i className="fa-solid fa-trash-can delete"></i>}
                                                  handleClick6={(e) => deleteDevelopmentFolder(e, sub1Folder._id, "sub1Folder")}
                                                  Menu7={subFolder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                  MenuName7={"Update File"}
                                                  icons7={<i className="fa-solid fa-edit edit"></i>}
                                                  handleClick7={(e) => updateDevelopmentFolderData(e, "sub1Folder", sub1Folder)}
                                                  Menu8={subFolder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                  className8="delete"
                                                  MenuName8={"Delete"}
                                                  icons8={<i className="fa-solid fa-trash-can delete"></i>}
                                                  handleClick8={(e) => deleteDevelopmentFolder(e, sub1Folder._id, "sub1Folder")}
                                                  Menu9={subFolder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                  MenuName9={"Properties"}
                                                  icons9={<i className="fa-solid fa-circle-info edit"></i>}
                                                  handleClick9={(e) => deleteDevelopmentFolder(e, sub1Folder._id, "sub1Folder")}
                                                />
                                              }
                                            </div>
                                            <Collapse in={sub1FolderId === sub1Folder._id}>
                                              <div>
                                                {projectSub2FolderList?.map((sub2Folder, value) => (
                                                  <ContextMenuTrigger id={`projectsub2folder_${value}`}>
                                                    <div key={value}>
                                                      <div className="link__tab">
                                                        <div
                                                          className={`text-start h-auto tab_url w-100 ${sub2FolderId === sub2Folder._id ? "activee" : ""
                                                            }`}
                                                          onClick={
                                                            sub2Folder.type === "folder" ?
                                                              () => onCLickOpenProjectSub2Folder(sub2FolderId === sub2Folder._id ? "" : sub2Folder._id)
                                                              :
                                                              (e) => handleTabChange(
                                                                e,
                                                                sub2Folder.name + (sub1Folder.name === "logs" ? ".glxlog" : sub1Folder.name === "outputs" ? ".glxhtm" : sub1Folder.name === "programs" ? ".glx" : "") + (folder.structureType === "standard" && ` (${folder?.name})`),
                                                                sub2Folder._id,
                                                                sub1Folder.name === "outputs" || sub1Folder.name === "logs" ? "fa-solid fa-file" : "fa-solid fa-file-code",
                                                                sub1Folder.name === "outputs" ? "codeEditorOutput" : sub1Folder.name === "logs" ? "codeEditorLog" : "codeEditor",
                                                              )
                                                          }
                                                          aria-expanded={sub2FolderId === sub2Folder._id}
                                                        >
                                                          <div className={`hstack justify-content-between ${(sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folderId.status === "lock" || item.status === "lock") && "fileReviewRed"}`}>
                                                            <div>
                                                              <i
                                                                className={`me-2 ps-5 fa-solid ${sub2FolderId === sub2Folder._id ? (sub2Folder.structureType === "programEnvironment" ? (sub2Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub2Folder.type === "folder" ? "fa-folder-open" : "fa-file-code")) : (sub2Folder.structureType === "programEnvironment" ? (sub2Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub2Folder.type === "folder" ? "fa-folder" : "fa-file-code"))}`}
                                                              ></i>
                                                              <Form.Label className="m-0"><span className={`${sub2Folder.type === "folder" && "text-capitalize"}`}>{sub2Folder.name}</span>{sub1Folder.name === "logs" ? ".glxlog" : sub1Folder.name === "outputs" ? ".glxhtm" : sub1Folder.name === "programs" ? ".glx" : ""}</Form.Label>
                                                            </div>
                                                            {sub2Folder.type === "folder" &&
                                                              <i
                                                                className={`fa-solid ${sub2FolderId === sub2Folder._id ? "fa-angle-down" : "fa-angle-right"}`}
                                                              ></i>
                                                            }
                                                          </div>
                                                        </div>
                                                        {(sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock") ?
                                                          <RightClick
                                                            ContextId={`projectsub2folder_${value}`}
                                                            Menu1={!(sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock")}
                                                            className1={sub2Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                            MenuName1={sub2Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                            icons1={<i className={`fa-solid ${sub2Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                            handleClick1={(e) => lockUnlockConsultantFolders(e, sub2Folder?._id, item.projectId, (sub2Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub2Folder")}
                                                          />
                                                          :
                                                          <RightClick
                                                            ContextId={`projectsub2folder_${value}`}
                                                            Menu1={(!sub2Folder.programEnvironment ? ((sub2Folder.name === "programs" || sub2Folder.name === "outputs" || sub2Folder.name === "logs" || sub1Folder.name === "programs" || sub1Folder.name === "outputs" || sub1Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true) : ((sub2Folder.name === "programs" || sub2Folder.name === "outputs" || sub2Folder.name === "logs" || sub1Folder.name === "programs" || sub1Folder.name === "outputs" || sub1Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : sub2Folder.programEnvironment === "customFile"))}
                                                            MenuName1={"Add New Folder"}
                                                            icons1={<i className="fa-solid fa-circle-plus edit"></i>}
                                                            handleClick1={(e) => addNewDevelopmentFolder(e, "sub2Folder", item.standardStructured, folder._id, sub2Folder._id)}
                                                            Menu2={!sub2Folder.programEnvironment ? ((sub2Folder.name === "programs" || sub2Folder.name === "outputs" || sub2Folder.name === "logs" || sub1Folder.name === "programs" || sub1Folder.name === "outputs" || sub1Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true) : (sub2Folder.programEnvironment === "customFile" ? !(sub2Folder.programEnvironment === "customFile") : !(sub2Folder.programEnvironment === "programFile"))}
                                                            MenuName2={"Add Program Environment"}
                                                            icons2={<i className="fa-solid fa-circle-plus edit"></i>}
                                                            handleClick2={(e) => addNewProjectProgramEnvironment(e, sub2Folder._id, item.projectId, "sub2Folder", item.standardStructured)}
                                                            Menu3={sub2Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                            MenuName3={"Add New File"}
                                                            icons3={<i className="fa-solid fa-circle-plus edit"></i>}
                                                            handleClick3={(e) => addNewDevelopmentFile(e, "sub2Folder", sub2Folder._id, item.standardStructured)}
                                                            Menu4={sub2Folder.type === "folder"}
                                                            className4={sub2Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                            MenuName4={sub2Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                            icons4={<i className={`fa-solid ${sub2Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                            handleClick4={(e) => lockUnlockConsultantFolders(e, sub2Folder?._id, item.projectId, (sub2Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub2Folder")}
                                                            Menu5={(sub2Folder.name === "programs" || sub2Folder.name === "outputs" || sub2Folder.name === "logs" || sub1Folder.name === "programs" || sub1Folder.name === "outputs" || sub1Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                            MenuName5={"Update Folder Name"}
                                                            icons5={<i className="fa-solid fa-edit edit"></i>}
                                                            handleClick5={(e) => updateDevelopmentFolderData(e, "sub2Folder", sub2Folder)}
                                                            Menu6={(sub2Folder.name === "programs" || sub2Folder.name === "outputs" || sub2Folder.name === "logs" || sub1Folder.name === "programs" || sub1Folder.name === "outputs" || sub1Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                            className6="delete"
                                                            MenuName6={"Delete Folder"}
                                                            icons6={<i className="fa-solid fa-trash-can delete"></i>}
                                                            handleClick6={(e) => deleteDevelopmentFolder(e, sub2Folder._id, "sub2Folder")}
                                                            Menu7={sub1Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                            MenuName7={"Update File"}
                                                            icons7={<i className="fa-solid fa-edit edit"></i>}
                                                            handleClick7={(e) => updateDevelopmentFolderData(e, "sub2Folder", sub2Folder)}
                                                            Menu8={sub1Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                            className8="delete"
                                                            MenuName8={"Delete"}
                                                            icons8={<i className="fa-solid fa-trash-can delete"></i>}
                                                            handleClick8={(e) => deleteDevelopmentFolder(e, sub2Folder._id, "sub2Folder")}
                                                            Menu9={sub1Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                            MenuName9={"Properties"}
                                                            icons9={<i className="fa-solid fa-circle-info edit"></i>}
                                                            handleClick9={(e) => deleteDevelopmentFolder(e, sub2Folder._id, "sub2Folder")}
                                                          />
                                                        }
                                                      </div>
                                                      <Collapse in={sub2FolderId === sub2Folder._id}>
                                                        <div>
                                                          {projectSub3FolderList?.map((sub3Folder, value) => (
                                                            <ContextMenuTrigger id={`projectsub3folder_${value}`}>
                                                              <div key={value}>
                                                                <div className="link__tab">
                                                                  <div
                                                                    className={`text-start h-auto tab_url w-100 ${sub3FolderId === sub3Folder._id ? "activee" : ""
                                                                      }`}
                                                                    onClick={
                                                                      sub3Folder.type === "folder" ?
                                                                        () => onCLickOpenProjectSub3Folder(sub3FolderId === sub3Folder._id ? "" : sub3Folder._id)
                                                                        :
                                                                        (e) => handleTabChange(
                                                                          e,
                                                                          sub3Folder.name + (sub2Folder.name === "logs" ? ".glxlog" : sub2Folder.name === "outputs" ? ".glxhtm" : sub2Folder.name === "programs" ? ".glx" : "") + (folder.structureType === "standard" && ` (${folder?.name})`),
                                                                          sub3Folder._id,
                                                                          sub2Folder.name === "outputs" || sub2Folder.name === "logs" ? "fa-solid fa-file" : "fa-solid fa-file-code",
                                                                          sub2Folder.name === "outputs" ? "codeEditorOutput" : sub2Folder.name === "logs" ? "codeEditorLog" : "codeEditor",
                                                                        )
                                                                    }
                                                                    aria-expanded={sub3FolderId === sub3Folder._id}
                                                                  >
                                                                    <div className={`hstack justify-content-between ${(sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folderId.status === "lock" || item.status === "lock") && "fileReviewRed"}`}>
                                                                      <div>
                                                                        <i
                                                                          style={{ paddingLeft: 40 }}
                                                                          className={`me-2 fa-solid ${sub3FolderId === sub3Folder._id ? (sub3Folder.structureType === "programEnvironment" ? (sub3Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub3Folder.type === "folder" ? "fa-folder" : "fa-file-code")) : (sub3Folder.structureType === "programEnvironment" ? (sub3Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub3Folder.type === "folder" ? "fa-folder" : "fa-file-code"))}`}
                                                                        ></i>
                                                                        <Form.Label className="m-0"><span className={`${sub3Folder.type === "folder" && "text-capitalize"}`}>{sub3Folder.name}</span>{sub2Folder.name === "logs" ? ".glxlog" : sub2Folder.name === "outputs" ? ".glxhtm" : sub2Folder.name === "programs" ? ".glx" : ""}</Form.Label>
                                                                      </div>
                                                                      {sub3Folder.type === "folder" &&
                                                                        <i
                                                                          className={`fa-solid ${sub3FolderId === sub3Folder._id ? "fa-angle-down" : "fa-angle-right"}`}
                                                                        ></i>
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                  {(sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock") ?
                                                                    <RightClick
                                                                      ContextId={`projectsub3folder_${value}`}
                                                                      Menu1={!(sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock")}
                                                                      className1={sub3Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                                      MenuName1={sub3Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                                      icons1={<i className={`fa-solid ${sub3Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                                      handleClick1={(e) => lockUnlockConsultantFolders(e, sub3Folder?._id, item.projectId, (sub3Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub3Folder")}
                                                                    />
                                                                    :
                                                                    <RightClick
                                                                      ContextId={`projectsub3folder_${value}`}
                                                                      Menu1={(!sub3Folder.programEnvironment ? ((sub3Folder.name === "programs" || sub3Folder.name === "outputs" || sub3Folder.name === "logs" || sub2Folder.name === "programs" || sub2Folder.name === "outputs" || sub2Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true) : ((sub3Folder.name === "programs" || sub3Folder.name === "outputs" || sub3Folder.name === "logs" || sub2Folder.name === "programs" || sub2Folder.name === "outputs" || sub2Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : sub3Folder.programEnvironment === "customFile"))}
                                                                      MenuName1={"Add New Folder"}
                                                                      icons1={<i className="fa-solid fa-circle-plus edit"></i>}
                                                                      handleClick1={(e) => addNewDevelopmentFolder(e, "sub3Folder", item.standardStructured, folder._id, sub3Folder._id)}
                                                                      Menu2={!sub3Folder.programEnvironment ? ((sub3Folder.name === "programs" || sub3Folder.name === "outputs" || sub3Folder.name === "logs" || sub2Folder.name === "programs" || sub2Folder.name === "outputs" || sub2Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true) : (sub3Folder.programEnvironment === "customFile" ? !(sub3Folder.programEnvironment === "customFile") : !(sub3Folder.programEnvironment === "programFile"))}
                                                                      MenuName2={"Add Program Environment"}
                                                                      icons2={<i className="fa-solid fa-circle-plus edit"></i>}
                                                                      handleClick2={(e) => addNewProjectProgramEnvironment(e, sub3Folder._id, item.projectId, "sub3Folder", item.standardStructured)}
                                                                      Menu3={sub3Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                      MenuName3={"Add New File"}
                                                                      icons3={<i className="fa-solid fa-circle-plus edit"></i>}
                                                                      handleClick3={(e) => addNewDevelopmentFile(e, "sub3Folder", sub3Folder._id, item.standardStructured)}
                                                                      Menu4={sub3Folder.type === "folder"}
                                                                      className4={sub3Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                                      MenuName4={sub3Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                                      icons4={<i className={`fa-solid ${sub3Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                                      handleClick4={(e) => lockUnlockConsultantFolders(e, sub3Folder?._id, item.projectId, (sub3Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub3Folder")}
                                                                      Menu5={(sub3Folder.name === "programs" || sub3Folder.name === "outputs" || sub3Folder.name === "logs" || sub2Folder.name === "programs" || sub2Folder.name === "outputs" || sub2Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                                      MenuName5={"Update Folder Name"}
                                                                      icons5={<i className="fa-solid fa-edit edit"></i>}
                                                                      handleClick5={(e) => updateDevelopmentFolderData(e, "sub3Folder", sub3Folder)}
                                                                      Menu6={(sub3Folder.name === "programs" || sub3Folder.name === "outputs" || sub3Folder.name === "logs" || sub2Folder.name === "programs" || sub2Folder.name === "outputs" || sub2Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                                      className6="delete"
                                                                      MenuName6={"Delete Folder"}
                                                                      icons6={<i className="fa-solid fa-trash-can delete"></i>}
                                                                      handleClick6={(e) => deleteDevelopmentFolder(e, sub3Folder._id, "sub3Folder")}
                                                                      Menu7={sub2Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                      MenuName7={"Update File"}
                                                                      icons7={<i className="fa-solid fa-edit edit"></i>}
                                                                      handleClick7={(e) => updateDevelopmentFolderData(e, "sub3Folder", sub3Folder)}
                                                                      Menu8={sub2Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                      className8="delete"
                                                                      MenuName8={"Delete"}
                                                                      icons8={<i className="fa-solid fa-trash-can delete"></i>}
                                                                      handleClick8={(e) => deleteDevelopmentFolder(e, sub3Folder._id, "sub3Folder")}
                                                                      Menu9={sub2Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                      MenuName9={"Properties"}
                                                                      icons9={<i className="fa-solid fa-circle-info edit"></i>}
                                                                      handleClick9={(e) => deleteDevelopmentFolder(e, sub3Folder._id, "sub3Folder")}
                                                                    />
                                                                  }
                                                                </div>
                                                                <Collapse in={sub3FolderId === sub3Folder._id}>
                                                                  <div>
                                                                    {projectSub4FolderList?.map((sub4Folder, value) => (
                                                                      <ContextMenuTrigger id={`projectsub4folder_${value}`}>
                                                                        <div key={value}>
                                                                          <div className="link__tab">
                                                                            <div
                                                                              className={`text-start h-auto tab_url w-100 ${sub4FolderId === sub4Folder._id ? "activee" : ""
                                                                                }`}
                                                                              onClick={
                                                                                sub4Folder.type === "folder" ?
                                                                                  () => onCLickOpenProjectSub4Folder(sub4FolderId === sub4Folder._id ? "" : sub4Folder._id)
                                                                                  :
                                                                                  (e) => handleTabChange(
                                                                                    e,
                                                                                    sub4Folder.name + (sub3Folder.name === "logs" ? ".glxlog" : sub3Folder.name === "outputs" ? ".glxhtm" : sub3Folder.name === "programs" ? ".glx" : "") + (folder.structureType === "standard" && ` (${folder?.name})`),
                                                                                    sub4Folder._id,
                                                                                    sub3Folder.name === "outputs" || sub3Folder.name === "logs" ? "fa-solid fa-file" : "fa-solid fa-file-code",
                                                                                    sub3Folder.name === "outputs" ? "codeEditorOutput" : sub3Folder.name === "logs" ? "codeEditorLog" : "codeEditor",
                                                                                  )
                                                                              }
                                                                              aria-expanded={sub4FolderId === sub4Folder._id}
                                                                            >
                                                                              <div className={`hstack justify-content-between ${(sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folderId.status === "lock" || item.status === "lock") && "fileReviewRed"}`}>
                                                                                <div>
                                                                                  <i
                                                                                    style={{ paddingLeft: 50 }}
                                                                                    className={`me-2 fa-solid ${sub4FolderId === sub4Folder._id ? (sub4Folder.structureType === "programEnvironment" ? (sub4Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub4Folder.type === "folder" ? "fa-folder" : "fa-file-code")) : (sub4Folder.structureType === "programEnvironment" ? (sub4Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub4Folder.type === "folder" ? "fa-folder" : "fa-file-code"))}`}
                                                                                  ></i>
                                                                                  <Form.Label className="m-0"><span className={`${sub4Folder.type === "folder" && "text-capitalize"}`}>{sub4Folder.name}</span>{sub3Folder.name === "logs" ? ".glxlog" : sub3Folder.name === "outputs" ? ".glxhtm" : sub3Folder.name === "programs" ? ".glx" : ""}</Form.Label>
                                                                                </div>
                                                                                {sub4Folder.type === "folder" &&
                                                                                  <i
                                                                                    className={`fa-solid ${sub4FolderId === sub4Folder._id ? "fa-angle-down" : "fa-angle-right"}`}
                                                                                  ></i>
                                                                                }
                                                                              </div>
                                                                            </div>
                                                                            {(sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock") ?
                                                                              <RightClick
                                                                                ContextId={`projectsub4folder_${value}`}
                                                                                Menu1={!(sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock")}
                                                                                className1={sub4Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                                                MenuName1={sub4Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                                                icons1={<i className={`fa-solid ${sub4Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                                                handleClick1={(e) => lockUnlockConsultantFolders(e, sub4Folder?._id, item.projectId, (sub4Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub4Folder")}
                                                                              />
                                                                              :
                                                                              <RightClick
                                                                                ContextId={`projectsub4folder_${value}`}
                                                                                Menu1={(!sub4Folder.programEnvironment ? ((sub4Folder.name === "programs" || sub4Folder.name === "outputs" || sub4Folder.name === "logs" || sub3Folder.name === "programs" || sub3Folder.name === "outputs" || sub3Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true) : ((sub4Folder.name === "programs" || sub4Folder.name === "outputs" || sub4Folder.name === "logs" || sub3Folder.name === "programs" || sub3Folder.name === "outputs" || sub3Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : sub4Folder.programEnvironment === "customFile"))}
                                                                                MenuName1={"Add New Folder"}
                                                                                icons1={<i className="fa-solid fa-circle-plus edit"></i>}
                                                                                handleClick1={(e) => addNewDevelopmentFolder(e, "sub4Folder", item.standardStructured, folder._id, sub4Folder._id)}
                                                                                Menu2={!sub4Folder.programEnvironment ? ((sub4Folder.name === "programs" || sub4Folder.name === "outputs" || sub4Folder.name === "logs" || sub3Folder.name === "programs" || sub3Folder.name === "outputs" || sub3Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true) : (sub4Folder.programEnvironment === "customFile" ? !(sub4Folder.programEnvironment === "customFile") : !(sub4Folder.programEnvironment === "programFile"))}
                                                                                MenuName2={"Add Program Environment"}
                                                                                icons2={<i className="fa-solid fa-circle-plus edit"></i>}
                                                                                handleClick2={(e) => addNewProjectProgramEnvironment(e, sub4Folder._id, item.projectId, "sub4Folder", item.standardStructured)}
                                                                                Menu3={sub4Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                MenuName3={"Add New File"}
                                                                                icons3={<i className="fa-solid fa-circle-plus edit"></i>}
                                                                                handleClick3={(e) => addNewDevelopmentFile(e, "sub4Folder", sub4Folder._id, item.standardStructured)}
                                                                                Menu4={sub4Folder.type === "folder"}
                                                                                className4={sub4Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                                                MenuName4={sub4Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                                                icons4={<i className={`fa-solid ${sub4Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                                                handleClick4={(e) => lockUnlockConsultantFolders(e, sub4Folder?._id, item.projectId, (sub4Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub4Folder")}
                                                                                Menu5={(sub4Folder.name === "programs" || sub4Folder.name === "outputs" || sub4Folder.name === "logs" || sub3Folder.name === "programs" || sub3Folder.name === "outputs" || sub3Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                                                MenuName5={"Update Folder Name"}
                                                                                icons5={<i className="fa-solid fa-edit edit"></i>}
                                                                                handleClick5={(e) => updateDevelopmentFolderData(e, "sub4Folder", sub4Folder)}
                                                                                Menu6={(sub4Folder.name === "programs" || sub4Folder.name === "outputs" || sub4Folder.name === "logs" || sub3Folder.name === "programs" || sub3Folder.name === "outputs" || sub3Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                                                className6="delete"
                                                                                MenuName6={"Delete Folder"}
                                                                                icons6={<i className="fa-solid fa-trash-can delete"></i>}
                                                                                handleClick6={(e) => deleteDevelopmentFolder(e, sub4Folder._id, "sub4Folder")}
                                                                                Menu7={sub3Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                MenuName7={"Update File"}
                                                                                icons7={<i className="fa-solid fa-edit edit"></i>}
                                                                                handleClick7={(e) => updateDevelopmentFolderData(e, "sub4Folder", sub4Folder)}
                                                                                Menu8={sub3Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                className8="delete"
                                                                                MenuName8={"Delete"}
                                                                                icons8={<i className="fa-solid fa-trash-can delete"></i>}
                                                                                handleClick8={(e) => deleteDevelopmentFolder(e, sub4Folder._id, "sub4Folder")}
                                                                                Menu9={sub3Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                MenuName9={"Properties"}
                                                                                icons9={<i className="fa-solid fa-circle-info edit"></i>}
                                                                                handleClick9={(e) => deleteDevelopmentFolder(e, sub4Folder._id, "sub4Folder")}
                                                                              />
                                                                            }
                                                                          </div>
                                                                          <Collapse in={sub4FolderId === sub4Folder._id}>
                                                                            <div>
                                                                              {projectSub5FolderList?.map((sub5Folder, value) => (
                                                                                <ContextMenuTrigger id={`projectsub5folder_${value}`}>
                                                                                  <div key={value}>
                                                                                    <div className="link__tab">
                                                                                      <div
                                                                                        className={`text-start h-auto tab_url w-100 ${sub5FolderId === sub5Folder._id ? "activee" : ""
                                                                                          }`}
                                                                                        onClick={
                                                                                          sub5Folder.type === "folder" ?
                                                                                            () => onCLickOpenProjectSub5Folder(sub5FolderId === sub5Folder._id ? "" : sub5Folder._id)
                                                                                            :
                                                                                            (e) => handleTabChange(
                                                                                              e,
                                                                                              sub5Folder.name + (sub4Folder.name === "logs" ? ".glxlog" : sub4Folder.name === "outputs" ? ".glxhtm" : sub4Folder.name === "programs" ? ".glx" : "") + (folder.structureType === "standard" && ` (${folder?.name})`),
                                                                                              sub5Folder._id,
                                                                                              sub4Folder.name === "outputs" || sub4Folder.name === "logs" ? "fa-solid fa-file" : "fa-solid fa-file-code",
                                                                                              sub4Folder.name === "outputs" ? "codeEditorOutput" : sub4Folder.name === "logs" ? "codeEditorLog" : "codeEditor",
                                                                                            )
                                                                                        }
                                                                                        aria-expanded={sub5FolderId === sub5Folder._id}
                                                                                      >
                                                                                        <div className={`hstack justify-content-between ${(sub5Folder.status === "lock" || sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folderId.status === "lock" || item.status === "lock") && "fileReviewRed"}`}>
                                                                                          <div>
                                                                                            <i
                                                                                              style={{ paddingLeft: 60 }}
                                                                                              className={`me-2 fa-solid ${sub5FolderId === sub5Folder._id ? (sub5Folder.structureType === "programEnvironment" ? (sub5Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub5Folder.type === "folder" ? "fa-folder" : "fa-file-code")) : (sub5Folder.structureType === "programEnvironment" ? (sub5Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub5Folder.type === "folder" ? "fa-folder" : "fa-file-code"))}`}
                                                                                            ></i>
                                                                                            <Form.Label className="m-0"><span className={`${sub5Folder.type === "folder" && "text-capitalize"}`}>{sub5Folder.name}</span>{sub4Folder.name === "logs" ? ".glxlog" : sub4Folder.name === "outputs" ? ".glxhtm" : sub4Folder.name === "programs" ? ".glx" : ""}</Form.Label>
                                                                                          </div>
                                                                                          {sub5Folder.type === "folder" &&
                                                                                            <i
                                                                                              className={`fa-solid ${sub5FolderId === sub5Folder._id ? "fa-angle-down" : "fa-angle-right"}`}
                                                                                            ></i>
                                                                                          }
                                                                                        </div>
                                                                                      </div>
                                                                                      {(sub5Folder.status === "lock" || sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock") ?
                                                                                        <RightClick
                                                                                          ContextId={`projectsub5folder_${value}`}
                                                                                          Menu1={!(sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock")}
                                                                                          className1={sub5Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                                                          MenuName1={sub5Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                                                          icons1={<i className={`fa-solid ${sub5Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                                                          handleClick1={(e) => lockUnlockConsultantFolders(e, sub5Folder?._id, item.projectId, (sub5Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub5Folder")}
                                                                                        />
                                                                                        :
                                                                                        <RightClick
                                                                                          ContextId={`projectsub5folder_${value}`}
                                                                                          Menu1={!sub5Folder.programEnvironment ? ((sub5Folder.name === "programs" || sub5Folder.name === "outputs" || sub5Folder.name === "logs" || sub4Folder.name === "programs" || sub4Folder.name === "outputs" || sub4Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true) : (sub5Folder.programEnvironment === "customFile" ? !(sub5Folder.programEnvironment === "customFile") : !(sub5Folder.programEnvironment === "programFile"))}
                                                                                          MenuName1={"Add Program Environment"}
                                                                                          icons1={<i className="fa-solid fa-circle-plus edit"></i>}
                                                                                          handleClick1={(e) => addNewProjectProgramEnvironment(e, sub5Folder._id, item.projectId, "sub5Folder", item.standardStructured)}
                                                                                          Menu2={sub5Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                          MenuName2={"Add New File"}
                                                                                          icons2={<i className="fa-solid fa-circle-plus edit"></i>}
                                                                                          handleClick2={(e) => addNewDevelopmentFile(e, "subFolder", subFolder._id, item.standardStructured)}
                                                                                          Menu3={(sub5Folder.name === "programs" || sub5Folder.name === "outputs" || sub5Folder.name === "logs" || sub4Folder.name === "programs" || sub4Folder.name === "outputs" || sub4Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                                                          MenuName3={"Update Folder Name"}
                                                                                          icons3={<i className="fa-solid fa-edit edit"></i>}
                                                                                          handleClick3={(e) => updateDevelopmentFolderData(e, "sub5Folder", sub5Folder)}
                                                                                          Menu4={sub5Folder.type === "folder"}
                                                                                          className4={sub5Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                                                          MenuName4={sub5Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                                                          icons4={<i className={`fa-solid ${sub5Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                                                          handleClick4={(e) => lockUnlockConsultantFolders(e, sub5Folder?._id, item.projectId, (sub5Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub5Folder")}
                                                                                          Menu5={(sub5Folder.name === "programs" || sub5Folder.name === "outputs" || sub5Folder.name === "logs" || sub4Folder.name === "programs" || sub4Folder.name === "outputs" || sub4Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                                                          className5="delete"
                                                                                          MenuName5={"Delete Folder"}
                                                                                          icons5={<i className="fa-solid fa-trash-can delete"></i>}
                                                                                          handleClick5={(e) => deleteDevelopmentFolder(e, sub5Folder._id, "sub5Folder")}
                                                                                          Menu6={sub4Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                          MenuName6={"Update File"}
                                                                                          icons6={<i className="fa-solid fa-edit edit"></i>}
                                                                                          handleClick6={(e) => updateDevelopmentFolderData(e, "sub5Folder", sub5Folder)}
                                                                                          Menu7={sub4Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                          className6="delete"
                                                                                          MenuName7={"Delete"}
                                                                                          icons7={<i className="fa-solid fa-trash-can delete"></i>}
                                                                                          handleClick7={(e) => deleteDevelopmentFolder(e, sub5Folder._id, "sub5Folder")}
                                                                                          Menu8={sub4Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                          MenuName8={"Properties"}
                                                                                          icons8={<i className="fa-solid fa-circle-info edit"></i>}
                                                                                          handleClick8={(e) => deleteDevelopmentFolder(e, sub5Folder._id, "sub5Folder")}
                                                                                        />
                                                                                      }
                                                                                    </div>
                                                                                    <Collapse in={sub5FolderId === sub5Folder._id}>
                                                                                      <div>
                                                                                        {projectSub6FolderList?.map((sub6Folder, value) => (
                                                                                          <ContextMenuTrigger id={`projectsub6folder_${value}`}>
                                                                                            <div key={value}>
                                                                                              <div className="link__tab">
                                                                                                <div
                                                                                                  className={`text-start h-auto tab_url w-100 ${sub6FolderId === sub6Folder._id ? "activee" : ""
                                                                                                    }`}
                                                                                                  onClick={
                                                                                                    sub6Folder.type === "folder" ?
                                                                                                      () => onCLickOpenProjectSub6Folder(sub6FolderId === sub6Folder._id ? "" : sub6Folder._id)
                                                                                                      :
                                                                                                      (e) => handleTabChange(
                                                                                                        e,
                                                                                                        sub6Folder.name + (sub5Folder.name === "logs" ? ".glxlog" : sub5Folder.name === "outputs" ? ".glxhtm" : sub5Folder.name === "programs" ? ".glx" : "") + (folder.structureType === "standard" && ` (${folder?.name})`),
                                                                                                        sub6Folder._id,
                                                                                                        sub5Folder.name === "outputs" || sub5Folder.name === "logs" ? "fa-solid fa-file" : "fa-solid fa-file-code",
                                                                                                        sub5Folder.name === "outputs" ? "codeEditorOutput" : sub5Folder.name === "logs" ? "codeEditorLog" : "codeEditor",
                                                                                                      )
                                                                                                  }
                                                                                                  aria-expanded={sub6FolderId === sub6Folder._id}
                                                                                                >
                                                                                                  <div className={`hstack justify-content-between ${(sub6Folder.status === "lock" || sub5Folder.status === "lock" || sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folderId.status === "lock" || item.status === "lock") && "fileReviewRed"}`}>
                                                                                                    <div>
                                                                                                      <i
                                                                                                        style={{ paddingLeft: 70 }}
                                                                                                        className={`me-2 fa-solid ${sub6FolderId === sub6Folder._id ? (sub6Folder.structureType === "programEnvironment" ? (sub6Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub6Folder.type === "folder" ? "fa-folder" : "fa-file-code")) : (sub6Folder.structureType === "programEnvironment" ? (sub6Folder.type === "file" ? "fa-file-code" : "fa-laptop-code") : (sub6Folder.type === "folder" ? "fa-folder" : "fa-file-code"))}`}
                                                                                                      ></i>
                                                                                                      <Form.Label className="m-0"><span className={`${sub6Folder.type === "folder" && "text-capitalize"}`}>{sub6Folder.name}</span>{sub5Folder.name === "logs" ? ".glxlog" : sub5Folder.name === "outputs" ? ".glxhtm" : sub5Folder.name === "programs" ? ".glx" : ""}</Form.Label>
                                                                                                    </div>
                                                                                                    {sub6Folder.type === "folder" &&
                                                                                                      <i
                                                                                                        className={`fa-solid ${sub6FolderId === sub6Folder._id ? "fa-angle-down" : "fa-angle-right"}`}
                                                                                                      ></i>
                                                                                                    }
                                                                                                  </div>
                                                                                                </div>
                                                                                                {(sub6Folder.status === "lock" || sub5Folder.status === "lock" || sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock") ?
                                                                                                  <RightClick
                                                                                                    ContextId={`projectsub6folder_${value}`}
                                                                                                    Menu1={!(sub5Folder.status === "lock" || sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock")}
                                                                                                    className1={sub6Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                                                                    MenuName1={sub6Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                                                                    icons1={<i className={`fa-solid ${sub6Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                                                                    handleClick1={(e) => lockUnlockConsultantFolders(e, sub6Folder?._id, item.projectId, (sub6Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub6Folder")}
                                                                                                  />
                                                                                                  :
                                                                                                  <RightClick
                                                                                                    ContextId={`projectsub6folder_${value}`}
                                                                                                    Menu1={sub6Folder.name === "programs"}
                                                                                                    MenuName1={"Add New File"}
                                                                                                    icons1={<i className="fa-solid fa-circle-plus edit"></i>}
                                                                                                    handleClick1={(e) => addNewDevelopmentFile(e, "sub6Folder", sub6Folder._id, item.standardStructured)}
                                                                                                    Menu2={(sub6Folder.name === "programs" || sub6Folder.name === "outputs" || sub6Folder.name === "logs" || sub5Folder.name === "programs" || sub5Folder.name === "outputs" || sub5Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                                                                    MenuName2={"Update Folder Name"}
                                                                                                    icons2={<i className="fa-solid fa-edit edit"></i>}
                                                                                                    handleClick2={(e) => updateDevelopmentFolderData(e, "sub6Folder", sub6Folder)}
                                                                                                    Menu3={(sub6Folder.name === "programs" || sub6Folder.name === "outputs" || sub6Folder.name === "logs" || sub5Folder.name === "programs" || sub5Folder.name === "outputs" || sub5Folder.name === "logs" || folder.name === "validation" || folder.name === "production") ? false : true}
                                                                                                    className3="delete"
                                                                                                    MenuName3={"Delete Folder"}
                                                                                                    icons3={<i className="fa-solid fa-trash-can delete"></i>}
                                                                                                    handleClick3={(e) => deleteDevelopmentFolder(e, sub6Folder._id, "sub6Folder")}
                                                                                                    Menu4={sub6Folder.type === "folder"}
                                                                                                    className4={sub6Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                                                                    MenuName4={sub6Folder.status === "unlock" ? "Lock Folder" : "Unlock Folder"}
                                                                                                    icons4={<i className={`fa-solid ${sub6Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                                                                    handleClick4={(e) => lockUnlockConsultantFolders(e, sub6Folder?._id, item.projectId, (sub6Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub6Folder")}
                                                                                                    Menu5={sub5Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                                    MenuName5={"Update File"}
                                                                                                    icons5={<i className="fa-solid fa-edit edit"></i>}
                                                                                                    handleClick5={(e) => updateDevelopmentFolderData(e, "sub6Folder", sub6Folder)}
                                                                                                    Menu6={sub5Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                                    className6="delete"
                                                                                                    MenuName6={"Delete"}
                                                                                                    icons6={<i className="fa-solid fa-trash-can delete"></i>}
                                                                                                    handleClick6={(e) => deleteDevelopmentFolder(e, sub6Folder._id, "sub6Folder")}
                                                                                                    Menu7={sub5Folder.name === "programs" ? ((folder.name === "production" || folder.name === "validation") ? false : true) : false}
                                                                                                    MenuName7={"Properties"}
                                                                                                    icons7={<i className="fa-solid fa-circle-info edit"></i>}
                                                                                                    handleClick7={(e) => deleteDevelopmentFolder(e, sub6Folder._id, "sub6Folder")}
                                                                                                  />
                                                                                                }
                                                                                              </div>
                                                                                              <Collapse in={sub6FolderId === sub6Folder._id}>
                                                                                                <div>
                                                                                                  {projectSub7FolderList?.map((sub7Folder, value) => (
                                                                                                    <ContextMenuTrigger id={`projectsub7folder_${value}`}>
                                                                                                      <div key={value}>
                                                                                                        <div className="link__tab">
                                                                                                          <div
                                                                                                            className={`text-start h-auto tab_url w-100 ${sub7FolderId === sub7Folder._id ? "activee" : ""
                                                                                                              }`}
                                                                                                            onClick={
                                                                                                              sub7Folder.type === "folder" ?
                                                                                                                () => onCLickOpenProjectSub7Folder(sub7FolderId === sub7Folder._id ? "" : sub7Folder._id)
                                                                                                                :
                                                                                                                (e) => handleTabChange(
                                                                                                                  e,
                                                                                                                  sub7Folder.name + (sub6Folder.name === "logs" ? ".glxlog" : sub6Folder.name === "outputs" ? ".glxhtm" : sub6Folder.name === "programs" ? ".glx" : "") + (folder.structureType === "standard" && ` (${folder?.name})`),
                                                                                                                  sub7Folder._id,
                                                                                                                  sub6Folder.name === "outputs" || sub6Folder.name === "logs" ? "fa-solid fa-file" : "fa-solid fa-file-code",
                                                                                                                  sub6Folder.name === "outputs" ? "codeEditorOutput" : sub6Folder.name === "logs" ? "codeEditorLog" : "codeEditor",
                                                                                                                )
                                                                                                            }
                                                                                                            aria-expanded={sub7FolderId === sub7Folder._id}
                                                                                                          >
                                                                                                            <div className={`hstack justify-content-between ${(sub7FolderId.status === "lock" || sub6Folder.status === "lock" || sub5Folder.status === "lock" || sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folderId.status === "lock" || item.status === "lock") && "fileReviewRed"}`}>
                                                                                                              <div>
                                                                                                                <i
                                                                                                                  style={{ paddingLeft: 80 }}
                                                                                                                  className={`me-2 fa-solid ${sub7Folder.type === "file" ? "fa-file-code" : "fa-laptop-code"}`}
                                                                                                                ></i>
                                                                                                                <Form.Label className="m-0"><span className={`${sub7Folder.type === "folder" && "text-capitalize"}`}>{sub7Folder.name}</span>{sub6Folder.name === "logs" ? ".glxlog" : sub6Folder.name === "outputs" ? ".glxhtm" : sub6Folder.name === "programs" ? ".glx" : ""}</Form.Label>
                                                                                                              </div>
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          {(sub7FolderId.status === "lock" || sub6Folder.status === "lock" || sub5Folder.status === "lock" || sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock") ?
                                                                                                            <RightClick
                                                                                                              ContextId={`projectsub7folder_${value}`}
                                                                                                              Menu1={!(sub6Folder.status === "lock" || sub5Folder.status === "lock" || sub4Folder.status === "lock" || sub3Folder.status === "lock" || sub2Folder.status === "lock" || sub1Folder.status === "lock" || subFolder.status === "lock" || folder.status === "lock" || item.status === "lock")}
                                                                                                              className1={sub7Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                                                                              MenuName1={sub7Folder.status === "unlock" ? "Check Out" : "Check In"}
                                                                                                              icons1={<i className={`fa-solid ${sub7Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                                                                              handleClick1={(e) => lockUnlockConsultantFolders(e, sub7Folder?._id, item.projectId, (sub7Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub7Folder")}
                                                                                                            />
                                                                                                            :
                                                                                                            <RightClick
                                                                                                              ContextId={`projectsub7folder_${value}`}
                                                                                                              Menu1
                                                                                                              className1={sub7Folder.status === "unlock" ? "delete" : "fileReviewGreen"}
                                                                                                              MenuName1={sub7Folder.status === "unlock" ? "Check Out" : "Check In"}
                                                                                                              icons1={<i className={`fa-solid ${sub7Folder.status === "unlock" ? "fa-lock delete" : "fa-unlock fileReviewGreen"}`}></i>}
                                                                                                              handleClick1={(e) => lockUnlockConsultantFolders(e, sub7Folder?._id, item.projectId, (sub7Folder.status === "lock" ? "unlock" : "lock"), "folder", "sub7Folder")}
                                                                                                              Menu2
                                                                                                              MenuName2={"Update File"}
                                                                                                              icons2={<i className="fa-solid fa-edit edit"></i>}
                                                                                                              handleClick2={(e) => updateDevelopmentFolderData(e, "sub7Folder", sub7Folder)}
                                                                                                              Menu3
                                                                                                              className3="delete"
                                                                                                              MenuName3={"Delete"}
                                                                                                              icons3={<i className="fa-solid fa-trash-can delete"></i>}
                                                                                                              handleClick3={(e) => deleteDevelopmentFolder(e, sub7Folder._id, "sub7Folder")}
                                                                                                              Menu4
                                                                                                              MenuName4={"Properties"}
                                                                                                              icons4={<i className="fa-solid fa-circle-info edit"></i>}
                                                                                                              handleClick4={(e) => deleteDevelopmentFolder(e, sub7Folder._id, "sub7Folder")}
                                                                                                            />
                                                                                                          }
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    </ContextMenuTrigger>
                                                                                                  ))}
                                                                                                </div>
                                                                                              </Collapse>
                                                                                            </div>
                                                                                          </ContextMenuTrigger>
                                                                                        ))}
                                                                                      </div>
                                                                                    </Collapse>
                                                                                  </div>
                                                                                </ContextMenuTrigger>
                                                                              ))}
                                                                            </div>
                                                                          </Collapse>
                                                                        </div>
                                                                      </ContextMenuTrigger>
                                                                    ))}
                                                                  </div>
                                                                </Collapse>
                                                              </div>
                                                            </ContextMenuTrigger>
                                                          ))}
                                                        </div>
                                                      </Collapse>
                                                    </div>
                                                  </ContextMenuTrigger>
                                                ))}
                                              </div>
                                            </Collapse>
                                          </div>
                                        </ContextMenuTrigger>
                                      ))}
                                    </div>
                                  </Collapse>
                                </div>
                              </ContextMenuTrigger>
                            ))}
                          </div>
                        </Collapse>
                      </div>
                    </ContextMenuTrigger>
                  ))}
                </div>
              </Collapse>
            </>
          ))}
          <div
            className={`link__tab ${activeTab === "consultantPackageHistory" ? "activee" : ""}`}
          >
            <button
              onClick={(e) => handleTabChange(e, "History", "consultantPackageHistory", "fa-solid fa-clock-rotate-left", "consultantPackageHistory")}
              className="btn text-start h-auto tab_url w-100"
            >
              <i className="fa-solid fa-clock-rotate-left me-2  small"></i>
              History
            </button>
          </div>
          <div
            className={`link__tab ${activeTab === "consultantTrashProject" ? "activee" : ""}`}
          >
            <button
              onClick={(e) => handleTabChange(e, "trash", "consultantTrashProject", "fa-solid fa-trash-can", "consultantTrashProject")}
              className="btn text-start h-auto tab_url w-100 fileReviewRed"
            >
              <i className="fa-solid fa-trash-can me-2  small"></i>
              Trash
            </button>
          </div>
        </>
      )}
      {showSidebarMenu === "library" && (
        <>
          {/*----------------------------------------- Data Library Tab ----------------------------------------- */}
          <div className="link__tabs1">
            {/* <div className="text-start h-auto tab_url w-100"> */}
            <div className="hstack justify-content-between">
              <div>
                <i className="fa-solid fa-folder-tree me-2"></i>
                <Form.Label className="m-0" style={{ fontWeight: 700 }}>
                  Library
                </Form.Label>
              </div>
              <i
                className="fa-solid fa-circle-plus"
                title="Add New Library"
                style={{ cursor: "pointer" }}
                onClick={setOpenAddLibraryFolder}
              ></i>
            </div>
          </div>
          {/* </div> */}

          {dummyLibraryList?.map((item, index) => (
            <div className="link__parent_tab">
              <ContextMenuTrigger id={`libraryid_${index}`}>
                <div
                  className={`text-start h-auto tab_urls w-100 ${item._id === sessionLibraryId ? "activee" : ""
                    }`}
                  onClick={() => {
                    item._id === sessionLibraryId
                      ? setSessionLibraryId("")
                      : setSessionLibraryId(item._id);
                    filterLibrarySubmenu(item._id);
                  }}
                  aria-expanded={item._id}
                >
                  <div className="hstack justify-content-between">
                    <div>
                      <i
                        className={`me-2 ps-3 fa-solid ${item._id !== sessionLibraryId ? "fa-folder" : "fa-folder-open"}`}
                      ></i>
                      <Form.Label className="m-0">{item?.name}</Form.Label>
                    </div>
                    <i
                      className={`fa-solid ${item._id === sessionLibraryId ? "fa-angle-down" : "fa-angle-right"}`}
                    ></i>
                  </div>
                </div>
                <RightClick
                  ContextId={`libraryid_${index}`}
                  Menu1
                  MenuName1={"Update"}
                  icons1={<i className="fa-solid fa-pen-to-square edit"></i>}
                  handleClick1={() => updateSessionLibraryDetails(item)}
                  Menu2
                  MenuName2={"Upload File"}
                  icons2={<i className="fa-regular fa-square-plus edit"></i>}
                  handleClick2={() => uploadLibraryFileData(item._id)}
                  Menu3
                  MenuName3={"Delete"}
                  icons3={<i className="fa-solid fa-trash-alt delete"></i>}
                  handleClick3={() => deleteSessionLibrary(item._id)}
                  Menu4
                  MenuName4={"Properties"}
                  icons4={<i className="fa-solid fa-circle-info edit"></i>}
                  handleClick4={() => viewSessionLibraryProperties(item)}
                />
                <Collapse in={item._id === sessionLibraryId}>
                  <div>
                    {libraryFileList?.map((data, index) => (
                      <ContextMenuTrigger id={`fileId_${index}`}>
                        <div key={index} className="link__tab">
                          <div
                            onClick={(e) =>
                              handleTabChange(e, data.fileName, data.fileId, "fa-solid fa-table", "libraryTable")
                            }
                            style={{ marginTop: 1 }}
                            className={`text-start h-auto tab_url w-100 ${activePage === data.fileName && "activee"} `}
                          >
                            <i className="fa-solid fa-table ps-4 me-2 small"></i>
                            {data.fileName}.glxdat
                          </div>
                        </div>
                        <RightClick
                          ContextId={`fileId_${index}`}
                          Menu1
                          MenuName1={"View"}
                          icons1={<i className="fa-solid fa-eye view"></i>}
                          handleClick1={(e) =>
                            handleTabChange(e, data.fileName, data.fileId, "fa-solid fa-table", "libraryTable")
                          }
                          Menu2
                          MenuName2={"Update File"}
                          icons2={
                            <i className="fa-solid fa-pen-to-square edit"></i>
                          }
                          handleClick2={() => updateLibraryFileData(data)}
                          Menu3
                          MenuName3={"Delete"}
                          icons3={
                            <i className="fa-solid fa-trash-alt delete"></i>
                          }
                          handleClick3={() =>
                            deleteSessionLibraryFileData(data)
                          }
                          Menu4
                          MenuName4={"Table Properties"}
                          icons4={
                            <i className="fa-solid fa-circle-info edit"></i>
                          }
                          handleClick4={() =>
                            viewLibraryFileTableProperties(data)
                          }
                          Menu5
                          MenuName5={"General Properties"}
                          icons5={
                            <i className="fa-solid fa-circle-info edit"></i>
                          }
                          handleClick5={() =>
                            viewLibraryFileGeneralProperties(data, item.name)
                          }
                        />
                      </ContextMenuTrigger>
                    ))}
                  </div>
                </Collapse>
              </ContextMenuTrigger>
            </div>
          ))}
          <div className="link__tab active_Button">
            <ContextMenuTrigger id="id2.1">
              <div
                className={`text-start h-auto tab_url w-100 ${openLogs ? "activee" : ""
                  }`}
                onClick={() => setOpenLogs(!openLogs)}
                aria-expanded={openLogs}
              >
                <div className="hstack justify-content-between activePackage">
                  <div>
                    <i
                      className={`me-2 ps-3 fa-solid ${!openLogs ? "fa-folder" : "fa-folder-open"}`}
                    ></i>
                    <Form.Label className="m-0">Help</Form.Label>
                  </div>
                  <i
                    className={`fa-solid ${openLogs ? "fa-angle-down" : "fa-angle-right"}`}
                  ></i>
                </div>
              </div>
            </ContextMenuTrigger>
          </div>
          <Collapse in={openLogs}>
            <div>
              {libraryList?.map((item, index) => (
                <ContextMenuTrigger id={`libraryHelpId_${index}`}>
                  <div key={index} className="link__tab">
                    <div
                      className={`text-start h-auto tab_url w-100 ${activePage === item.name && "activee"} `}
                      onClick={(e) =>
                        handleTabChange(e, item.name, item.libraryId, "fa-solid fa-table", "libraryTable")
                      }
                    >
                      <i className="fa-solid fa-table ps-4 me-2 small"></i>
                      {item.name}.glxdat
                    </div>
                    <RightClick
                      ContextId={`libraryHelpId_${index}`}
                      Menu1
                      MenuName1={"View"}
                      icons1={<i className="fa-solid fa-eye edit"></i>}
                      className1={"edit"}
                      handleClick1={(e) =>
                        handleTabChange(e, item.name, item.libraryId)
                      }
                      Menu2
                      MenuName2={"Export"}
                      icons2={<i className="fa-solid fa-download edit"></i>}
                      className2={"edit"}
                      handleClick2={setExportData}
                      Menu3
                      MenuName3={"Table Property"}
                      icons3={<i className="fa-solid fa-circle-info edit"></i>}
                      className3={"edit"}
                      handleClick3={setTableProperty}
                      Menu4
                      MenuName4={"General Property"}
                      icons4={<i className="fa-solid fa-circle-info edit"></i>}
                      className4={"edit"}
                      handleClick4={() =>
                        viewLibraryFileProperty(item)
                      }
                    />
                  </div>
                </ContextMenuTrigger>
              ))}
            </div>
          </Collapse>
          <div className="link__tab">
            <ContextMenuTrigger id="id3.0">
              <div
                className={`text-start h-auto tab_url w-100 ${openOutputs ? "activee" : ""
                  }`}
                onClick={() => setOpenOutputs(!openOutputs)}
                aria-expanded={openOutputs}
              >
                <div className="hstack justify-content-between">
                  <div>
                    <i
                      className={`me-2 ps-3 fa-solid ${!openOutputs ? "fa-folder" : "fa-folder-open"}`}
                    ></i>
                    <Form.Label className="m-0">Temp</Form.Label>
                  </div>
                  <i
                    className={`fa-solid ${openOutputs ? "fa-angle-down" : "fa-angle-right"}`}
                  ></i>
                </div>
              </div>
            </ContextMenuTrigger>
          </div>
          <Collapse in={openOutputs}>
            <div>
              {libraryTempList?.map((item, index) => (
                <ContextMenuTrigger id={`tepmId_${index}`}>
                  <div className="link__tab active_Button">
                    <div
                      className="text-start h-auto tab_url w-100"
                      onClick={(e) => handleTabChange(e, item.name, item.libraryId, "fa-solid fa-table", "libraryTable")}
                    >
                      <i className="fa-solid fa-table ps-4 me-2 small"></i>
                      {item.name}.glxdat
                    </div>
                    <RightClick
                      ContextId={`tepmId_${index}`}
                      Menu1
                      MenuName1={"View"}
                      icons1={<i className="fa-solid fa-eye edit"></i>}
                      className1={"edit"}
                      handleClick1={(e) =>
                        handleTabChange(e, item.name, item.libraryId, "fa-solid fa-table", "libraryTable")
                      }
                      Menu2
                      MenuName2={"Update"}
                      icons2={<i className="fa-solid fa-edit edit"></i>}
                      className2={"edit"}
                      handleClick2={(e) =>
                        updatePackageFileData(item)
                      }
                      Menu3
                      MenuName3={"Duplicate"}
                      icons3={<i className="fa-solid fa-copy edit"></i>}
                      className3="edit"
                      handleClick3={() => duplicateLibraryFolderAndData(item?.libraryId)}
                      Menu4
                      MenuName4={"Export"}
                      icons4={<i className="fa-solid fa-download edit"></i>}
                      className4={"edit"}
                      handleClick4={setExportData}
                      Menu5
                      MenuName5={"Delete"}
                      icons5={
                        <i className="fa-solid fa-trash-alt delete"></i>
                      }
                      className5="delete"
                      handleClick5={() =>
                        deleteLibraryFolderAndData(item?.libraryId)
                      }
                      Menu6
                      MenuName6={"Table Property"}
                      icons6={<i className="fa-solid fa-circle-info edit"></i>}
                      className6={"edit"}
                      handleClick6={setTableProperty}
                      Menu7
                      MenuName7={"General Property"}
                      icons7={<i className="fa-solid fa-circle-info edit"></i>}
                      className7={"edit"}
                      handleClick7={() =>
                        viewLibraryFileProperty(item)
                      }
                    />
                  </div>
                </ContextMenuTrigger>
              ))}
            </div>
          </Collapse>
        </>
      )}
      {(roleType === "consultant" ||
        roleType === "developer" ||
        roleType === "subadmin" ||
        roleType === "validator") &&
        showSidebarMenu === "account" && (
          <>
            <div className="link__tabs1">
              <div>
                <div className="hstack justify-content-between">
                  <div>
                    <i className="me-2 ps-2 fa-solid fa-folder-open"></i>
                    <Form.Label className="m-0" style={{ fontWeight: 700 }}>
                      Account
                    </Form.Label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Collapse in={!openDataLibrary}>
                <div>
                  <div
                    className={`link__tab ${activeTab === "profile" ? "activee" : ""}`}
                  >
                    <button
                      onClick={(e) => handleTabChange(e, "Profile", "profile", "fa-solid fa-user", "accountProfile")}
                      className="btn text-start h-auto tab_url w-100"
                    >
                      <i className="fa-solid fa-user ps-4 me-2 small"></i>
                      Profile
                    </button>
                  </div>
                  {roleType === "consultant" && (
                    <div
                      onClick={(e) => handleTabChange(e, "Billing", "billing", "fa-solid fa-file-invoice-dollar", "billingHistory")}
                      className={`link__tab ${activeTab === "billing" ? "activee" : ""}`}
                    >
                      <button className="btn text-start h-auto tab_url w-100">
                        <i className="fa-solid fa-file-invoice-dollar ps-4 me-2 small"></i>
                        Billing
                      </button>
                    </div>
                  )}
                  {roleType === "developer" && (
                    <div
                      onClick={(e) => handleTabChange(e, "KYC", "kyc", "fa-solid fa-person-circle-check", "kycData")}
                      className={`link__tab ${activeTab === "kyc" ? "activee" : ""}`}
                    >
                      <button className="btn text-start h-auto tab_url w-100">
                        <i className="fa-solid fa-person-circle-check ps-4 me-2 small"></i>
                        KYC
                      </button>
                    </div>
                  )}
                  {roleType === "developer" && (
                    <div
                      onClick={(e) => handleTabChange(e, "Revenue", "revenue", "fa-solid fa-file-invoice-dollar", "revenueDetails")}
                      className={`link__tab ${activeTab === "revenue" ? "activee" : ""}`}
                    >
                      <button className="btn text-start h-auto tab_url w-100">
                        <i className="fa-solid fa-file-invoice-dollar ps-4 me-2 small"></i>
                        Revenue
                      </button>
                    </div>
                  )}
                  {roleType === "developer" && (
                    <div
                      onClick={(e) => handleTabChange(e, "Payout", "payout", "fa-solid fa-money-bill-1-wave", "payoutHistory")}
                      className={`link__tab ${activeTab === "payout" ? "activee" : ""}`}
                    >
                      <button className="btn text-start h-auto tab_url w-100">
                        <i className="fa-solid fa-money-bill-1-wave ps-4 me-2 small"></i>
                        Payout
                      </button>
                    </div>
                  )}
                  <div
                    onClick={(e) => handleTabChange(e, "Log Data", "logdata", "fa-solid fa-clock-rotate-left", "logHistory")}
                    className={`link__tab ${activeTab === "logdata" ? "activee" : ""}`}
                  >
                    <button className="btn text-start h-auto tab_url w-100">
                      <i className="fa-solid fa-clock-rotate-left ps-4 me-2 small"></i>
                      Log Data
                    </button>
                  </div>
                  <div
                    onClick={(e) =>
                      handleTabChange(e, "Failed Status", "failedStatus", "fa-solid fa-ban", "failedLogData")
                    }
                    className={`link__tab ${activeTab === "failedStatus" ? "activee" : ""}`}
                  >
                    <button className="btn text-start h-auto tab_url w-100">
                      <i className="fa-solid fa-ban ps-4 me-2 small"></i>
                      Failed Status
                    </button>
                  </div>
                </div>
              </Collapse>
            </div>
          </>
        )}
      {roleType === "consultant" && showSidebarMenu === "packages" && (
        <div className="link__tabs1">
          <div>
            <div className="hstack justify-content-between">
              <div>
                <i className="me-2 ps-2 fa-solid fa-cubes"></i>
                <Form.Label className="m-0" style={{ fontWeight: 700 }}>
                  Packages
                </Form.Label>
              </div>
            </div>
          </div>
        </div>
      )}
      {roleType === "consultant" && showSidebarMenu === "packages" && (
        <div
          className={`link__tab ${activeTab === "packagefunctionality" ? "activee" : ""}`}
        >
          <button
            onClick={(e) =>
              handleTabChange(e, "Packages", "packagefunctionality", "fa-solid fa-cubes", "packageList")
            }
            className="btn text-start h-auto tab_url w-100"
          >
            <i className="fa-solid fa-cubes me-2 ps-3 small"></i>
            All Packages
          </button>
        </div>
      )}
      {roleType === "consultant" && showSidebarMenu === "packages" && (
        <div
          onClick={(e) => handleTabChange(e, "Favorite", "favorite", "fa-solid fa-heart", "favoritesPackage")}
          className={`link__tab ${activeTab === "favorite" ? "activee" : ""}`}
        >
          <button className="btn text-start h-auto tab_url w-100">
            <i className="fa-solid fa-heart ps-3 me-2 small"></i>
            Favorites
          </button>
        </div>
      )}
      {roleType === "consultant" && showSidebarMenu === "packages" && (
        <div
          onClick={(e) => handleTabChange(e, "My Orders", "myOrders", "fa-solid fa-folder-closed", "myOrder")}
          className={`link__tab ${activeTab === "myOrders" ? "activee" : ""}`}
        >
          <button className="btn text-start h-auto tab_url w-100">
            <i className="fa-solid fa-folder-closed ps-3 me-2 small"></i>
            My Orders
          </button>
        </div>
      )}
      {roleType === "consultant" && showSidebarMenu === "packages" && (
        <div
          onClick={(e) => handleTabChange(e, "My Packges", "myPackages", "fa-solid fa-box", "myPurchansedPackage")}
          className={`link__tab ${activeTab === "myPackages" ? "activee" : ""}`}
        >
          <button className="btn text-start h-auto tab_url w-100">
            <i className="fa-solid fa-box ps-3 me-2 small"></i>
            My Packages
          </button>
        </div>
      )}
      {(roleType === "developer" || roleType === "validator" || roleType === "subadmin") && showSidebarMenu === "developPackages" && (
        <>
          <div className="link__tabs1">
            <div className="hstack justify-content-between">
              <div>
                <i className="fa-solid fa-cubes me-2 ps-1"></i>
                <Form.Label className="m-0">Packages</Form.Label>
              </div>
              {roleType === "developer" &&
                <i
                  className="fa-solid fa-circle-plus"
                  title="Add New Package"
                  style={{ cursor: "pointer" }}
                  onClick={setOpenAddNewPackages}
                ></i>
              }
            </div>
          </div>
          {packageFolders?.map((item, index) => (
            <>
              <div className="link__tab">
                <ContextMenuTrigger id={`id_${index}`}>
                  <div
                    className={`text-start h-auto tab_url w-100 ${item._id === packageId ? "activee" : ""
                      }`}
                    onClick={() => {
                      item._id === packageId
                        ? setPackageId("")
                        : openPackagesSubFolderFunction(item);
                    }}
                    aria-expanded={packageId}
                  >
                    <div className="hstack justify-content-between">
                      <div className="hstack">
                        {(item.updateMode || item.validationMode || item.reviewMode) && <i title="For Update" style={{ fontSize: 6 }} className="fa-solid fa-circle"></i>}<i style={(item.updateMode || item.validationMode || item.reviewMode) ? { paddingLeft: 4 } : { paddingLeft: 10 }} className={`fa-solid fa-laptop-code me-2 ${item.status === "disable" ? "fileReviewGray" : item.status === "pending" ? "fileReviewOrange" : (item.status === "inactive" || item.status === "rejected") ? "fileReviewRed" : item.status === "published" ? "fileReviewGreen" : ""}`}></i>
                        <Form.Label className={`m-0 ${item.status === "disable" ? "fileReviewGray" : item.status === "pending" ? "fileReviewOrange" : (item.status === "inactive" || item.status === "rejected") ? "fileReviewRed" : item.status === "published" ? "fileReviewGreen" : ""}`}>{item.name}</Form.Label>
                      </div>
                      {item.status !== "disable" &&
                        <i
                          className={`fa-solid ${item._id === packageId ? "fa-angle-down" : "fa-angle-right"}`}
                        ></i>
                      }
                    </div>
                    <RightClick
                      ContextId={`id_${index}`}
                      Menu1={item.publishStatus === "ready" && (item.status === "draft" || item.status === "rejected")}
                      MenuName1={`${item.status === "draft" ? "Publish" : "Re-Publish"} Package`}
                      icons1={<i className="fa-solid fa-paper-plane edit"></i>}
                      className1="edit"
                      handleClick1={() => publishDeveloperPackage(item)}
                      Menu2={item.status === "draft" || item.status === "rejected"}
                      MenuName2={"Update Folder"}
                      icons2={
                        <i className="fa-solid fa-pen-to-square edit"></i>
                      }
                      className2="edit"
                      handleClick2={() => UpdateFolderdata(item)}
                      Menu3={item.status === "draft" || item.status === "rejected"}
                      MenuName3={"Delete Package"}
                      icons3={<i className="fa-solid fa-trash-alt delete"></i>}
                      className3="delete"
                      handleClick3={() =>
                        deletePackageFolderData(item.packageId)
                      }
                      Menu4={roleType === "subadmin" && item.validationStatus === "ready" && item.syntaxStatus === "ongoing"}
                      MenuName4={"Validate"}
                      icons4={
                        <i className="fa-solid fa-circle-check edit"></i>
                      }
                      className4="edit"
                      handleClick4={(e) =>
                        packageFunctionValidationData(item._id)
                      }
                      Menu5={roleType === "validator" && item.validationStatus === "ready" && item.validatorStatus === "ongoing"}
                      MenuName5={"Validate"}
                      icons5={
                        <i className="fa-solid fa-circle-check edit"></i>
                      }
                      className5="edit"
                      handleClick5={(e) =>
                        packageFunctionFinalValidation(item._id)
                      }
                      Menu6={roleType === "validator" && item.reviewMode && item.validatorStatus === "completed"}
                      MenuName6={"Validate"}
                      icons6={
                        <i className="fa-solid fa-circle-check edit"></i>
                      }
                      className6="edit"
                      handleClick6={(e) =>
                        packageUpdateFunctionFinalValidation(item._id)
                      }
                      Menu7={roleType === "subadmin" && item.validationMode && item.syntaxStatus === "completed"}
                      MenuName7={"Validate"}
                      icons7={
                        <i className="fa-solid fa-circle-check edit"></i>
                      }
                      className7="edit"
                      handleClick7={(e) =>
                        packageFunctionValidationDataForUpdatePackage(item._id)
                      }
                      Menu8={item.updateMode}
                      MenuName8={"Update Request"}
                      icons8={<i className="fa-solid fa-edit edit"></i>}
                      className8="edit"
                      handleClick8={() => viewAssigntaskForUpdate(item.packageUpdateRequest, item.fileStatus)}
                      Menu9
                      MenuName9={"Properties"}
                      icons9={<i className="fa-solid fa-circle-info edit"></i>}
                      className9="edit"
                      handleClick9={() => viewPackageProperties(item)}
                    />
                  </div>
                </ContextMenuTrigger>
              </div>
              <Collapse in={item._id === packageId && item.status !== "disable"}>
                <div>
                  {packageSubFolder?.map((subFolder, key) => (
                    <ContextMenuTrigger key={key} id={`subid_${key}`}>
                      <div className="link__tab">
                        <div
                          className={`text-start h-auto tab_url w-100 ${(subFolder?.name === "files"
                            ? subFolder?.fileId
                            : subFolder?._id) === packageSubFolderId
                            ? "activee"
                            : ""
                            }`}
                          onClick={() => {
                            (subFolder?.name === "files"
                              ? subFolder?.fileId
                              : subFolder?._id) === packageSubFolderId
                              ? setPackageSubFolderId("")
                              : openPackageFileById(subFolder);
                          }}
                          aria-expanded={
                            (subFolder?.name === "files"
                              ? subFolder?.fileId
                              : subFolder?._id) === packageSubFolderId
                          }
                        >
                          <div className="hstack justify-content-between">
                            <div>
                              <i
                                className={`fa-solid  me-2 ps-4 ${(subFolder?.name === "files" ? subFolder?.fileId : subFolder?._id) === packageSubFolderId ? "fa-folder-open" : "fa-folder"}`}
                              ></i>
                              <Form.Label className="m-0 text-capitalize">
                                {subFolder?.name}
                              </Form.Label>
                            </div>
                            <i
                              className={`fa-solid ${(subFolder?.name === "files" ? subFolder?.fileId : subFolder?._id) === packageSubFolderId ? "fa-angle-down" : "fa-angle-right"}`}
                            ></i>
                          </div>
                          <RightClick
                            ContextId={`subid_${key}`}
                            Menu1={subFolder?.name === "data" && (item?.status === "draft" || item?.status === "rejected" || item.updateMode)}
                            MenuName1={"Upload Data"}
                            icons1={
                              <i className="fa-solid fa-arrow-up-from-bracket edit"></i>
                            }
                            className1="edit"
                            handleClick1={setUploadDataPackage}
                            Menu2={subFolder?.name === "docs" && (item?.status === "draft" || item?.status === "rejected" || item.updateMode)}
                            MenuName2={"Upload Docs"}
                            icons2={
                              <i className="fa-solid fa-arrow-up-from-bracket edit"></i>
                            }
                            className2="edit"
                            handleClick2={() =>
                              uploadPackageDocument(subFolder)
                            }
                          />
                        </div>
                      </div>
                      <Collapse
                        in={
                          (subFolder?.name === "files"
                            ? subFolder?.fileId
                            : subFolder?._id) === packageSubFolderId
                        }
                      >
                        <div>
                          {packageSubfile?.map((subFiles, subKey) => (
                            <ContextMenuTrigger
                              key={subKey}
                              id={`subdFileId_${subKey}`}
                            >
                              <div className="link__tab">
                                <div className="activePackage">
                                  <div
                                    onClick={(e) => {
                                      subFiles?._id === packageSubFileId
                                        ? setPackageSubFileId("")
                                        : setPackageSubFileId(subFiles?._id);
                                      handleTabChange(
                                        e,
                                        subFolder?.name === "docs"
                                          ? null
                                          : subFolder?.name === "data"
                                            ? `${subFiles.name}${(subFolder?.name === "files" && ".glx") || (subFolder?.name === "data" && ".glxdat")}`
                                            : `${subFiles.name}${(subFolder?.name === "files" && ".glx") || (subFolder?.name === "data" && ".glxdat")} (${item.name})`,
                                        subFolder?.name === "docs"
                                          ? null
                                          : subFolder?.name === "data"
                                            ? subFiles?.libraryId
                                            : subFiles?._id,
                                        subFolder?.name === "files" ? "fa-solid fa-file-code" : "fa-solid fa-table",
                                        subFolder.name === "files" ? "codeEditor" : "libraryTable"
                                      );
                                    }}
                                    className={
                                      `text-start h-auto tab_url w-100 
                                      ${subFiles.name === "validation" && ((item?.validatorStatus === "completed" ? !item.reviewMode : (item?.validatorStatus === "completed")) ? "fileReviewGreen"
                                        : item?.validatorStatus === "rejected" ? "fileReviewRed" : "")} 
                                      ${subFiles.name === "function" && ((item?.syntaxStatus === "completed" ? !item.validationMode : (item?.syntaxStatus === "completed")) ? "fileReviewGreen"
                                        : item?.syntaxStatus === "rejected" ? "fileReviewRed" : "")} 
                                      ${(subFiles.name === "function") && ((subFiles.status === "lock" ? !item.validationMode : subFiles.status === "lock") && "fileReviewGreen")} 
                                      ${(subFiles.name === "validation") && ((subFiles.status === "lock" ? !item.reviewMode : subFiles.status === "lock") && "fileReviewGreen")} 
                                      ${subFiles.name === "index" && ((subFiles.status === "lock" ? !item.updateMode : subFiles.status === "lock") && "fileReviewGreen" || (item.status === "completed" ? !item.updateMode : item.status === "completed") ? "fileReviewGreen"
                                        : item.status === "rejected" ? "fileReviewRed" : item.status === "pending" ? "fileReviewOrange" : "")}
                                    ${activePage ===
                                      `${subFiles.name}${(subFolder?.name === "files" && ".glx") || (subFolder?.name === "data" && ".glxdat")} (${item.name})` &&
                                      "activee"
                                      }`}
                                  >
                                    <i
                                      className={`fa-solid ${(subFolder?.name === "files" &&
                                        "fa-file-code") ||
                                        (subFolder?.name === "data" &&
                                          "fa-table") ||
                                        (subFolder?.name === "docs" &&
                                          `${subFiles.extension
                                            ? subFiles.extension === "txt"
                                              ? "fa-file-lines"
                                              : subFiles.extension ===
                                                "zip" ||
                                                subFiles.extension === "rar"
                                                ? "fa-file-zipper"
                                                : subFiles.extension === "pdf"
                                                  ? "fa-file-pdf"
                                                  : subFiles.extension ===
                                                    "csv"
                                                    ? "fa-file-csv"
                                                    : subFiles.extension ===
                                                      "gas"
                                                      ? "fa-file-code"
                                                      : subFiles.extension ===
                                                        "doc" ||
                                                        subFiles.extension ===
                                                        "docx"
                                                        ? "fa-file-word"
                                                        : subFiles.extension ===
                                                          "xls" ||
                                                          subFiles.extension ===
                                                          "xlsx"
                                                          ? "fa-file-excel"
                                                          : subFiles.extension ===
                                                            "ppt" ||
                                                            subFiles.extension ===
                                                            "pptx"
                                                            ? "fa-file-powerpoint"
                                                            : "fa-file"
                                            : "fa-file"
                                          }`)
                                        } ps-5 me-2 small`}
                                    ></i>
                                    {subFiles.name}
                                    {(subFolder?.name === "files" && ".glx") ||
                                      (subFolder?.name === "data" &&
                                        ".glxdat") ||
                                      (subFolder?.name === "docs" &&
                                        `.${subFiles?.extension}`)}
                                  </div>
                                </div>
                                <RightClick
                                  ContextId={`subdFileId_${subKey}`}
                                  Menu1={(subFiles.status === "lock" && (item?.status === "draft" || item?.status === "rejected")) || (subFiles.status === "lock" && item.updateMode)}
                                  MenuName1={"CheckOut"}
                                  icons1={
                                    <i className="fa-solid fa-arrow-right-from-bracket edit"></i>
                                  }
                                  className1="edit"
                                  handleClick1={(e) =>
                                    checkOutPackageFiles(e, subFiles?._id)
                                  }
                                  Menu2={(subFiles.status === "draft" && (item?.status === "draft" || item?.status === "rejected")) || (subFiles.status === "draft" && item.updateMode)}
                                  MenuName2={"CheckIn"}
                                  icons2={
                                    <i className="fa-solid fa-arrow-right-to-bracket edit"></i>
                                  }
                                  className2="edit"
                                  handleClick2={(e) =>
                                    checkInCodeFile(subFiles)
                                  }
                                  Menu3={subFolder?.name === "files" && subFiles?.name === "index"}
                                  MenuName3={"Compare Version"}
                                  icons3={
                                    <i className="fa-solid fa-code-compare edit"></i>
                                  }
                                  className3="edit"
                                  handleClick3={(e) =>
                                    currentCodeCompare(subFiles)
                                  }
                                  Menu4={subFolder?.name === "data" && (item?.status === "draft" || item?.status === "rejected")}
                                  MenuName4={"Update"}
                                  icons4={
                                    <i className="fa-solid fa-pen-to-square edit"></i>
                                  }
                                  className4="edit"
                                  handleClick4={() =>
                                    updatePackageFileData(subFiles)
                                  }
                                  Menu5={subFolder?.name === "data" && (item?.status === "draft" || item?.status === "rejected")}
                                  MenuName5={"Duplicate"}
                                  icons5={
                                    <i className="fa-solid fa-copy edit"></i>
                                  }
                                  className5="edit"
                                  handleClick5={() =>
                                    duplicateLibraryFolderAndData(
                                      subFiles?.libraryId
                                    )
                                  }
                                  Menu6={subFolder?.name === "data" && (item?.status === "draft" || item?.status === "rejected")}
                                  MenuName6={"Delete"}
                                  icons6={
                                    <i className="fa-solid fa-trash-alt delete"></i>
                                  }
                                  className6="delete"
                                  handleClick6={() =>
                                    deleteLibraryFolderAndData(
                                      subFiles?.libraryId
                                    )
                                  }
                                  Menu0={subFolder?.name === "docs"}
                                  MenuName0={"Download"}
                                  icons0={
                                    <i className="fa-solid fa-download edit"></i>
                                  }
                                  className0="edit"
                                  MinuLink0={subFiles?.packageDocs}
                                  Menu7={subFolder?.name === "docs" && (item?.status === "draft" || item?.status === "rejected")}
                                  MenuName7={"Update"}
                                  icons7={
                                    <i className="fa-solid fa-pen-to-square edit"></i>
                                  }
                                  className7="edit"
                                  handleClick7={() =>
                                    updatePackageDocumentData(subFiles)
                                  }
                                  Menu8={subFolder?.name === "docs" && (item?.status === "draft" || item?.status === "rejected")}
                                  MenuName8={"Delete"}
                                  icons8={
                                    <i className="fa-solid fa-circle-info delete"></i>
                                  }
                                  className8="delete"
                                  handleClick8={() =>
                                    deletePackageDocumentData(subFiles?._id)
                                  }
                                  Menu9={subFolder?.name === "data"}
                                  MenuName9={"Table Properties"}
                                  icons9={
                                    <i className="fa-solid fa-circle-info edit"></i>
                                  }
                                  className9="edit"
                                  handleClick9={setTableProperty}
                                  Menu10
                                  MenuName10={"General Properties"}
                                  icons10={
                                    <i className="fa-solid fa-circle-info edit"></i>
                                  }
                                  className10="edit"
                                  handleClick10={() =>
                                    viewPackagesSubFileProperty(subFiles)
                                  }
                                />
                              </div>
                            </ContextMenuTrigger>
                          ))}
                        </div>
                      </Collapse>
                    </ContextMenuTrigger>
                  ))}
                </div>
              </Collapse>
            </>
          ))}
        </>
      )}
      {/*----------------------------------------- Modal ----------------------------------------- */}

      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          >
            <h5>Add New Folder</h5>
          </div>
        }
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Form.Control
          required
          type="text"
          placeholder="First name"
          defaultValue="Mark"
        />
      </Modal>

      <AddNewLibrary
        bounds={bounds}
        onStart={onStart}
        submit={"Submit"}
        disabled={disabled}
        draggleRef={draggleRef}
        title={"Add New Library"}
        show={openAddLibraryFolder}
        setShow={setOpenAddLibraryFolder}
        dummyLibraryList={dummyLibraryList}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        setDummyLibraryList={setDummyLibraryList}
      />

      <AddNewPackagesModal
        bounds={bounds}
        submit={"Submit"}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        show={openAddNewPackages}
        categoryList={categoryList}
        setShow={setOpenAddNewPackages}
        title={"Add New Packages Folder"}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        getPackageFolderLists={getPackageFolderLists}
      />

      <PackageFileCheckIn
        bounds={bounds}
        submit={"CheckIn"}
        onStart={onStart}
        disabled={disabled}
        title={"CheckIn File"}
        draggleRef={draggleRef}
        show={packagesFileCheckIn}
        setShow={setPackagesFileCheckIn}
        packageFileType={packageFileType}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        openPackageFileById={openPackageFileById}
        getPackageFileCodeById={getPackageFileCodeById}
        packagesPropertiesData={packagesPropertiesData}
      />

      <CurrentCodeCompareModal
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        title={"Code Compare"}
        draggleRef={draggleRef}
        show={currentCodeCompareData}
        setShow={setCurrentCodeCompareData}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        packagesPropertiesData={packagesPropertiesData}
      />

      <PackagesPropertiesModalData
        bounds={bounds}
        onStart={onStart}
        tableRef={tableRef}
        disabled={disabled}
        draggleRef={draggleRef}
        show={packagesProperties}
        title={"Packages Properties"}
        setShow={setPackagesProperties}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        packagesPropertiesData={packagesPropertiesData}
      />

      <PackagesUpdateRequestModalData
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        show={packagesUpdateRequestTask}
        submit={"Publish Updated Package"}
        onMouseOut={() => setDisabled(true)}
        updatedFileStatus={updatedFileStatus}
        setShow={setPackagesUpdateRequestTask}
        onMouseOver={() => setDisabled(false)}
        packageUpdateRequest={packageUpdateRequest}
        getPackageFolderLists={getPackageFolderLists}
        title={"Update Package Change Logs & Publish"}
        getPackageFileCodeById={getPackageFileCodeById}
      />

      <PackagesSubFilePropertyModalData
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        show={subFileProperties}
        setShow={setSubFileProperties}
        title={"Packages File Properties"}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        packageFileType={packageFileType?.name}
        subFileTableProperties={subFileTableProperties}
      />

      <LibraryFileHelpTempPropertiesModalData
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        show={libraryFileGeneralProperty}
        title={"Library File Properties"}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        setShow={setLibraryFileGeneralProperty}
        subFileTableProperties={subFileTableProperties}
      />

      <UpdateLibrary
        bounds={bounds}
        submit={"Update"}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        title={"Update Library"}
        show={openUpdateLibraryFolder}
        setShow={setOpenUpdateLibraryFolder}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        sessionLibraryData={sessionLibraryData}
        setDummyLibraryList={setDummyLibraryList}
      />

      <UpdateLibraryFile
        onStart={onStart}
        bounds={bounds}
        submit={"Update"}
        disabled={disabled}
        title={"Update File"}
        draggleRef={draggleRef}
        show={openLibraryUpdateFileData}
        libraryFileData={libraryFileData}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        setShow={setOpenLibraryUpdateFileData}
        setLibraryFileList={setLibraryFileList}
      />

      <ViewLibrary
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        title={"Folder Properties"}
        show={openViewLibraryFolder}
        setShow={setOpenViewLibraryFolder}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        sessionLibraryData={sessionLibraryData}
      />

      <ViewLibraryGeneralFileProperty
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        title={"General Properties"}
        libraryFileData={libraryFileData}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        show={openLibraryGeneralPropertyFileData}
        setShow={setOpenLibraryGeneralPropertyFileData}
      />

      <ViewLibraryTableFileProperty
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        title={"Table Properties"}
        hideShowData={hideShowData}
        fileId={libraryFileData.fileId}
        onMouseOut={() => setDisabled(true)}
        columnList={libraryFileData?.column}
        onMouseOver={() => setDisabled(false)}
        show={openLibraryTablePropertyFileData}
        setShow={setOpenLibraryTablePropertyFileData}
      />

      <UpdatePackageMetadataModal
        bounds={bounds}
        submit={"Update"}
        onStart={onStart}
        disabled={disabled}
        show={openEditFolder}
        draggleRef={draggleRef}
        categoryList={categoryList}
        setShow={setOpenEditFolder}
        title={"Update Package Folder"}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        openEditFolderData={openEditFolderData}
        getPackageFolderLists={getPackageFolderLists}
      />

      <UpdatePackageLibraryDataModal
        bounds={bounds}
        submit={"Update"}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        title={"Update Package Data"}
        show={packagesUpdateLibraryFile}
        packageFileType={packageFileType}
        onMouseOut={() => setDisabled(true)}
        setShow={setPackagesUpdateLibraryFile}
        onMouseOver={() => setDisabled(false)}
        openPackageFileById={openPackageFileById}
        packagesPropertiesData={packagesPropertiesData}
        getTempLibraryFolderData={getTempLibraryFolderData}
      />

      <UpdatePackageDocumentDataModal
        bounds={bounds}
        submit={"Update"}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        show={updatePackageDocument}
        title={"Update Package Document"}
        packageFileDocs={packageFileDocs}
        packageFileType={packageFileType}
        setShow={setUpdatePackageDocument}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        openPackageFileById={openPackageFileById}
      />

      <PackagesUploadDataModal
        bounds={bounds}
        submit={"Upload"}
        onStart={onStart}
        disabled={disabled}
        packageId={packageId}
        title={"Upload Data"}
        draggleRef={draggleRef}
        show={uploadDataPackage}
        setShow={setUploadDataPackage}
        userCurrentData={userCurrentData}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        openPackageFileById={openPackageFileById}
      />

      <PackagesUploadDocsModal
        bounds={bounds}
        submit={"Upload"}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        show={uploadDocsPackage}
        title={"Upload Documents"}
        setShow={setUploadDocsPackage}
        packageFileDocs={packageFileDocs}
        packageFileType={packageFileType}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        openPackageFileById={openPackageFileById}
      />

      <PublishPackageModal
        Close="Close"
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        show={openAddNewPackage}
        categoryList={categoryList}
        setShow={setOpenAddNewPackage}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        openEditFolderData={openEditFolderData}
        getPackageFolderLists={getPackageFolderLists}
        submit={openEditFolderData?.status === "draft" ? "Publish" : "Re-Publish"}
        title={openEditFolderData?.status === "draft" ? "Publish Package" : "Re-Publish Package"}
      />

      <LibraryUploadFile
        Close="Close"
        submit={"Next"}
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        title={"Upload File"}
        draggleRef={draggleRef}
        show={uploadLibraryFile}
        libraryId={sessionLibraryId}
        setShow={setUploadLibraryFile}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
      />

      <ExportLibraryData
        bounds={bounds}
        submit={"Export"}
        onStart={onStart}
        show={exportData}
        disabled={disabled}
        libraryId={libraryId}
        setShow={setExportData}
        draggleRef={draggleRef}
        title={"Export Your Data "}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
      />
      <HelpLibraryTablePropertyModal
        bounds={bounds}
        onStart={onStart}
        submit={"Submit"}
        disabled={disabled}
        show={tableProperty}
        libraryId={libraryId}
        draggleRef={draggleRef}
        columnList={columnList}
        title={"Table Property"}
        setShow={setTableProperty}
        columnStatus={columnStatus}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        getLibraryDataByFolderId={getLibraryDataByFolderId}
      />

      <PackageFunctionValidation
        bounds={bounds}
        onStart={onStart}
        submit={"Submit"}
        disabled={disabled}
        draggleRef={draggleRef}
        show={packageValidationPopup}
        packageId={subFolderPackageId}
        setShow={setPackageValidationPopup}
        onMouseOut={() => setDisabled(true)}
        title={"Package Function Validation"}
        onMouseOver={() => setDisabled(false)}
        openPackageFileById={openPackageFileById}
      />

      <PackageFunctionValidationForUpdate
        bounds={bounds}
        onStart={onStart}
        submit={"Submit"}
        disabled={disabled}
        draggleRef={draggleRef}
        packageId={subFolderPackageId}
        onMouseOut={() => setDisabled(true)}
        show={packageValidationForUpdatePopup}
        onMouseOver={() => setDisabled(false)}
        openPackageFileById={openPackageFileById}
        setShow={setPackageValidationForUpdatePopup}
        title={"Package Function Validation For Update"}
      />

      <PackageFunctionFinalValidation
        bounds={bounds}
        onStart={onStart}
        submit={"Submit"}
        disabled={disabled}
        draggleRef={draggleRef}
        packageId={subFolderPackageId}
        show={packageFinalValidationPopup}
        onMouseOut={() => setDisabled(true)}
        title={"Package Function Validation"}
        onMouseOver={() => setDisabled(false)}
        setShow={setPackageFinalValidationPopup}
        openPackageFileById={openPackageFileById}
      />

      <PackageFunctionFinalValidationForUpdate
        bounds={bounds}
        onStart={onStart}
        submit={"Submit"}
        disabled={disabled}
        draggleRef={draggleRef}
        packageId={subFolderPackageId}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        openPackageFileById={openPackageFileById}
        show={packageFinalValidationForUpdatePopup}
        title={"Package Function Validation For Update"}
        setShow={setPackageFinalValidationForUpdatePopup}
      />

      <AddNewProjectModal
        bounds={bounds}
        submit={"Submit"}
        onStart={onStart}
        disabled={disabled}
        show={addProjectPop}
        title={"Add Project"}
        draggleRef={draggleRef}
        setShow={setAddProjectPop}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        getConsultantProjectFolder={getConsultantProjectFolder}
      />

      <UpdateProjectModal
        bounds={bounds}
        submit={"Submit"}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        show={updateProjectPop}
        title={"Update Project"}
        projectData={projectData}
        setShow={setUpdateProjectPop}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        getConsultantProjectFolder={getConsultantProjectFolder}
      />

      <ViewPropertiesProjectModal
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        title={"Property"}
        tableRef={tableRef}
        draggleRef={draggleRef}
        projectData={projectData}
        show={viewPropertiesProjectPop}
        onMouseOut={() => setDisabled(true)}
        setShow={setViewPropertiesProjectPop}
        onMouseOver={() => setDisabled(false)}
      />

      <AddNewCustomProjectFolderModal
        bounds={bounds}
        submit={"Submit"}
        onStart={onStart}
        disabled={disabled}
        folderId={folderId}
        projectId={projectId}
        draggleRef={draggleRef}
        show={addProjectCustomFolderPop}
        onMouseOut={() => setDisabled(true)}
        setShow={setAddProjectCustomFolderPop}
        onMouseOver={() => setDisabled(false)}
        title={"Add New Project Custom Folder"}
        getProjectFolderStructured={getProjectFolderStructured}
      />

      <AddNewDevelopmentFolderModal
        type={type}
        bounds={bounds}
        submit={"Submit"}
        onStart={onStart}
        disabled={disabled}
        folderId={folderId}
        draggleRef={draggleRef}
        title={"Add New Folder"}
        subFolderId={subFolderId}
        sub1FolderId={sub1FolderId}
        sub2FolderId={sub2FolderId}
        sub3FolderId={sub3FolderId}
        sub4FolderId={sub4FolderId}
        show={addDevelopmentFolderPop}
        structuredType={structuredType}
        onMouseOut={() => setDisabled(true)}
        setShow={setAddDevelopmentFolderPop}
        onMouseOver={() => setDisabled(false)}
        getProjectFolderStructured={getProjectFolderStructured}
      />

      <AddNewDevelopmentFileModal
        type={type}
        bounds={bounds}
        submit={"Submit"}
        onStart={onStart}
        disabled={disabled}
        folderId={folderId}
        title={"Add New File"}
        draggleRef={draggleRef}
        subFolderId={subFolderId}
        sub1FolderId={sub1FolderId}
        sub2FolderId={sub2FolderId}
        sub3FolderId={sub3FolderId}
        sub4FolderId={sub4FolderId}
        sub5FolderId={sub5FolderId}
        sub6FolderId={sub6FolderId}
        show={addDevelopmentFilePop}
        structuredType={structuredType}
        setShow={setAddDevelopmentFilePop}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        getProjectFolderStructured={getProjectFolderStructured}
      />

      <UpdateDevelopmentFolderModal
        type={type}
        bounds={bounds}
        submit={"Submit"}
        onStart={onStart}
        disabled={disabled}
        draggleRef={draggleRef}
        title={"Update Folder"}
        folderData={folderData}
        show={updateDevelopmentFolderPop}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
        setShow={setUpdateDevelopmentFolderPop}
        getProjectFolderStructured={getProjectFolderStructured}
      />
    </>
  );
};

export default CollapseMenu;
