import React, { useEffect, useState } from "react";
import { Col, Form, Table } from "react-bootstrap";
import { packagesServices } from "../../APIServices/Editor/packagesServices";

const SyntaxFunction = ({
  width,
  isFullScreen,
  setIsFullScreen,
  editorFullMode,
  fileId,
  editorTheme,
}) => {
  const collumnsArray = ["title", "value"];
  const [syntaxData, setSyntaxData] = useState([
    { statement: "", syntax: "", arguments: [] },
  ]);
  const [syntaxFunction, setSyntaxFunction] = useState("");
  const [status, setStatus] = useState("");
  const [syntaxStatus, setSyntaxStatus] = useState("");
  const [version, setVersion] = useState("");
  const [loader, setLoader] = useState(false);

  const addUpdateSyntaxFunctionForPython = async () => {
    setLoader(true);
    let userData = {};
    userData.id = fileId;
    userData.functionName = syntaxFunction;
    userData.statements = syntaxData;
    let data =
      await packagesServices.addUpdateSyntaxFunctionForPython(userData);
    if (data?.statusCode === 200) {
      setLoader(false);
      getPackageStatements(fileId);
    }
  };

  const getPackageStatements = async (fileId) => {
    let data = await packagesServices.getPackageStatements(fileId);
    setSyntaxStatus(data?.syntaxStatus);
    setSyntaxData(
      data?.statements || [
        { statement: "", statementDescription: "", syntax: "", arguments: [] },
      ]
    );
    setSyntaxFunction(data?.functionName);
    setStatus(data?.status);
    setVersion(data?.version);
  };

  const handleAddStatement = () => {
    setSyntaxData([
      ...syntaxData,
      { statement: "", statementDescription: "", syntax: "", arguments: [] },
    ]);
  };

  const handleAddArguments = (sectionIndex) => {
    const updatedSyntax = [...syntaxData];
    updatedSyntax[sectionIndex].arguments.push({});
    setSyntaxData(updatedSyntax);
  };

  const updateState = (e, sectionIndex, rowIndex, columnName) => {
    const updatedSyntax = [...syntaxData];
    updatedSyntax[sectionIndex].arguments[rowIndex][columnName] =
      e.target.value;
    setSyntaxData(updatedSyntax);
  };

  const handleRemoveArguments = (sectionIndex, rowIndex) => {
    const updatedSyntax = [...syntaxData];
    updatedSyntax[sectionIndex].arguments.splice(rowIndex, 1);
    setSyntaxData(updatedSyntax);
  };

  const handleRemoveStatements = (sectionIndex) => {
    const updatedSyntax = syntaxData.filter(
      (_, index) => index !== sectionIndex
    );
    setSyntaxData(updatedSyntax);
  };

  const updateSyntaxState = (e, sectionIndex, key) => {
    const updatedSyntax = [...syntaxData];
    updatedSyntax[sectionIndex][key] = e.target.value;
    setSyntaxData(updatedSyntax);
  };

  useEffect(() => {
    getPackageStatements(fileId);
  }, [fileId]);

  return (
    <>
      <div
        className={`p-0 output__section_widget ${!editorFullMode ? "" : "fullModeHeight"} ${!editorTheme ? "outputShadowEditorTheme" : "outputShadow"}`}
        style={{
          width: isFullScreen
            ? "98%"
            : width <= 1100
              ? 1500 - width + "px"
              : "400px",
        }}
      >
        <div
          className="border-bottom p-2"
          style={{ background: "#ebebeb", position: "relative" }}
        >
          <div className="hstack gap-2 header_tool justify-content-between">
            <p className="m-0">
              <b>Syntax Function</b>
            </p>
            <div className="hstack gap-1">
              {status === "draft" &&
                syntaxStatus === "ongoing" &&
                (!loader ? (
                  <i
                    onClick={addUpdateSyntaxFunctionForPython}
                    className={"fa-solid fa-save"}
                    title={"Save Statements"}
                  ></i>
                ) : (
                  <div title="Data Loading" className="Saveloader"></div>
                ))}
              <div className="vr"></div>
              <i
                className={
                  isFullScreen ? "fa-solid fa-minimize" : "fa-solid fa-maximize"
                }
                title={isFullScreen ? "Minimized Mode" : "Full Mode"}
                onClick={() => setIsFullScreen(!isFullScreen)}
              ></i>
            </div>
          </div>
        </div>
        <div
          className={`px-2 pt-2 ${!editorFullMode ? "output__section_widget_height" : "output__section_widget_full_height"}`}
        >
          <Form.Group as={Col} md="12">
            <Form.Label>Function Name</Form.Label>
            <Form.Control
              disabled={
                status === "lock" ||
                syntaxStatus === "completed" ||
                version !== "1.0.0"
              }
              type="text"
              onChange={(e) => setSyntaxFunction(e.target.value)}
              value={syntaxFunction}
              placeholder="Enter Function Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="12" className="my-2">
            <Form.Label>
              {status === "draft" && syntaxStatus === "ongoing" && (
                <>
                  <span className="mt-2">Add Statement</span>
                  <i
                    className="fa-solid fa-circle-plus ms-2"
                    style={{
                      color: "var(--mainColor)",
                      cursor: "pointer",
                    }}
                    title="Add Statement"
                    onClick={() => handleAddStatement()}
                  ></i>
                </>
              )}
            </Form.Label>
            <div className="mb-3">
              {syntaxData?.map((section, sectionIndex) => (
                <div className="details_data1 mb-3" key={sectionIndex}>
                  <Form.Group as={Col} md="12" className="mb-2">
                    <div className="hstack justify-content-between">
                      <Form.Label>Statement ({sectionIndex + 1})</Form.Label>
                      {(sectionIndex > 0 || syntaxData?.length > 1) &&
                        syntaxStatus === "ongoing" &&
                        status === "draft" && (
                          <i
                            className="fa-solid fa-circle-xmark mb-2"
                            style={{
                              color: "var(--red_color)",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemoveStatements(sectionIndex)}
                          ></i>
                        )}
                    </div>
                    <Form.Control
                      disabled={
                        status === "lock" || syntaxStatus === "completed"
                      }
                      required
                      type="text"
                      placeholder="Enter Statement Title"
                      value={section.statement}
                      onChange={(e) =>
                        updateSyntaxState(e, sectionIndex, "statement")
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="12" className="mb-2">
                    <Form.Label>Statement Description</Form.Label>
                    <Form.Control
                      disabled={
                        status === "lock" || syntaxStatus === "completed"
                      }
                      required
                      as="textarea"
                      placeholder="Enter Statement Description"
                      value={section.statementDescription}
                      onChange={(e) =>
                        updateSyntaxState(
                          e,
                          sectionIndex,
                          "statementDescription"
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="12" className="mb-2">
                    <Form.Label>Syntax</Form.Label>
                    <Form.Control
                      disabled={
                        status === "lock" || syntaxStatus === "completed"
                      }
                      required
                      type="text"
                      placeholder="Enter Syntax"
                      value={section.syntax}
                      onChange={(e) =>
                        updateSyntaxState(e, sectionIndex, "syntax")
                      }
                    />
                  </Form.Group>
                  {(section?.arguments?.length === 0 && status === "lock") ||
                  syntaxStatus === "completed" ? (
                    ""
                  ) : (
                    <Form.Group as={Col} md="12" className="mb-2">
                      <Form.Label>
                        Argument (Optional)
                        {status === "draft" && syntaxStatus === "ongoing" && (
                          <i
                            className="fa-solid fa-circle-plus ms-2"
                            style={{
                              color: "var(--mainColor)",
                              cursor: "pointer",
                            }}
                            title="Add Argument"
                            onClick={() => handleAddArguments(sectionIndex)}
                          ></i>
                        )}
                      </Form.Label>
                      <div
                        className="informationTable"
                        style={{ overflow: "visible" }}
                      >
                        <Table bordered className="m-0">
                          <tbody>
                            <tr>
                              <th>
                                <p>Attribute</p>
                              </th>
                              <th>
                                <p>Value</p>
                              </th>
                            </tr>
                            {section?.arguments?.map((row, rowIndex) => (
                              <tr
                                key={rowIndex}
                                style={{ position: "relative" }}
                              >
                                {collumnsArray.map((column, index) => (
                                  <td key={index} className="p-0">
                                    <input
                                      disabled={
                                        status === "lock" ||
                                        syntaxStatus === "completed"
                                      }
                                      className={index === 0 ? "bold" : ""}
                                      type="text"
                                      value={row[column] || ""}
                                      onChange={(e) =>
                                        updateState(
                                          e,
                                          sectionIndex,
                                          rowIndex,
                                          column
                                        )
                                      }
                                    />
                                  </td>
                                ))}
                                {status === "draft" &&
                                  syntaxStatus === "ongoing" && (
                                    <i
                                      className="fa-solid fa-xmark"
                                      title="Delete Row"
                                      onClick={() =>
                                        handleRemoveArguments(
                                          sectionIndex,
                                          rowIndex
                                        )
                                      }
                                    ></i>
                                  )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Form.Group>
                  )}
                </div>
              ))}
              {status === "draft" && syntaxStatus === "ongoing" && (
                <div className="button_type">
                  <button onClick={handleAddStatement}>
                    Add More Statement{" "}
                  </button>
                </div>
              )}
            </div>
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default SyntaxFunction;
