import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { authServices } from "../../../APIServices/authServices";

const KycVerification = ({ userCurrrentData, currentUserData }) => {
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [confirmAccountNo, setConfirmAccount] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [holderName, setHolderName] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [panCardFile, setPanCardFile] = useState(null);
  const [identityProofFile, setIdentityProofFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleupdateKyc = async () => {
    setLoader(true);
    let userData = {};
    userData.bankName = bankName;
    userData.accountNumber = accountNumber;
    userData.ifscCode = ifscCode;
    userData.holderName = holderName;
    userData.swiftCode = swiftCode;
    userData.panCardFile = panCardFile;
    userData.identityProofFile = identityProofFile;

    let data = await authServices.updateUserKYCDetails(userData);
    if (data?.statusCode) {
      currentUserData();
      setStatusMessage(data?.statusCode);
      setLoader(false);
    }
  };

  const panCardFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setPanCardFile(file);
    } else {
      setPanCardFile(null);
      setUploadMessage("Only PDF documents are acceptable.");
    }
    setTimeout(() => {
      setUploadMessage();
    }, 3000);
  };
  const identityProofFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setIdentityProofFile(file);
    } else {
      setIdentityProofFile(null);
      setUploadMessage("Only PDF documents are acceptable.");
    }
    setTimeout(() => {
      setUploadMessage();
    }, 3000);
  };

  useEffect(() => {
    setBankName(userCurrrentData?.bankName);
    setAccountNumber(userCurrrentData?.accountNumber);
    setConfirmAccount(userCurrrentData?.accountNumber);
    setIfscCode(userCurrrentData?.ifscCode);
    setHolderName(userCurrrentData?.holderName);
    setSwiftCode(userCurrrentData?.swiftCode);
  }, [userCurrrentData]);

  return (
    <>
      <Container  fluid className="mt-2">
        <Row>
          <Col md={7}>
            <div className="details_data1">
              <div className="account_detai text-center">
                <h6>Bank Details</h6>
              </div>
              <div className="kycPageHeight">
                <Container fluid>
                  <Row>
                    <Form.Group as={Col} md="12" className="p-0 mb-2">
                      <Form.Label>
                        Bank Name<span>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => setBankName(e.target.value)}
                        value={bankName}
                        required
                        type="text"
                        placeholder="Enter bank name"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="p-0 mb-2">
                      <Form.Label>
                        Account Number<span>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          setAccountNumber(e.target.value);
                        }}
                        value={accountNumber}
                        required
                        type="password"
                        placeholder="Enter account number"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="p-0 mb-2">
                      <Form.Label>
                        Confirm Account Number<span>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          setConfirmAccount(e.target.value);
                        }}
                        value={confirmAccountNo}
                        required
                        type="number"
                        placeholder="Confirm account number"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="p-0 mb-2">
                      <Form.Label>
                        IFSC code<span>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => setIfscCode(e.target.value)}
                        value={ifscCode}
                        required
                        type="text"
                        placeholder="Enter IFSC code"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="p-0 mb-2">
                      <Form.Label>
                        Account Holder Name<span>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => setHolderName(e.target.value)}
                        value={holderName}
                        required
                        type="text"
                        placeholder="Enter account holder name"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="p-0 mb-2">
                      <Form.Label>Swift Code</Form.Label>
                      <Form.Control
                        onChange={(e) => setSwiftCode(e.target.value)}
                        value={swiftCode}
                        type="text"
                        placeholder="Enter swift code"
                      />
                    </Form.Group>
                  </Row>
                </Container>
              </div>
              <div className="button_type justify-content-between hstack pt-2 border-top">
                <div className="d-flex justify-content-between">
                  {Number(accountNumber) === Number(confirmAccountNo) ? (
                    <p className="message m-0" style={{ color: "green" }}>
                      * Account number and confirm account number are same.
                    </p>
                  ) : (
                    <p className="message m-0" style={{ color: "red" }}>
                      * Account number and confirm account number are not same.
                    </p>
                  )}
                </div>
                <button
                  className="m-0 hstack gap-2"
                  disabled={
                    Number(accountNumber) !== Number(confirmAccountNo) ||
                    !accountNumber ||
                    !confirmAccountNo ||
                    !swiftCode ||
                    !holderName ||
                    !ifscCode
                  }
                  onClick={handleupdateKyc}
                >
                  <Spinner
                    as="span"
                    animation={loader && "border"}
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Submit
                </button>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="details_data1">
              <div className="account_detai text-center">
                <h6>Upload Documents</h6>
              </div>
              <div className="kycPageHeight">
                <Container fluid>
                  <Row>
                    <Form.Group as={Col} md="12" className="p-0 mb-2">
                      <Form.Label>
                        Upload Pan Card<span>*</span>
                      </Form.Label>

                      <Form.Control
                        required
                        type="file"
                        accept=".pdf"
                        onChange={panCardFileChange}
                        placeholder="Upload pan card"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="p-0 mb-2">
                      <Form.Label>
                        Identity Proof<span>*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="file"
                        accept=".pdf"
                        onChange={identityProofFileChange}
                        placeholder="Upload identity proof"
                      />
                    </Form.Group>
                  </Row>
                </Container>
              </div>
              <div className="button_type text-end pt-2 border-top">
                <button
                  className="m-0"
                  disabled={!panCardFile || !identityProofFile}
                  onClick={handleupdateKyc}
                >
                  Submit
                </button>
                <p className="message text-start m-0" style={{ color: "red" }}>
                  {uploadMessage}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default KycVerification;
