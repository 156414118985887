import React, { useEffect, useRef, useState } from "react";
import TableHeader from "../../../Component/TableHeader/TableHeader";
import SubscriptionExportBillingData from "./SubscriptionExportBillingData";
import SubscriptionChartDataModal from "./SubscriptionChartDataModal";
import { subscriptionPlanServices } from "../../../APIServices/Editor/subscriptionPlanServices";
import SubscriptionBillingTable from "./SubscriptionBillingTable";

const SubscriptionBilling = ({ tableRef, onMouseDown, editorFullMode }) => {
  const [disabled, setDisabled] = useState(true);
  const [exportBillingData, setExportBillingLogData] = useState("");
  const [openChartBilling, setOpenChartBilling] = useState("");
  const [loading, setLoading] = useState(false);

  const [transactions, setTransactions] = useState([]);

  const getSubscriptionTransectionList = async () => {
    setLoading(true);
    let data = await subscriptionPlanServices.getSubscriptionTransectionList()
    setTransactions(data?.data);
    setLoading(false);
  }

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    getSubscriptionTransectionList();
  }, []);
  return (
    <>
      <TableHeader
        ChartLine
        handleChart={setOpenChartBilling}
        ExportBtn
        handleExport={setExportBillingLogData}
      />
      <SubscriptionBillingTable
        tableRef={tableRef}
        loading={loading}
        onMouseDown={onMouseDown}
        editorFullMode={editorFullMode}
        transactions={transactions}
      />
      <SubscriptionExportBillingData
        show={exportBillingData}
        setShow={setExportBillingLogData}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title={"Export Billing Data "}
        submit={"Export"}
        disabled={disabled}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
      />
      <SubscriptionChartDataModal
        show={openChartBilling}
        setShow={setOpenChartBilling}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title={"Billing Analytics"}
        submit={"Submit"}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
      />
    </>
  );
};

export default SubscriptionBilling;
