import React, { useState } from "react";
import { Form, Collapse } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { copyToClipboard } from "../../../../Utils/UniversalFunction";

const PackageExample = ({ examples }) => {
  const [open, setOpen] = useState("");
  const [copied, setCopied] = useState("");
  return (
    <>
      {examples?.map((item, index) => (
        <div key={index}>
          <div
            onClick={() => setOpen(open === index ? "" : index)}
            className="hstack justify-content-between pointer"
          >
            <h6 className="m-0">{item?.name}</h6>
            <i
              class={`fa-solid ${open === index ? "fa-caret-down" : " fa-caret-right"}`}
            ></i>
          </div>
          <hr className="my-2"/>
          <Collapse in={open === index}>
            <div id="example-collapse-text" className="mt-2">
              <p>{item?.description}</p>
              <Form.Label>Procedure</Form.Label>
              <div style={{ fontSize: 14 }} className="position-relative">
                <SyntaxHighlighter style={dracula}>
                  {item?.procedure}
                </SyntaxHighlighter>
                <i
                  onClick={() =>
                    copyToClipboard(
                      item.procedure,
                      "procedure" + index,
                      setCopied
                    )
                  }
                  class={`fa-regular ${copied === "procedure" + index ? "fa-circle-check" : "fa-copy"}`}
                  aria-hidden="true"
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              <Form.Label className="mt-4">Output</Form.Label>
              <div
                dangerouslySetInnerHTML={{ __html: item?.output }}
              />
            </div>
          </Collapse>
        </div>
      ))}
    </>
  );
};
export default PackageExample;
