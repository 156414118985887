import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form } from "react-bootstrap";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices";

const PackagesUploadDocsModal = ({
  show,
  title,
  submit,
  bounds,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  packageFileDocs,
  openPackageFileById,
  packageFileType
}) => {
  const [uploadDocs, setUploadDocs] = useState("");

  const uploadDocuments = async (e) => {
    let fileData = e.target.files[0];
    if (fileData) {
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      setUploadDocs(fileData)
    }
  };

  const uploadPackageDocument = async (e) => {
    const formData = new FormData();
    formData.append("file", uploadDocs);
    formData.append("docFolderId", packageFileDocs?._id);
    formData.append("packageId", packageFileDocs?.packageId);
    let result = await packagesServices.uploadPackageDocument(
      formData
    );
    if (result?.statusCode === 200) {
      setUploadDocs("");
      setShow(!show)
      openPackageFileById(packageFileType)
    }
  };
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Form.Label>
            Upload Document<span>*</span>
          </Form.Label>
          <Form.Control
            className="mb-3"
            required
            type="file"
            accept=".doc, .docx, .xlsx, .xls, .csv, .pdf, .ppt, .pptx, .txt, .zip, .rar, .xpt, .gas, .json"
            onChange={(e) => uploadDocuments(e)}
          />
        </div>
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button onClick={()=>uploadPackageDocument()} disabled={!uploadDocs}>{submit}</button>
        </div>
      </Modal>
    </>
  );
};
export default PackagesUploadDocsModal;
