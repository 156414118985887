import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Table } from "react-bootstrap";

const ViewRevenue = ({
  show,
  setShow,
  onStart,
  draggleRef,
  bounds,
  onMouseOver,
  onMouseOut,
  disabled,
  title,
  // submit,
}) => {
  const [packages, setPackages] = useState([
    {
      purchasedDateTime: "20/12/2024 | 14:20",
      discout: 10,
      revenue: 100,
    },
    {
      purchasedDateTime: "20/12/2024 | 14:20",
      discout: 10,
      revenue: 100,
    },
    {
      purchasedDateTime: "20/12/2024 | 14:20",
      discout: 10,
      revenue: 100,
    },
    {
      purchasedDateTime: "20/12/2024 | 14:20",
      discout: 10,
      revenue: 100,
    },
    {
      purchasedDateTime: "20/12/2024 | 14:20",
      discout: 10,
      revenue: 100,
    },
  ]);
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={600}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="table_content">
          <Table striped hover>
            <thead>
              <tr>
                <th>Purchased Date & Time</th>
                <th>Discount</th>
                <th>Total Revenue</th>
              </tr>
            </thead>
            <tbody>
              {packages.map((item, index) => (
                <tr key={index}>
                  <td>{item.purchasedDateTime}</td>
                  <td>${item.discout}</td>
                  <td>${item.revenue}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* <div className="button_type text-end">
            <button>{submit}</button>
          </div> */}
        </div>
      </Modal>
    </>
  );
};
export default ViewRevenue;
