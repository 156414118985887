import React, { useRef, useState } from "react";
import LibraryHelpTable from "./LibraryHelpTable";
import ExportLibraryData from "./LibraryHelps/ExportLibraryData";
import TableHeader from "../../Component/TableHeader/TableHeader";
import { Form, InputGroup } from "react-bootstrap";

const LibraryHelp = ({
  tableRef,
  onMouseDown,
  dataList,
  columnList,
  libraryId,
  libraryType,
  editorFullMode,
}) => {
  const [columnType, setColumnType] = useState("name");
  const [disabled, setDisabled] = useState(true);

  const [exportData, setExportData] = useState("");
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const startIndex = (Number(pageNumber) - 1) * Number(itemsPerPage);
  const endIndex = startIndex + Number(itemsPerPage);
  let result = dataList?.slice(startIndex, endIndex) || [];
  let totalPage = Math.ceil(dataList.length / itemsPerPage);

  return (
    <>
      <div className="table_head">
        <TableHeader
          selectOption
          selectChange={setColumnType}
          columnType={columnType}
          ExportBtn={libraryType === "help"}
          handleExport={setExportData}
        />
        <div
          className="table_content overflow-auto mx-2"
          style={{
            maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
          }}
        >
          <LibraryHelpTable
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            columnList={columnList}
            dataList={result}
            itemsPerPage={itemsPerPage}
            pageNumber={pageNumber}
            columnType={columnType}
          />
        </div>
        <div className="hstack justify-content-between mx-2 mt-2">
          <div className="hstack gap-2">
            <div className="inputforSecelct">
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  RESULTS/ PAGE
                </InputGroup.Text>
                <Form.Select
                  onChange={(e) => setItemsPerPage(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: 65 }}
                >
                  <option selected={itemsPerPage === 5} value={5}>
                    5
                  </option>
                  <option selected={itemsPerPage === 10} value={10}>
                    10
                  </option>
                  <option selected={itemsPerPage === 15} value={15}>
                    15
                  </option>
                  <option selected={itemsPerPage === 25} value={25}>
                    25
                  </option>
                  <option selected={itemsPerPage === 50} value={50}>
                    50
                  </option>
                  <option selected={itemsPerPage === 100} value={100}>
                    100
                  </option>
                  <option selected={itemsPerPage === 500} value={500}>
                    500
                  </option>
                </Form.Select>
              </InputGroup>
            </div>
            <div className="inputforSecelct">
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">PAGE</InputGroup.Text>
                <Form.Control
                  type="number"
                  onChange={(e) => setPageNumber(e.target.value)}
                  value={pageNumber}
                  required
                  style={{ width: 65 }}
                />
                <InputGroup.Text id="inputGroupPrepend">
                  / {totalPage}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <p className="m-0">
            <b>
              Columns : {columnList?.length} | Rows : {dataList?.length}
            </b>
          </p>
        </div>
      </div>
      <ExportLibraryData
        show={exportData}
        setShow={setExportData}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        title={"Export Your Data "}
        submit={"Export"}
        libraryId={libraryId}
        disabled={disabled}
        onMouseOut={() => setDisabled(true)}
        onMouseOver={() => setDisabled(false)}
      />
    </>
  );
};

export default LibraryHelp;
