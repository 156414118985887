import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form, Spinner } from "react-bootstrap";
import { handleResponse } from "../../../APIHelpers/Responses";
const AddNewLibrary = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  setDummyLibraryList,
}) => {
  const [libraryName, setLibraryName] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);

  const addNewSessionLibrary = () => {
    setLoader(true);
    let uniqueId = Math.random().toString().substr(2, 10);
    let userData = {};
    userData._id = uniqueId;
    userData.name = libraryName;
    userData.description = description;
    userData.createdAt = new Date();
    userData.updatedAt = new Date();

    let dummyLibraryList = JSON.parse(localStorage.getItem("dummyLibraryList"));

    if (!dummyLibraryList || dummyLibraryList.length === 0) {
      localStorage.setItem("dummyLibraryList", JSON.stringify([userData]));
      setDummyLibraryList([userData]);
      clearInputData();
      setLoader(false);
      let Message = {
        statusCode: 200,
        customMessage: "Folder has been Successfully Added.",
      };
      handleResponse(Message);
    } else {
      let checkData = dummyLibraryList.find(
        (library) => library.name === libraryName
      );
      if (checkData) {
        let Message = {
          statusCode: 400,
          customMessage: "Opps! Folder Name Already Exist.",
        };
        handleResponse(Message);
        clearInputData();
      } else {
        dummyLibraryList.push(userData);
        dummyLibraryList.sort((a, b) => a.createdAt - b.createdAt);
        localStorage.setItem(
          "dummyLibraryList",
          JSON.stringify(dummyLibraryList)
        );
        setDummyLibraryList(dummyLibraryList);
        clearInputData();
        let Message = {
          statusCode: 200,
          customMessage: "Folder has been Successfully Added.",
        };
        handleResponse(Message);
      }
    }
  };

  const [error, setError] = useState("");
  const validateFileTitle = (input) => {
    const fileTitleRegex = /^[a-zA-Z0-9_]+$/;

    if (!fileTitleRegex.test(input)) {
      setError(
        "File Title should only contain capital letters, small letters, or numbers"
      );
    } else {
      setError("");
    }
  };

  const clearInputData = () => {
    setShow(!show);
    setLibraryName("");
    setDescription("");
  };

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={clearInputData}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Form.Label>
            Folder Name<span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter Folder Name"
            autoFocus
            value={libraryName}
            onChange={(e) => {
              setLibraryName(e.target.value);
              validateFileTitle(e.target.value);
            }}
          />
          {error && (
            <span style={{ fontSize: 10, color: "red", fontWeight: 600 }}>
              Folder Name Title should only contain capital letters, small
              letters, underScore, and numbers "Space Not Allowed"
              <br />
            </span>
          )}
          <Form.Label className="mt-3">
            Description<span>*</span>
          </Form.Label>
          <Form.Control
            className="mb-3"
            required
            as="textarea"
            placeholder="Enter Folder Description"
            autoFocus
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <hr className="w-100 m-0" />
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            onClick={addNewSessionLibrary}
            disabled={!libraryName || !description || error}
          >
            {loader ? (
                <Spinner
                  animation="border"
                  style={{ width: 13, height: 13 }}
                  className="me-1"
                />
              ) : null}
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default AddNewLibrary;
