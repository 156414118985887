import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/ext-language_tools";
import 'ace-builds/src-noconflict/ext-beautify';

const ACEEditor = ({ editorRef, editorValue, editorTheme, codeFileStatus, fontSize, cursorRowColumn, setCodeLocalData, checkCompareData, setCodeCompare }) => {
  return (
    <AceEditor
      ref={editorRef}
      mode="python"
      theme={!editorTheme ? "monokai" : "chrome"}
      name="UNIQUE_ID_OF_DIV"
      fontSize={fontSize}
      onCursorChange={cursorRowColumn}
      onChange={(newValue) => {setCodeLocalData(newValue); checkCompareData(newValue); setCodeCompare(JSON.parse(localStorage.getItem("codeCompare")) || [])}}
      value={editorValue}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        highlightActiveLine: true,
        highlightGutterLine: true,
        showLineNumbers: true,
        showGutter: true,
        readOnly: codeFileStatus === "lock",
        behavioursEnabled: true,
        cursorStyle: 'disabled',
        showPrintMargin: true,
      }}
    />
  );
};

export default ACEEditor;
