import React from "react";

const ExpiredInvitationMessage = () => {
  return (
    <>
      <div className="sign_url">
        <div className="form_details text-center w-50 py-5">
          <div className=" mb-3">
            <i
              style={{ color: "red", fontSize: 60 }}
              class="fa-solid fa-circle-xmark"
              alt="Invitation Link Expired"
            ></i>
          </div>
          <div className=" mb-2" style={{ color: "#103c5e", fontSize: "25px" }}>
            <b>Expired Invitation Link</b>
          </div>
          <span style={{fontSize: "14px"}}>
            Your Invitation link has been expired. Please contact "
            <b>Administrator</b>". After that check your mail, then you are able
            to Registration
          </span>
        </div>
      </div>
    </>
  );
};

export default ExpiredInvitationMessage;
