import React from "react";

const InviteConfirmation = () => {
  return (
    <>
      <div className="sign_url">
        <div className="form_details text-center w-50 py-5">
          <div className=" mb-2" style={{ color: "#103c5e", fontSize: "25px" }}>
            <b>Confirmation</b>
          </div>
          <div className="mb-3">
            <i
              style={{ color: "green", fontSize: 60 }}
              class="fa-solid fa-circle-check"
              alt="Registration Successful"
            ></i>
          </div>
          <span>You have been successfully registered.
           Please wait for "Administrator"<br/> Approval.
           After that check your mail, then you are able to login </span>
          <div className="sub_buttons pt-4">
            <a href="/">
              <button>Login</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteConfirmation;
