import React, { useEffect, useRef, useState } from "react";
import { Container, Badge, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Header = ({ setShowSidebarMenu, roleType, handleTabChange }) => {
  const AppsRef = useRef(null);
  const CartRef = useRef(null);
  const [appsActive, setAppsActive] = useState(false);
  const [cartActive, setCartActive] = useState(false);

  const toggleApps = () => {
    setAppsActive(!appsActive);
  };
  const toggleCart = () => {
    setCartActive(!cartActive);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (CartRef.current && !CartRef.current.contains(event.target)) {
        setCartActive(false);
      }
      if (AppsRef.current && !AppsRef.current.contains(event.target)) {
        setAppsActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <Container fluid>
        <div className="hstack justify-content-between py-1">
          <div
            ref={AppsRef}
            className={`profile position-relative ${appsActive ? "active" : ""
              }`}
          >
            <div className="icon_wrap hstack gap-1" onClick={toggleApps}>
              <div
                className={`title_name hstack gap-1 px-2 p-1 ${appsActive ? "profileNameActive" : ""
                  }`}
                style={{ cursor: "pointer" }}
              >
                <p className="m-0">
                  <b>Apps</b>
                </p>
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>
            <div className="profile_dd shadow">
              <ul className="profile_ul">
                {roleType === "consultant" && (
                  <li>
                    <NavLink
                      onClick={() => {
                        toggleApps();
                        setShowSidebarMenu("file");
                      }}
                    >
                      <i className="fa-solid fa-folder-open me-2"></i> Files
                    </NavLink>
                  </li>
                )}
                {roleType === "consultant" && (
                  <li>
                    <NavLink
                      onClick={() => {
                        toggleApps();
                        setShowSidebarMenu("packages");
                      }}
                    >
                      <i className="fa-solid fa-cubes me-2"></i> Packages
                    </NavLink>
                  </li>
                )}

                {roleType === "developer" ||
                  roleType === "validator" ||
                  (roleType === "subadmin" && (
                    <li>
                      <NavLink
                        onClick={() => {
                          toggleApps();
                          setShowSidebarMenu("developPackages");
                        }}
                      >
                        <i className="fa-solid fa-cubes me-2"></i> Packages
                      </NavLink>
                    </li>
                  ))}
                <li>
                  <NavLink
                    onClick={() => {
                      toggleApps();
                      setShowSidebarMenu("library");
                    }}
                  >
                    <i className="fa-solid fa-folder-tree me-2"></i> Library
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      toggleApps();
                      setShowSidebarMenu("account");
                    }}
                  >
                    <i className="fa-solid fa-user me-2"></i> Account
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="hstack gap-3 cart_datas">
            {roleType === "consultant" && (
              <>
                <div
                  ref={CartRef}
                  className={`profile position-relative ${cartActive ? "active" : ""
                    }`}
                >
                  <div className="icon_wrap hstack gap-1" onClick={toggleCart}>
                    <div
                      className={`title_name hstack gap-1 px-2 p-1 ${cartActive ? "profileNameActive" : ""
                        }`}
                      style={{ cursor: "pointer" }}
                    >
                      <div style={{ fontSize: "12px" }} className="store_drop">
                        <i className="fa-solid fa-basket-shopping"></i>
                        <Badge className="number_badge">88</Badge>
                      </div>
                    </div>
                  </div>
                  <div
                    className="profile_dd shadow"
                    style={{ right: 0, width: 400 }}
                  >
                    <ul className="profile_ul drop_list_group">
                      <Table bordered striped className="m-0">
                        <thead>
                          <tr>
                            <th colSpan={2}>Packages Name</th>
                            <th>Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan={2}>Lorem ipsum dolor sit. x2</td>
                            <td className="text-success">$1100.00</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>Lorem ipsum dolor sit. x2</td>
                            <td className="text-success">$1100.00</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>Lorem ipsum dolor sit. x2</td>
                            <td className="text-success">$1100.00</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>Lorem ipsum dolor sit. x2</td>
                            <td className="text-success">$1100.00</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>Lorem ipsum dolor sit. x2</td>
                            <td className="text-success">$1100.00</td>
                          </tr>
                          <tr>
                            <th colSpan={2}>Total</th>
                            <td className="text-success">
                              <b>$5500.00</b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <div className="add_carts1 mb-2">
                        <button>
                          <i className="fa-solid fa-eye me-2"></i> View Cart
                        </button>
                        <button>
                          <i className="fa-solid fa-cart-shopping me-2"></i>
                          Checkout
                        </button>
                      </div>
                    </ul>
                  </div>
                </div>
                <div
                  style={{ fontSize: "12px", cursor: "pointer" }}
                  className="store_drop"
                  title="Fevorites"
                  onClick={(e) => handleTabChange(e, "Favorite", "favorite")}
                >
                  <i className="fa-regular fa-heart fav_table"></i>
                  <Badge className="nums_badge">88</Badge>
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
