import { headers } from "../../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../APIHelpers/Responses";
import config from "../../config/config.json";

export const consultantServices = {
  addNewConsultantProject,
  updateConsultantProjectFolder,
  updateConsultantProjectHeaderInformation,
  getConsultantProjectFolder,
  deleteConsultantProjectFolder,
  restoredConsultantProjectFolder,
  addNewProjectFolderStructured,
  getProjectFolderStructured,
  addNewDevelopmentFolder,
  updateDevelopmentFolderData,
  addNewProjectProgramEnvironment,
  addNewDevelopmentFile,
  lockUnlockConsultantFolders,
  deleteDevelopmentFolderData,
  restoreDevelopmentFolderData,
  getConsultantDeletedProjectFileFolder,
  permanentDeleteConsultantFolders,
  getBasePackageForUseConsultant,
  importPackageForUse,
  updateProjectFileCode,
  getProjectFileOutputById,
  getProjectFileLogById,
  getProjectInformation,
  pushProjectFileCodeinProductionMode
};

// Add New Project Folder
async function addNewConsultantProject(userData) {
  const response = await fetch(
    `${config.API_URL}addNewConsultantProject`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Project Folder
async function updateConsultantProjectFolder(userData) {
  const response = await fetch(
    `${config.API_URL}updateConsultantProjectFolder`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Project Information
async function updateConsultantProjectHeaderInformation(userData) {
  const response = await fetch(
    `${config.API_URL}updateConsultantProjectHeaderInformation`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Project Folder
async function getConsultantProjectFolder() {
  const response = await fetch(
    `${config.API_URL}getConsultantProjectFolder`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Project Folder
async function deleteConsultantProjectFolder(userData) {
  const response = await fetch(
    `${config.API_URL}deleteConsultantProjectFolder`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Restore Project Folder
async function restoredConsultantProjectFolder(userData) {
  const response = await fetch(
    `${config.API_URL}restoredConsultantProjectFolder`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add New Project Folder Structured
async function addNewProjectFolderStructured(userData) {
  const response = await fetch(
    `${config.API_URL}addNewProjectFolderStructured`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Project Folder Structured
async function getProjectFolderStructured(folderId) {
  const response = await fetch(
    `${config.API_URL}getProjectFolderStructured/${folderId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Development Folder
async function addNewDevelopmentFolder(userData) {
  const response = await fetch(
    `${config.API_URL}addNewDevelopmentFolder`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Development Folder
async function updateDevelopmentFolderData(userData) {
  const response = await fetch(
    `${config.API_URL}updateDevelopmentFolderData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add New Project Program Environment
async function addNewProjectProgramEnvironment(userData) {
  const response = await fetch(
    `${config.API_URL}addNewProjectProgramEnvironment`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add New File
async function addNewDevelopmentFile(userData) {
  const response = await fetch(
    `${config.API_URL}addNewDevelopmentFile`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Lock unlock Consultant Folders
async function lockUnlockConsultantFolders(userData) {
  const response = await fetch(
    `${config.API_URL}lockUnlockConsultantFolders`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Delete Folder
async function deleteDevelopmentFolderData(userData) {
  const response = await fetch(
    `${config.API_URL}deleteDevelopmentFolderData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Restore Folder
async function restoreDevelopmentFolderData(userData) {
  const response = await fetch(
    `${config.API_URL}restoreDevelopmentFolderData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Deleted Project File Folder
async function getConsultantDeletedProjectFileFolder() {
  const response = await fetch(
    `${config.API_URL}getConsultantDeletedProjectFileFolder`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Permanent Delete Consultant Folder
async function permanentDeleteConsultantFolders(userData) {
  const response = await fetch(
    `${config.API_URL}permanentDeleteConsultantFolders`,
    headersWithAuth("DELETE", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Base Package For Consultant
async function getBasePackageForUseConsultant() {
  const response = await fetch(
    `${config.API_URL}getBasePackageForUseConsultant`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Import Package For use
async function importPackageForUse(userData) {
  const response = await fetch(
    `${config.API_URL}importPackageForUse`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Project File Code
async function updateProjectFileCode(userData) {
  const response = await fetch(
    `${config.API_URL}updateProjectFileCode`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Deleted Project File Folder
async function getProjectFileOutputById(id) {
  const response = await fetch(
    `${config.API_URL}getProjectFileOutputById/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Deleted Project File Folder
async function getProjectFileLogById(id) {
  const response = await fetch(
    `${config.API_URL}getProjectFileLogById/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Project Information
async function getProjectInformation(id) {
  const response = await fetch(
    `${config.API_URL}getProjectInformation/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Push Project File Code in Production Mode
async function pushProjectFileCodeinProductionMode(userData) {
  const response = await fetch(
    `${config.API_URL}pushProjectFileCodeinProductionMode`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}