import React, { useRef, useState } from "react";
import { authServices } from "../../../APIServices/authServices";

const TwoFactorAuthByApps = ({
  appTwoFA,
  setNextBy,
  setModalSize,
  getTwoFactorAuthType,
}) => {
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const otpInputs = useRef([]);

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedValues = [...otpValues];
      updatedValues[index] = value;
      setOtpValues(updatedValues);

      if (value !== "" && index < 5) {
        otpInputs.current[index + 1].focus();
      }

      if (value === "" && index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };

  const [base64, setBase64] = useState("");
  const [secret, setSecret] = useState("");
  const [check, setCheck] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showQR, setShowQR] = useState(false);

  const generateQRCode = async () => {
    let data = await authServices.generateQRCode();
    setBase64(data.base64);
    setSecret(data.secret);
  };

  const showQRCode = async () => {
    let data = await authServices.showQRCode();
    setBase64(data);
    if (data) {
      setShowQR(true);
    }
  };

  const enableTwoFactorAuth = async () => {
    let userData = {};
    userData.secret = secret;
    userData.OTP = otpValues;
    let data = await authServices.enableTwoFactorAuth(userData);
    if (data?.statusCode === 200) {
      getTwoFactorAuthType();
      setOtpValues(["", "", "", "", "", ""]);
      setModalSize(false);
    }
  };

  const disableTwoFactorAuth = async () => {
    let userData = {};
    userData.secret = secret;
    userData.otp = otpValues;
    let data = await authServices.disableTwoFactorAuth(userData);
    if (data?.statusCode === 200) {
      getTwoFactorAuthType();
      setDisable(false);
      setShowQR(false);
      setCheck(false);
      setNextBy(true);
      setModalSize(false);
      setOtpValues(["", "", "", "", "", ""]);
      setBase64("");
      setSecret("");
    }
  };

  const getQRDataForDisable = async () => {
    let data = await authServices.getQRData();
    setSecret(data);
    setDisable(true);
    setModalSize(true);
  };

  return (
    <>
      {!disable ? (
        <>
          {!appTwoFA ? (
            <>
              {!check ? (
                <>
                  <div className="common_css">
                    {base64 ? (
                      <div className="qr__imag">
                        <img style={{ color: "red" }} src={base64} />
                      </div>
                    ) : (
                      <>
                        <div className="qr__imag mb-3">
                          <div className="security-shield">
                            <i className="fa-solid fa-unlock mb-2"></i>
                            <p className="m-0">
                              <b>Disabled</b>
                            </p>
                          </div>
                        </div>
                        <p style={{ fontSize: 12, textAlign: "center" }}>
                          To enable Apps 2FA, generate QR. After generating the
                          QR, you have to scan the QR with the Authenticator
                          app, after that the OTP will be automatically sent to
                          your device.
                        </p>
                      </>
                    )}
                  </div>
                  <hr className="w-100 m-0" />
                  {base64 ? (
                    <div className="hstack gap-3 justify-content-end px-3 pt-2 button_type footer_btn w-full">
                      <button onClick={() => setBase64("")}>Back</button>
                      <button
                        className="w-100"
                        onClick={() => generateQRCode()}
                      >
                        {base64 ? "Re-Generate QR" : "Generate QR"}
                      </button>
                      <button
                        onClick={() => {
                          setCheck(true);
                          setModalSize(true);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  ) : (
                    <div className="hstack gap-3 justify-content-end px-3 pt-2 button_type footer_btn">
                      <button onClick={() => setNextBy(false)}>Back</button>
                      <button
                        style={{ width: 140 }}
                        onClick={() => generateQRCode()}
                      >
                        {base64 ? "Re-Generate QR" : "Generate QR"}
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="common_css text-center">
                    <label
                      className="m-0"
                      style={{ fontSize: 14, fontWeight: 600 }}
                    >
                      Enter OTP
                    </label>
                    <div className="otp-input-fields px-0">
                      {otpValues.map((value, index) => (
                        <input
                          key={index}
                          type="text"
                          pattern="\d*"
                          maxLength="1"
                          className={`otp__digit otp__field__${index + 1}`}
                          value={value}
                          onChange={(e) => handleInputChange(index, e)}
                          ref={(input) => (otpInputs.current[index] = input)}
                        />
                      ))}
                    </div>
                  </div>
                  <hr className="w-100 m-0" />
                  <div className="hstack gap-3 justify-content-end px-3 pt-2 button_type footer_btn">
                    <button
                      onClick={() => {
                        setCheck(false);
                        setOtpValues(["", "", "", "", "", ""]);
                        setModalSize(false);
                      }}
                    >
                      Back
                    </button>
                    <button onClick={() => enableTwoFactorAuth()}>
                      Enable
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {!showQR ? (
                <>
                  <div className="qr__imag">
                    <div className="security-shield">
                      <i className="fa-solid fa-lock mb-2"></i>
                      <p className="m-0">
                        <b>Enabled (2FA by Apps)</b>
                      </p>
                    </div>
                  </div>
                  <div className="hstack gap-3 justify-content-center pt-3 button_type">
                    <button onClick={() => showQRCode()}>Show QR</button>
                    <button onClick={() => getQRDataForDisable()}>
                      Disable
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="qr__imag">
                    {base64 && <img src={base64} />}
                  </div>
                  <div className="hstack gap-3 justify-content-center pt-3 button_type">
                    <button onClick={() => setShowQR(false)}>Hide QR</button>
                    <button onClick={() => getQRDataForDisable()}>
                      Disable
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <label className="small mb-1" for="inputCountry">
            Enter OTP
          </label>
          <div className="otp-input-fields px-0 justify-content-start">
            {otpValues.map((value, index) => (
              <input
                key={index}
                type="text"
                pattern="\d*"
                maxLength="1"
                className={`otp__digit otp__field__${index + 1}`}
                value={value}
                onChange={(e) => handleInputChange(index, e)}
                ref={(input) => (otpInputs.current[index] = input)}
              />
            ))}
          </div>
          <div className="hstack gap-3 justify-content-end pt-2 button_type">
            <button
              onClick={() => {
                setDisable(false);
                setOtpValues(["", "", "", "", "", ""]);
                setModalSize(false);
              }}
            >
              Back
            </button>
            <button onClick={() => disableTwoFactorAuth()}>Disable 2FA</button>
          </div>
        </>
      )}
    </>
  );
};

export default TwoFactorAuthByApps;
