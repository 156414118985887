import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { authServices } from "../../APIServices/authServices";
import { addressServices } from "../../APIServices/addressServices";
import ProfilePic from "../../Component/img/profile.jpg";

const Profile = ({ userCurrentData, currentUserData, roleType }) => {
  const [fullName, setfullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [cityList, setCityList] = useState([]);
  const [cityId, setCityId] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState(null);
  const [billingState, setBillingState] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingType, setBillingType] = useState("");
  const [billingFirstName, setBillingFirstName] = useState("");
  const [billingMiddleName, setBillingMiddleName] = useState("");
  const [billingLastName, setBillingLastName] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [billingCountryId, setBillingCountryId] = useState("");
  const [billingStateId, setBillingStateId] = useState("");
  const [billingCityId, setBillingCityId] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingZipCode, setBillingZipCode] = useState("");
  const [loader, setLoader] = useState(false);

  const [statusMessage, setStatusMessage] = useState("");

  const UpdateProfile = async () => {
    setLoader(true);
    let userData = {};
    userData.fullName = firstName + "" + middleName + "" + lastName;
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.phone = phone;
    userData.gender = gender;
    userData.address = address;
    userData.countryId = countryId;
    userData.stateId = stateId;
    userData.cityId = cityId;
    userData.zipCode = zipCode;

    userData.billingFirstName = billingFirstName;
    userData.billingMiddleName = billingMiddleName;
    userData.billingLastName = billingLastName;
    userData.billingEmail = billingEmail;
    userData.billingCountryId = billingCountryId;
    userData.billingStateId = billingStateId;
    userData.billingCityId = billingCityId;
    userData.billingPhone = billingPhone;
    userData.billingAddress = billingAddress;
    userData.billingZipcode = billingZipCode;
    userData.billingType = billingType;

    let data = await authServices.updateProfile(userData);
    if (data?.statusCode) {
      currentUserData();
      setStatusMessage(data?.statusCode);
      setLoader(false);
    }
  };

  const getCountries = async () => {
    let data = await addressServices.getCountries();
    setCountryList(data?.data);
  };

  const getStates = async (id) => {
    let data = await addressServices.getStates(id);
    setStateList(data?.data);
  };

  const getCities = async (id) => {
    let data = await addressServices.getCities(id);
    setCityList(data?.data);
  };

  const findStateName = async (countryId) => {
    let data = await addressServices.findStateName(countryId);
    setState(data?.data);
  };

  const findCityName = async (stateId) => {
    let data = await addressServices.findCityName(stateId);
    setCity(data?.data);
  };

  const findBillingStateName = async (countryId) => {
    let data = await addressServices.findStateName(countryId);
    setBillingState(data?.data);
  };

  const findBillingCityName = async (stateId) => {
    let data = await addressServices.findCityName(stateId);
    setBillingCity(data?.data);
  };

  const handleImageChange = async (e) => {
    let fileData = e.target.files[0];
    if (fileData) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(fileData);
      const formData = new FormData();
      formData.append("file", fileData);
      let result = await authServices.updateProfileImage(
        formData,
        userCurrentData?._id
      );
      if (result?.statusCode === 200) {
        currentUserData();
      }
    }
  };
  useEffect(() => {
    findStateName(userCurrentData?.stateId);
    findCityName(userCurrentData?.cityId);
    setfullName(userCurrentData?.fullName);
    setFirstName(userCurrentData?.firstName);
    setMiddleName(userCurrentData?.middleName);
    setLastName(userCurrentData?.lastName);
    setGender(userCurrentData?.gender);
    setCountryId(userCurrentData?.countryId);
    setStateId(userCurrentData?.stateId);
    setCityId(userCurrentData?.cityId);
    setZipCode(userCurrentData?.zipCode);
    setPhone(userCurrentData?.phone);
    setAddress(userCurrentData?.address);
    setEmail(userCurrentData?.email);
    setBillingFirstName(userCurrentData?.billingFirstName);
    setBillingMiddleName(userCurrentData?.billingMiddleName);
    setBillingLastName(userCurrentData?.billingLastName);
    setBillingEmail(userCurrentData?.billingEmail);
    setBillingCountryId(userCurrentData?.billingCountryId);
    setBillingPhone(userCurrentData?.billingPhone);
    setBillingAddress(userCurrentData?.billingAddress);
    setBillingAddress(userCurrentData?.billingAddress);
    setImage(userCurrentData?.profileImage);

    getCountries();

    // setBillingType(userCurrentData?.billingType || "false");

    findBillingStateName(
      billingType === "true"
        ? userCurrentData?.stateId
        : userCurrentData?.billingStateId || ""
    );
    findBillingCityName(
      billingType === "true"
        ? userCurrentData?.cityId
        : userCurrentData?.billingCityId || ""
    );

    setBillingFirstName(
      billingType === "true"
        ? userCurrentData?.firstName
        : userCurrentData?.billingFirstName || ""
    );
    setBillingMiddleName(
      billingType === "true"
        ? userCurrentData?.middleName
        : userCurrentData?.billingMiddleName || ""
    );
    setBillingLastName(
      billingType === "true"
        ? userCurrentData?.lastName
        : userCurrentData?.billingLastName || ""
    );
    setBillingEmail(
      billingType === "true"
        ? userCurrentData?.email
        : userCurrentData?.billingEmail || ""
    );
    setBillingCountryId(
      billingType === "true"
        ? userCurrentData?.countryId
        : userCurrentData?.billingCountryId || ""
    );
    setBillingStateId(
      billingType === "true"
        ? userCurrentData?.stateId
        : userCurrentData?.billingStateId || ""
    );
    setBillingCityId(
      billingType === "true"
        ? userCurrentData?.cityId
        : userCurrentData?.billingCityId || ""
    );
    setBillingPhone(
      billingType === "true"
        ? userCurrentData?.phone
        : userCurrentData?.billingPhone || ""
    );
    setBillingAddress(
      billingType === "true"
        ? userCurrentData?.address
        : userCurrentData?.billingAddress || ""
    );
    setBillingZipCode(
      billingType === "true"
        ? userCurrentData?.zipCode
        : userCurrentData?.billingZipCode || ""
    );
  }, [billingType, userCurrentData]);

  const handleBillingType = (value) => {
    setBillingType(value);
    setBillingFirstName("");
    setBillingMiddleName("");
    setBillingLastName("");
    setBillingEmail("");
    setBillingCountryId("");
    setBillingStateId("");
    setBillingCityId("");
    setBillingPhone("");
    setBillingAddress("");
    setBillingZipCode("");
    setBillingState("");
    setBillingCity("");
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setError("");
    } else {
      setError("Invalid Email, Please Enter Valid Email!");
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setError("");
    } else {
      setError("Phone Number should have 10 digits ");
    }
  };
  return (
    <>
      <div className="">
        <Container fluid className="mt-2">
          <Row>
            <Col md={4}>
              <div className="details_data1">
                <div className="account_detai text-center">
                  <h6>Profile Image</h6>
                </div>
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor="imageUpload"
                      title="Upload Profile Pic"
                    ></label>
                  </div>
                  <div className="avatar-preview">
                    {image ? (
                      <img src={image} alt="Preview" />
                    ) : (
                      <img src={ProfilePic} alt="Default Preview" />
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="details_data1">
                <div className="account_detai text-center">
                  <h6>Profile Details</h6>
                </div>
                <div className="kycPageHeight">
                  <Container fluid>
                    <Row>
                      <Form.Group as={Col} md="12" className="mb-2">
                        <Form.Label>
                          Display Name(how your name apper to other user on the
                          site)
                        </Form.Label>

                        <Form.Control
                          required
                          type="text"
                          value={fullName}
                          onChange={(event) => {
                            setfullName(event.target.value);
                          }}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-2">
                        <Form.Label>
                          First Name<span className="text-danger">*</span>
                        </Form.Label>

                        <Form.Control
                          onChange={(event) => {
                            setFirstName(event.target.value);
                          }}
                          value={firstName}
                          required
                          type="text"
                          placeholder="First Name"
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-2">
                        <Form.Label>
                          Middle Name
                        </Form.Label>
                        <Form.Control
                          onChange={(event) => {
                            setMiddleName(event.target.value);
                          }}
                          value={middleName}
                          required
                          type="text"
                          placeholder="Middle Name"
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-2">
                        <Form.Label>
                          Last Name
                        </Form.Label>
                        <Form.Control
                          onChange={(event) => {
                            setLastName(event.target.value);
                          }}
                          value={lastName}
                          required
                          type="text"
                          placeholder="Last Name"
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-2">
                        <Form.Label>
                          Email<span className="text-danger">*</span>
                        </Form.Label>

                        <Form.Control
                          onChange={(e) => {
                            setEmail(e.target.value);
                            validateEmail(e.target.value);
                          }}
                          value={email}
                          disabled
                          type="email"
                          placeholder="Enter Your email id"
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-2">
                        <Form.Label>
                          Phone Number<span className="text-danger">*</span>
                        </Form.Label>

                        <Form.Control
                          onChange={(e) => {
                            setPhone(e.target.value);
                            validatePhoneNumber(e.target.value);
                          }}
                          value={phone}
                          required
                          type="number"
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-2">
                        <Form.Label>Select Gender</Form.Label>
                        <Form.Select
                          onChange={(e) => setGender(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option>Select Gender</option>
                          <option selected={gender === "male"} value="male">
                            Male
                          </option>
                          <option selected={gender === "female"} value="female">
                            Female
                          </option>
                          <option selected={gender === "other"} alue="other">
                            Other
                          </option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} md="12" className="mb-2">
                        <Form.Label>
                          Address<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          onChange={(event) => {
                            setAddress(event.target.value);
                          }}
                          value={address}
                          required
                          as="textarea"
                          placeholder="Enter your address"
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-2">
                        <Form.Label>
                          Country<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          onChange={(e) => setCountryId(e.target.value)}
                          onClick={(e) =>
                            getStates(e.target[e.target.selectedIndex].title)
                          }
                          aria-label="Default select example"
                        >
                          <option>Select Country</option>
                          {countryList?.length > 0
                            ? countryList?.map((item) => (
                                <option
                                  selected={item._id === countryId}
                                  value={item._id}
                                  title={item.id}
                                >
                                  {item.name}
                                </option>
                              ))
                            : ""}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-2">
                        <Form.Label>
                          State<span className="text-danger">*</span>
                        </Form.Label>

                        {stateList?.length > 0 ? (
                          <Form.Select
                            onChange={(e) => setStateId(e.target.value)}
                            onClick={(e) =>
                              getCities(e.target[e.target.selectedIndex].title)
                            }
                            aria-label="Default select example"
                          >
                            <option>Select State</option>
                            {stateList?.map((item) => (
                              <option
                                selected={item._id === stateId}
                                value={item._id}
                                title={item.id}
                              >
                                {item.name}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          <Form.Control type="text" disabled value={state} />
                        )}
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-2">
                        <Form.Label>
                          City<span className="text-danger">*</span>
                        </Form.Label>

                        {cityList?.length > 0 ? (
                          <Form.Select
                            onChange={(e) => setCityId(e.target.value)}
                            aria-label="Default select example"
                          >
                            <option>Select City</option>
                            {cityList?.map((item) => (
                              <option
                                selected={item._id === cityId}
                                value={item._id}
                              >
                                {item.name}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          <Form.Control type="text" disabled value={city} />
                        )}
                      </Form.Group>
                      <Form.Group as={Col} md="4" className="mb-2">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          onChange={(event) => {
                            setZipCode(event.target.value);
                          }}
                          value={zipCode}
                          type="text"
                        />
                      </Form.Group>
                    </Row>
                    {roleType === "consultant" && (
                      <Row>
                        <Form.Group as={Col} md="12" className="mb-2">
                          <div className="box d-flex">
                            <Form.Check
                              checked={billingType === "true"}
                              onClick={(e) => handleBillingType(e.target.value)}
                              value={billingType === "false" ? "true" : "false"}
                            />
                            <span>
                              <b>Billing Details</b> (if Billing Address is same
                              and above address then click checkbox)
                            </span>
                          </div>
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-2">
                          <Form.Label>
                            First Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            onChange={(event) => {
                              setBillingFirstName(event.target.value);
                            }}
                            value={billingFirstName}
                            required
                            type="text"
                            placeholder="First Name"
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-2">
                          <Form.Label>Middle Name</Form.Label>
                          <Form.Control
                            onChange={(event) => {
                              setBillingMiddleName(event.target.value);
                            }}
                            value={billingMiddleName}
                            required
                            type="text"
                            placeholder="Middle Name"
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-2">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            onChange={(event) => {
                              setBillingLastName(event.target.value);
                            }}
                            value={billingLastName}
                            required
                            type="text"
                            placeholder="Last Name"
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-2">
                          <Form.Label>
                            Email <span className="text-danger">*</span>
                          </Form.Label>

                          <Form.Control
                            onChange={(e) => {
                              setBillingEmail(e.target.value);
                              validateEmail(e.target.value);
                            }}
                            value={billingEmail}
                            type="email"
                            placeholder="Enter Your email id"
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-2">
                          <Form.Label>
                            Phone Number <span className="text-danger">*</span>
                          </Form.Label>

                          <Form.Control
                            onChange={(e) => {
                              setBillingPhone(e.target.value);
                              validatePhoneNumber(e.target.value);
                            }}
                            value={billingPhone}
                            required
                            type="number"
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-2">
                          <Form.Label>
                            Country <span className="text-danger">*</span>
                          </Form.Label>

                          <Form.Select
                            onChange={(e) =>
                              setBillingCountryId(e.target.value)
                            }
                            onClick={(e) =>
                              getStates(e.target[e.target.selectedIndex].title)
                            }
                            aria-label="Default select example"
                          >
                            <option>Select Country</option>
                            {countryList?.length > 0
                              ? countryList?.map((item) => (
                                  <option
                                    selected={item._id === billingCountryId}
                                    value={item._id}
                                    title={item.id}
                                  >
                                    {item.name}
                                  </option>
                                ))
                              : ""}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-2">
                          <Form.Label>
                            State <span className="text-danger">*</span>
                          </Form.Label>

                          {stateList?.length > 0 ? (
                            <Form.Select
                              onChange={(e) =>
                                setBillingStateId(e.target.value)
                              }
                              onClick={(e) =>
                                getCities(
                                  e.target[e.target.selectedIndex].title
                                )
                              }
                              aria-label="Default select example"
                            >
                              <option>Select State</option>
                              {stateList?.map((item) => (
                                <option
                                  selected={item._id === billingStateId}
                                  value={item._id}
                                  title={item.id}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </Form.Select>
                          ) : (
                            <Form.Control
                              disabled
                              type="text"
                              value={billingState}
                            />
                          )}
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-2">
                          <Form.Label>
                            City <span className="text-danger">*</span>
                          </Form.Label>
                          {cityList?.length > 0 ? (
                            <Form.Select
                              onChange={(e) => setBillingCityId(e.target.value)}
                              aria-label="Default select example"
                            >
                              <option>Select City</option>
                              {cityList?.map((item) => (
                                <option
                                  selected={item._id === billingCityId}
                                  value={item._id}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </Form.Select>
                          ) : (
                            <Form.Control
                              disabled
                              type="text"
                              value={billingCity}
                            />
                          )}
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-2">
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control
                            onChange={(event) => {
                              setBillingZipCode(event.target.value);
                            }}
                            value={billingZipCode}
                            required
                            type="text"
                          />
                        </Form.Group>
                        <Form.Group as={Col} xl={12} lg={8} md={8} sm={12} className="mb-2">
                          <Form.Label>
                            Address<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            onChange={(event) => {
                              setBillingAddress(event.target.value);
                            }}
                            value={billingAddress}
                            required
                            as="textarea"
                            placeholder="Enter your address"
                          />
                        </Form.Group>
                      </Row>
                    )}
                  </Container>
                </div>
                <div className="button_type pt-2 border-top">
                  <div className="hstack justify-content-between">
                    <div className="hstack justify-content-between">
                      {error ? (
                        <p className="message m-0" style={{ color: "red" }}>
                          {error}
                        </p>
                      ) : (
                        <p
                          className="message m-0"
                          style={
                            statusMessage?.statusCode === 200
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {statusMessage?.customMessage}
                        </p>
                      )}
                    </div>
                    <button
                      className="m-0 hstack gap-2"
                      onClick={UpdateProfile}
                      disabled={error}
                    >
                      <Spinner
                        as="span"
                        animation={loader && "border"}
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Profile;
