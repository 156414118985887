import React from "react";
import { Outlet, Navigate } from "react-router";
import { getStore } from "../Utils/UniversalFunction";
import OtpVerification from "../Component/Auth/OtpVerification";
import ChangePassword from "../Component/Auth/ChangePassword";
import SubscriptionBasePlan from "../Component/Auth/Subscription/SubscriptionBasePlan";

const auth = getStore("token");

const currentUrl = window.location.href;
const splitUrl = currentUrl?.split("/");
const currentPath = splitUrl[3];

const DashboardPermission = ({
  twoFA,
  authType,
  email,
  roleType,
  currentUserData,
  passwordUpdate,
  subscriptionExpiry
}) => {
  const expiryDate = new Date(subscriptionExpiry ? subscriptionExpiry : "01-01-1950");
  const currentDate = new Date();
  const subscriptionCheck = expiryDate.toISOString() > currentDate.toISOString();

  if (auth) {
    return twoFA ? (
      <OtpVerification
        authType={authType}
        currentUserData={currentUserData}
        email={email}
      />
    ) : !passwordUpdate ? (
      <ChangePassword currentUserData={currentUserData} />
    ) : roleType === "consultant" ? (
      subscriptionCheck ? <Outlet to="/home" />
        : <SubscriptionBasePlan currentUserData={currentUserData} />
    ) : (
      <Outlet to="/home" />
    );
  } else {
    return (window.location = "/");
  }
};

const LoginPermission = () => {
  if (!auth) {
    if (currentPath === "login") {
      return <Outlet to="/login" />;
    } else if (currentPath === "forget-password") {
      return <Outlet to="/forget-password" />;
    } else if (currentPath === "otp-verification") {
      return <Outlet to="/otp-verification" />;
    } else if (currentPath.split("=")[0] === "resetPassword?token") {
      return <Outlet to="/resetPassword" />;
    } else if (currentPath === "sign-up") {
      return <Outlet to="/sign-up" />;
    } else if (currentPath === "") {
      return <Outlet to="/" />;
    }
  } else {
    if (
      currentPath === "" ||
      currentPath === "login" ||
      currentPath === "forget-password" ||
      currentPath === "otp-verification" ||
      currentPath === "resetPassword" ||
      currentPath === "sign-up"
    ) {
      return <Navigate to="/home" />;
    }
  }
};

export const Permission = {
  LoginPermission,
  DashboardPermission,
};
