import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Col, Form, Spinner } from "react-bootstrap";
import { libraryServices } from "../../../APIServices/Editor/libraryServices";

const ExportLibraryData = ({
  show,
  setShow,
  onStart,
  draggleRef,
  bounds,
  onMouseOver,
  onMouseOut,
  disabled,
  title,
  submit,
  libraryId,
}) => {
  let [extension, setExtension] = useState("");
  const [loader, setLoader] = useState(false);

  const exportLibraryFileMultiFormat = async () => {
    setLoader(true);
    let userData = {};
    userData.extension = extension;
    userData.libraryId = libraryId;
    let data = await libraryServices.exportLibraryFileMultiFormat(userData);
    if (data?.data) {
      setShow();
      setLoader(false);
    }
  };
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={350}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div>
          <div className="common_css table_content">
            <Form.Group as={Col} md="12" className="mb-1">
              <Form.Label>
                Format <span>*</span>
              </Form.Label>
              <Form.Select
                onChange={(e) => setExtension(e.target.value)}
                aria-label="Default select example"
                style={{ fontSize: "13px" }}
              >
                <option>Select Format</option>
                <option value="csv">CSV</option>
                <option value="excel">Excel</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="button_type footer_btn text-end px-3 pt-2">
            <button
              disabled={!extension}
              onClick={exportLibraryFileMultiFormat}
              className="mb-2"
            >
              {loader ? (
                <Spinner
                  animation="border"
                  style={{ width: 13, height: 13 }}
                  className="me-1"
                />
              ) : null}
              {submit}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ExportLibraryData;
