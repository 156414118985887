import React from "react";
import { Modal } from "antd";
import Moment from "react-moment";
import Draggable from "react-draggable";
import { Table } from "react-bootstrap";
const ViewLibraryGeneralFileProperty = ({
  show,
  setShow,
  onStart,
  draggleRef,
  bounds,
  onMouseOver,
  onMouseOut,
  disabled,
  title,
  libraryFileData
}) => {

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Table>
            <thead className="headerFixed">
              <tr>
                <th>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>File Name</td>
                <td>{libraryFileData?.fileName}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{libraryFileData?.description}</td>
              </tr>
              <tr>
                <td>Path</td>
                <td>{`/library/${libraryFileData?.folderName}/${libraryFileData?.fileName}`}</td>
              </tr>
              <tr>
                <td>Row</td>
                <td>{libraryFileData?.noofrow}</td>
              </tr>
              <tr>
                <td>Column</td>
                <td>{libraryFileData?.noofcolumn}</td>
              </tr>
              <tr>
                <td>Created Date | Time</td>
                <td><Moment format="DD MMM YYYY | HH:mm" withTitle>
                  {libraryFileData?.createdAt}
                </Moment></td>
              </tr>
              <tr>
                <td>Updated Date | Time</td>
                <td><Moment format="DD MMM YYYY | HH:mm" withTitle>
                  {libraryFileData?.updatedAt}
                </Moment></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
    </>
  );
};
export default ViewLibraryGeneralFileProperty;
