import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Validatortable from "../Packages/PackageRecentHistory";
import AllLogsData from "../../DashboardCommonData/DashboardLogsData";
import RecentActivity from "../../DashboardCommonData/RecentActivity";
import AnalyticsData from "./AnalyticsData";

const AdminValidatorDashboard = ({ handleTabChange, editorFullMode }) => {
  return (
    <>
      <div
        className={
          editorFullMode
            ? "dashboardFullHeight pb-5"
            : "dashboard_Section_height"
        }
      >
        <Container fluid>
          <Row>
            <Col md={12}>
              <AnalyticsData />
            </Col>
            <div className="graph__title my-2">
              <p className="m-0">Recent Work Activity </p>
            </div>
            <Col xl={8} lg={8} md={8} sm={12} className="mb-3">
              <Card className="shadow-sm border-0 h-100">
                <Card.Body>
                  <Validatortable
                    Validtable
                    handleTabChange={handleTabChange}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} lg={12} md={12} sm={12} className="mb-3">
              <Card className="shadow-sm border-0 h-100">
                <Card.Body>
                  <div>
                    <p
                      className="m-0"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Recent Activity
                    </p>
                  </div>
                  <div className="m-0">
                    <div style={{ height: 330, overflow: "auto" }}>
                      <RecentActivity />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <AllLogsData handleTabChange={handleTabChange} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AdminValidatorDashboard;
