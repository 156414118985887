import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { authServices } from "../../APIServices/authServices";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");

  const forgotPassword = async () => {
    let userData = {};
    userData.email = email;
    let data = await authServices.forgotPassword(userData);
    if (data?.statusCode === 200) {
      window.location.href = "/login";
    }
  };

  return (
    <>
      <div className="log_url">
        <div className="forms_details">
          <div className="login_box">
            <div className="Welcome_to">
              <h2>Forget Password</h2>
              <p>
                When you provide your email address, we'll send you a message
                with information on how to reset.
              </p>
            </div>
            <div>
              <Form>
                <Form.Label>Email ID</Form.Label>
                <InputGroup className="mb-4">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa-solid fa-envelope"></i>
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Enter your email id"
                    aria-label="email"
                    aria-describedby="basic-addon1"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <button className="button_sign_log " disabled ={!email}
                onClick={forgotPassword}>
                  Link to Reset Password
                </button>
              </Form>
              <NavLink to={"/login"}>
                <div
                  className="text-end mt-1"
                  style={{
                    cursor: "pointer",
                    color: "#103c5e",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Back to login
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
