import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form, Spinner } from "react-bootstrap";
import { consultantServices } from "../../../APIServices/Editor/consultantServices";
const AddNewProjectModal = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  getConsultantProjectFolder,
}) => {
  let [projectName, setProjectName] = useState("")
  let [description, setDescription] = useState("")
  let [sponsorName, setSponsorName] = useState("")
  let [projectId, setProjectId] = useState("")
  let [studyId, setStudyId] = useState("")
  const [loader, setLoader] = useState(false);

  const addNewConsultantProject = async () => {
    setLoader(true);
    let userData = {};
    userData.name = projectName;
    userData.description = description;
    userData.sponsorName = sponsorName;
    userData.projectLabelId = projectId;
    userData.studyLabelId = studyId;
    let data = await consultantServices.addNewConsultantProject(userData);
    if (data?.statusCode === 200) {
      setShow(false);
      setLoader(false);
      setProjectName("");
      setDescription("");
      setSponsorName("");
      setProjectId("");
      setStudyId("");
      getConsultantProjectFolder();
    }
  };

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={500}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Form.Label>
            Project Name<span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            placeholder="Enter Project Name"
            autoFocus
          />
          <Form.Label className="mt-3">
            Sponsor Name<span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={sponsorName}
            onChange={(e) => setSponsorName(e.target.value)}
            placeholder="Enter Sponsor Name"
            autoFocus
          />
          <Form.Label className="mt-3">
            Project Id<span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={projectId}
            onChange={(e) => setProjectId(e.target.value)}
            placeholder="Enter Project Id"
            autoFocus
          />
          <Form.Label className="mt-3">
            Study Id<span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={studyId}
            onChange={(e) => setStudyId(e.target.value)}
            placeholder="Enter Study Id"
            autoFocus
          />
          <Form.Label className="mt-3">
            Description<span>*</span>
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Project Description"
            autoFocus
          />
        </div>
        <hr className="w-100 m-0" />
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            disabled={!projectName || !sponsorName || !projectId || !studyId || !description}
            onClick={addNewConsultantProject}
          >
            {loader ? (
                <Spinner
                  animation="border"
                  style={{ width: 13, height: 13 }}
                  className="me-1"
                />
              ) : null}
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default AddNewProjectModal;
