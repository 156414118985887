import axios from "axios";
import { headers } from "../../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../APIHelpers/Responses";
import config from "../../config/config.json";

export const packagesServices = {
  getPackageFolderLists,
  addNewPackageFolder,
  deletePackageFolderData,
  updatePackagesData,
  openPackageSubFolderById,
  openPackageFileById,
  checkOutPackageFiles,
  checkInPackageFiles,
  updatePackageFileCode,
  uploadFileinLibrary,
  uploadPackageDocument,
  getPackageFileCodeById,
  getPackageInformation,
  updateLibraryFolderData,
  duplicateLibraryFolderAndData,
  deleteLibraryFolderAndData,
  getPackageDocumentLists,
  updatePackagesDocumentName,
  deletePackageDocumentData,
  getPackageFileCodeVersionById,
  runDeveloperPythonCode,
  runDeveloperGASCode,
  runGASCodeWithMultiFunction,
  getPackageFileOutputById,
  getPackageFileLogsById,
  getPackageCodeByVersion,
  getActivePackageCategory,
  getPackageTagList,
  publishPackagesDataForDeveloper,
  addUpdateSyntaxFunctionForPython,
  getPackageStatements,
  packageFunctionValidationStatus,
  addExampleForPackageValidation,
  getExampleForPackageValidation,
  deleteExampleForPackageValidation,
  getSoftwareForPackageValidation,
  updateSoftwareValidationOutputFile,
  removeAddsoftwareValidationForPackage,
  removeFilesoftwareValidationForPackage,
  updateSoftwareValidationOutputFileURL,
  updateSoftwareValidationData,
  packageValidationStatus,
  getPublishedPackageListForConsultant,
  searchPublishedPackageListForConsultant,
  getPublishedPackageDetailsForConsultant,
  addPackageExampleForConsulatnt,
  getPackageExampleForConsultant,
  getPackageExampleForPackageList,
  deletePackageExampleForConsultant,
  updateDeveloperStatusInRequestVersionPackage,
  updatePackageFunctionValidationStatus,
  packageValidationStatusForUpdatePackage,
};

// get packages folder list

async function getPackageFolderLists() {
  const response = await fetch(
    `${config.API_URL}getPackageFolderLists`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// get packages file list
async function openPackageSubFolderById(packageId) {
  const response = await fetch(
    `${config.API_URL}openPackageSubFolderById/${packageId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// get open package file list
async function openPackageFileById(folderId) {
  const response = await fetch(
    `${config.API_URL}openPackageFileById/${folderId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//   Add new packages folder
async function addNewPackageFolder(userData) {
  const response = await fetch(
    `${config.API_URL}addNewPackageFolder`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//   delete packages
async function deletePackageFolderData(packageId) {
  const response = await fetch(
    `${config.API_URL}deletePackageFolderData/${packageId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// update Data
async function updatePackagesData(userData) {
  const response = await fetch(
    `${config.API_URL}updatePackagesData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Package Code
async function updatePackageFileCode(userData) {
  const response = await fetch(
    `${config.API_URL}updatePackageFileCode`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// checkIn Sub file
async function checkInPackageFiles(userData) {
  const response = await fetch(
    `${config.API_URL}checkInPackageFiles`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// checkOut Sub file
async function checkOutPackageFiles(userData) {
  const response = await fetch(
    `${config.API_URL}checkOutPackageFiles`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//  upload File in Library,
async function uploadFileinLibrary(formData) {
  const response = await axios.post(
    `${config.API_URL}uploadFileinLibrary`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}

//  Upload Document in Package,
async function uploadPackageDocument(formData) {
  const response = await axios.post(
    `${config.API_URL}uploadPackageDocument`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}

// get packages file list
async function getPackageFileCodeById(fileId) {
  const response = await fetch(
    `${config.API_URL}getPackageFileCodeById/${fileId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// get packages information
async function getPackageInformation(fileId) {
  const response = await fetch(
    `${config.API_URL}getPackageInformation/${fileId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//   Delete Library Data
async function deleteLibraryFolderAndData(libraryId) {
  const response = await fetch(
    `${config.API_URL}deleteLibraryFolderAndData/${libraryId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//   Duplicate Library Data
async function duplicateLibraryFolderAndData(userData) {
  const response = await fetch(
    `${config.API_URL}duplicateLibraryFolderAndData`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Library Data
async function updateLibraryFolderData(userData) {
  const response = await fetch(
    `${config.API_URL}updateLibraryFolderData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Package Document List
async function getPackageDocumentLists(docFolderId, packageId) {
  const response = await fetch(
    `${config.API_URL}getPackageDocumentLists/${docFolderId}/${packageId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//   Update Package Document Data
async function updatePackagesDocumentName(userData) {
  const response = await fetch(
    `${config.API_URL}updatePackagesDocumentName`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//   Delete Library Data
async function deletePackageDocumentData(id) {
  const response = await fetch(
    `${config.API_URL}deletePackageDocumentData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Package File Code Version bY ID
async function getPackageFileCodeVersionById(id) {
  const response = await fetch(
    `${config.API_URL}getPackageFileCodeVersionById/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Run Python Developer Code
async function runDeveloperPythonCode(userData) {
  const response = await fetch(
    `${config.API_URL}runDeveloperPythonCode`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Run GAS Validator Code
async function runDeveloperGASCode(userData) {
  const response = await fetch(
    `${config.API_URL}runDeveloperGASCode`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Run GAS Cunsultant Code
async function runGASCodeWithMultiFunction(userData) {
  const response = await fetch(
    `${config.API_URL}runGASCodeWithMultiFunction`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Package File Output by Id
async function getPackageFileOutputById(fileId) {
  const response = await fetch(
    `${config.API_URL}getPackageFileOutputById/${fileId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Package File Log by Id
async function getPackageFileLogsById(fileId) {
  const response = await fetch(
    `${config.API_URL}getPackageFileLogsById/${fileId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Package File Log by Id
async function getPackageCodeByVersion(id, version) {
  const response = await fetch(
    `${config.API_URL}getPackageCodeByVersion/${id}/${version}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Package Category List
async function getActivePackageCategory() {
  const response = await fetch(
    `${config.API_URL}getActivePackageCategory`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Package Tag List
async function getPackageTagList() {
  const response = await fetch(
    `${config.API_URL}getPackageTagList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// update Data
async function publishPackagesDataForDeveloper(userData) {
  const response = await fetch(
    `${config.API_URL}publishPackagesDataForDeveloper`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Package Statements
async function getPackageStatements(fileId) {
  const response = await fetch(
    `${config.API_URL}getPackageStatements/${fileId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add Update Syntax Function 
async function addUpdateSyntaxFunctionForPython(userData) {
  const response = await fetch(
    `${config.API_URL}addUpdateSyntaxFunctionForPython`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Package Function Validation Status
async function packageFunctionValidationStatus(userData) {
  const response = await fetch(
    `${config.API_URL}packageFunctionValidationStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Package Validation Status
async function packageValidationStatus(userData) {
  const response = await fetch(
    `${config.API_URL}packageValidationStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add Example For Package Validation
async function addExampleForPackageValidation(formData) {
  const response = await axios.post(
    `${config.API_URL}addExampleForPackageValidation`,
    formData
  );
  const data = response?.data;
  handleResponse(data);
  return data;
}

// Get Example For Package Validation
async function getExampleForPackageValidation(fileId) {
  const response = await fetch(
    `${config.API_URL}getExampleForPackageValidation/${fileId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Software For Package Validation
async function getSoftwareForPackageValidation(fileId) {
  const response = await fetch(
    `${config.API_URL}getSoftwareForPackageValidation/${fileId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Published Package List For Consultant
async function getPublishedPackageListForConsultant() {
  const response = await fetch(
    `${config.API_URL}getPublishedPackageListForConsultant`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Published Package Details For Consultant
async function getPublishedPackageDetailsForConsultant(packageId) {
  const response = await fetch(
    `${config.API_URL}getPublishedPackageDetailsForConsultant/${packageId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Example for Package Validation
async function deleteExampleForPackageValidation(packageId) {
  const response = await fetch(
    `${config.API_URL}deleteExampleForPackageValidation/${packageId}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

// Remove & Add Software For Package Validation
async function removeAddsoftwareValidationForPackage(userData) {
  const response = await fetch(
    `${config.API_URL}removeAddsoftwareValidationForPackage`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Remove Output Software For Package Validation
async function removeFilesoftwareValidationForPackage(userData) {
  const response = await fetch(
    `${config.API_URL}removeFilesoftwareValidationForPackage`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Output Software For Package Validation
async function updateSoftwareValidationOutputFileURL(userData) {
  const response = await fetch(
    `${config.API_URL}updateSoftwareValidationOutputFileURL`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Software For Package Validation
async function updateSoftwareValidationData(userData) {
  const response = await fetch(
    `${config.API_URL}updateSoftwareValidationData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Profile Image
async function updateSoftwareValidationOutputFile(formData) {
  const response = await axios.post(`${config.API_URL}updateSoftwareValidationOutputFile`, formData)
  const data = response?.data;
  return data;
}

//  Search Published Package List For Consultant
async function searchPublishedPackageListForConsultant(userData) {
  const response = await fetch(
    `${config.API_URL}searchPublishedPackageListForConsultant`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add Package Example For Package Consultant
async function addPackageExampleForConsulatnt(userData) {
  const response = await fetch(
    `${config.API_URL}addPackageExampleForConsulatnt`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Package Example For Consultant
async function getPackageExampleForConsultant(id) {
  const response = await fetch(
    `${config.API_URL}getPackageExampleForConsultant/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Package Example For Package Lisr
async function getPackageExampleForPackageList(id) {
  const response = await fetch(
    `${config.API_URL}getPackageExampleForPackageList/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Example For Package Consultant
async function deletePackageExampleForConsultant(id) {
  const response = await fetch(
    `${config.API_URL}deletePackageExampleForConsultant/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

// Update Package Developer Status Update Package Version Data
async function updateDeveloperStatusInRequestVersionPackage(userData) {
  const response = await fetch(
    `${config.API_URL}updateDeveloperStatusInRequestVersionPackage`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Package Function Validation Status
async function updatePackageFunctionValidationStatus(userData) {
  const response = await fetch(
    `${config.API_URL}updatePackageFunctionValidationStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Package Function Final Validation Status
async function packageValidationStatusForUpdatePackage(userData) {
  const response = await fetch(
    `${config.API_URL}packageValidationStatusForUpdatePackage`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}