import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div className="header-bg Footer_section">
      <Container fluid>
        <div className="hstack justify-content-between">
          <h2 className="m-0 py-2">GAS</h2>
          <p className="m-0">
            <small>Copyright © {year} - GAS | Galax Biotech</small>
          </p>
          <p className="m-0">V1.0.0</p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
