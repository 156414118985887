import React from "react";
import { Table } from "react-bootstrap";
import CompanyLogo from "../../image/Logo.png";

const SubscriptionInvoiceReceipt = () => {
  const handlePrint = () => {
    window.print();
  };

  const currentItems = [
    {
      id: 1,
      planName: "Basic Plan",
      planType: "Yearly",
      price: 45.0,
      quantity: 1,
      tracking_code: "DKJHGET8T39873T34T73548",
      discount: 10,
      total: 45.0,
    },
  ];

  const couponDiscount = 15;
  const subtotal = currentItems.reduce(
    (acc, item) => acc + (item.price - item.discount),
    0
  );
  const cgst = (subtotal * 9) / 100;
  const sgst = (subtotal * 9) / 100;
  const total = subtotal + cgst + sgst - couponDiscount;
  return (
    <>
      <div
        style={{ height: "100vh", overflow: "auto" }}
        className="position-relative"
      >
        <Table style={{margin: 0}} className="table_content">
          <tbody>
            <Table style={{margin: 0}} className="table_content">
              <tr>
                <td>
                  <h6 style={{ fontSize: 20 }}>
                    <b>Order Id. : #19548</b>
                  </h6>
                </td>
                <td>
                  <div className="text-end">
                    <h6 style={{ fontSize: 20 }}>
                      <b>Invoice No. : 2024_000001</b>
                    </h6>
                    <label>
                      <b>Invoice Date : 30/04/2024</b>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 style={{ fontSize: 15 }}>
                    <img
                      style={{ width: 80 }}
                      src={CompanyLogo}
                      alt="Company Logo"
                    />
                  </h6>
                </td>
                <td>
                  <h6 style={{ fontSize: 15 }}>
                    <b>Billing Details</b>
                  </h6>
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>
                  <label>
                    <b>Seller Name :</b> Galax Biotech
                  </label>
                  <br />
                  <label>
                    <b>Email :</b> hulimavu@gmail.com
                  </label>
                  <br />
                  <label>
                    <b>Address :</b> 14,1st Cross Rd, Bannerghatta Rd, behind
                    New Era Girls Hoste, Janatha Colony, Hulimavu, Bengaluru,
                    Karnataka 560076•099640 36984
                  </label>
                  <br />
                  <label>
                    <b>GSTIN No. :</b> 16543adcc65s4dc3a4d
                  </label>
                  <br />
                  <label>
                    <b>State Name :</b> Karnataka
                  </label>
                  <br />
                  <label>
                    <b>State Code :</b> 0121343
                  </label>
                  <br />
                </td>
                <td>
                  <label>
                    <b>Buyer Name :</b> Gaurav Chauhan
                  </label>
                  <br />
                  <label>
                    <b>Email :</b> hulimavu@gmail.com
                  </label>
                  <br />
                  <label>
                    <b>Phone :</b> +91 8597584685
                  </label>
                  <label>
                    <b>Address :</b> 14,1st Cross Rd, Bannerghatta Rd, behind
                    New Era Girls Hoste, Janatha Colony, Hulimavu, Bengaluru,
                    Karnataka 560076•099640 36984
                  </label>
                  <br />
                  <label>
                    <b>GSTIN No. :</b> 16543adcc65s4dc3a4d
                  </label>
                  <br />
                  <label>
                    <b>State Name :</b> Karnataka
                  </label>
                  <br />
                  <label>
                    <b>State Code :</b> 0121343
                  </label>
                  <br />
                </td>
              </tr>
            </Table>
            <Table style={{margin: 0}} className="table_content">
              <thead>
                <tr>
                  <th style={{ width: 40 }}>Sr.</th>
                  <th>Plan Name</th>
                  <th>Plan Type</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>HSN/SAC</th>
                  <th style={{ width: 60 }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.planName}</td>
                    <td>{item.planType}</td>
                    <td>${item.price}</td>
                    <td>${item.discount}</td>
                    <td>{item.tracking_code}</td>
                    <td>${(item.price - item.discount) * item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Table style={{margin: 0}} className="table_content">
              <tbody>
                <tr>
                  <td>
                    <div className="text-end me-3">
                      <label>
                        <b>Subtotal :</b> ${subtotal.toFixed(2)}
                      </label>
                      <br />
                      <label>
                        <b>Coupon Discount :</b> ${couponDiscount.toFixed(2)}
                      </label>
                      <br />
                      <label>
                        <b>CGST :</b> ${cgst.toFixed(2)}
                      </label>
                      <br />
                      <label>
                        <b>SGST :</b> ${sgst.toFixed(2)}
                      </label>
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="text-end me-3">
                      <label>
                        <b>Total :</b> ${total.toFixed(2)}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Thanks for shopping with us.</label>
                    <br />
                    <label>
                      <i
                        className="fa-solid fa-heart me-1"
                        style={{ color: "red" }}
                      ></i>
                      with GAS Team
                    </label>
                    <br />
                  </td>
                </tr>
              </tbody>
            </Table>
          </tbody>
        </Table>
        <div className="button_type">
        <button
          className="printFloatingButton p-3 py-2 printButton"
          onClick={handlePrint}
          title="Print Invoice"
        >
          <i className="fa-solid fa-print me-1"></i>Print Invoice
        </button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionInvoiceReceipt;
