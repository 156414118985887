import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { consultantServices } from "../../APIServices/Editor/consultantServices";

const ConsultantPackageList = ({
  width,
  isFullScreen,
  setIsFullScreen,
  editorFullMode,
  handleTabChange,
  editorTheme
}) => {
  const [basePackageList, setBasePackageList] = useState([]);
  const [purchasedPackageList, setPurchasedPackageList] = useState([]);
  const [activeTab, setActiveTab] = useState("base");
  const getBasePackageForUseConsultant = async () => {
    let data = await consultantServices.getBasePackageForUseConsultant();
    setBasePackageList(data?.data);
  };

  const getPurchasedPackageForUseConsultant = async () => {
    setPurchasedPackageList()
  };

  const importPackageForUse = async (packageId, packageName, fileId) => {
    let userData = {}
    userData.type = activeTab
    userData.packageId = packageId
    userData.packageName = packageName
    userData.fileId = fileId
    let data = await consultantServices.importPackageForUse(userData)
    if (data?.statusCode === 200) {
      getBasePackageForUseConsultant();
      getPurchasedPackageForUseConsultant();
    }
  }

  useEffect(() => {
    getBasePackageForUseConsultant();
  }, []);

  const renderPackages = () => {
    if (activeTab === "base") {
      return (
        <div className="p-3">
          {basePackageList?.map((item, index) => (
            <div key={index} className="mb-2">
              <Form.Label><b>{index + 1}. </b>{item.packageName}</Form.Label>
              {(item?.packageVersion)?.map((data, key) => (
                <div className="hstack gap-2">
                  <Form.Check
                    type="radio"
                    id={`${index}_packageId_${key}`}
                    name={`${index}_packageName_${key}`}
                    checked={data.status}
                    onChange={() => importPackageForUse(item.packageId, item.packageName, data.fileId)}
                    label={`v${data.version}`}
                  />
                  <div className="infoCircle" title="View Details">
                    <i
                      className="fa-solid fa-info"
                      onClick={(e) =>
                        handleTabChange(
                          e,
                          item.packageName,
                          item.packageId,
                          "fa-solid fa-cube",
                          "consultantPackageList"
                        )
                      }
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="p-3">
          <div className="mb-2">
            <Form.Label>Make Merge (Expiry Date: 12/05/2026)</Form.Label>
            <div className="hstack gap-2">
              <Form.Check
                type="radio"
                id="Expiry1"
                name="Expiry"
                label="v1.0.0"
              />
              <div className="infoCircle" title="View Details">
                <i className="fa-solid fa-info"></i>
              </div>
            </div>
            <div className="hstack gap-2">
              <Form.Check
                type="radio"
                id="Expiry2"
                name="Expiry"
                label="v1.0.0"
              />
              <div className="infoCircle" title="View Details">
                <i className="fa-solid fa-info"></i>
              </div>
            </div>
            <div className="hstack gap-2">
              <Form.Check
                type="radio"
                id="Expiry3"
                name="Expiry"
                label="v1.0.0"
              />
              <div className="infoCircle" title="View Details">
                <i className="fa-solid fa-info"></i>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <Form.Label>Make Merge (Expired)</Form.Label>
            <div className="hstack gap-2">
              <Form.Check
                type="radio"
                id="Expired1"
                name="Expired"
                label="v1.0.0"
              />
              <div className="infoCircle" title="View Details">
                <i className="fa-solid fa-info"></i>
              </div>
            </div>
            <div className="hstack gap-2">
              <Form.Check
                type="radio"
                id="Expired2"
                name="Expired"
                label="v1.0.0"
              />
              <div className="infoCircle" title="View Details">
                <i className="fa-solid fa-info"></i>
              </div>
            </div>
            <div className="hstack gap-2">
              <Form.Check
                type="radio"
                id="Expired3"
                name="Expired"
                label="v1.0.0"
              />
              <div className="infoCircle" title="View Details">
                <i className="fa-solid fa-info"></i>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div
        className={`p-0 output__section_widget ${!editorFullMode ? "" : "fullModeHeight"} ${!editorTheme ? "outputShadowEditorTheme" : "outputShadow"}`}
        style={{
          width: isFullScreen
            ? "98%"
            : width <= 1100
              ? 1500 - width + "px"
              : "400px",
        }}
      >
        <div
          className="border-bottom p-2"
          style={{ background: "#ebebeb", position: "relative" }}
        >
          <div className="hstack gap-2 header_tool justify-content-between">
            <p className="m-0">
              <b>Packages</b>
            </p>
            <div className="hstack gap-1">
              <i
                className={
                  isFullScreen ? "fa-solid fa-minimize" : "fa-solid fa-maximize"
                }
                title={isFullScreen ? "Minimized Mode" : "Full Mode"}
                onClick={() => setIsFullScreen(!isFullScreen)}
              ></i>
            </div>
          </div>
        </div>
        <div
          className={`px-2 pt-2 ${!editorFullMode ? "output__section_widget_height" : "output__section_widget_full_height"}`}
          style={{ background: "#fff" }}
        >
          <div className="hstack justify-content-center gap-2">
            <button
              className={`inactiveButton ${activeTab === "base" ? "activeButton" : ""}`}
              onClick={() => { setActiveTab("base"); getBasePackageForUseConsultant() }}
            >
              Base Package
            </button>
            <button
              className={`inactiveButton ${activeTab === "purchased" ? "activeButton" : ""}`}
              onClick={() => { setActiveTab("purchased"); getPurchasedPackageForUseConsultant() }}
            >
              Purchased Package
            </button>
          </div>
          {renderPackages()}
        </div>
      </div>
    </>
  );
};

export default ConsultantPackageList;
