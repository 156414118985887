import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Permission } from "./ProtectRoute/PermissionRoutes";
import { authServices } from "./APIServices/authServices";
import { getStore } from "./Utils/UniversalFunction";
import SignUp from "./Component/Auth/SignUp";
import Login from "./Component/Auth/Login";
import ForgetPassword from "./Component/Auth/ForgetPassword";
import ResetPassword from "./Component/Auth/ResetPassword";
import OtpVerification from "./Component/Auth/OtpVerification";
import ChangePassword from "./Component/Auth/ChangePassword";
import ControlPanel from "./Page/ControlPanel";
import NotFoundPage from "./Component/PageNotFound";
import "./App.css";
import InvitationForm from "./Page/Invitation/InvitationForm";
import SuccessPayment from "./Component/Auth/SuccessPayment";
import FailedPayment from "./Component/Auth/FailedPayment";
import SubscriptionInvoiceReceipt from "./Component/Auth/SubscriptionInvoiceReceipt";

export default function App() {
  const auth = getStore("token");
  const { LoginPermission, DashboardPermission } = Permission;
  const [twoFA, setTwoFA] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [email, setEmail] = useState("");
  const [authType, setAuthType] = useState("");
  const [subscriptionExpiry, setSubscriptionExpiry] = useState("");
  const [userCurrentData, setUserCurrentData] = useState("");

  let currentUserData = async () => {
    let result = await authServices.getCurrectLoginUser();
    let data = result?.currentData;
    setRoleType(data?.roleType);
    setEmail(data?.email);
    setTwoFA(data?.twoFA);
    setPasswordUpdate(data?.isUpdatePassword);
    setAuthType(data?.authType);
    setUserCurrentData(data);
    setSubscriptionExpiry(data?.subscriptionExpiry);
  };

  useEffect(() => {
    if (auth) {
      currentUserData();
    }
  }, [auth]);

  return (
    <>
      <Routes>
        <Route path="/*" element={<NotFoundPage />} />

        <Route path="/" element={<LoginPermission />}>
          <Route path="/" element={<Login />} />
        </Route>

        <Route path="/login" element={<LoginPermission />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route
          path="/subscription-payment-success/:uniqueId"
          element={<SuccessPayment />}
        />
        <Route
          path="/subscription-payment-cancel/:uniqueId"
          element={<FailedPayment />}
        />

        <Route path="/forget-password" element={<LoginPermission />}>
          <Route path="/forget-password" element={<ForgetPassword />} />
        </Route>

        <Route path="/invitation/:token" element={<InvitationForm />} />

        <Route path="/change-password" element={<LoginPermission />}>
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>

        <Route path="/resetPassword" element={<LoginPermission />}>
          <Route path="/resetPassword" element={<ResetPassword />} />
        </Route>

        <Route path="/otp-verification" element={<LoginPermission />}>
          <Route path="/otp-verification" element={<OtpVerification />} />
        </Route>

        <Route path="/sign-up" element={<LoginPermission />}>
          <Route path="/sign-up" element={<SignUp />} />
        </Route>

        <Route
          path="/home"
          element={
            <DashboardPermission
              twoFA={twoFA}
              authType={authType}
              email={email}
              roleType={roleType}
              subscriptionExpiry={subscriptionExpiry}
              passwordUpdate={passwordUpdate}
              currentUserData={currentUserData}
            />
          }
        >
          <Route
            path="/home"
            element={
              <ControlPanel
                userCurrentData={userCurrentData}
                currentUserData={currentUserData}
                roleType={roleType}
              />
            }
          />
        </Route>

        <Route
          path="/subscriptioninvoiceReceipt/:transactionId"
          element={
            <DashboardPermission
              twoFA={twoFA}
              authType={authType}
              email={email}
              roleType={roleType}
              subscriptionExpiry={subscriptionExpiry}
              passwordUpdate={passwordUpdate}
              currentUserData={currentUserData}
            />
          }
        >
          <Route
            path="/subscriptioninvoiceReceipt/:transactionId"
            element={
              <SubscriptionInvoiceReceipt
                userCurrentData={userCurrentData}
                currentUserData={currentUserData}
                roleType={roleType}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
}
