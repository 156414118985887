import React, { useRef, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useReactToPrint } from "react-to-print";

const LogConsole = ({
  width,
  isFullScreen,
  setIsFullScreen,
  editorFullMode,
  copyToClipboard,
  codeLogs,
  copy,
  editorTheme
}) => {
  const [fontSize, setFontSize] = useState(14);

  function fontIncrease() {
    setFontSize((prevFontSize) => prevFontSize + 1);
  }

  function fontDecrease() {
    setFontSize((prevFontSize) => Math.max(prevFontSize - 1, 5));
  }

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div
        className={`p-0 output__section_widget logsTextColor ${!editorFullMode ? "" : "fullModeHeight"} ${!editorTheme ? "outputShadowEditorTheme" : "outputShadow"}`}
        style={{
          width: isFullScreen
            ? "98%"
            : width <= 1100
              ? 1500 - width + "px"
              : "400px",
        }}
      >
        <div
          className="border-bottom p-2"
          style={{ background: "#ebebeb" }}
        >
          <div className="hstack gap-2 header_tool justify-content-between">
            <p className="m-0">
              <b>Logs</b>
            </p>
            <div className="hstack gap-1">
              <i
                className="fa solid fa-plus"
                onClick={fontIncrease}
                title="Increase Font"
              ></i>
              <div className="vr"></div>
              <i
                className="fa solid fa-minus"
                onClick={fontDecrease}
                title="Decrease Font"
              ></i>
              <div className="vr"></div>
              <i
                className={
                  copy === "logs"
                    ? "fa-regular fa-circle-check"
                    : "fa-solid fa-copy"
                }
                onClick={() => copyToClipboard(codeLogs, "logs")}
                title={copy === "logs" ? "Copied" : "Copy Logs"}
              ></i>
              <div className="vr"></div>
              <i
                className="fa-solid fa-print"
                onClick={handlePrint}
                title="Print Generated Logs"
              ></i>

              <div className="vr"></div>
              <i
                className={
                  isFullScreen ? "fa-solid fa-minimize" : "fa-solid fa-maximize"
                }
                title={isFullScreen ? "Minimized Mode" : "Full Mode"}
                onClick={() => setIsFullScreen(!isFullScreen)}
              ></i>
            </div>
          </div>
        </div>
        <div
          className={`px-2 pt-2 ${!editorFullMode ? "output__section_widget_height" : "output__section_widget_full_height"}`}
        >
          <i style={{ fontSize: fontSize }} ref={componentRef}>
            {codeLogs ? (
              <SyntaxHighlighter style={docco}>{codeLogs}</SyntaxHighlighter>
            ) : (
              "No Error"
            )}
          </i>
        </div>
      </div>
    </>
  );
};

export default LogConsole;
