import React, { useEffect, useState } from "react";
import PackageLeftBar from "./PackageLeftBar.js";
import PackageListRightPanel from "./PackageListRightPanel.js";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices.jsx";

function Packages({ handleTabChange, editorFullMode }) {
  const [packageLists, setPackageLists] = useState([]);
  const searchPublishedPackageListForConsultant = async (searchData) => {
    let userData = {}
    userData.searchData = searchData
    let data = await packagesServices.searchPublishedPackageListForConsultant(userData)
    setPackageLists(data?.data)
  }

  const getPublishedPackageListForConsultant = async () => {
    let data = await packagesServices.getPublishedPackageListForConsultant()
    setPackageLists(data?.data)
  }

  useEffect(() => {
    getPublishedPackageListForConsultant();
  }, []);

  return (
    <div className={`d-flex`}>
      <PackageLeftBar
        editorFullMode={editorFullMode}
        searchPublishedPackageListForConsultant={searchPublishedPackageListForConsultant}
      />
      <PackageListRightPanel
        handleTabChange={handleTabChange}
        editorFullMode={editorFullMode}
        packageLists={packageLists}
      />
    </div>
  );
}

export default Packages;
