import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form, Table } from "react-bootstrap";
import { libraryServices } from "../../../APIServices/Editor/libraryServices";

const HelpLibraryTablePropertyModal = ({
  show,
  title,
  bounds,
  submit,
  onStart,
  setShow,
  disabled,
  libraryId,
  columnList,
  onMouseOut,
  draggleRef,
  onMouseOver,
  columnStatus,
  getLibraryDataByFolderId,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const handleChange = (e, index) => {
    const { checked, name } = e.target;
    let updatedSelectedRows = [...selectedRows];
    if (name === "selectAll") {
      if (checked) {
        updatedSelectedRows = columnList?.map((item, index) => index);
      } else {
        updatedSelectedRows = [];
      }
    } else {
      if (checked) {
        if (!updatedSelectedRows?.includes(index)) {
          updatedSelectedRows?.push(index);
        }
      } else {
        updatedSelectedRows = updatedSelectedRows.filter(
          (item) => item !== index
        );
      }
    }
    setSelectedRows(updatedSelectedRows);
  };
  const libraryHideShowColumn = async () => {
    let userData = {};
    userData.libraryId = libraryId;
    userData.indexs = selectedRows;
    let data = await libraryServices.libraryHideShowColumn(userData);
    if (data?.statusCode === 200) {
      setShow();
      getLibraryDataByFolderId(libraryId);
    }
  };
  const cleanDataCloseModal = () => {
    setShow();
    setSelectedRows([]);
  };
  useEffect(() => {
    setSelectedRows(columnStatus);
  }, [columnStatus]);

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={900}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => cleanDataCloseModal(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="">
          <div className="table_content">
            <div className="tablePropertiesHeight">
              <Table>
                <thead className="thead-sticky">
                  <tr>
                    <th>
                      <Form.Check
                        type="checkbox"
                        name="selectAll"
                        className="text-center"
                        title="Checked For Hide All Column"
                        checked={
                          selectedRows?.length === 0
                            ? false
                            : selectedRows?.length === columnList?.length
                        }
                        onChange={(e) => handleChange(e)}
                      />
                    </th>
                    <th style={{ width: "32%" }}>Column Name</th>
                    <th>Column Label</th>
                    <th>Type</th>
                    <th>Length</th>
                  </tr>
                </thead>
                <tbody>
                  {columnList?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          name={item?.name}
                          id={item?.id}
                          className="text-center"
                          title="Checked For Hide Column"
                          checked={selectedRows?.includes(index)}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.label}</td>
                      <td className="text-capitalize">{item.type}</td>
                      <td>{item.length}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="button_type footer_btn text-end px-3 pt-2 bottom_table" style={{marginTop: "-4px"}}>
            <button
              onClick={() => {
                libraryHideShowColumn();
                setShow();
              }}
              className="mb-2"
            >
              {submit}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default HelpLibraryTablePropertyModal;
