import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Collapse, Form } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { packagesServices } from "../../../APIServices/Editor/packagesServices";
import Swal from "sweetalert2";
const ExampleForOutputByAdminValidator = ({
  show,
  title,
  bounds,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  exampleOutputList,
  getPackageExampleForConsultant
}) => {
  const [open, setOpen] = useState("");

  const deletePackageExampleForConsultant = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this Package Example Output?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await packagesServices.deletePackageExampleForConsultant(id);
        if (data?.statusCode === 200) {
          getPackageExampleForConsultant();
        }
      }
    });
  };
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={700}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="p-3 packageExampleHeight">
          {exampleOutputList?.map((item, index) => (
            <div key={index} className="m-0">
              <div className="hstack justify-content-between border-bottom mb-4">
                <h6 style={{ fontSize: 14 }} onClick={() => setOpen(open === index ? "" : index)} className="pointer">{item?.name}</h6>
                <div className="hstack gap-3">
                  <i onClick={() => setOpen(open === index ? "" : index)} className={`fa-solid ${open ? "fa-caret-down" : " fa-caret-right"}`}></i>
                  <i onClick={() => deletePackageExampleForConsultant(item._id)} className="fa fa-trash-can pointer" style={{ color: "red", fontSize: 11 }}></i>
                </div>
              </div>
              <Collapse in={open === index}>
                <div id="example-collapse-text" className="mt-2">
                  <p>{item?.description}</p>
                  <Form.Label>Procedure</Form.Label>
                  <SyntaxHighlighter style={dracula}>{item?.procedure}</SyntaxHighlighter>
                  <Form.Label className="mt-4">Output</Form.Label>
                  <div
                    style={{ fontSize: 13 }}
                    dangerouslySetInnerHTML={{ __html: item?.output }}
                  />
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};
export default ExampleForOutputByAdminValidator;
