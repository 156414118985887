import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const AnalyticsData = () => {
  return (
    <>
      <div className="graph__title mt-2">
        <p className="mb-1">Packages </p>
      </div>
      <Row className="mt-2">
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1"><b>100</b></Card.Title>
                  <Card.Text>
                    <p>Total Packages</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1"><b>50</b></Card.Title>
                  <Card.Text>
                    <p>Published Package</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1"><b>30</b></Card.Title>
                  <Card.Text>
                    <p>Pending for validation packages </p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1"><b>20</b></Card.Title>
                  <Card.Text>
                    <p>Draft packages</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="graph__title mt-2">
        <p className="mb-1">Revenue & Payment </p>
      </div>
      <Row className="mt-2">
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1"><b>$500</b></Card.Title>
                  <Card.Text>
                    <p>Total Purchased Package</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1"><b>$300</b></Card.Title>
                  <Card.Text>
                    <p>Total Revenue</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1"><b>$100</b></Card.Title>
                  <Card.Text>
                    <p>Total Withdraw </p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-3">
          <Card className="shadow-sm border-0" style={{height: 100}}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="icon rounded-circle">
                  <i className="fa-solid fa-file-contract"></i>
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1"><b>$200</b></Card.Title>
                  <Card.Text>
                    <p>Remaining Balance</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AnalyticsData;
