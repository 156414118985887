import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { subscriptionPlanServices } from "../../APIServices/Editor/subscriptionPlanServices";

const FailedPayment = () => {
  let { uniqueId } = useParams();

  const confirmSubscriptionPaymentStatus = async () => {
    let userData = {
      uniqueId: uniqueId,
    };
    await subscriptionPlanServices.confirmSubscriptionPaymentStatus(userData);
  };

  useEffect(() => {
    confirmSubscriptionPaymentStatus();
  }, []);
  return (
    <>
      <div
        className="paymentSucess paymentfailed hstack justify-content-center"
        style={{ height: "100vh" }}
      >
        <Card style={{ width: "18rem" }}>
          <Card.Body className="text-center">
            <div className="circle mb-4">
              <div className="checkmark hstack">✗</div>
            </div>
            <Card.Title className="mb-3">
              <b>Payment Failed</b>
            </Card.Title>
            <Card.Text className="mb-2">
              Opps! your payment is cancelled. Please try again.
            </Card.Text>
            <div className="button_type">
              <NavLink to="/home" >
                <button>Go to Home Page</button>
              </NavLink>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default FailedPayment;
