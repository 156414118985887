import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import TabList from "./TabList";
import Profile from "./MyAccount/Profile";
import Navbar from "../Component/Navbar/Navbar";
import Footer from "../Component/Footer/Footer";
import LogData from "./MyAccount/LogData/LogData";
import LibraryHelp from "./LibraryHelp/LibraryHelp";
import Payout from "./Developer/Account/Payout/Payout";
import Revenue from "./Developer/Account/Revenue/Revenue";
import TrashHistory from "./Consultant/Trash/TrashHistory";
import EditorComponent from "./EditorFiles/EditorComponent";
import CollapseMenu from "../Component/Collpse/CollapseMenu";
import Packages from "./Consultant/Packages/Packages/Packages";
import GraphicsComponent from "./EditorFiles/GraphicsComponent";
import FailedStatus from "./MyAccount/FailedStatus/FailedStatus";
import KycVerification from "./Developer/Account/KycVerification";
import PackageHistrory from "./Validator/Packages/PackageHistrory";
import { libraryServices } from "../APIServices/Editor/libraryServices";
import DeveloperDashboard from "./Developer/Dashboard/DeveloperDashboard";
import Validatordashboard from "./Validator/Dashboard/ValidatorDashboard";
import { packagesServices } from "../APIServices/Editor/packagesServices";
import ConsultantMyOrdersTable from "./Consultant/OrderTable/MyOrdersTable";
import FevoritesConsultant from "./Consultant/Favorites/FevoritesConsultant";
import ConsultantDashboard from "./Consultant/Dashboard/ConsultantDashboard";
import PackagesDetails from "./Consultant/Packages/PackageDetails/PackagesDetails";
import SubscriptionBilling from "./Consultant/SubscriptionPlans/SubscriptionBilling";
import AdminValidatorDashboard from "./AdminValidator/Dashboard/AdminValidatorDashboard";
import ConsultantMyPackagesTable from "./Consultant/PackageTable/ConsultantMyPackagesTable";
import ConsultantFullPageLogConsole from "./EditorFiles/ConsoleLogs/ConsultantFullPageLogConsole";
import ConsultantFullPageOutputConsole from "./EditorFiles/ConsoleOutputs/ConsultantFullPageOutputConsole";
import ProgramByProjectHistoryTableData from "./Consultant/Dashboard/TableData/ProgramByProjectHistoryTableData";
function ControlPanel({ roleType, userCurrentData, currentUserData }) {
  const [editorGraphics, setEditorGraphics] = useState("editor");
  const [width, setWidth] = useState("");
  const [editorFullMode, setEditorFullMode] = useState(false);
  const [showSidebarMenu, setShowSidebarMenu] = useState(
    roleType === "consultant"
      ? "file"
      : roleType === "developer" ||
        roleType === "validator" ||
        roleType === "subadmin"
        ? "developPackages"
        : ""
  );
  const [openLogs, setOpenLogs] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [editorTheme, setEditorTheme] = useState(true);
  const [openProgram, setOpenProgram] = useState(false);
  const [openOutputs, setOpenOutputs] = useState(false);
  const [openLibrary, setOpenLibrary] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [openRunProgram, setOpenRunProgram] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [openProduction, setOpenProduction] = useState(false);
  const [openDevelopment, setOpenDevelopment] = useState(false);
  const [openDataLibrary, setOpenDataLibrary] = useState(false);
  const [openHelpLibrary, setOpenHelpLibrary] = useState(false);
  const [openCustomDocuments, setOpenCustomDocuments] = useState(false);
  const [currentCodeCompareData, setCurrentCodeCompareData] = useState(false);
  const [openRightTab, setOpenRightTab] = useState("");
  const [copy, setCopy] = useState(false);

  const copyToClipboard = (code, name) => {
    const textarea = document.createElement("textarea");
    textarea.value = code;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopy(name);
    setTimeout(() => setCopy(""), 2000);
  };

  const onResizeX = (e) => {
    document.addEventListener("mousemove", onmousemove);
    document.addEventListener("mouseup", onmouseup);
    function onmousemove(e) {
      setWidth(e.pageX);
    }
    function onmouseup(e) {
      document.removeEventListener("mousemove", onmousemove);
      document.removeEventListener("mouseup", onmouseup);
    }
  };

  const handleSidebarMenu = (menu) => {
    setOpenLogs(false);
    setOpenFiles(false);
    setOpenProgram(false);
    setOpenOutputs(false);
    setOpenLibrary(false);
    setOpenDocuments(false);
    setShowSidebarMenu(menu);
    setOpenRunProgram(false);
    setOpenValidation(false);
    setOpenProduction(false);
    setOpenDevelopment(false);
    setOpenDataLibrary(false);
    setOpenHelpLibrary(false);
    setOpenCustomDocuments(false);
  };

  const [activeTab, setActiveTab] = useState(
    JSON.parse(localStorage.getItem("activeTabData")) || []
  );

  const [tabLists, setTabLists] = useState(() => {
    const savedTabListData = localStorage.getItem("tabListData");
    try {
      return savedTabListData ? JSON.parse(savedTabListData) : [];
    } catch (error) {
      console.error("Error parsing tabListData from localStorage", error);
      return [];
    }
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState(
    JSON.parse(localStorage.getItem("tabListData")) || []
  );

  const [codeCompare, setCodeCompare] = useState(
    JSON.parse(localStorage.getItem("codeCompare")) || []
  );

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
    if (searchQuery.trim() === "") {
      setSearchResult(tabLists);
    } else {
      const result = searchResult.filter((resource) =>
        resource.name.includes(searchQuery)
      );
      setSearchResult(result);
    }
  };

  const handleTabChange = (e, name, page, icon, type) => {
    setOpenRightTab("");
    if (name && page) {
      setLibraryId(page);
      setFileId(page);
      setActiveTab([name, page, icon, type]);
      localStorage.setItem("activeTabData", JSON.stringify([name, page, icon, type]));
      const isDuplicate = tabLists.some((tab) => tab.page === page);

      if (!isDuplicate) {
        const newTabLists = [...tabLists, { name, page, icon, type }];
        setTabLists(newTabLists);
        setSearchResult(newTabLists);
        localStorage.setItem("tabListData", JSON.stringify(newTabLists));
      }
    }
  };

  const handleSetTab = (data) => {
    setOpenRightTab("");
    setLibraryId(data[1]);
    setFileId(data[1]);
    setActiveTab(data);
    localStorage.setItem("activeTabData", JSON.stringify(data));
  };

  const handleCloseButtonClick = (page) => {
    setOpenRightTab("");
    let checkData = codeCompare?.find((data) => data?.id === page);
    let checkStatus = checkData?.status;
    if (checkStatus) {
      Swal.fire({
        title: "Your Program not Save",
        text: `You want to Close this Tab`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, close it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let tabCodeList = JSON.parse(localStorage.getItem("editorCode"));
          let newTabCodeData =
            tabCodeList?.filter((item) => !(item.id === page)) || [];
          localStorage.setItem("editorCode", JSON.stringify(newTabCodeData));

          let codeCompareList = JSON.parse(localStorage.getItem("codeCompare"));
          let newCodeCompare =
            codeCompareList?.filter((item) => !(item.id === page)) || [];
          localStorage.setItem("codeCompare", JSON.stringify(newCodeCompare));

          const currentIndex =
            tabLists.findIndex((entry) => entry.page === page) + 1;
          const totalLength = tabLists?.length;
          const isClosingActiveTab = activeTab[1] === page;
          const updatedTabLists = tabLists.filter(
            (tab) => !(tab.page === page)
          );

          setTabLists(updatedTabLists);
          setSearchResult(updatedTabLists);
          localStorage.setItem("tabListData", JSON.stringify(updatedTabLists));

          let saveData;

          if (isClosingActiveTab && updatedTabLists.length > 0) {
            if (totalLength === currentIndex) {
              const lastTab = updatedTabLists[updatedTabLists.length - 1];
              saveData = [lastTab.name, lastTab.page, lastTab.icon, lastTab.type];
            } else {
              const currentTab = tabLists.find(
                (element, index) => index === currentIndex
              );
              saveData = [currentTab.name, currentTab.page, currentTab.icon, currentTab.type];
            }
          } else if (isClosingActiveTab) {
            saveData = [];
          } else {
            saveData = activeTab.length > 0 ? activeTab : [];
          }
          setTimeout(() => {
            setActiveTab(saveData);
            if (saveData?.length === 0) {
              localStorage.removeItem("activeTabData");
            } else {
              localStorage.setItem("activeTabData", JSON.stringify(saveData));
            }
          }, 0);
        }
      });
    } else {
      let tabCodeList = JSON.parse(localStorage.getItem("editorCode"));
      let newTabCodeData =
        tabCodeList?.filter((item) => !(item.id === page)) || [];
      localStorage.setItem("editorCode", JSON.stringify(newTabCodeData));

      let codeCompareList = JSON.parse(localStorage.getItem("codeCompare"));
      let newCodeCompare =
        codeCompareList?.filter((item) => !(item.id === page)) || [];
      localStorage.setItem("codeCompare", JSON.stringify(newCodeCompare));

      const currentIndex =
        tabLists.findIndex((entry) => entry.page === page) + 1;
      const totalLength = tabLists?.length;
      const isClosingActiveTab = activeTab[1] === page;
      const updatedTabLists = tabLists.filter((tab) => !(tab.page === page));

      setTabLists(updatedTabLists);
      setSearchResult(updatedTabLists);
      localStorage.setItem("tabListData", JSON.stringify(updatedTabLists));

      let saveData;

      if (isClosingActiveTab && updatedTabLists.length > 0) {
        if (totalLength === currentIndex) {
          const lastTab = updatedTabLists[updatedTabLists.length - 1];
          saveData = [lastTab.name, lastTab.page, lastTab.icon, lastTab.type];
        } else {
          const currentTab = tabLists.find(
            (element, index) => index === currentIndex
          );
          saveData = [currentTab.name, currentTab.page, currentTab.icon, currentTab.type];
        }
      } else if (isClosingActiveTab) {
        saveData = [];
      } else {
        saveData = activeTab.length > 0 ? activeTab : [];
      }
      setTimeout(() => {
        if (saveData?.length === 0) {
          localStorage.removeItem("activeTabData");
        } else {
          setActiveTab(saveData);
          localStorage.setItem("activeTabData", JSON.stringify(saveData));
        }
      }, 0);
    }
  };

  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);
  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const th = tableRef.current.querySelector(
          `th:nth-child(${resizingColumn + 1})`
        );
        th.style.width = `${width}px`;
        tableRef.current.classList.add("resizing");
      }
    };
    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef.current.querySelectorAll("th").forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };

  const [columnList, setColumnList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [libraryList, setLibraryList] = useState([]);
  const [libraryTempList, setLibraryTempList] = useState([]);
  const [libraryId, setLibraryId] = useState(
    JSON.parse(localStorage.getItem("activeTabData"))?.[1] || ""
  );
  const [fileId, setFileId] = useState(
    JSON.parse(localStorage.getItem("activeTabData"))?.[1] || ""
  );
  const [libraryType, setLibraryType] = useState("");
  const [columnStatus, setColumnStatus] = useState([]);

  const getLibraryFolderData = async () => {
    let data = await libraryServices.getLibraryFolderData("help");
    setLibraryList(data?.data);
  };

  const getTempLibraryFolderData = async () => {
    let data = await libraryServices.getLibraryFolderData("temp");
    setLibraryTempList(data?.data);
  };

  const getLibraryDataByFolderId = async (libraryId) => {
    let activeTabData = JSON.parse(localStorage.getItem("activeTabData")) || [];
    let libraryFileList =
      JSON.parse(localStorage.getItem("libraryFileList")) || [];
    let checkData = libraryFileList?.find(
      (item) => item.fileId === activeTabData[1]
    );
    if (checkData) {
      let hideShowLibraryData = JSON.parse(
        localStorage.getItem("hideShowLibraryData")
      );
      let checkHideShowData = hideShowLibraryData?.find(
        (item) => item.fileId === activeTabData[1]
      );
      let hideShowLibrary = checkHideShowData.index;
      hideShowLibrary.sort((a, b) => a - b);
      setColumnList(checkData?.column);
      setColumnStatus(hideShowLibrary);
      setDataList(checkData?.data?.map((item) => Object.values(item)));
      setLibraryType("session");
    } else {
      let data = await libraryServices.getLibraryDataByFolderId(libraryId);
      let result = data?.data;
      setColumnList(result?.column);
      setColumnStatus(result?.columnStatus);
      setDataList(result?.data?.map((item) => Object.values(item)));
      setLibraryType("help");
    }
  };

  let activeId = JSON.parse(localStorage.getItem("activeTabData"))?.[1];
  let tabCodeList = JSON.parse(localStorage.getItem("editorCode")) || [];

  const [editorValue, setEditorValue] = useState("");
  const [databseCode, setDatabseCode] = useState("");
  const [localCode, setLocalCode] = useState("");
  const [codeFileStatus, setCodeFileStatus] = useState("");

  const getPackageFileCodeById = async () => {
    let data = await packagesServices.getPackageFileCodeById(activeId);
    let localData = tabCodeList?.find((item) => item.id === activeId);
    setEditorValue(localData ? localData?.code : data?.code);
    setDatabseCode(data?.code);
    setLocalCode(localData?.code);
    setCodeFileStatus(data?.status);
  };

  const checkCompareData = (editorValue) => {
    let latestCode = editorValue ? editorValue : localCode;
    let object = {
      status: latestCode ? !(latestCode === databseCode) : false,
      id: activeId,
    };
    if (!codeCompare) {
      localStorage.setItem("codeCompare", JSON.stringify([object]));
    } else {
      const compareCodeList = codeCompare.filter((tab) => tab.id !== activeId);
      compareCodeList.push(object);
      localStorage.setItem("codeCompare", JSON.stringify(compareCodeList));
    }
  };

  const setCodeLocalData = (editorValue) => {
    setEditorValue(editorValue);
    if (!tabCodeList) {
      localStorage.setItem(
        "editorCode",
        JSON.stringify([{ code: editorValue, id: activeId }])
      );
    } else {
      const updatedTabCodeList = tabCodeList.filter(
        (tab) => tab.id !== activeId
      );
      updatedTabCodeList.push({ code: editorValue, id: activeId });
      localStorage.setItem("editorCode", JSON.stringify(updatedTabCodeList));
    }
  };

  const [isHovered, setIsHovered] = useState("");

  useEffect(() => {
    getLibraryDataByFolderId(libraryId);
    getLibraryFolderData();
    getTempLibraryFolderData();
    setLibraryId(activeId || "");
    setFileId(activeId || "");
  }, [libraryId, activeId]);

  return (
    <div id="app">
      {!editorFullMode && (
        <Navbar
          roleType={roleType}
          handleTabChange={handleTabChange}
          setShowSidebarMenu={handleSidebarMenu}
          userCurrentData={userCurrentData}
          currentUserData={currentUserData}
        />
      )}
      <div className="resizable-x">
        {!editorFullMode && (
          <>
            <div className="div0" style={{ width: width + "px" }}>
              <section className="middle-content">
                <div className="sidebar">
                  <CollapseMenu
                    fileId={fileId}
                    roleType={roleType}
                    openLogs={openLogs}
                    setFileId={setFileId}
                    libraryId={libraryId}
                    openFiles={openFiles}
                    columnList={columnList}
                    activeTab={activeTab[1]}
                    setOpenLogs={setOpenLogs}
                    openOutputs={openOutputs}
                    libraryList={libraryList}
                    onMouseDown={onMouseDown}
                    activePage={activeTab[0]}
                    openProgram={openProgram}
                    openLibrary={openLibrary}
                    columnStatus={columnStatus}
                    setOpenFiles={setOpenFiles}
                    setLibraryId={setLibraryId}
                    openDocuments={openDocuments}
                    openRunProgram={openRunProgram}
                    setOpenLibrary={setOpenLibrary}
                    setLibraryList={setLibraryList}
                    setOpenProgram={setOpenProgram}
                    setOpenOutputs={setOpenOutputs}
                    openValidation={openValidation}
                    openProduction={openProduction}
                    handleTabChange={handleTabChange}
                    showSidebarMenu={showSidebarMenu}
                    openDevelopment={openDevelopment}
                    libraryTempList={libraryTempList}
                    openDataLibrary={openDataLibrary}
                    openHelpLibrary={openHelpLibrary}
                    userCurrentData={userCurrentData}
                    setOpenDocuments={setOpenDocuments}
                    setOpenValidation={setOpenValidation}
                    setOpenRunProgram={setOpenRunProgram}
                    setOpenProduction={setOpenProduction}
                    setOpenDevelopment={setOpenDevelopment}
                    setOpenHelpLibrary={setOpenHelpLibrary}
                    setOpenDataLibrary={setOpenDataLibrary}
                    openCustomDocuments={openCustomDocuments}
                    getLibraryFolderData={getLibraryFolderData}
                    setOpenCustomDocuments={setOpenCustomDocuments}
                    getPackageFileCodeById={getPackageFileCodeById}
                    currentCodeCompareData={currentCodeCompareData}
                    getTempLibraryFolderData={getTempLibraryFolderData}
                    getLibraryDataByFolderId={getLibraryDataByFolderId}
                    setCurrentCodeCompareData={setCurrentCodeCompareData}
                  />
                </div>
              </section>
            </div>
            <div
              className="resizer-x"
              onMouseDown={onResizeX}
              onTouchStart={onResizeX}
            ></div>
          </>
        )}
        <div className="div1">
          <section className="middle-content">
            <div
              className={`sidebar ${!editorFullMode ? "sidebar__height" : "fullModeHeight"
                }`}
              style={{ overflow: "hidden" }}
            >
              <div className="menus">
                <TabList
                  tabLists={tabLists}
                  activeTab={activeTab}
                  isHovered={isHovered}
                  searchQuery={searchQuery}
                  codeCompare={codeCompare}
                  setTabLists={setTabLists}
                  editorTheme={editorTheme}
                  setIsHovered={setIsHovered}
                  setActiveTab={setActiveTab}
                  searchResult={searchResult}
                  handleSetTab={handleSetTab}
                  handleSearch={handleSearch}
                  setEditorTheme={setEditorTheme}
                  editorFullMode={editorFullMode}
                  setOpenRightTab={setOpenRightTab}
                  setEditorFullMode={setEditorFullMode}
                  handleCloseButtonClick={handleCloseButtonClick}
                />
                {(activeTab[1] === fileId &&
                  activeTab[3] === "codeEditor") ? (
                  editorGraphics === "editor" ? (
                    <EditorComponent
                      editorTheme={editorTheme}
                      setEditorGraphics={setEditorGraphics}
                      editorFullMode={editorFullMode}
                      fileId={fileId}
                      getPackageFileCodeById={getPackageFileCodeById}
                      setEditorValue={setEditorValue}
                      editorValue={editorValue}
                      codeFileStatus={codeFileStatus}
                      setCodeLocalData={setCodeLocalData}
                      setCurrentCodeCompareData={setCurrentCodeCompareData}
                      currentCodeCompareData={currentCodeCompareData}
                      checkCompareData={checkCompareData}
                      codeCompare={codeCompare}
                      setCodeCompare={setCodeCompare}
                      activeTab={activeTab}
                      openRightTab={openRightTab}
                      setOpenRightTab={setOpenRightTab}
                      roleType={roleType}
                      handleTabChange={handleTabChange}
                      copy={copy}
                      copyToClipboard={copyToClipboard}
                    />
                  ) : (
                    <GraphicsComponent
                      setEditorGraphics={setEditorGraphics}
                      editorFullMode={editorFullMode}
                      fileId={fileId}
                    />
                  )
                ) : (activeTab[1] === fileId &&
                  activeTab[3] === "codeEditorOutput") ? (
                  <ConsultantFullPageOutputConsole
                    editorFullMode={"fullMode"}
                    fileId={fileId}
                    copy={copy}
                  />
                ) : (activeTab[1] === fileId &&
                  activeTab[3] === "codeEditorLog") ? (
                  <ConsultantFullPageLogConsole
                    fileId={fileId}
                    copy={copy}
                    setCopy={setCopy}
                  />
                ) : activeTab[1] === "packagefunctionality" ? (
                  <Packages
                    handleTabChange={handleTabChange}
                    editorFullMode={editorFullMode}
                  />
                ) : activeTab?.length === 0 ? (
                  roleType === "consultant" ? (
                    <ConsultantDashboard
                      handleTabChange={handleTabChange}
                      editorFullMode={editorFullMode}
                      userCurrentData={userCurrentData}
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                    />
                  ) : roleType === "developer" ? (
                    <DeveloperDashboard
                      handleTabChange={handleTabChange}
                      editorFullMode={editorFullMode}
                    />
                  ) : roleType === "validator" ? (
                    <Validatordashboard
                      handleTabChange={handleTabChange}
                      editorFullMode={editorFullMode}
                    />
                  ) : roleType === "subadmin" ? (
                    <AdminValidatorDashboard
                      handleTabChange={handleTabChange}
                      editorFullMode={editorFullMode}
                    />
                  ) : (
                    ""
                  )
                ) : activeTab[2] === "fa-solid fa-cube" ? (
                  <PackagesDetails editorFullMode={editorFullMode} />
                ) : activeTab[1] === "profile" ? (
                  <Profile
                    roleType={roleType}
                    userCurrentData={userCurrentData}
                    currentUserData={currentUserData}
                    editorFullMode={editorFullMode}
                  />
                ) : activeTab[1] === "logdata" ? (
                  <LogData
                    editorFullMode={editorFullMode}
                    roleType={roleType}
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                  />
                ) : activeTab[1] === "failedStatus" ? (
                  <FailedStatus
                    editorFullMode={editorFullMode}
                    roleType={roleType}
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                  />
                ) : activeTab[1] === "favorite" ? (
                  <FevoritesConsultant
                    editorFullMode={editorFullMode}
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                  />
                ) : activeTab[1] === "myOrders" ? (
                  <ConsultantMyOrdersTable
                    editorFullMode={editorFullMode}
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                  />
                ) : activeTab[1] === "billing" ? (
                  <SubscriptionBilling
                    handleTabChange={handleTabChange}
                    editorFullMode={editorFullMode}
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                  />
                ) : activeTab[1] === "myPackages" ? (
                  <ConsultantMyPackagesTable
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                    editorFullMode={editorFullMode}
                  />
                ) : activeTab[1] === libraryId || activeTab[1] === fileId ? (
                  columnList?.length > 1 && (
                    <LibraryHelp
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      columnList={columnList}
                      dataList={dataList}
                      libraryId={libraryId}
                      libraryType={libraryType}
                      editorFullMode={editorFullMode}
                    />
                  )
                ) : activeTab[1] === "consultantPackageHistory" ? (
                  activeTab?.length > 1 && (
                    <ProgramByProjectHistoryTableData
                      editorFullMode={editorFullMode}
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                    />
                  )
                ) : (
                  ""
                )}

                {roleType === "consultant" &&
                  activeTab[1] === "consultantTrashProject" ?
                  activeTab?.length > 1 && (
                    <TrashHistory
                      editorFullMode={editorFullMode}
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                    />
                  )
                  : ""}

                {roleType === "consultant" &&
                  activeTab[1] === "consultantPackageHistory"
                  ? activeTab?.length > 1 && (
                    <ProgramByProjectHistoryTableData
                      editorFullMode={editorFullMode}
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                    />
                  )
                  : ""}

                {roleType === "validator" && activeTab[1] === "packageHistory"
                  ? activeTab?.length > 1 && (
                    <PackageHistrory
                      editorFullMode={editorFullMode}
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                    />
                  )
                  : ""}

                {roleType === "developer" && activeTab[1] === "kyc" ? (
                  <KycVerification
                    userCurrentData={userCurrentData}
                    currentUserData={currentUserData}
                  />
                ) : activeTab[1] === "revenue" ? (
                  <Revenue
                    editorFullMode={editorFullMode}
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                  />
                ) : activeTab[1] === "payout" ? (
                  <Payout
                    editorFullMode={editorFullMode}
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      {!editorFullMode && <Footer />}
    </div>
  );
}

export default ControlPanel;
