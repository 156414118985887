import React, { useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form } from "react-bootstrap";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices";
const AddNewPackagesModal = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  onMouseOut,
  draggleRef,
  onMouseOver,
  categoryList,
  getPackageFolderLists,
}) => {
  let [folderName, setFolderName] = useState("");
  let [folderDescription, setFolderDescription] = useState("");
  let [category, setCategory] = useState("");
  
  const addNewPackageFolder = async () => {
    let userData = {};
    userData.name = folderName;
    userData.description = folderDescription;
    userData.category = category;
    let data = await packagesServices.addNewPackageFolder(userData);
    if (data?.statusCode === 200) {
      setShow();
      setFolderName("");
      setFolderDescription("");
      getPackageFolderLists();
      setCategory("")
    }
  };

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css">
          <Form.Label>
            Folder Name<span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder="Enter Folder Name"
            autoFocus
          />
          <Form.Label className="mt-3">
            Description<span>*</span>
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            value={folderDescription}
            onChange={(e) => setFolderDescription(e.target.value)}
            placeholder="Enter Folder Description"
            autoFocus
            />
          <Form.Label className="mt-3">Categories<span>*</span></Form.Label>
          <Form.Select
            className="mb-3"
            aria-label="Default select example"
            onChange={(e) => setCategory(e.target.value)}
          >
            <option>Select Categories</option>
            {categoryList?.map((item, index) => (
              <option key={index} value={item.categoryId}>{item.category}</option>
            ))}
          </Form.Select>
        </div>
        <hr className="w-100 m-0" />
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            disabled={!folderName || !folderDescription || !category}
            onClick={addNewPackageFolder}
          >
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default AddNewPackagesModal;
