import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Col, Form } from "react-bootstrap";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices";

const UpdatePackageDocumentDataModal = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  draggleRef,
  onMouseOut,
  onMouseOver,
  packageFileDocs,
  openPackageFileById,
  packageFileType
}) => {
  const [editTitle, setEditTitle] = useState("");
  const [error, setError] = useState("");
  const updatePackagesDocumentName = async () => {
    let userData = {};
    userData.id = packageFileDocs?._id;
    userData.name = editTitle;
    let data = await packagesServices.updatePackagesDocumentName(userData);
    if (data?.statusCode === 200) {
      setShow();
      openPackageFileById(packageFileType);
    }
  };

  const validateFileTitle = (input) => {
    const fileTitleRegex = /^[a-zA-Z0-9_]+$/;

    if (!fileTitleRegex.test(input)) {
      setError(
        "File Title should only contain capital letters, small letters, or numbers"
      );
    } else {
      setError("");
    }
  };

  useEffect(() => {
    setEditTitle(packageFileDocs?.name);
  }, [packageFileDocs]);
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={400}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="common_css table_content">
          <Form.Group as={Col} md="12">
            <Form.Label>
              File Name <span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={(e) => {
                setEditTitle(e.target.value);
                validateFileTitle(e.target.value);
              }}
              value={editTitle}
              placeholder="Enter File Name"
              style={{ fontSize: 13 }}
            />
            {error && (
              <span
                style={{ fontSize: 10, color: "red", fontWeight: 600 }}
              >
                Folder Name Title should only contain capital letters,
                small letters, underScore, and numbers "Space Not Allowed"
                <br />
              </span>
            )}
          </Form.Group>
        </div>
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            onClick={updatePackagesDocumentName}
            disabled={!editTitle}
          >
            {submit}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default UpdatePackageDocumentDataModal;
