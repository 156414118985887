import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Form, InputGroup, Table } from "react-bootstrap";
import { packagesServices } from "../../../APIServices/Editor/packagesServices";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ProcedureValidationOutput from "./ProcedureValidationOutput";
import AddSoftwareValidation from "./AddSoftwareValidation";
import Swal from "sweetalert2";
const PackageFunctionFinalValidationForUpdate = ({
  show,
  title,
  bounds,
  submit,
  setShow,
  onStart,
  disabled,
  packageId,
  draggleRef,
  onMouseOut,
  onMouseOver,
  openPackageFileById,
}) => {
  const [status, setStatus] = useState("");
  const [password, setPassword] = useState("");
  const [remark, setRemark] = useState("");
  const [output, setOutput] = useState("");
  const [exampleList, setExampleList] = useState([]);
  const [validationList, setValidationList] = useState([]);
  const [softwareList, setSoftwareList] = useState([{ software: "SAS" }, { software: "R" }]);
  const [showPassword, setShowPassword] = useState(false);
  const [procedureValidationData, setProcedureValidationData] = useState(false);
  const [interSoftwareValidation, setInterSoftwareValidation] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const openPopupProcedureOutputData = (e, output) => {
    setProcedureValidationData(true);
    setOutput(output);
  };

  const getExampleForPackageValidation = async (packageId) => {
    let data = await packagesServices.getExampleForPackageValidation(packageId);
    setExampleList(data?.data);
  };

  const getSoftwareForPackageValidation = async (packageId) => {
    let data = await packagesServices.getSoftwareForPackageValidation(packageId);
    let validation = data?.data

    if ((validation?.[0]?.validation)?.length > 0) {
      setSoftwareList(validation?.[0]?.validation)
    }

    let finalData = []
    for (const element of validation) {
      let objectData = {}
      objectData._id = element?._id
      objectData.validationId = element?.validationId
      objectData.packageId = element?.packageId
      objectData.validation = (element?.validation)?.length > 0 ? element?.validation : softwareList
      finalData.push(objectData)
    }
    setValidationList(finalData);
  }

  const isValid = validationList?.every(item =>
    item?.validation.every(validation =>
      validation.validation && validation.output !== undefined && validation.output !== null && validation.output !== ""
    )
  );

  const deleteExampleForPackageValidation = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Example?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await packagesServices.deleteExampleForPackageValidation(id);
        if (data?.statusCode === 200) {
          getExampleForPackageValidation(packageId);
        }
      }
    });
  };

  const removeOutputFile = async (id, softwareName) => {
    let userData = {}
    userData.id = id
    userData.software = softwareName
    let data = await packagesServices.removeFilesoftwareValidationForPackage(userData)
    if (data?.statusCode === 200) {
      getSoftwareForPackageValidation(packageId)
    }
  };

  const handleFileUpload = async (id, softwareName, event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    let response = await packagesServices.updateSoftwareValidationOutputFile(formData)
    const fileUrl = response.data;
    let userData = {}
    userData.id = id
    userData.software = softwareName
    userData.output = fileUrl
    let data = await packagesServices.updateSoftwareValidationOutputFileURL(userData)
    if (data?.statusCode === 200) {
      getSoftwareForPackageValidation(packageId)
    }
  };

  const handleUpdateValidation = async (id, softwareName, validation) => {
    let userData = {}
    userData.id = id
    userData.software = softwareName
    userData.validation = validation
    let data = await packagesServices.updateSoftwareValidationData(userData)
    if (data?.statusCode === 200) {
      getSoftwareForPackageValidation(packageId)
    }
  };

  const addData = async (newData) => {
    const exists = softwareList.some(item => isEqual(item, newData));
    if (!exists && softwareList.length < 5) {
      softwareList.push(newData);
    }
    if (!exists && softwareList.length >= 5) {
      softwareList.pop();
      softwareList.push(newData);
    }
    setSoftwareList(softwareList)

    let finalData = []
    for (const element of validationList) {
      let validationList = element.validation
      const exists = validationList.some(item => isEqual(item, newData));
      if (!exists && validationList.length < 5) {
        validationList.push(newData);
      }
      if (!exists && validationList.length >= 5) {
        validationList.pop();
        validationList.push(newData);
      }
      let objectData = {}
      objectData._id = element?._id
      objectData.validationId = element?.validationId
      objectData.packageId = element?.packageId
      objectData.validation = validationList
      finalData.push(objectData)
    }
    let data = await packagesServices.removeAddsoftwareValidationForPackage({ validation: finalData })
    if (data?.statusCode === 200) {
      getSoftwareForPackageValidation(packageId)
      setValidationList(finalData);
    }
  }

  const handleRemove = async (index) => {
    setSoftwareList(prevItems => prevItems?.filter((_, i) => i !== index));

    let finalData = []
    for (const element of validationList) {
      let prevItems = (element.validation)?.filter((_, i) => i !== index)
      let objectData = {}
      objectData._id = element?._id
      objectData.validationId = element?.validationId
      objectData.packageId = element?.packageId
      objectData.validation = prevItems
      finalData.push(objectData)
    }
    let data = await packagesServices.removeAddsoftwareValidationForPackage({ validation: finalData })
    if (data?.statusCode === 200) {
      getSoftwareForPackageValidation(packageId)
      setValidationList(finalData);
    }
  }

  const packageValidationStatusForUpdatePackage = async () => {
    let userData = {}
    userData.id = packageId
    userData.status = status
    userData.remark = remark
    userData.password = password
    let data = await packagesServices.packageValidationStatusForUpdatePackage(userData)
    if (data?.statusCode === 200) {
      setShow(false)
      openPackageFileById(data?.subFolder);
    }
  }

  function isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  useEffect(() => {
    getExampleForPackageValidation(packageId);
    getSoftwareForPackageValidation(packageId);
  }, [packageId]);
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={1100}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div
          className="common_css bgChange"
          style={{ maxHeight: "calc(100vh - 300px)" }}
        >
          <Form.Group className="mb-3">
            <Form.Label>Procedure Validation Table</Form.Label>
            <div className="overflow-auto">
              <Table bordered className="m-0">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Input</th>
                    <th>Procedure</th>
                    <th className="text-center">Output</th>
                    <th className="text-center">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {exampleList?.map((item, index) => (
                    <tr key={index}>
                      <td>Example {index + 1}</td>
                      <td className="p-0">
                        <SyntaxHighlighter style={docco}>
                          {item.input ? item.input : ""}
                        </SyntaxHighlighter>
                      </td>
                      <td className="p-0">
                        <SyntaxHighlighter style={docco}>
                          {item.procedure ? item.procedure : ""}
                        </SyntaxHighlighter>
                      </td>
                      <td className="p-0">
                        <div className="mt-1 text-center">
                          <div className="button_type ">
                            <button
                              className="m-0"
                              onClick={(e) =>
                                openPopupProcedureOutputData(e, item.output)
                              }
                              style={{ padding: "1px 10px" }}
                            >
                              View
                            </button>
                          </div>
                        </div>
                      </td>
                      <td className="p-0">
                        <div
                          className="mt-1 text-center"
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            onClick={() =>
                              deleteExampleForPackageValidation(item.validationId)
                            }
                            className="fa-solid fa-xmark"
                            style={{ color: "red" }}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Inter Software Validation
              {softwareList?.length < 5 &&
                <i
                  style={{ cursor: "pointer" }}
                  className="fa-solid fa-circle-plus ms-2"
                  onClick={setInterSoftwareValidation}
                  title="Add Software"
                ></i>
              }
            </Form.Label>
            <div className="fontChange">
              <div className="overflow-auto">
                <Table bordered className="m-0">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Output</th>
                      {softwareList?.map((item, index) => (
                        <th key={index}>
                          <div className="justify-content-between hstack">
                            <span>{item.software}</span>
                            {index + 2 > 3 &&
                              <i
                                style={{ cursor: "pointer", color: "red", fontSize: 12 }}
                                className="fa-solid fa-trash-can ms-2"
                                onClick={() => handleRemove(index)}
                                title="Remove Software"
                              ></i>
                            }
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {validationList?.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <b>Example {index + 1}</b>
                        </td>
                        <td>
                          <b>Output</b>
                        </td>
                        {data?.validation?.map((item, key) => (
                          <td key={key}>
                            <Form.Check
                              type="radio"
                              name={`${item.software}_${key}_${index}`}
                              id={`${item.software}_${key}_${index}_notmatch`}
                              label="Match"
                              checked={item.validation === "Match"}
                              onChange={() =>
                                handleUpdateValidation(
                                  data._id,
                                  item.software,
                                  "Match"
                                )
                              }
                            />
                            <Form.Check
                              type="radio"
                              name={`${item.software}_${key}_${index}`}
                              id={`${item.software}_${key}_${index}_notmatch`}
                              label="Not Match"
                              checked={item.validation === "Not Match"}
                              onChange={() =>
                                handleUpdateValidation(
                                  data._id,
                                  item.software,
                                  "Not Match"
                                )
                              }
                            />
                            {item?.output ?
                              <>
                                <div className="hstack gap-2 mt-3">
                                  <a target="_blank" rel="noopener noreferrer" href={item?.output}>
                                    <i
                                      className="fa-solid fa-file-pdf"
                                      style={{ fontSize: 17, cursor: "pointer", color: "#000" }}
                                      title="View Output"
                                    ></i>
                                  </a>
                                  <i
                                    onClick={(e) => removeOutputFile(data._id, item.software, index, item.validation)}
                                    className="fa-regular fa-circle-xmark"
                                    style={{ color: "red", cursor: "pointer" }}
                                    title="Remove Output"
                                  ></i>
                                </div>
                                <Form.Text>example-{index + 1}-{item.software}-output.pdf</Form.Text>
                              </>
                              :
                              <>
                                <Form.Text>
                                  <b>Upload {item.software} Output (.pdf)</b>
                                </Form.Text>
                                <Form.Control
                                  type="file"
                                  id="fileUpload"
                                  accept=".pdf"
                                  onChange={(e) => handleFileUpload(data._id, item.software, e)}
                                />
                              </>
                            }
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Validation Status<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setStatus(e.target.value)}
              aria-label="Default select example"
              style={{ fontSize: "13px" }}
            >
              <option>Select Validation Status</option>
              <option selected={status === "completed"} value="completed">Completed</option>
              <option selected={status === "rejected"} value="rejected">Rejected</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="pb-3">
            <Form.Label>
              Remark<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              required
              as="textarea"
              placeholder="Enter Remark"
              autoFocus
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
          </Form.Group>
          {status === "completed" && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>
                  E-Signature <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="position-relative">
                  <InputGroup className="pb-4">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa-solid fa-lock"></i>
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      aria-label="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  <div
                    className="password_visible"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <i className="fa-regular fa-eye"></i>
                    ) : (
                      <i className="fa-regular fa-eye-slash"></i>
                    )}
                  </div>
                </div>
              </Form.Group>
            </>
          )}
        </div>
        <div className="button_type footer_btn text-end px-3 pt-2">
          <button
            disabled={!status || !remark || !password || exampleList?.length === 0 || !isValid}
            onClick={packageValidationStatusForUpdatePackage}
          >
            {submit}
          </button>
        </div>
      </Modal>

      <ProcedureValidationOutput
        bounds={bounds}
        onStart={onStart}
        disabled={disabled}
        show={procedureValidationData}
        draggleRef={draggleRef}
        output={output}
        title={"Procedure Validation Output"}
        setShow={setProcedureValidationData}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
      />

      <AddSoftwareValidation
        bounds={bounds}
        onStart={onStart}
        submit={"Add"}
        disabled={disabled}
        show={interSoftwareValidation}
        draggleRef={draggleRef}
        title={"Add Inter Software Validation"}
        setShow={setInterSoftwareValidation}
        onMouseOut={onMouseOut}
        addData={addData}
        onMouseOver={onMouseOver}
      />
    </>
  );
};
export default PackageFunctionFinalValidationForUpdate;
