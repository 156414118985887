import { Modal } from "antd";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Draggable from "react-draggable";

const ProcedureValidationOutput = ({
  show,
  title,
  bounds,
  setShow,
  onStart,
  disabled,
  draggleRef,
  onMouseOut,
  onMouseOver,
  output,
}) => {
  const [fontSize, setFontSize] = useState(12);

  function fontIncrease() {
    setFontSize((prevFontSize) => prevFontSize + 1);
  }

  function fontDecrease() {
    setFontSize((prevFontSize) => Math.max(prevFontSize - 1, 5));
  }
  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={500}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="OutputToolbar border-bottom ">
          <div className="OutputToolbar-Left px-2 hstack">
            <i
              class="p-2 fa-solid fa-square-plus"
              onClick={fontIncrease}
              title="Increase Font Size"
            ></i>
            <div className="vr"></div>
            <i
              class="p-2 fa-solid fa-square-minus"
              onClick={fontDecrease}
              title="Decrease Font Size"
            ></i>
            <div className="vr"></div>
            <Dropdown style={{ marginTop: "-4px" }}>
              <Dropdown.Toggle
                variant="none"
                id="dropdown-basic"
                className="m-0 p-1 px-2"
              >
                <i
                  className="fa-solid fa-download"
                  title="Download Generated Data"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" title="Download result as an HTML file">
                  <i className="fa-solid fa-file-code"></i> HTML
                </Dropdown.Item>
                <Dropdown.Item href="#" title="Download result as an PDF file">
                  <i className="fa-solid fa-file-pdf"></i> PDF
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="overflow-auto" style={{ maxHeight: "calc(100vh - 250px" }}>
          <div
            style={{ fontSize: fontSize }}
            dangerouslySetInnerHTML={{ __html: output }}
          />
        </div>
      </Modal>
    </>
  );
};

export default ProcedureValidationOutput;
