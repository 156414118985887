import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "react-bootstrap";
import ACEEditor from "../../Component/Editor/ACEEditor";
import LogConsole from "./ConsoleLogs/LogConsole";
import HistoryConsole from "./HistoryConsole";
import { packagesServices } from "../../APIServices/Editor/packagesServices";
import "ace-builds/src-noconflict/ext-beautify";
import UpdatePackageInformationConsole from "./HeaderInformation/UpdatePackageInformationConsole";
import OutputConsole from "./ConsoleOutputs/OutputConsole";
import EditorShortcutKeys from "./EditorShortcutKeys";
import SyntaxFunction from "./SyntaxFunction";
import ConsultantPackageList from "./ConsultantPackageList";
import UpdateProjectInformationConsole from "./HeaderInformation/UpdateProjectInformationConsole";
import { consultantServices } from "../../APIServices/Editor/consultantServices";
import ConsultantLogMainConsole from "./ConsoleLogs/ConsultantLogMainConsole";
import ConsultantOutputMainConsole from "./ConsoleOutputs/ConsultantOutputMainConsole";

function EditorComponent({
  editorFullMode,
  setEditorGraphics,
  editorTheme,
  fileId,
  editorValue,
  setEditorValue,
  codeFileStatus,
  getPackageFileCodeById,
  setCodeLocalData,
  setCurrentCodeCompareData,
  currentCodeCompareData,
  codeCompare,
  setCodeCompare,
  checkCompareData,
  activeTab,
  setOpenRightTab,
  openRightTab,
  roleType,
  handleTabChange,
  copy,
  copyToClipboard
}) {
  const [consoleWidth, setConsoleWidth] = useState(1100);
  const [searchData, setsearchData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [gotoLine, setGotoLine] = useState("");
  const [gotoReplace, setGotoReplace] = useState("");
  const [lineNumber, setLineNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCode, setSelectedCode] = useState("");
  const [editorRow, setEditorRow] = useState(1);
  const [editorColumn, setEditorColumn] = useState(1);
  const [totalLineNumber, setTotalLineNumber] = useState(0);
  const [fontSize, setFontSize] = useState(13);
  const [loader, setLoader] = useState(false);

  const onConsoleResizeX = (e) => {
    document.addEventListener("mousemove", onmousemove);
    document.addEventListener("mouseup", onmouseup);
    function onmousemove(e) {
      setConsoleWidth(e.pageX);
    }
    function onmouseup(e) {
      document.removeEventListener("mousemove", onmousemove);
      document.removeEventListener("mouseup", onmouseup);
    }
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const editorRef = useRef(null);

  const clearEditorData = () => {
    if (editorRef.current) {
      editorRef.current.editor.setValue("");
    }
    setEditorValue("");
  };

  const updateUndoRedoState = () => {
    if (editorRef.current) {
      const undoManager = editorRef.current.editor
        .getSession()
        .getUndoManager();
      setCanUndo(undoManager.hasUndo());
      setCanRedo(undoManager.hasRedo());
    }
  };

  const undoEditorData = () => {
    if (editorRef.current) {
      editorRef.current.editor.undo();
      updateUndoRedoState();
    }
  };

  const redoEditorData = () => {
    if (editorRef.current) {
      editorRef.current.editor.redo();
      updateUndoRedoState();
    }
  };

  const searchFindData = () => {
    setsearchData(!searchData);
  };

  const gotoReplaceData = () => {
    setGotoReplace(!gotoReplace);
  };

  const formatCode = () => {
    const beautify = require("ace-builds/src-noconflict/ext-beautify");
    if (editorRef.current) {
      const session = editorRef.current.editor.getSession();
      beautify.beautify(session);
    }
  };

  function cursorRowColumn() {
    const editor = editorRef.current.editor;
    let result = editor.selection.getCursor();
    setEditorRow(result?.row);
    setEditorColumn(result?.column);

    let totalLength = editor.session.getLength();
    setTotalLineNumber(totalLength);

    let selectedCode = editor.getSelectedText();
    setSelectedCode(selectedCode);
  }

  const gotoCodeLine = () => {
    setGotoLine(!gotoLine);
    setLineNumber("");
    setErrorMessage("");
  };

  const goToLineFunction = (lineNumber) => {
    setLineNumber(lineNumber);
    if (editorRef.current) {
      const editor = editorRef.current.editor;
      const lineCount = editor.session.getLength();

      if (lineNumber > 0 && lineNumber <= lineCount) {
        editor.gotoLine(lineNumber, 0, true);
        setErrorMessage("");
      } else {
        setErrorMessage(`Line ${lineNumber} not found.`);
      }
    }
  };

  const handleEditorSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
    if (editorRef && editorRef.current && editorRef.current.editor) {
      editorRef.current.editor.find(searchQuery, {
        backwards: false,
        wrap: true,
        caseSensitive: false,
        wholeWord: false,
        regExp: false,
      });
    }
  };

  const runDeveloperPythonCode = async () => {
    setLoader(true);
    setOpenRightTab("")
    let userData = {};
    userData.id = fileId;
    userData.code = selectedCode ? selectedCode : editorValue;
    let data = await packagesServices.runDeveloperPythonCode(userData);
    if (data?.statusCode === 200) {
      setLoader(false);
      if (data?.runResult) {
        getPackageFileOutputById(fileId);
      } else {
        getPackageFileLogsById(fileId);
      }
    }
  };

  const runDeveloperGASCode = async () => {
    setLoader(true);
    setOpenRightTab("")
    let userData = {};
    userData.id = fileId;
    userData.code = selectedCode ? selectedCode : editorValue;
    let data;
    if (roleType === "consultant") {
      data = await packagesServices.runGASCodeWithMultiFunction(userData);
    } else {
      data = await packagesServices.runDeveloperGASCode(userData);
    }
    if (data?.statusCode === 200) {
      setLoader(false);
      if (data?.runResult) {
        if (roleType === "consultant") {
          getProjectFileOutputById(fileId);
        } else {
          getPackageFileOutputById(fileId);
        }
      } else {
        if (roleType === "consultant") {
          getProjectFileLogById(fileId);
        } else {
          getPackageFileLogsById(fileId);
        }
      }
    }
  };

  const [codeOutputs, setCodeOutputs] = useState("");
  const [codeArrayOutputs, setCodeArrayOutputs] = useState([]);
  const [functionName, setFunctionName] = useState("");
  const [outputsRunTime, setOutputsRunTime] = useState(0);
  const [type, setType] = useState("");

  const getPackageFileOutputById = async (fileId) => {
    let data = await packagesServices?.getPackageFileOutputById(fileId);
    setCodeOutputs(data?.output);
    setOutputsRunTime(data?.runTimes);
    setFunctionName(data?.functionName);
    setType(data?.type);
    setOpenRightTab("outputResult");
  };

  const getProjectFileOutputById = async (fileId) => {
    let data = await consultantServices?.getProjectFileOutputById(fileId);
    setCodeArrayOutputs(data?.output);
    setOutputsRunTime(data?.runTimes);
    setOpenRightTab("outputResult");
  };

  const [codeLogs, setCodeLogs] = useState("");
  const [codeArrayLogs, setCodeArrayLogs] = useState([]);
  const [logsRunTime, setLogsRunTime] = useState(0);

  const getPackageFileLogsById = async (fileId) => {
    let data = await packagesServices?.getPackageFileLogsById(fileId);
    setCodeLogs(data?.log);
    setLogsRunTime(data?.runTimes);
    setOpenRightTab("logs");
  };

  const getProjectFileLogById = async (fileId) => {
    let data = await consultantServices?.getProjectFileLogById(fileId);
    setCodeArrayLogs(data?.log);
    setLogsRunTime(data?.runTimes);
    setOpenRightTab("logs");
  };

  const updatePackageFileCode = async () => {
    let userData = {};
    userData.id = fileId;
    userData.code = editorValue;
    let data

    if (roleType === "consultant") {
      data = await consultantServices.updateProjectFileCode(userData);
    } else {
      data = await packagesServices.updatePackageFileCode(userData);
    }
    if (data?.statusCode === 200) {
      let codeCompare = JSON.parse(localStorage.getItem("codeCompare")) || [];
      let object = {
        status: false,
        id: fileId,
      };
      if (!codeCompare) {
        localStorage.setItem("codeCompare", JSON.stringify([object]));
      } else {
        const compareCodeList = codeCompare.filter((tab) => tab.id !== fileId);
        compareCodeList.push(object);
        localStorage.setItem("codeCompare", JSON.stringify(compareCodeList));
      }
    }
    setCodeCompare(JSON.parse(localStorage.getItem("codeCompare")) || []);
  };

  function fontIncrease() {
    setFontSize((prevFontSize) => prevFontSize + 1);
  }

  function fontDecrease() {
    setFontSize((prevFontSize) => Math.max(prevFontSize - 1, 5));
  }

  const [canRedo, setCanRedo] = useState(false);
  const [canUndo, setCanUndo] = useState(false);

  const pushProjectFileCodeinProductionMode = async() => {
    let userData = {}
    userData.id = fileId
    await consultantServices.pushProjectFileCodeinProductionMode(userData)
  }

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.editor;
      editor.commands.addCommand({
        name: "save",
        bindKey: { win: "Ctrl-S", mac: "Command-S" },
        exec: () => {
          updatePackageFileCode();
        },
      });
      editor.commands.addCommand({
        name: "run",
        bindKey: { win: "Ctrl-R", mac: "Command-R" },
        exec: () => {
          activeTab[0]?.includes(".glx")
            ? runDeveloperPythonCode()
            : runDeveloperGASCode();
        },
      });
      editor.commands.addCommand({
        name: "find",
        bindKey: { win: "Ctrl-F", mac: "Command-F" },
        exec: () => {
          searchFindData();
        },
      });
      editor.commands.addCommand({
        name: "replace",
        bindKey: { win: "Ctrl-H", mac: "Command-H" },
        exec: () => {
          gotoReplaceData();
        },
      });
      editor.commands.addCommand({
        name: "undo",
        bindKey: { win: "Ctrl-Z", mac: "Command-Z" },
        exec: () => {
          undoEditorData();
        },
      });
      editor.commands.addCommand({
        name: "undo",
        bindKey: { win: "Ctrl-Shift-Y", mac: "Command-Shift-Y" },
        exec: () => {
          undoEditorData();
        },
      });
      editor.commands.addCommand({
        name: "redo",
        bindKey: { win: "Ctrl-Y", mac: "Command-Y" },
        exec: () => {
          redoEditorData();
        },
      });
      editor.commands.addCommand({
        name: "redo",
        bindKey: { win: "Ctrl-Shift-Z", mac: "Command-Shift-Z" },
        exec: () => {
          redoEditorData();
        },
      });
      editor.commands.addCommand({
        name: "fontIncrease",
        bindKey: { win: "Ctrl-+", mac: "Command-+" },
        exec: () => {
          fontIncrease();
        },
      });
      editor.commands.addCommand({
        name: "fondDecrease",
        bindKey: { win: "Ctrl--", mac: "Command--" },
        exec: () => {
          fontDecrease();
        },
      });
      editor.commands.addCommand({
        name: "clear",
        bindKey: { win: "Ctrl-E", mac: "Command-E" },
        exec: () => {
          clearEditorData();
        },
      });
      editor.commands.addCommand({
        name: "formatting",
        bindKey: { win: "Ctrl-Shift+F", mac: "Command-Shift+F" },
        exec: () => {
          formatCode();
        },
      });
      editor.commands.addCommand({
        name: "stop",
        bindKey: { win: "Esc", mac: "Esc" },
        exec: () => {
          setLoader(false);
        },
      });
      editor.commands.addCommand({
        name: "logs",
        bindKey: { win: "Alt-L", mac: "Option-L" },
        exec: () => {
          roleType === "consultant" ? getProjectFileLogById(fileId) : getPackageFileLogsById(fileId)
        },
      });
      editor.commands.addCommand({
        name: "output",
        bindKey: { win: "Alt-O", mac: "Option-O" },
        exec: () => {
          roleType === "consultant" ? getProjectFileOutputById(fileId) : getPackageFileOutputById(fileId)
        },
      });
      editor.commands.addCommand({
        name: "info",
        bindKey: { win: "Alt-I", mac: "Option-I" },
        exec: () => {
          setOpenRightTab("packageInfo");
        },
      });
      editor.commands.addCommand({
        name: "version",
        bindKey: { win: "Alt-V", mac: "Option-V" },
        exec: () => {
          setOpenRightTab("versionHistory");
        },
      });
      editor.commands.addCommand({
        name: "compare",
        bindKey: { win: "Alt-C", mac: "Option-C" },
        exec: () => {
          setCurrentCodeCompareData(!currentCodeCompareData);
        },
      });
      editor.commands.addCommand({
        name: "toLowerCase",
        bindKey: { win: "Ctrl-L", mac: "Command-L" },
        exec: function (editor) {
          const selectedText = editor.getSelectedText();
          if (selectedText) {
            const lowerText = selectedText.toLowerCase();
            editor.session.replace(editor.getSelectionRange(), lowerText);
          }
        },
        readOnly: false,
      });
      editor.commands.addCommand({
        name: "cut",
        bindKey: { win: "Ctrl-X", mac: "Command-X" },
        exec: function (editor) {
          const selectedText = editor.getSelectedText();
          if (selectedText) {
            navigator.clipboard.writeText(selectedText).then(() => {
              editor.session.replace(editor.getSelectionRange(), "");
            });
          } else {
            const { row } = editor.getCursorPosition();
            const lineText = editor.session.getLine(row);
            navigator.clipboard.writeText(lineText).then(() => {
              editor.session.remove({
                start: { row: row, column: 0 },
                end: { row: row, column: lineText.length },
              });
            });
          }
        },
        readOnly: false,
      });
      editor.commands.addCommand({
        name: "toUpperCase",
        bindKey: { win: "Ctrl-U", mac: "Command-U" },
        exec: function (editor) {
          const selectedText = editor.getSelectedText();
          if (selectedText) {
            const upperText = selectedText.toUpperCase();
            editor.session.replace(editor.getSelectionRange(), upperText);
          }
        },
        readOnly: false,
      });
      editor.commands.addCommand({
        name: "copy",
        bindKey: { win: "Ctrl-C", mac: "Command-C" },
        exec: function (editor) {
          const selectedText = editor.getSelectedText();
          if (selectedText) {
            navigator.clipboard.writeText(selectedText);
          } else {
            const { row } = editor.getCursorPosition();
            const lineText = editor.session.getLine(row);
            navigator.clipboard.writeText(lineText);
          }
        },
        readOnly: false,
      });

      editor.commands.addCommand({
        name: "moveLineUp",
        bindKey: { win: "Alt-Up", mac: "Option-Up" },
        exec: function (editor) {
          const range = editor.getSelectionRange();
          if (!range.isEmpty()) {
            editor.moveLinesUp();
          } else {
            const pos = editor.getCursorPosition();
            editor.moveLinesUp();
            editor.moveCursorToPosition({
              row: pos.row - 1,
              column: pos.column,
            });
          }
        },
        readOnly: false,
      });

      editor.commands.addCommand({
        name: "moveLineDown",
        bindKey: { win: "Alt-Down", mac: "Option-Down" },
        exec: function (editor) {
          const range = editor.getSelectionRange();
          if (!range.isEmpty()) {
            editor.moveLinesDown();
          } else {
            const pos = editor.getCursorPosition();
            editor.moveLinesDown();
            editor.moveCursorToPosition({
              row: pos.row + 1,
              column: pos.column,
            });
          }
        },
        readOnly: false,
      });

      const undoManager = editor.getSession().getUndoManager();

      const updateUndoRedoState = () => {
        setCanUndo(undoManager.hasUndo());
        setCanRedo(undoManager.hasRedo());
      };

      editor.getSession().on("change", updateUndoRedoState);

      updateUndoRedoState();
    }
    getPackageFileCodeById();
  }, [fileId]);

  let activeTabName = activeTab[0].split(" ")[0];
  return (
    <>
      <div className="border-bottom px-2 py-1">
        <div className="hstack justify-content-between">
          <div className="hstack gap-2 header_tool">
            <i
              onClick={updatePackageFileCode}
              className="fa-solid fa-floppy-disk"
              title="Save"
              style={
                codeCompare?.find((item) => item.id === fileId && item.status)
                  ? { cursor: "pointer" }
                  : { cursor: "no-drop", background: "transparent" }
              }
            ></i>
            <div className="vr"></div>
            {loader ? (
              <span className="loader" title="Runing"></span>
            ) : codeFileStatus === "lock" &&
              activeTab[0]?.includes(".glx") ? (
              <i
                onClick={() => {
                  roleType === "developer"
                    ? runDeveloperPythonCode()
                    : runDeveloperGASCode();
                }}
                className="fa-solid fa-play"
                title="Run"
              ></i>
            ) : (
              <i
                onClick={() => {
                  roleType === "developer"
                    ? runDeveloperPythonCode()
                    : runDeveloperGASCode();
                }}
                className="fa-solid fa-play"
                title="Run"
              ></i>
            )}
            <div className="vr"></div>
            {loader && (
              <>
                <i
                  onClick={() => setLoader(false)}
                  className="fa-solid fa-stop"
                  title="Stop"
                ></i>
                <div className="vr"></div>
              </>
            )}
            {codeFileStatus === "lock" ? (
              <i
                style={{ cursor: "no-drop", background: "transparent" }}
                className="fa-solid fa-file-circle-xmark"
                title={"Clear Code"}
              ></i>
            ) : (
              <i
                style={
                  !editorValue
                    ? { cursor: "no-drop", background: "transparent" }
                    : { cursor: "pointer" }
                }
                className="fa-solid fa-file-circle-xmark"
                title={editorValue ? "Clear Code" : "No Code"}
                onClick={clearEditorData}
              ></i>
            )}
            <div className="vr"></div>
            <i
              className={`${searchData
                ? "fa-solid fa-magnifying-glass activee__icon"
                : "fa-solid fa-magnifying-glass"
                }`}
              title="Find and Replace"
              onClick={() => searchFindData()}
            ></i>
            <div className="vr"></div>
            {codeFileStatus === "lock" ? (
              <i
                style={{ cursor: "no-drop", background: "transparent" }}
                className="fa-solid fa-reply"
                title={"No Undo"}
              ></i>
            ) : (
              <i
                style={
                  !canUndo
                    ? { cursor: "no-drop", background: "transparent" }
                    : { cursor: "pointer" }
                }
                className="fa-solid fa-reply"
                title={!canUndo ? "No Undo" : "Undo"}
                onClick={() => {
                  !canUndo ? setCanUndo(false) : undoEditorData();
                }}
              ></i>
            )}
            <div className="vr"></div>
            <i
              style={
                !canRedo
                  ? { cursor: "no-drop", background: "transparent" }
                  : { cursor: "pointer" }
              }
              className="fa-solid fa-share"
              title={!canRedo ? "No Redo" : "Redo"}
              onClick={() => {
                !canRedo ? setCanRedo(false) : redoEditorData();
              }}
            ></i>
            <div className="vr"></div>
            {codeFileStatus === "lock" ? (
              <i
                className="fa-solid fa-bars"
                title="Format"
                style={{ cursor: "no-drop", background: "transparent" }}
              ></i>
            ) : (
              <i
                onClick={formatCode}
                className="fa-solid fa-bars"
                title="Format"
              ></i>
            )}
            <div className="vr"></div>
            <i
              onClick={fontIncrease}
              className="fa-solid fa-plus"
              title="Increase Font Size"
            ></i>
            <div className="vr"></div>
            <i
              style={
                fontSize === 5
                  ? { cursor: "no-drop", background: "transparent" }
                  : { cursor: "pointer" }
              }
              onClick={fontDecrease}
              className="fa-solid fa-minus"
              title={
                fontSize === 5
                  ? "Not Permissable Font Size"
                  : "Decrease Font Size"
              }
            ></i>
            <div className="vr"></div>
            {selectedCode ? (
              <i
                onClick={() => copyToClipboard(selectedCode, "code")}
                className={
                  copy === "code"
                    ? "fa-regular fa-circle-check"
                    : "fa-solid fa-copy"
                }
                title={copy === "code" ? "Copied" : "Copy"}
              ></i>
            ) : (
              <i
                onClick={() => copyToClipboard(editorValue, "code")}
                className={
                  copy === "code"
                    ? "fa-regular fa-circle-check"
                    : "fa-solid fa-copy"
                }
                title={copy === "code" ? "Copied" : "Copy"}
              ></i>
            )}
            <div className="vr"></div>
            <i
              onClick={pushProjectFileCodeinProductionMode}
              className="fa-solid fa-upload"
              title="Push Code in Production"
            ></i>
            <div className="vr"></div>
            <i
              className={`${gotoLine ? "fa-solid fa-road activee__icon" : "fa-solid fa-road"
                }`}
              onClick={() => gotoCodeLine()}
              title="Goto"
            ></i>
            {gotoLine && (
              <input
                type="number"
                value={lineNumber}
                onChange={(e) => goToLineFunction(e.target.value)}
                className="goto_class"
              />
            )}
            <span style={{ color: "red", fontSize: 13 }}>
              {lineNumber && errorMessage}
            </span>
          </div>
          {/* <p
            className="view__graphn"
            title="Comming Soon..."
            style={{ cursor: "no-drop" }}
            onClick={() => setEditorGraphics("graphics")}
          >
            Graphical View
          </p> */}
        </div>
      </div>
      <div className={!isFullScreen ? "d-flex" : ""}>
        {!isFullScreen && (
          <div
            className={`w-100 findWrapper ${!editorFullMode ? "CodePoint" : "fullModeHeight"
              }`}
          >
            <ACEEditor
              editorTheme={editorTheme}
              editorRef={editorRef}
              editorValue={editorValue}
              codeFileStatus={codeFileStatus}
              fontSize={fontSize}
              formatCode={formatCode}
              cursorRowColumn={cursorRowColumn}
              setCodeLocalData={setCodeLocalData}
              checkCompareData={checkCompareData}
              setCodeCompare={setCodeCompare}
            />
            {searchData && (
              <>
                <div
                  className={`findandreplce ${editorTheme ? "findandreplce__whiteMode" : "findandreplce__darkMode"}`}
                >
                  <div className="hstack gap-2">
                    <i
                      className={
                        gotoReplace
                          ? "fa-solid fa-angle-down"
                          : "fa-solid fa-chevron-right"
                      }
                      title="Toggle Replace"
                      onClick={() => gotoReplaceData()}
                    ></i>
                    <div>
                      <div className="hstack gap-2">
                        <input
                          type="search"
                          className={editorTheme ? "whiteMode" : "darkMode"}
                          title="Find"
                          placeholder="Find (For History)"
                          value={searchQuery}
                          onChange={(e) => handleEditorSearch(e.target.value)}
                        />
                        <div>
                          <span style={{ color: "red" }}>No Result</span>
                        </div>
                        <i
                          className="fa-solid fa-arrow-up"
                          title="Previous Match"
                        ></i>
                        <i
                          className="fa-solid fa-arrow-down"
                          title="Next Match"
                          onClick={(e) => handleEditorSearch(searchQuery)}
                        ></i>
                        <i
                          className="fa-solid fa-xmark"
                          title="Close"
                          onClick={() => searchFindData()}
                        ></i>
                      </div>
                      {gotoReplace && (
                        <div className="hstack gap-2">
                          <input
                            type="search"
                            title="Replace"
                            className={editorTheme ? "whiteMode" : "darkMode"}
                            placeholder="Replace"
                          />
                          <i
                            className="fa-regular fa-clone"
                            title="Replace"
                          ></i>
                          <i
                            className="fa-regular fa-paste"
                            title="Replace All"
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div
          className={`line__output ${!editorTheme ? "light_mode_output" : "dark_mode_output"}`}
        >
          No. of Lines: {totalLineNumber} | Line: {editorRow + 1} | Col:{" "}
          {editorColumn + 1}
        </div>
        <div
          className={`outputtool_point ${!editorFullMode ? "outputtool_point__height" : "fullModeHeight"
            }`}
        >
          <ul>
            <li
              onClick={() => {
                openRightTab === "logs"
                  ? setOpenRightTab("")
                  : (roleType === "consultant" ? getProjectFileLogById(fileId) : getPackageFileLogsById(fileId));
              }}
              aria-controls="example-collapse-text"
              title="View Error Logs"
              className={openRightTab === "logs" ? "activee" : " "}
            >
              <i className="fa-solid fa-file-lines"></i>
            </li>
            <li
              onClick={() => {
                openRightTab === "outputResult"
                  ? setOpenRightTab("")
                  : (roleType === "consultant" ? getProjectFileOutputById(fileId) : getPackageFileOutputById(fileId));
              }}
              aria-controls="example-collapse-text"
              title="View Output"
              className={
                openRightTab === "outputResult" ? "activee" : " "
              }
            >
              <i class="fa-solid fa-computer"></i>
            </li>
            {((roleType === "subadmin" || roleType === "validator") && (activeTabName === "validation.glx" || activeTabName === "function.glx")) &&
              <li
                onClick={() => {
                  openRightTab === "syntax"
                    ? setOpenRightTab("")
                    : setOpenRightTab("syntax");
                }}
                aria-controls="example-collapse-text"
                title="Syntax"
                className={openRightTab === "syntax" ? "activee" : " "}
              >
                <i class="fa-solid fa-florin-sign"></i>
              </li>
            }
            {roleType === "consultant" && (
              <li
                onClick={() => {
                  openRightTab === "packages"
                    ? setOpenRightTab("")
                    : setOpenRightTab("packages");
                }}
                aria-controls="example-collapse-text"
                title="Packages"
                className={openRightTab === "packages" ? "activee" : " "}
              >
                <i class="fa-solid fa-cube"></i>
              </li>
            )}
            {(roleType === "developer" || roleType === "consultant") &&
              <li
                onClick={() => {
                  openRightTab === "versionHistory"
                    ? setOpenRightTab("")
                    : setOpenRightTab("versionHistory");
                }}
                aria-controls="example-collapse-text"
                title="Version History"
                className={openRightTab === "versionHistory" ? "activee" : " "}
              >
                <i class="fa-solid fa-clock-rotate-left"></i>
              </li>
            }
            <li
              onClick={() => {
                openRightTab === "packageInfo"
                  ? setOpenRightTab("")
                  : setOpenRightTab("packageInfo");
              }}
              aria-controls="example-collapse-text"
              title="Information"
              className={openRightTab === "packageInfo" ? "activee" : " "}
            >
              <i className="fa-solid fa-info"></i>
            </li>
            <li
              onClick={() => {
                openRightTab === "shortcutKeys"
                  ? setOpenRightTab("")
                  : setOpenRightTab("shortcutKeys");
              }}
              aria-controls="example-collapse-text"
              title="Shortcut Keys"
              className={openRightTab === "shortcutKeys" ? "activee" : " "}
            >
              <i class="fa-solid fa-layer-group"></i>
            </li>
          </ul>
        </div>
        <Collapse in={openRightTab === "logs"} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                style={{background: "#ebebeb"}}
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              {roleType === "consultant" ?
                <ConsultantLogMainConsole
                  width={consoleWidth}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  editorFullMode={editorFullMode}
                  codeLogs={codeArrayLogs}
                  copy={copy}
                  copyToClipboard={copyToClipboard}
                  logsRunTime={logsRunTime}
                  editorTheme={editorTheme}
                />
                :
                <LogConsole
                  width={consoleWidth}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  editorFullMode={editorFullMode}
                  codeLogs={codeLogs}
                  copy={copy}
                  editorTheme={editorTheme}
                  copyToClipboard={copyToClipboard}
                  logsRunTime={logsRunTime}
                />
              }
            </div>
          </div>
        </Collapse>
        <Collapse in={openRightTab === "packageInfo"} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                style={{background: "#ebebeb"}}
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              {roleType === "consultant" ?
                <UpdateProjectInformationConsole
                  width={consoleWidth}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  fileId={fileId}
                  editorFullMode={editorFullMode}
                  editorTheme={editorTheme}
                />
                :
                <UpdatePackageInformationConsole
                  width={consoleWidth}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  fileId={fileId}
                  editorFullMode={editorFullMode}
                  editorTheme={editorTheme}
                />
              }
            </div>
          </div>
        </Collapse>
        <Collapse in={openRightTab === "versionHistory"} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                style={{background: "#ebebeb"}}
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              <HistoryConsole
                width={consoleWidth}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                fileId={fileId}
                editorFullMode={editorFullMode}
                editorTheme={editorTheme}
              />
            </div>
          </div>
        </Collapse>
        <Collapse in={openRightTab === "packages"} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                style={{background: "#ebebeb"}}
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              <ConsultantPackageList
                width={consoleWidth}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                handleTabChange={handleTabChange}
                editorFullMode={editorFullMode}
                editorTheme={editorTheme}
              />
            </div>
          </div>
        </Collapse>
        <Collapse in={openRightTab === "syntax"} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                style={{background: "#ebebeb"}}
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              <SyntaxFunction
                width={consoleWidth}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                fileId={fileId}
                editorFullMode={editorFullMode}
                editorTheme={editorTheme}
              />
            </div>
          </div>
        </Collapse>
        <Collapse in={openRightTab === "outputResult"} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                style={{background: "#ebebeb"}}
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              {roleType === "consultant" ?
                <ConsultantOutputMainConsole
                  width={consoleWidth}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  editorFullMode={editorFullMode}
                  codeOutputs={codeArrayOutputs}
                  fileId={fileId}
                  roleType={roleType}
                  editorTheme={editorTheme}
                  codeFileStatus={codeFileStatus}
                />
                :
                <OutputConsole
                  width={consoleWidth}
                  isFullScreen={isFullScreen}
                  codeOutputs={codeOutputs}
                  functionName={functionName}
                  setIsFullScreen={setIsFullScreen}
                  fileId={fileId}
                  roleType={roleType}
                  editorTheme={editorTheme}
                  editorValue={selectedCode ? selectedCode : editorValue}
                  editorFullMode={editorFullMode}
                  codeFileStatus={codeFileStatus}
                  type={type}
                />
              }
            </div>
          </div>
        </Collapse>
        <Collapse in={openRightTab === "shortcutKeys"} dimension="width">
          <div id="example-collapse-text">
            <div className="d-flex">
              <div
                className="resizer-x"
                style={{background: "#ebebeb"}}
                onMouseDown={onConsoleResizeX}
                onTouchStart={onConsoleResizeX}
              ></div>
              <EditorShortcutKeys
                width={consoleWidth}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                fileId={fileId}
                  editorTheme={editorTheme}
                  editorFullMode={editorFullMode}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
}

export default EditorComponent;
