import React, { useEffect, useRef, useState } from "react";
import PackageInformationConsole from "../HeaderInformation/PackageInformationConsole";
import MAKETTESTOutput from "../GraphicalOutput/MAKETTESTOutput";
import DynamicTableRowColumn from "../GraphicalOutput/DynamicTableRowColumn";
import { packagesServices } from "../../../APIServices/Editor/packagesServices";
import ReactDOMServer from "react-dom/server";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import AddOutputForConsultantExample from "../../AdminValidator/Packages/AddOutputForConsultantExample";
import ExampleForOutputByAdminValidator from "../../AdminValidator/Packages/ExampleForOutputByAdminValidator";
import MAKESUMMARYOutput from "../GraphicalOutput/MAKESUMMARYOutput";
import ProjectInformationConsole from "../HeaderInformation/ProjectInformationConsole";
import { NavLink } from "react-router-dom";

const OutputConsole = ({
  width,
  isFullScreen,
  setIsFullScreen,
  editorFullMode,
  codeOutputs,
  functionName,
  fileId,
  roleType,
  editorValue,
  codeFileStatus,
  type,
  editorTheme
}) => {
  const [packageInfo, setPackageInfo] = useState("");
  const [loader, setLoader] = useState(false);
  const [fontSize, setFontSize] = useState(12);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [addOutputExample, setAddOutputExample] = useState(false);
  const [viewAllOutputExample, setViewAllOutputExample] = useState(false);
  const [exampleOutputList, setExampleOutputList] = useState([]);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  function fontIncrease() {
    setFontSize((prevFontSize) => prevFontSize + 1);
  }

  function fontDecrease() {
    setFontSize((prevFontSize) => Math.max(prevFontSize - 1, 5));
  }

  const getPackageExampleForConsultant = async () => {
    let data = await packagesServices.getPackageExampleForConsultant(fileId);
    setExampleOutputList(data?.data);
    setViewAllOutputExample(true);
  };
  const addExampleForPackageValidation = async (roleType) => {
    let output = ReactDOMServer.renderToString(
      codeOutputs ? (
        type === "output" ? (
          <>
            {roleType === "consultant" ? (
              <ProjectInformationConsole packageInfo={packageInfo} />
            ) : (
              <PackageInformationConsole packageInfo={packageInfo} />
            )}
            {functionName === "MAKE TTEST" ? (
              <MAKETTESTOutput
                codeOutputs={codeOutputs}
                functionName={functionName}
              />
            ) : functionName === "MAKE RANDOM" ||
              functionName === "MAKE SORT" ||
              functionName === "MAKE SWITCH" ||
              functionName === "MAKE MERGE" ? (
              <DynamicTableRowColumn
                codeOutputs={codeOutputs}
                functionName={functionName}
              />
            ) : functionName === "MAKE SUMMARY" ? (
              <MAKESUMMARYOutput
                codeOutputs={codeOutputs}
                functionName={functionName}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <SyntaxHighlighter style={docco}>{codeOutputs}</SyntaxHighlighter>
        )
      ) : (
        <div className="text-center">No Output</div>
      )
    );
    setLoader(true);
    let userData = {};
    userData.code = editorValue;
    userData.id = fileId;
    userData.output = output;

    if (roleType === "subadmin") {
      userData.name = name;
      userData.description = description;
    }

    let data;
    if (roleType === "validator") {
      data = await packagesServices.addExampleForPackageValidation(userData);
    } else if (roleType === "subadmin") {
      data = await packagesServices.addPackageExampleForConsulatnt(userData);
    }
    if (data?.statusCode === 200) {
      setLoader(false);
      setName("");
      setDescription("");
    }
  };

  const getPackageInformation = async (fileId) => {
    let data = await packagesServices.getPackageInformation(fileId);
    setPackageInfo(data?.data);
  };

  const ProfileRef = useRef(null);
  const [profileActive, setProfileActive] = useState(false);
  const toggleProfile = () => {
    setProfileActive(!profileActive);
  };
  useEffect(() => {
    const handleClickProfileMenuOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setProfileActive(false);
      }
    };
    document.addEventListener("click", handleClickProfileMenuOutside, true);

    return () => {
      document.removeEventListener(
        "click",
        handleClickProfileMenuOutside,
        true
      );
    };
  }, []);

  useEffect(() => {
    getPackageInformation(fileId);
  }, [fileId]);
  return (
    <>
      <div
        className={`p-0 outputFont output__section_widget ${!editorFullMode ? "" : "fullModeHeight"} ${!editorTheme ? "outputShadowEditorTheme" : "outputShadow"}`}
        style={{
          width: isFullScreen
            ? "98%"
            : width <= 1100
              ? 1500 - width + "px"
              : "400px",
        }}
      >
        <div
          className="border-bottom p-2"
          style={{ background: "#ebebeb" }}
        >
          <div className="hstack gap-2 header_tool justify-content-between">
            <p className="m-0 d-flex">
              <b>Output</b>
              {roleType === "validator" &&
                codeFileStatus === "draft" &&
                (!loader ? (
                  <i
                    className="fa solid fa-file-circle-plus mx-2"
                    onClick={() => addExampleForPackageValidation("validator")}
                    title="Add to the Validation Procedure Table"
                  ></i>
                ) : (
                  <div title="Data Loading" className="Saveloader2"></div>
                ))}
              {roleType === "subadmin" &&
                (!loader ? (
                  <i
                    className="fa solid fa-file-circle-plus mx-2"
                    onClick={() => setAddOutputExample(true)}
                    title="Add to the Validation Procedure Table"
                  ></i>
                ) : (
                  <div title="Data Loading" className="Saveloader2"></div>
                ))}
            </p>
            <div className="hstack gap-1 OutputToolbar-Left">
              <i
                className="fa solid fa-plus"
                onClick={fontIncrease}
                title="Increase Font"
              ></i>

              <div className="vr"></div>
              <i
                className="fa solid fa-minus"
                onClick={fontDecrease}
                title="Decrease Font"
              ></i>
              {roleType === "subadmin" && (
                <>
                  <div className="vr"></div>
                  <i
                    className="fa solid fa-eye"
                    onClick={() => getPackageExampleForConsultant()}
                    title="View Output"
                  ></i>
                </>
              )}
              <div className="vr"></div>
              <div
                ref={ProfileRef}
                className={`profile position-relative ${
                  profileActive ? "active" : ""
                }`}
              >
                <div className="icon_wrap hstack gap-1" onClick={toggleProfile}>
                  <i
                    className={`fa-solid fa-download ${profileActive ? "activee__icon" : ""}`}
                    title="Download Log"
                  ></i>
                </div>
                <div className="profile_dd shadow" style={{right: 0}}>
                  <ul className="profile_ul">
                    <li>
                      <NavLink
                        onClick={() => {
                          toggleProfile();
                        }}
                        title="Download Log as an HTML file"
                      >
                        <i className="fa-solid fa-file-code"></i> HTML
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          toggleProfile();
                        }}
                        title="Download Log as an TEXT file"
                      >
                        <i className="fa-solid fa-file-lines"></i> TEXT
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="vr"></div>
              <i
                className={
                  isFullScreen ? "fa-solid fa-minimize" : "fa-solid fa-maximize"
                }
                title={isFullScreen ? "Minimized Mode" : "Full Mode"}
                onClick={() => setIsFullScreen(!isFullScreen)}
              ></i>
            </div>
          </div>
        </div>
        <div
          className={`px-2 pt-2 ${!editorFullMode ? "output__section_widget_height" : "output__section_widget_full_height"}`}
        >
          <div style={{ fontSize: fontSize }}>
            {codeOutputs ? (
              type === "output" ? (
                <>
                  {roleType === "consultant" ? (
                    <ProjectInformationConsole packageInfo={packageInfo} />
                  ) : (
                    <PackageInformationConsole packageInfo={packageInfo} />
                  )}
                  {functionName === "MAKE TTEST" ? (
                    <MAKETTESTOutput
                      codeOutputs={codeOutputs}
                      functionName={functionName}
                    />
                  ) : functionName === "MAKE RANDOM" ||
                    functionName === "MAKE SORT" ||
                    functionName === "MAKE SWITCH" ||
                    functionName === "MAKE MERGE" ? (
                    <DynamicTableRowColumn
                      codeOutputs={codeOutputs}
                      functionName={functionName}
                    />
                  ) : functionName === "MAKE SUMMARY" ? (
                    <MAKESUMMARYOutput
                      codeOutputs={codeOutputs}
                      functionName={functionName}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <SyntaxHighlighter style={docco}>
                  {codeOutputs}
                </SyntaxHighlighter>
              )
            ) : (
              <div className="text-center">No Output</div>
            )}
          </div>
        </div>
      </div>
      <AddOutputForConsultantExample
        show={addOutputExample}
        setShow={setAddOutputExample}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        addExampleForPackageValidation={addExampleForPackageValidation}
        title={"Add Output Example"}
        submit={"Submit"}
      />
      <ExampleForOutputByAdminValidator
        show={viewAllOutputExample}
        setShow={setViewAllOutputExample}
        onStart={onStart}
        draggleRef={draggleRef}
        bounds={bounds}
        onMouseOver={() => setDisabled(false)}
        onMouseOut={() => setDisabled(true)}
        disabled={disabled}
        exampleOutputList={exampleOutputList}
        getPackageExampleForConsultant={getPackageExampleForConsultant}
        title={"View Output Example"}
      />
    </>
  );
};

export default OutputConsole;
